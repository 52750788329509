import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import './styles.css';

export interface INotificationProps {
    uiStore?: UiStore;
}

@inject('uiStore')
@observer
class Notification extends React.Component<INotificationProps, {}> {

    constructor(props: INotificationProps) {
        super(props);
        this.handleClose = this.handleClose.bind(this);
    }

    private handleClose() {
        this.props.uiStore!.notificationUiState.close();
    }

    public render() {
        const { notificationUiState } = this.props.uiStore!;
        return (
            <div>
            <Snackbar
                className={`notification ${notificationUiState.type}`}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={notificationUiState.notificationActive}
                onClose={this.handleClose}
                message={<span>{notificationUiState.notification}</span>}
                action={[
                <IconButton
                    key="close"
                    color="inherit"
                    onClick={this.handleClose}
                >
                    <CloseIcon />
                </IconButton>,
                ]}
            />
            </div>
        );
    }
}

export default Notification;
