import { observable } from 'mobx';
import { OrderStatus, TimeslotType, TruckType, Order, DeliveryType } from './order';
import { DateTime } from 'luxon';
import { IFreightOnlyResponse } from 'src/services/sales-api';
import { toDate } from '../datetime';
import { DeliveryDate } from './delivery-date';
import { Address } from './address';
import { Validate } from '../validate';

export class FreightOnly extends DeliveryDate {
    @observable public deliveryEndTime: string = '';
    @observable public deliveryStartTime: string = '';
    @observable public fromAddress: Address = new Address({});
    @observable public fromExWorksId?: number;
    @observable public isCrane: boolean = false;
    @observable public toAddress: Address = new Address({});
    @observable public timeslot: TimeslotType = TimeslotType.AnyTime;
    @observable public truckType: TruckType = TruckType.Standard;
    @observable public instruction: string = '';
    @observable public subcontract: boolean = false;

    constructor(order: Order) {
        super(order);
    }

    public static fromResponse(r: IFreightOnlyResponse, order: Order): FreightOnly {
        const fo = new FreightOnly(order);
        if (!r) return fo;
        fo.deliveryEndTime = r.deliveryEndTime;
        fo.deliveryStartTime = r.deliveryStartTime;
        fo.fromAddress = Address.fromResponse(r.fromAddress);
        fo.toAddress = Address.fromResponse(r.toAddress);
        fo.date = !r.date ? null : toDate(r.date);
        fo.dateTba = r.dateTba;
        fo.isCrane = r.isCrane;
        fo.timeslot = r.timeslot;
        fo.truckType = r.truckType;
        fo.instruction = r.instruction || '';
        fo.subcontract = r.subcontract;
        return fo;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "deliveryEndTime": (val: string) => {
            if (!Validate.hasValue(this.deliveryStartTime) && !Validate.hasValue(val)) {
                this.errors.deliveryEndTime = null;
                return true;
            }

            const endTime = Validate.getTime(val);
            const startTime = Validate.getTime(this.deliveryStartTime);

            if (isNaN(endTime)) {
                this.errors.deliveryEndTime = "not a valid 24hr time (eg. 9:00)";
                return false;    
            }
            
            if (isNaN(startTime)) {
                this.errors.deliveryStartTime = "invalid start time";
                this.errors.deliveryEndTime = null;
                return true;
            }

            if ((endTime - startTime) < 60) {
                this.errors.deliveryEndTime = "minimum delivery window is 1 hr"
            } else {
                this.errors.deliveryEndTime = null;
            }

            return this.errors.deliveryEndTime === null;
        },
        "deliveryStartTime": (val: string) => {
            if (!Validate.hasValue(val) && !Validate.hasValue(this.deliveryEndTime)) {
                this.errors.deliveryStartTime = null;
                return true;
            }

            const endTime = Validate.getTime(this.deliveryEndTime);
            const startTime = Validate.getTime(val);

            if (isNaN(startTime)) {
                this.errors.deliveryStartTime = "not a valid 24hr time (eg. 7:00)";
                return false;
            }

            if (isNaN(endTime)) {
                this.errors.deliveryEndTime = "invalid end time";
                this.errors.deliveryStartTime = null;
                return true;
            }

            if ((endTime - startTime) < 60) {
                this.errors.deliveryStartTime = "minimum delivery window is 1 hr"
            } else {
                this.errors.deliveryStartTime = null;
            }

            return this.errors.deliveryStartTime === null;
        },
        "toAddress": (val: string) => {
            if (this.order.deliveryType !== DeliveryType.FreightOnly) return true;
            return this.toAddress.validate();
        },
        "fromAddress": (val: string) => {
            if (this.order.deliveryType !== DeliveryType.FreightOnly) return true;
            return this.fromAddress.validate();
        },
        "date": (val: DateTime) => {
            if (this.order.deliveryType !== DeliveryType.FreightOnly || this.order.status !== OrderStatus.Order) {
                this.errors.date = null;
                return true;
            }
            super.validateDate(val);
            return this.errors.date === null;
        },
    }
}