import * as React from 'react';
import { useEffect, useState } from "react";

export const LoadWhenVisible: React.FunctionComponent<any> = (props) => {
  const ref = React.useRef(null);
  const isVisible = useOnScreen(ref);

  return <div ref={ref}>{isVisible && props.children}</div>;
};

export function useOnScreen(ref: React.MutableRefObject<any>) {
  const [isIntersecting, setIntersecting] = useState(false);

  const observer = new IntersectionObserver(([entry]) => {
    // stop listening when it becomes visible (we once load stuff and then stop paying attention to visibility)
    if(entry.isIntersecting) {
        observer.disconnect();
    }
    setIntersecting(entry.isIntersecting);
  });

  useEffect(() => {
    observer.observe(ref.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return isIntersecting;
}
