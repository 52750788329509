
export const scrollToError = () => {
    const labels = Array.from(document.querySelectorAll("p"));
    labels.some(label => {
        if (!label.className.split(' ').some(c => /MuiFormHelperText-error.*/.test(c))) return false;
        const bodyRect = document.body.getBoundingClientRect();
        const elemRect = label.getBoundingClientRect();
        window.scrollTo({ top: (elemRect.top - bodyRect.top) - 150, left: 0, behavior: 'smooth' });
        return true;
    });
}

export const scrollToTop = () => {
    window.scrollTo(0, 0);
}