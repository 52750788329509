import * as React from 'react';
import { Grid } from '@material-ui/core';
import { IExWorksSummary } from '../../models/exworks-summary';
import { RouteComponentProps } from 'react-router';
import { Validate } from 'src/validate';

interface IExWorksProps {
    exWorkses: IExWorksSummary[];
}

type Props = IExWorksProps & RouteComponentProps<{}>;

const ExWorks: React.SFC<Props> = (props) => {
    return <div>
        {
            props.exWorkses.map(s => {
                return <Grid container className="item" key={s.id}
                    onClick={() => { props.history.push(`${props.location.pathname}/exworks/${s.id}`); }}>
                    <Grid item xs={1} />
                    <Grid item xs={11} className="name">{`${s.code} - ${s.name}`}</Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11} className={"address" + (Validate.hasValue(s.address) ? "" : " error")}><span>{s.address || "ADDRESS NOT SPECIFIED" }</span></Grid>
                </Grid>
            })
        }
    </div>
};

export default ExWorks;