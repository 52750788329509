import './styles.css';

import * as React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { FormControl, InputLabel, Select, WithStyles, withStyles, Grid, MenuItem, Button } from '@material-ui/core';
import { observable, action } from 'mobx';
import { observer, inject } from 'mobx-react';

import { Header } from '../Header/Header';
import { RouteComponentProps } from 'react-router';
import { ProductsStore } from 'src/stores/products-store';
import { SuppliersStore } from 'src/stores/suppliers-store';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { ISupplierSummary } from 'src/models/supplier-summary';
import { globalStyles } from 'src/theme';
import { IRegionSummary } from 'src/models/region-summary';
import PriceList from './PriceList';
import tracker from '../Loader/loader-tracker';
import { Loader } from '../Loader/Loader';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface IPriceListIndexProps extends WithStyles<typeof globalStyles> {
  productsStore?: ProductsStore;
  suppliersStore?: SuppliersStore;
}

type Props = IPriceListIndexProps & RouteComponentProps<{}>;

@inject('productsStore', 'suppliersStore')
@observer
class PriceListIndex extends React.Component<Props, {}> {
  private static readonly noRegion = { label: '', value: { hasPricing: false, id: 0, name: '' } };

  @observable private selectedExWorksId: number;
  @observable private selectedRegion: SuggestionItem<IRegionSummary>;
  @observable private selectedSupplier: SuggestionItem<ISupplierSummary>;

  constructor(props: Props) {
    super(props);
    this.getRegionSuggestions = this.getRegionSuggestions.bind(this);
    this.getSupplierSuggestions = this.getSupplierSuggestions.bind(this);
    this.handleExWorksChanged = this.handleExWorksChanged.bind(this);
    this.handleRegionSelected = this.handleRegionSelected.bind(this);
    this.handleSupplierSelected = this.handleSupplierSelected.bind(this);
    this.initForm();
  }

  @action
  private initForm() {
    this.selectedExWorksId = 0;
    this.selectedRegion = PriceListIndex.noRegion;
    this.selectedSupplier = { label: '', value: { code: '', id: 0, name: '' } };
    this.props.productsStore!.clearPriceList();
  }

  public componentWillUnmount() {
    this.props.productsStore!.clearPriceList();
  }

  public render() {
    return (
      <React.Fragment>
        <Header title="Price List" />
        <Grid className="price-list" container>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <section className="filters">
              <Autocomplete
                label="Supplier"
                onSelect={this.handleSupplierSelected}
                getSuggestions={this.getSupplierSuggestions}
                placeholder={'Search for a supplier'}
                selectedItem={this.selectedSupplier}
                autoFocus
              />
              <FormControl className={this.props.classes.textField}>
                <InputLabel htmlFor="exWorks">Ex Works</InputLabel>
                <Select
                  disabled={this.selectedSupplier.label === ''}
                  disableUnderline
                  value={this.selectedExWorksId}
                  onChange={this.handleExWorksChanged}
                  inputProps={{ name: 'exWorks', id: 'exWorks' }}
                >
                  {[
                    <MenuItem value={0} key={0}>
                      Select an Exworks
                    </MenuItem>,
                  ].concat(
                    this.props.suppliersStore!.supplier.exWorkses.map((x) => (
                      <MenuItem value={x.id} key={x.id}>
                        {x.name}
                      </MenuItem>
                    )),
                  )}
                </Select>
              </FormControl>
              <Autocomplete
                label="Region"
                onSelect={this.handleRegionSelected}
                getSuggestions={this.getRegionSuggestions}
                selectedItem={this.selectedRegion}
                disabled={this.selectedExWorksId === 0}
                placeholder={'Search for a region'}
              />
              {this.props.productsStore!.priceList.pricing.length !== 0 && (
                <Button variant="outlined" onClick={() => this.initForm()}>
                  Clear search
                </Button>
              )}
            </section>
            <section className="prices">
              <Loader area="price-list" />
              <PriceList />
            </section>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  private getRegionSuggestions(value: string): Promise<Array<SuggestionItem<IRegionSummary>>> {
    const suppliersStore = this.props.suppliersStore!;
    return new Promise<Array<SuggestionItem<IRegionSummary>>>((resolve) =>
      resolve(
        suppliersStore.exWorksRegions
          .filter((s) => s.name.toUpperCase().indexOf(value.toLocaleUpperCase()) > -1)
          .map((r) => {
            return {
              label: r.name,
              value: {
                hasPricing: r.hasPricing,
                id: r.id,
                name: r.name,
              },
            } as SuggestionItem<IRegionSummary>;
          }),
      ),
    );
  }

  private getSupplierSuggestions(value: string): Promise<Array<SuggestionItem<ISupplierSummary>>> {
    return this.props.suppliersStore!.searchSuppliers(value);
  }

  @action
  private handleExWorksChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    this.selectedExWorksId = Number(e.target.value);
    if (!this.selectedExWorksId) return;

    this.selectedRegion = PriceListIndex.noRegion;
    this.props.productsStore!.clearPriceList();
    this.props.suppliersStore!.getExWorksRegions(Number(e.target.value));
  }

  @action
  private async handleRegionSelected(selected: SuggestionItem<IRegionSummary>) {
    this.selectedRegion = selected;

    this.props.productsStore!.clearPriceList();
    await tracker.track(
      this.props.productsStore!.getExWorksPricing(this.selectedExWorksId, this.selectedRegion.value.id),
      'price-list',
    );
  }

  @action
  private async handleSupplierSelected(selected: SuggestionItem<ISupplierSummary>) {
    this.selectedSupplier = selected;
    this.selectedExWorksId = 0;
    this.selectedRegion = PriceListIndex.noRegion;

    this.props.productsStore!.clearPriceList();
    await this.props.suppliersStore!.load(selected.value.id);
  }
}

export default withStyles(globalStyles)(withAITracking(reactPlugin, PriceListIndex, 'PriceListIndex'));
