import { observable, action, runInAction } from 'mobx';
import { ISalesRep, CustomersApi } from '../services/customers-api';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete';
import { CustomerSearch } from '../models/customer-search';
import { Customer } from '../models/customer';
import { SalesRep } from '../models/sales-rep';
import { NotificationUiState } from './ui-state/notification-ui-state';

export interface ICustomerSummary {
    id: number;
    isCashSaleAccount: boolean;
    isOnlineStore: boolean;
    isAbcFrieghtJobsAccount: boolean;
    email: string;
    isInDispute: boolean;
    maxOrderAmount: number;
    phone: string;
}

export interface IHaveName {
    name: string;
}

export interface IOwnerName extends IHaveName {
    repCode: string;
}

export interface ICustomerWithTiersSummary {
    id: number;
    isCashSaleAccount: boolean;
    nonDefaultTierIds: number[];
    defaultTierId: number;
    isInDispute: boolean;
}

export class CustomersStore {
    @observable public customer: Customer = new Customer();
    @observable public customerSearch: CustomerSearch = new CustomerSearch();
    @observable public customerSuggestions: Array<SuggestionItem<ICustomerSummary>> = [];
    @observable public siteContactSuggestions: Array<SuggestionItem<IHaveName>> = [];
    @observable public ownerSuggestions: Array<SuggestionItem<IOwnerName>> = [];
    @observable public customerSuggestionsWithTiers: Array<SuggestionItem<ICustomerWithTiersSummary>> = [];
    @observable public salesReps: SalesRep[] = [];
    @observable public selectedSalesRep: SalesRep = new SalesRep();

    constructor(private customersApi: CustomersApi) { }

    @action
    public async load(id: string): Promise<Customer> {
        const customerResponse = await this.customersApi.get(id);
        runInAction(() => {
            this.customer = Customer.fromResponse(customerResponse);
            this.customer.track();
        });
        return this.customer;
    }

    @action
    public async saveCustomer(): Promise<boolean> {
        const ok = await this.customersApi.saveCustomer(this.customer);
        this.customer.untrack();
        return ok;
    }

    @action
    public async searchCustomers(query: string, includeDeleted: boolean): Promise<Array<SuggestionItem<ICustomerSummary>>> {
        const customers = !includeDeleted
            ? await this.customersApi.searchCustomers(query)
            : await this.customersApi.searchAllCustomers(query);

        runInAction(() => {
            this.customerSuggestions = customers.map(c => {
                return {
                    label: c.name,
                    value: {
                        code: c.code.value,
                        id: c.id,
                        isCashSaleAccount: c.code.isCashSaleAccount,
                        isOnlineStore: c.code.isOnlineStore,
                        isAbcFrieghtJobsAccount: c.code.isAbcFreightJobsAccount,
                        email: c.email,
                        isInDispute: c.isInDispute,
                        maxOrderAmount: c.maxOrderAmount,
                        phone: c.phoneNumber
                    }
                } as SuggestionItem<ICustomerSummary>;
            })
        });
        return this.customerSuggestions;
    }

    @action 
    public async searchSiteContacts(query: string): Promise<Array<SuggestionItem<IHaveName>>> {
        const contacts = await this.customersApi.searchSiteContacts(query);
        runInAction(() => {
            this.siteContactSuggestions = contacts.map(c => {
                return {
                    label: c.name,
                    value: { name: c.name }
                } as SuggestionItem<IHaveName>;
            })
        });
        return this.siteContactSuggestions;
    }

    @action 
    public async searchOwners(query: string): Promise<Array<SuggestionItem<IOwnerName>>> {
        const owners = await this.customersApi.searchOwners(query);
        console.log(owners);
        runInAction(() => {
            this.ownerSuggestions = owners.map(c => {
                return {
                    label: c.name,
                    value: { name: c.name, repCode: c.repCode },
                } as SuggestionItem<IOwnerName>;
            })
        });
        return this.ownerSuggestions;
    }

    @action
    public async searchCustomersWithTiers(query: string): Promise<Array<SuggestionItem<ICustomerWithTiersSummary>>> {
        const customers = await this.customersApi.searchCustomersWithTiers(query);
        runInAction(() => {
            this.customerSuggestionsWithTiers = customers.map(c => {
                return {
                    label: c.name,
                    value: {
                        code: c.code.value,
                        id: c.id,
                        isCashSaleAccount: c.code.isCashSaleAccount,
                        nonDefaultTierIds: c.nonDefaultTierIds,
                        defaultTierId: c.defaultTierId,
                        isInDispute: c.isInDispute
                    }
                } as SuggestionItem<ICustomerWithTiersSummary>;
            })
        });
        return this.customerSuggestionsWithTiers;
    }

    @action
    public async getSalesReps(): Promise<ISalesRep[]> {
        const salesReps = await this.customersApi.getSalesReps();
        runInAction(() => {
            this.salesReps = salesReps;
        });

        return this.salesReps;
    }

    @action
    public async updateSalesRep(notificationUiState: NotificationUiState): Promise<boolean> {
        try {
            const ok = await this.customersApi.updateSalesRep(this.selectedSalesRep);
            if (ok) {
                this.selectedSalesRep.untrack();
                notificationUiState.showInfo('Sales rep has been successfully updated.');
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.error(e);
            notificationUiState.showError("Error saving sales rep - either not found or couldn't save.");
            return false;
        }
    }

    public async downloadTierPricingCsv(notificationUiState: NotificationUiState): Promise<Blob | null>  {
        try {
            notificationUiState.showInfo(`Generating CSV. This may take a moment...`);
            return await this.customersApi.downloadTierPricingCsv();
        }
        catch (e) {
            console.error(e);
            notificationUiState.showError(`Error downloading CSV`);
            return null;
        }
    }
}
