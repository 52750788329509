import { ApplicationInsights, ITelemetryPlugin } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

const connectionString = window.config.connectionString;

// NOTE : We can only use Application Insights Web v2.5 with the React plugin v3.0 as long as we are using React 16
// https://github.com/microsoft/applicationinsights-react-js#compatibility-matrix
const reactPlugin = new ReactPlugin();
const appInsights = new ApplicationInsights({
  config: {
    connectionString,
    extensions: [reactPlugin as unknown as ITelemetryPlugin],
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    enableRequestHeaderTracking: true,
    enableResponseHeaderTracking: true,
    correlationHeaderExcludedDomains: ['*.queue.core.windows.net'],
  },
});

function initialise(ai: ApplicationInsights): void {
  if (process.env.NODE_ENV === 'test') return;

  if (!connectionString) {
    // eslint-disable-next-line no-console
    console.warn('Missing Azure Connection String from the appsettings.js file.');
    return;
  }

  ai.loadAppInsights();
}

initialise(appInsights);

export { appInsights, reactPlugin };
