import { Model } from './model';
import { observable, action, runInAction } from 'mobx';
import { DateTime } from 'luxon';
import { DeliveryDate } from './delivery-date';

export class OrderSplit extends Model {

    @observable public deliveryDate: DateTime | null | undefined;
    @observable public deliveryDateTba: boolean;
    @observable public parentDeliveryDate: DateTime | null | undefined;
    @observable public parentDeliveryDateTba: boolean;

    constructor() {
        super();
        runInAction(() => { 
            this.deliveryDate = null;
            this.deliveryDateTba = false;
            this.parentDeliveryDate = null;
            this.parentDeliveryDateTba = false;
        });
    }

    @action 
    public setDeliveryDate(deliveryDate: DateTime) {
        this.updateAndValidate('deliveryDate', deliveryDate);
    }

    @action 
    public setDeliveryDateTba(isTba: boolean) {
        this.deliveryDateTba = isTba;
        if (isTba) this.updateAndValidate('deliveryDate', null);
    }

    @action 
    public setParentDeliveryDate(parentDeliveryDate: DateTime) {
        this.updateAndValidate('parentDeliveryDate', parentDeliveryDate);
    }

    @action 
    public setParentDeliveryDateTba(isParentTba: boolean) {
        this.parentDeliveryDateTba = isParentTba;
        if (isParentTba) {
            this.updateAndValidate('parentDeliveryDate', null);
        }
    }

    @action
    public updateParentOrderDelivery(parentOrderDelivery: DeliveryDate) {
        if(parentOrderDelivery) {
            if(parentOrderDelivery.date) {
                this.setParentDeliveryDate(parentOrderDelivery.date);
            }
            if(parentOrderDelivery.dateTba) {
                this.setParentDeliveryDateTba(parentOrderDelivery.dateTba);
            }
        }
    }
    
    protected validators: {[name: string]: (val: any) => boolean} = {
        "deliveryDate": (val: DateTime) => {
            this.errors.deliveryDate = val || this.deliveryDateTba
                ? null : "Provide a delivery date or confirm date is TBA"
            return this.errors.deliveryDate === null;
        },
        "parentDeliveryDate": (val: DateTime) => {
            this.errors.parentDeliveryDate = val || this.parentDeliveryDateTba
                ? null : "Provide a delivery date or confirm date is TBA"
            return this.errors.parentDeliveryDate === null;
        }
    }
}