import { observable, action } from "mobx";
import { Tracker } from './tracker';

class LoaderTracker {
    @observable private areas: { [area: string] : Tracker } = {};

    @action
    public track(promise: Promise<any>, area: string = "main") {
        this.areas[area] = this.areas[area] || new Tracker();
        return this.areas[area].track(promise);
    }

    public complete(area: string = "main"): boolean {
        if (!this.areas[area]) return true;
        return this.areas[area].complete;
    }

    @action
    public deleteTracker(key: string) {
        delete this.areas[key];
    }
}

const tracker = new LoaderTracker();
export default tracker;