import * as React from 'react';
import { Prompt } from 'react-router';
import { observer } from 'mobx-react';
import changeTracker from './change-tracker';

export const withChangesPrompt = <P extends object>(Component: React.ComponentType<P>) => {
    @observer
    class WithPrompt extends React.Component<P> {
        public render() {
            return <React.Fragment>
                <Prompt
                    when={changeTracker.isDirty}
                    message="You have unsaved changes. Are you sure you want to leave?"
                />
                <Component {...this.props as P} />
            </React.Fragment>
        }

        public componentWillMount() {
            window.onbeforeunload = () => {
                return (changeTracker.isDirty) 
                    ? true
                    : void (0);
            };
        }

        public componentWillUnmount() {
            window.onbeforeunload = null;
            changeTracker.reset();
        }
    }
    return WithPrompt;
};