import './styles.css';

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import Save from '@material-ui/icons/Save';
import { RouteComponentProps } from 'react-router';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { Header } from '../Header/Header';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { UiStore } from '../../stores/ui-store';
import { SuppliersStore } from 'src/stores/suppliers-store';
import ExWorksForm from './ExWorksForm';
import { ActionItem } from '../ActionsMenu/ActionItem';
import tracker from '../Loader/loader-tracker';
import { withChangesPrompt } from '../Prompt/Prompt';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface IEditExWorksProps {
  suppliersStore?: SuppliersStore;
  uiStore?: UiStore;
}
interface MatchParams {
  id: string;
}

type Props = IEditExWorksProps & RouteComponentProps<MatchParams>;

@inject('suppliersStore', 'uiStore')
@observer
class EditExWorks extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public async componentDidMount() {
    const exWorksId = this.props.match.params.id;
    await tracker.track(this.props.suppliersStore!.loadExWorks(exWorksId));
  }

  public render() {
    return (
      <div className="content">
        <Header title={'Edit Ex-works'} />

        <ExWorksForm>
          <ActionsMenu>
            <ActionItem label="Save Ex-works" onClick={this.handleSubmit}>
              <Save />
            </ActionItem>
          </ActionsMenu>
        </ExWorksForm>
      </div>
    );
  }

  private async handleSubmit(e: React.FormEvent) {
    const { history, uiStore, suppliersStore } = this.props;
    const { notificationUiState } = this.props.uiStore!;
    if (suppliersStore!.exWorks.validate()) {
      const ok = await suppliersStore!.saveExWorks();
      if (ok) {
        uiStore!.setActionsMenuActive(false);
        history.push(`/suppliers/${suppliersStore!.supplier.id}`);
      } else {
        notificationUiState.showError('Error saving ex-works...');
      }
    }
  }
}

export default withChangesPrompt(withAITracking(reactPlugin, EditExWorks, 'EditExWorks'));
