import { Model } from './model';
import { observable, action } from 'mobx';
import { Validate } from '../validate';
import { IMarkupDetail } from '../services/products-api';

export class Markup extends Model {
    private static readonly T1Key: string = "T1";
    @observable public id: number;
    @observable public tierId: number;
    @observable public tierName: string;
    @observable public percentageMarkup?: number;

    @action 
    public static fromResponse(m: IMarkupDetail): Markup {
        const markup = new Markup();
        markup.id = m.id;
        markup.percentageMarkup = m.percentageMarkup;
        markup.tierId = m.tierId;
        markup.tierName = m.tierName;

        return markup;
    }

    protected validators: {[name: string]: (val: any) => boolean} = {
        "percentageMarkup": (val?: number) => {
            this.errors.percentageMarkup = (!val && this.tierName !== Markup.T1Key) || Validate.decimal(val) && val! >= 0
                ? null : "Must be more than or equal to 0";
            return this.errors.percentageMarkup === null;
        }
    }
}
