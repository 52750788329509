import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { ProductsStore, IServiceDetail, IExWorksDetail } from '../../stores/products-store';
import { UiStore } from '../../stores/ui-store';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { observable, action } from 'mobx';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';
import { SupplierCommentLine } from 'src/models/supplier-comment-line';

export interface IAddSupplierCommentProps {
    salesStore?: SalesStore
    uiStore?: UiStore
    productsStore?: ProductsStore
}

@inject('salesStore', 'uiStore', 'productsStore')
@observer
class AddSupplierComment extends React.Component<IAddSupplierCommentProps> {

    @observable private selection: SuggestionItem<IServiceDetail>;

    constructor(props: IAddSupplierCommentProps) {
        super(props);
        this.handleSupplierSelected = this.handleSupplierSelected.bind(this);
        this.getServiceSuggestions = this.getServiceSuggestions.bind(this);
        this.clearSelection();
    }

    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        return <React.Fragment>
            {
                !orderUiState.addSupplierCommentActive
                && <div className="add-supplier-comment-button">
                    <Button
                        variant="outlined"
                        disabled={!salesStore!.order.readyForProductLines || salesStore!.order.productLines.length === 0}
                        onClick={() => orderUiState.setAddSupplierComment(true)}>
                        Add Comment <AddIcon />
                    </Button>
                </div>
            }
            {
                orderUiState.addSupplierCommentActive
                && <Autocomplete
                    label="Choose Supplier"
                    autoFocus={true}
                    onSelect={this.handleSupplierSelected}
                    selectedItem={this.selection}
                    getSuggestions={this.getServiceSuggestions}
                    suggestionToComponent={{
                        containerStyle: { minHeight: "50px", height: "auto" },
                        getComponent: (item: SuggestionItem<IExWorksDetail>) => (
                            <div>
                                <div>{item.label}</div>
                                <div className="sub-text"><small>{item.value.name}</small></div>
                            </div>
                        )
                    }}
                    onClear={() => orderUiState.setAddSupplierComment(false)}
                />
            }
        </React.Fragment>
    }

    private handleSupplierSelected(selected: SuggestionItem<IExWorksDetail>) {
        this.clearSelection();
        this.props.uiStore!.orderUiState.setAddSupplierComment(false);
        const { order } = this.props.salesStore!
        const supplierComment = new SupplierCommentLine(0, selected.value.supplierName, selected.value.name, selected.value.id > 0 ? selected.value.id : undefined, "", order);
        order.addSupplierCommentLine(supplierComment, this.props.uiStore!.notificationUiState);
    }

    private getServiceSuggestions(value: string): Promise<Array<SuggestionItem<IExWorksDetail>>> {
        const { order } = this.props.salesStore!
        return order.getSupplierNames(value);
    }

    @action
    private clearSelection() {
        this.selection = { label: '', value: null } as SuggestionItem<any>;
    }
}

export default AddSupplierComment;