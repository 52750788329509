import * as React from 'react';
import { Route, RouteProps } from 'react-router';
import { inject, observer } from 'mobx-react';

import Unauthorised from '../components/Error/Unauthorised';
import { AuthStore, UserGroup } from '../stores/auth-store';

interface IProtectedRouteProps extends RouteProps {
  authStore?: AuthStore;
  userGroups: UserGroup[];
}

export const ProtectedRoute: React.FC<IProtectedRouteProps> = inject('authStore')(
  observer((props: IProtectedRouteProps) => {
    return props.userGroups.some((g) => props.authStore!.groupHasAccess(g)) ? <Route {...props} /> : <Unauthorised />;
  }),
);
