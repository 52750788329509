import * as React from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Dialog from '@material-ui/core/Dialog';
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';

export interface IConfirmationProps {
    uiStore?: UiStore;
}


@inject('uiStore')
@observer
class Confirmation extends React.Component<IConfirmationProps, {}> {

    constructor(props: IConfirmationProps) {
        super(props);
        this.handleYes = this.handleYes.bind(this);
        this.handleNo = this.handleNo.bind(this);
    }

    private handleNo = () => {
        this.props.uiStore!.notificationUiState.close();
    };

    private handleYes = () => {
        
        this.props.uiStore!.notificationUiState.ok();
    };

  

    public render() {
        const { notificationUiState } = this.props.uiStore!;       
        return (
            <Dialog
                maxWidth="xs"
                open={notificationUiState.confirmationActive}
            >
                <DialogTitle>Service Delivery</DialogTitle>
                <DialogContent>
                    {<span>{notificationUiState.notification}</span>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={this.handleYes}>Yes</Button>
                    <Button autoFocus onClick={this.handleNo}>No</Button>                  
                </DialogActions>
            </Dialog>
        );
    }
}

export default Confirmation;
