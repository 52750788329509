import { action, observable } from 'mobx';
import { IRangePricing } from '../services/customers-api';
import { Model } from './model';

export class RangePricing extends Model {
    public rangeId: number = 0;
    public rangeName: string = '';
    public code: string = '';
    @observable public tierId: number = 0;

    @action
    public static fromResponse(src: IRangePricing): RangePricing {
        const tgt = new RangePricing();
        tgt.rangeId = src.rangeId;
        tgt.rangeName = src.rangeName;
        tgt.code = src.code;
        tgt.tierId = src.tierId;
        return tgt;
    }
}
