import * as React from 'react';
import { observer } from 'mobx-react';
import { TextField, InputAdornment } from '@material-ui/core';
import { InputProps } from '@material-ui/core/Input';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { observable, action } from 'mobx';

export interface ICurrencyChangedEvent {
    parsedValue: number;
}

interface ICurrencyFieldProps {
    name: string;
    value: number;
    disabled: boolean;
    error?: any;
    InputProps?: InputProps;
    label?: string;
    onChange(e: ICurrencyChangedEvent): void;
    className?: string;
    fullWidth?: boolean;
    autoFocus?: boolean;
    placeholder?: string;
}

@observer
class CurrencyField extends React.Component<ICurrencyFieldProps> {

    @observable private maskedValue: string;

    constructor(props: ICurrencyFieldProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.getNumberFormat = this.getNumberFormat.bind(this);
        this.setMaskedValueFromProps();
    }

    public componentDidUpdate(prevProps: ICurrencyFieldProps) {
        if (prevProps.value === this.props.value) return;
        this.setMaskedValueFromProps();
    }

    public render() {
        return <TextField
            className={this.props.className}
            InputProps={{
                disableUnderline: true,
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                inputComponent: this.getNumberFormat,
                ...this.props.InputProps
            }}
            label={this.props.label}
            name={this.props.name}
            value={this.maskedValue}
            error={!!this.props.error}
            helperText={this.props.error || null}
            fullWidth={this.props.fullWidth === null ? true : this.props.fullWidth}
            disabled={this.props.disabled}
            autoFocus={this.props.autoFocus}
            placeholder={this.props.placeholder || "0.00"}
        />
    }

    @action
    private setMaskedValue(value: string) {
        this.maskedValue = value;
    }

    private setMaskedValueFromProps() {
        this.setMaskedValue(this.props.value ? String(this.props.value) : '');
    }

    private handleChange(values: NumberFormatValues) {
        this.setMaskedValue(values.formattedValue);
        const parsedValue = Number(values.value);
        this.props.onChange({ parsedValue: isNaN(parsedValue) ? 0 : parsedValue } as ICurrencyChangedEvent);
    }

    private getNumberFormat(props: any) {
        const { inputRef, ...other } = props;
        return (
            <NumberFormat
                {...other}
                getInputRef={inputRef}
                onValueChange={this.handleChange}
                decimalScale={2}
                fixedDecimalScale
            />
        );
    }
}

export default CurrencyField;