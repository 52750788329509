import './styles.css';
import { RouteComponentProps } from 'react-router';
import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Save } from '@material-ui/icons';

import { Header } from '../Header/Header';
import { CustomersStore } from '../../stores/customers-store';
import { UiStore } from '../../stores/ui-store';
import RangePricingRow from './RangePricingRow';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import { ProductsStore } from '../../stores/products-store';
import { AuthStore, UserGroup } from '../../stores/auth-store';
import tracker from '../Loader/loader-tracker';
import { CustomerView } from '../../stores/ui-state/customer-ui-state';
import { withChangesPrompt } from '../Prompt/Prompt';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface ICustomerDetailProps {
  customersStore?: CustomersStore;
  uiStore?: UiStore;
  productsStore?: ProductsStore;
  authStore?: AuthStore;
}

type Props = ICustomerDetailProps & RouteComponentProps<MatchParams>;
interface MatchParams {
  id: string;
}

@inject('customersStore', 'uiStore', 'productsStore', 'authStore')
@observer
class CustomerDetail extends React.Component<Props> {
  private customersStore?: CustomersStore;
  private uiStore?: UiStore;
  private productsStore?: ProductsStore;
  private authStore?: AuthStore;

  constructor(props: Props) {
    super(props);
    this.customersStore = props.customersStore;
    this.uiStore = props.uiStore;
    this.authStore = props.authStore;
    this.productsStore = props.productsStore;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGetPricing = this.handleGetPricing.bind(this);
  }

  private setIsPricingReadOnly() {
    const pricingManager = this.authStore!.userGroups.find((s) => s.name === UserGroup.PricingManager);
    const adminGroup = this.authStore!.userGroups.find((s) => s.name === UserGroup.Administrator);

    const isReadOnly = !this.authStore!.hasAccess(pricingManager!.id) && !this.authStore!.hasAccess(adminGroup!.id);

    this.uiStore!.customerUiState.setIsPricingReadOnly(isReadOnly);
  }

  public async componentDidMount() {
    const id = this.props.match.params.id;
    await tracker.track(this.productsStore!.getTiers());
    await tracker.track(this.customersStore!.load(id));
    this.setIsPricingReadOnly();
  }

  public render() {
    return (
      <React.Fragment>
        <Header title={this.customersStore!.customer!.name}>
          {{
            tabs: (
              <ul>
                <li>
                  <a
                    onClick={this.handleGetPricing}
                    className={
                      this.uiStore!.customerUiState.activeCustomerView === CustomerView.Pricing ? 'active' : ''
                    }
                  >
                    Pricing
                  </a>
                </li>
              </ul>
            ),
          }}
        </Header>
        <section className="customer">
          {this.uiStore!.customerUiState.activeCustomerView === CustomerView.Pricing &&
            this.customersStore!.customer.rangePricings.map((r, i) => (
              <RangePricingRow isReadOnly={this.uiStore!.customerUiState.isPricingReadOnly} rangePricing={r} key={i} />
            ))}
        </section>
        <ActionsMenu>
          {!this.uiStore!.customerUiState.isPricingReadOnly && (
            <ActionItem label="Save Customer" onClick={this.handleSubmit}>
              <Save />
            </ActionItem>
          )}
        </ActionsMenu>
      </React.Fragment>
    );
  }

  private async handleGetPricing() {
    this.uiStore!.customerUiState.setActiveCustomerView(CustomerView.Pricing);
  }

  private async handleSubmit() {
    const { history, uiStore, customersStore } = this.props;
    const { notificationUiState } = this.props.uiStore!;
    const ok = await tracker.track(customersStore!.saveCustomer());
    if (ok) {
      uiStore!.setActionsMenuActive(false);
      history.push(`/customers`);
    } else {
      notificationUiState.showError('Error saving customer...');
    }
  }
}

export default withChangesPrompt(withAITracking(reactPlugin, CustomerDetail, 'CustomerDetail'));
