import * as React from 'react';
import { UiStore } from '../../stores/ui-store';
import { observer, inject } from 'mobx-react';
import './styles.css';
import { observable, action, runInAction } from 'mobx';
import { Link, RouteComponentProps } from 'react-router-dom';
import { UserGroup, AuthStore } from '../../stores/auth-store';
import { msalInstance, getClaimValue } from '../../utilities/msalConfig';

interface INavProps {
  uiStore?: UiStore;
  authStore?: AuthStore;
}

type Props = INavProps & RouteComponentProps<{}>;

@inject('uiStore', 'authStore')
@observer
export class Nav extends React.Component<Props> {
  private uiStore?: UiStore;
  private authStore?: AuthStore;
  private links: Array<{ name: string; route: string; userGroups?: UserGroup[] }> = [
    { name: 'Sales', route: '/' },
    { name: 'Price Lists', route: '/price-lists' },
    { name: 'Customers', route: '/customers' },
    { name: 'Ranges', route: '/ranges', userGroups: [UserGroup.PricingManager] },
    { name: 'Suppliers', route: '/suppliers', userGroups: [UserGroup.PricingManager] },
    { name: 'Locations', route: '/locations', userGroups: [UserGroup.PricingManager] },
    { name: 'User', route: '/user' }, // previously Sales Rep
    { name: 'Reports', route: '/reports', userGroups: [UserGroup.PricingManager] },
    { name: 'Unleashed Sync', route: '/attacheSync', userGroups: [UserGroup.Administrator, UserGroup.PricingManager] },
    { name: 'Settings', route: '/settings', userGroups: [UserGroup.Administrator] },
  ];

  @observable private activeRoute: string;

  constructor(props: Props) {
    super(props);
    this.uiStore = props.uiStore;
    this.authStore = props.authStore;
    this.handleClose = this.handleClose.bind(this);
    this.handleSelection = this.handleSelection.bind(this);
    runInAction(() => (this.activeRoute = this.props.location.pathname));
  }

  public render() {
    const repCode = getClaimValue('extn.repCode');
    return (
      <div className={'lg-nav ' + (this.uiStore!.navActive ? 'active' : '')} onClick={this.handleClose}>
        <div className="inner">
          <h2>Mojoe</h2>
          <ul>
            {repCode &&
              this.links
                .filter((l) => {
                  if (!l.userGroups) return true;
                  return l.userGroups.some((userGroup) => this.authStore!.groupHasAccess(userGroup));
                })
                .map((l) => {
                  return (
                    <li
                      key={l.name}
                      className={this.activeRoute === l.route ? 'active' : ''}
                      onClick={() => {
                        this.handleSelection(l.route);
                      }}
                    >
                      <Link to={l.route}>{l.name}</Link>
                    </li>
                  );
                })}
            <li onClick={() => msalInstance.logoutRedirect()}>
              <Link to="">
                Logout
                <div className="username">{this.props.authStore!.userName}</div>
              </Link>
            </li>
          </ul>
        </div>
        <div className="logo-container">
          <img src={process.env.PUBLIC_URL + '/logo.png'} alt="ABC Building Products" />
        </div>
        <span className="version-tag">v{window.config.version}</span>
      </div>
    );
  }

  public handleClose() {
    this.uiStore!.setNavActive(false);
  }

  @action
  public handleSelection(link: string) {
    this.activeRoute = link;
  }
}
