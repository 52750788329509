import { observable, runInAction, computed } from 'mobx';
import { Model } from './model';
import { ISupplierCommentResponse } from '../services/sales-api';
import { Order } from './order';

export class SupplierCommentLine extends Model {
    @observable public id: number;
    @observable public supplierName: string;
    @observable public exWorksName: string;
    @observable public exWorksId: number | undefined;
    @observable public comment: string;
    @observable private order?: Order;

    constructor(id: number, supplierName: string, exWorksName: string, exWorksId: number | undefined, comment: string, order?: Order) {
        super();
        runInAction(() => {
            this.id = id;
            this.supplierName = supplierName;
            this.exWorksId = exWorksId;
            this.exWorksName = exWorksName;
            this.comment = comment;
            this.order = order;
        })
    }

    @computed
    public get isNew(): boolean {
        return this.id === 0;
    }

    public clone(): SupplierCommentLine {
        return new SupplierCommentLine(this.id, this.supplierName, this.exWorksName, this.exWorksId, this.comment, this.order);
    }

    public static fromResponse(s: ISupplierCommentResponse, order?: Order): SupplierCommentLine {
        return new SupplierCommentLine(s.id, s.supplierName, s.exWorksName, s.exWorksId, s.comment, order);
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "comment": (val: string) => {
            this.errors.comment = val
                ? null : "Comment can not be empty";
            return this.errors.comment === null;
        }
    }
}
