import * as React from 'react'
import './styles.css'
import { withScriptjs } from 'react-google-maps'
import StandaloneSearchBox from 'react-google-maps/lib/components/places/StandaloneSearchBox'
import { compose, withProps, lifecycle } from 'recompose'
import { TextField, InputAdornment, createStyles, Theme, WithStyles, withStyles } from '@material-ui/core'
import { observer, inject } from 'mobx-react'
import { UiStore } from '../../stores/ui-store'
import Place from '@material-ui/icons/Place'
import { IconTooltip } from '../IconTooltip/IconTooltip'
import { Address } from 'src/models/address'

interface IAddressSearchProps extends WithStyles<typeof styles> {
  uiStore?: UiStore
  handleAddressSelected(address: Address): void
  handleChange(e: React.ChangeEvent<HTMLInputElement>): void
  fieldName: string
  className?: string | null
  error?: string | null
  label: string
  disabled?: boolean
  address?: string
  toggleMap?: boolean
}
interface IInnerProps {
  places: google.maps.places.PlaceResult[]
  bounds: google.maps.LatLngBounds
  onSearchBoxMounted(): void
  onPlacesChanged(): void
}

interface IState {
  mapActive: boolean
}

@inject('uiStore')
@observer
class AddressSearchBox extends React.Component<IAddressSearchProps, IState> {
  public componentDidMount() {
    this.setState({ mapActive: this.props.toggleMap ? false : true })
  }

  private Search = compose<IInnerProps, IAddressSearchProps>(
    withProps({
      googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${window.config.googleApiKey}&libraries=geometry,drawing,places`,
      loadingElement: <div style={{ height: `100%` }} />,
      containerElement: <div style={{ height: `400px` }} />
    }),
      lifecycle<IAddressSearchProps, {}, {}>({
        componentWillMount() {
            let searchBox: google.maps.places.SearchBox;
            let partialAddress: google.maps.places.PlacesService;
        this.setState({
          places: [],
            onSearchBoxMounted: (ref: google.maps.places.SearchBox, pa: google.maps.places.PlacesService) => {
                searchBox = ref;
                partialAddress = pa;
            },
            onPlacesChanged: () => {
                const findAddressPart = (part: string, placeResult: google.maps.places.PlaceResult) => {
                if (placeResult.address_components) {
                    const p = placeResult.address_components.find(c => c.types.some(t => t === part));
                    return p ? p.long_name : '';
                }
                return '';
             }

            const findSuburb = (formattedAddress: string) => {
                const parts = formattedAddress.split(',');
                if (parts && parts.length > 0) {
                  return parts[0];
                }
                return '';
              }

              const extractSuburbFromEnteredText = (enteredText: string) => {
                  const parts = enteredText.split(' ');
                  return parts.pop();
              }

              const places = searchBox.getPlaces();

              if (!places.length) {
                  const suburb = extractSuburbFromEnteredText(this.props.address!);
                  const request = {
                      query: suburb!,
                      fields: ['name', 'geometry'],
                  };
                  try {
                      partialAddress.findPlaceFromQuery(request, (results, status) => {
                          console.log(results);
                          if (status === google.maps.places.PlacesServiceStatus.OK) {
                              // extract lat lng here.
                              const guessedAddress = new Address({
                                formattedAddress: suburb
                              });
                              this.props.handleAddressSelected(guessedAddress);
                              this.props.uiStore!.addressMapUiState.setLatLng(this.props.fieldName, { lat: -27.060646073607163, lng: 153.0012612905862 });
                          } else {
                            console.log("doesn't work");
                          }
                      });
                  } catch (e) {
                      console.log(e);
                  }
                  console.log(suburb);
              } else {
                  const place = places[0];
                  const lat = place.geometry.location.lat();
                  const lng = place.geometry.location.lng();
                  this.props.uiStore!.addressMapUiState.setLatLng(this.props.fieldName, { lat, lng });

                  const result = new Address({
                      formattedAddress: place.formatted_address,
                      street: `${findAddressPart('street_number', place)} ${findAddressPart('route', place)}`.trim(),
                      suburb: findAddressPart('locality', place) === '' ? findSuburb(place.formatted_address) : findAddressPart('locality', place),
                      state: findAddressPart('administrative_area_level_1', place),
                      postcode: findAddressPart('postal_code', place),
                      latLng: { lat, lng }
                  });
                  this.props.handleAddressSelected(result);
              }
          }
        })
      }
    }),
    withScriptjs
  )((props: IInnerProps) => (
    <div>
      <StandaloneSearchBox
        ref={props.onSearchBoxMounted}
        bounds={new google.maps.LatLngBounds(new google.maps.LatLng(-43.00311, 113.6594), new google.maps.LatLng(-12.46113, 153.61194))}
        onPlacesChanged={props.onPlacesChanged}>
        <TextField
          className={`${this.props.classes.textField} ${this.props.className}`}
          InputProps={{
            disableUnderline: true,
            endAdornment: this.props.toggleMap ? (
              <InputAdornment
                className={this.props.classes.pointer}
                position='end'
                onClick={() => {
                  this.setState({ mapActive: !this.state.mapActive })
                }}>
                <IconTooltip title={`Show / hide map`}>{{ icon: <Place className='comment-status' /> }}</IconTooltip>
              </InputAdornment>
            ) : null
          }}
          label={this.props.label}
          type='text'
          name={this.props.fieldName}
          autoComplete='no'
          error={!!this.props.error}
          helperText={this.props.error}
          disabled={this.props.disabled}
          value={this.props.address}
          onChange={this.props.handleChange}
          fullWidth
        />
      </StandaloneSearchBox>
    </div>
  ))

  public render() {
    return (
      <div className='address-search'>
        <this.Search {...this.props} />
        {this.state && this.state.mapActive && this.props.children}
      </div>
    )
  }
}

const styles = (theme: Theme) =>
  createStyles({
    textField: {
      width: '100%',
      marginBottom: 20
    },
    pointer: {
      cursor: 'pointer'
    }
  })

export default withStyles(styles)(AddressSearchBox)
