import { useState, useEffect } from 'react';
import { getWithExpiry, setWithExpiry } from './localStorage';

const useHandleCache = () => {
    const [isLoading, setIsLoading] = useState(true);
    const [isLatestVersionAvailable, setIsLatestVersionAvailable] = useState(true);

    const clearCacheAndReload = () => {
        const expiry = getWithExpiry("vrsn");
        if (expiry === null) {
            if (caches) {
                caches.keys().then(function (names) {
                    for (const name of names) caches.delete(name);
                });
            }
            window.location.reload();
        }
    };

    const checkVersionMismatch = (latestVersion: string, currentVersion: string) => {
        const letestVersionArr = latestVersion.split(/\./g);
        const currentVersionArr = currentVersion.split(/\./g);

        while (letestVersionArr.length || currentVersionArr.length) {
            const a = Number(letestVersionArr.shift());
            const b = Number(currentVersionArr.shift());

            if (a === b) continue;

            return a > b || isNaN(b);
        }

        return false;
    };

    useEffect(() => {
        fetch('/meta.json')
            .then((response) => response.json())
            .then((meta) => {
                if (window.location.host.includes('local')) {
                  setIsLoading(false);
                  setIsLatestVersionAvailable(true);
                  return;
                }

                const latestVersion = meta.version;
                const currentVersion = window.config.version;

                const expiry = getWithExpiry("vrsn")
                const shouldForceRefresh = checkVersionMismatch(latestVersion, currentVersion);

                if (shouldForceRefresh) {
                    if (expiry !== null) {
                        setIsLoading(false);
                        setIsLatestVersionAvailable(true);
                    }
                    else {
                        setWithExpiry('vrsn', latestVersion, 86400 * 1000);
                        setIsLoading(false);
                        setIsLatestVersionAvailable(false);
                    }
                }
            });
    }, []);

    return { isLoading, isLatestVersionAvailable, clearCacheAndReload };
};

export default useHandleCache;
