import { observable, action, runInAction, computed } from 'mobx';

import { msalInstance } from '../utilities/msalConfig';
import { UserApi, type IUserGroupResponse } from '../services/user-api';

export class AuthStore {
  @observable public userGroups: IUserGroupResponse[] = [];

  constructor(private userApi: UserApi) {}

  @action
  public async getUserGroups(): Promise<IUserGroupResponse[]> {
    if (this.userGroups.length) return this.userGroups;
    const groups = await this.userApi.getUserGroups();
    return runInAction(() => {
      this.userGroups = groups;
      return this.userGroups;
    });
  }

  public hasAccess(groupId: string): boolean {
    const user = msalInstance.getActiveAccount();
    if (!user || !Array.isArray(user.idTokenClaims?.groups)) return false;

    return user.idTokenClaims?.groups.includes(groupId) ?? false;
  }

  public groupHasAccess(group: UserGroup) {
    const id = this.getGroupId(group);
    return this.hasAccess(id);
  }

  public getGroupId(userGroup: UserGroup | string) {
    const group = this.userGroups.find((g) => g.name === userGroup);
    if (!group) return '';
    return group.id;
  }

  public get userName(): string {
    const user = msalInstance.getActiveAccount();
    if (!user || !user?.idTokenClaims?.name) return '';
    return user.idTokenClaims.name;
  }

  @computed
  public get isAccountsReceivable() {
    return this.hasAccess(this.getGroupId(UserGroup.AccountsReceivable));
  }

  @computed
  public get isAdmin() {
    return this.hasAccess(this.getGroupId(UserGroup.Administrator));
  }

  @computed
  public get isPricingManager() {
    return this.hasAccess(this.getGroupId(UserGroup.PricingManager));
  }

  @computed
  public get isMJDispatch() {
    return this.hasAccess(this.getGroupId(UserGroup.MJDispatch));
  }
}

export enum UserGroup {
  Administrator = 'Administrator',
  SalesManager = 'SalesManager',
  PricingManager = 'PricingManager',
  AccountsReceivable = 'AccountsReceivable',
  MJDispatch = 'MJDispatch',
}
