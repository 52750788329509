import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import { Grid } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import Search from '@material-ui/icons/Search';

export interface IHeaderProps {
    title: string;
    hideTitleForDesktop?: boolean;
    uiStore?: UiStore;
    children?: {
        tabs: React.ReactElement<any>
    };
    onClickSearch?(): void
}

@inject('uiStore')
@observer
export class Header extends React.Component<IHeaderProps, {}> {
    constructor(props: IHeaderProps) {
        super(props);
        this.handleNavToggle = this.handleNavToggle.bind(this);
    }

    public render() {
        const { onClickSearch, children, hideTitleForDesktop, title } = this.props;
        return <React.Fragment>
            <Grid container className={"header " + (hideTitleForDesktop ? "hide-lg" : "")}>
                <Grid item xs={1} className="center">
                    <MenuIcon onClick={this.handleNavToggle} className="menu-icon action" />
                </Grid>
                <Grid item xs={10}>
                    <h2>{title}</h2>
                </Grid>
                <Grid item xs={1} className="optional-icon">
                    {
                        onClickSearch
                        && <Search onClick={onClickSearch} className="action" />
                    }
                </Grid>
            </Grid>
            {
                children
                && <Grid container className={"tab-nav " + (children && hideTitleForDesktop ? "expand-lg" : "")}>
                    <Grid item xs={1} />
                    <Grid item xs={8}>
                        {children.tabs}
                    </Grid>
                    <Grid item xs={1} className="search-lg">
                        {
                            onClickSearch
                            && <Search onClick={onClickSearch} className="action" />
                        }
                    </Grid>
                </Grid>
            }
        </React.Fragment>
    }

    public handleNavToggle() {
        const { uiStore } = this.props;
        uiStore!.setNavActive(true);
    }
}