import { observable, action } from 'mobx';
import { ICustomerSummary, IHaveName, IOwnerName } from '../customers-store';
import { SuggestionItem } from 'src/components/Autocomplete/Autocomplete';

export class OrderSearchUiState {
    @observable public selectedCustomerSuggestion: SuggestionItem<ICustomerSummary> | undefined;
    @observable public selectedSiteContactSuggestion: SuggestionItem<IHaveName> | undefined;
    @observable public selectedOwnerSuggestion: SuggestionItem<IOwnerName> | undefined;
    @observable public selectedAddressSuggestion: SuggestionItem<string> | undefined;
    @observable public ownerSearchText: string;
    @observable public customerSearchText: string;
    @observable public addressSearchText: string;
    @observable public siteContactSearchText: string;
    @observable public filtersActive: boolean;

    @action
    public setCustomerSuggestion(suggestion: SuggestionItem<ICustomerSummary> | undefined) {
        this.selectedCustomerSuggestion = suggestion;
    }

    @action
    public setSiteContactSuggestion(suggestion: SuggestionItem<IHaveName> | undefined) {
        this.selectedSiteContactSuggestion = suggestion;
    }

    @action
    public setOwnerSuggestion(suggestion: SuggestionItem<IOwnerName> | undefined) {
        this.selectedOwnerSuggestion = suggestion;
    }

    @action
    public setAddressSuggestion(suggestion: SuggestionItem<string> | undefined) {
        this.selectedAddressSuggestion = suggestion;
    }

    @action 
    public toggleFiltersActive() {
        this.filtersActive = !this.filtersActive;
    }

    @action
    public setOwnerSearchText(search: string) {
        this.ownerSearchText = search;
    }

    @action
    public setCustomerSearchText(search: string) {
        this.customerSearchText = search;
    }

    @action
    public setAddressSearchText(search: string) {
        this.addressSearchText = search;
    }

    @action
    public setSiteContactSearchText(search: string) {
        this.siteContactSearchText = search;
    }
}