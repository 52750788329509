import React, { useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { MsalAuthenticationTemplate, useIsAuthenticated, useMsal } from '@azure/msal-react';
import { EventMessage, EventType, InteractionStatus, InteractionType } from '@azure/msal-browser';

import { Nav } from './components/Nav/Nav';
import { UiStore } from './stores/ui-store';
import Notification from './components/Notification/Notification';
import Confirmation from './components/Confirmation/Confirmation';
import { Loader } from './components/Loader/Loader';
import { AuthStore } from './stores/auth-store';
import { Environment } from './components/Environment/Environment';
import { MojoeRoutes } from './routes/MojoeRoutes';
import { reactPlugin } from './utilities/appInsightsConfig';
import { loginRequest } from './utilities/msalConfig';
import { MsalError } from './components/Error/MsalError';
import useHandleCache from './utilities/useHandleCache';

export interface IAppProps {
  uiStore?: UiStore;
  authStore?: AuthStore;
}

export const App = inject(
  'uiStore',
  'authStore',
)(
  observer(({ uiStore, authStore }: IAppProps) => {
    const history = useHistory();
    const match = useRouteMatch();
    const location = useLocation();
    const { instance, inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    const { isLatestVersionAvailable, clearCacheAndReload } = useHandleCache();

    // Sets the active Microsoft account
    useEffect(() => {
      const activeAccount = instance.getActiveAccount();
      const accounts = instance.getAllAccounts();

      if (isAuthenticated && accounts.length > 0 && !activeAccount) {
        instance.setActiveAccount(accounts[0]);
      }
    }, [isAuthenticated, instance]);

    useEffect(() => {
      if (isAuthenticated && inProgress === InteractionStatus.None) authStore?.getUserGroups().catch(console.log);
    }, [authStore, isAuthenticated, inProgress]);

    useEffect(() => {
      const cb = instance.addEventCallback((event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS) {
          window.location.reload();
          return;
        }
      });

      return () => {
        if (cb) instance.removeEventCallback(cb);
      };
    }, [instance]);

    return (
      isLatestVersionAvailable
        ? (
          <MsalAuthenticationTemplate
            interactionType={InteractionType.Redirect}
            authenticationRequest={loginRequest}
            errorComponent={MsalError}
          >
            <Environment />
            <Nav history={history} location={location} match={match} uiStore={uiStore} authStore={authStore} />
            <Loader />
            <AppInsightsContext.Provider value={reactPlugin}>
              <div
                data-testid="main-container"
                className={'main-container ' + (uiStore?.actionsMenuActive ? 'actions-menu-active' : '')}
              >
                <MojoeRoutes />
              </div>
            </AppInsightsContext.Provider>
            <Notification />
            <Confirmation />
          </MsalAuthenticationTemplate>
          )
        : (
            <div>
              {clearCacheAndReload()}
            </div>
          )
    );
  }),
);
