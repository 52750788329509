import * as React from 'react';
import './styles.css';
import { Grid } from '@material-ui/core';
import { Header } from '../Header/Header';
import { useAppInsightsContext, useTrackMetric } from '@microsoft/applicationinsights-react-js';

const Error: React.FC = () => {
  const appInsights = useAppInsightsContext();
  useTrackMetric(appInsights, 'Error');

  return (
    <div>
      <Header title="Error" hideTitleForDesktop />
      <section className="error-section">
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <h3>Not Authorized</h3>
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={11}>
            Try contacting the administrator and make sure you have a valid Rep Code assigned against your login.
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default Error;
