import type { IOrderReportQuery } from 'src/models/reports-query';
import { apiFetch } from '../utilities/apiFetch';

export class ReportsApi {
  public async getCSV(query: IOrderReportQuery): Promise<Blob | null> {
    const response = await apiFetch('/api/reports', {
      method: 'post',
      body: JSON.stringify(query),
    });
    const blob = await response?.blob();
    return blob ?? null;
  }

  public async getPDF(query: IOrderReportQuery): Promise<Blob | null> {
    const response = await apiFetch('/api/reports/getpdfreport', {
      method: 'post',
      body: JSON.stringify(query),
    });
    const blob = await response?.blob();
    return blob ?? null;
  }
}
