import './index.css';

import 'typeface-roboto';
import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'mobx-react';
import { configure } from 'mobx';
import { MsalProvider } from '@azure/msal-react';
import MuiThemeProvider from '@material-ui/core/styles/MuiThemeProvider';
import createGenerateClassName from '@material-ui/core/styles/createGenerateClassName';
import JssProvider from 'react-jss/lib/JssProvider';

import { App } from './App';
import { initTheme } from './theme';
import { initialiseStores } from './stores/configureStore';
import { msalInstance } from './utilities/msalConfig';

// Create deterministic class names during prod build
const generateClassName = createGenerateClassName({
  dangerouslyUseGlobalCSS: true,
});

export const stores = initialiseStores();

ReactDOM.render(
  <MsalProvider instance={msalInstance}>
    <MuiThemeProvider theme={initTheme()}>
      <Provider {...stores}>
        <JssProvider generateClassName={generateClassName}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </JssProvider>
      </Provider>
    </MuiThemeProvider>
  </MsalProvider>,
  document.getElementById('root') as HTMLElement,
);

configure({
  enforceActions: 'always',
});
