import { observable, action, runInAction } from 'mobx';
import { ISupplierSummary } from '../models/supplier-summary';
import { ProductsApi, ITiersResponse, IAbcLocationResponse, IStockingPointResponse } from '../services/products-api';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete'
import { IRangeSummary } from '../models/range-summary';
import { Range } from '../models/range'
import { IPriceList } from 'src/models/price-list';
import { AbcLocation } from '../models/abc-location';

export interface IServiceDetail {
    id: number;
    price: number;
    code: string;
    purchaseUnit: string;
}

export interface IProductDetail {
    id: number;
    productName: string;
    code: string;
    purchaseUnit: string;
    noPerPurchaseUnit: number;
    unitWeight: number;
    exWorks: IExWorksDetail;
    kind: ProductSuggestionKind;
    exWorksPrice: number;
    freightCost: number;
}

export interface IExWorksDetail {
    id: number;
    name: string;
    supplierName: string;
    kind: ProductSuggestionKind;
}

export enum ProductSuggestionKind {
    exWorks,
    product
}

export type ProductSuggestion = IExWorksDetail | IProductDetail;

export class ProductsStore {
    @observable public loading: boolean = false;
    @observable public priceList: IPriceList = { exWorks: '', exWorksId: 0, headerRow: [], pricing: [], region: '', regionId: 0 };
    @observable public productSuggestions: Array<SuggestionItem<ProductSuggestion>> = [];
    @observable public range: Range = new Range();
    @observable public ranges: IRangeSummary[] = [];
    @observable public locations: IAbcLocationResponse[] = [];
    @observable public suppliers: ISupplierSummary[] = [];
    @observable public serviceSuggestions: Array<SuggestionItem<IServiceDetail>> = [];
    @observable public tiers: ITiersResponse[] = [];
    @observable public tiersMap: { [id: number]: string } = [];
    @observable public location: AbcLocation = new AbcLocation();
    @observable public stockingPoints: IStockingPointResponse[] = [];
    @observable public paymentLocations: IAbcLocationResponse[] = [];

    constructor(private productsApi: ProductsApi) { }

    @action
    public async clearPriceList() {
        this.priceList = { exWorks: '', exWorksId: 0, headerRow: [], pricing: [], region: '', regionId: 0 };
    }

    @action
    public async getExWorksPricing(exWorksId: number, regionId: number): Promise<IPriceList> {
        const priceList = await this.productsApi.getPriceList(exWorksId, regionId);
        runInAction(() => {
            this.priceList = priceList;
        });
        return this.priceList;
    }

    @action
    public async getRanges(): Promise<IRangeSummary[]> {
        const ranges = await this.productsApi.getRanges();
        runInAction(() => {
            this.ranges = ranges.sort((a: IRangeSummary, b: IRangeSummary) => {
                return a.name < b.name ? -1 : 1;
            });
        });
        return this.ranges;
    }

    @action
    public async getStockingPoints() {
        if (this.stockingPoints.length) return this.stockingPoints;
        const stockingPoints = await this.productsApi.getStockingPoints();
        runInAction(() => {
            this.stockingPoints = stockingPoints;
        });

        return this.stockingPoints;
    }

    @action
    public async getPaymentLocations() {
        if (this.paymentLocations.length) return this.paymentLocations;
        const paymentLocations = await this.productsApi.getPaymentLocations();
        runInAction(() => {
            this.paymentLocations = paymentLocations;
        });

        return this.paymentLocations;
    }

    @action
    public async getLocations(): Promise<IAbcLocationResponse[]> {
        const locations = await this.productsApi.getLocations();
        runInAction(() => {
            this.locations = locations;
        });

        return this.locations;
    }

    @action
    public async loadLocation(id: string): Promise<AbcLocation> {
        const locationReponse = await this.productsApi.loadLocation(id);
        return runInAction(() => {
            this.location = AbcLocation.fromResponse(locationReponse);
            this.location.track();
            return this.location;
        });
    }

    @action
    public async saveLocation(): Promise<boolean> {
        const ok = await this.productsApi.saveLocation(this.location);
        this.location.untrack();
        return ok;
    }

    @action
    public async getTiers(): Promise<ITiersResponse[]> {
        if (this.tiers.length) return this.tiers;
        const tiers = await this.productsApi.getTiers();
        runInAction(() => {
            this.tiers = tiers;
            this.tiersMap = tiers.reduce((map, tier) => {
                map[tier.id] = tier.name;
                return map;
            }, {});
        });
        return this.tiers;
    }

    @action
    public async getTiersForProduct(id: number): Promise<ITiersResponse[]> {
        return await this.productsApi.getTiersForProduct(id);
    }

    @action
    public async loadRange(id: string): Promise<Range> {
        const rangeResponse = await this.productsApi.loadRange(id);
        return runInAction(() => {
            this.range = Range.fromResponse(rangeResponse);
            this.range.track();
            return this.range;
        });
    }

    @action
    public async saveRange(): Promise<boolean> {
        const ok = await this.productsApi.saveRange(this.range);
        this.range.untrack();
        return ok;
    }

    @action
    public async searchServices(query: string): Promise<Array<SuggestionItem<IServiceDetail>>> {
        const services = await this.productsApi.searchServices(query);
        runInAction(() => {
            this.serviceSuggestions = services.map(s => {
                return { label: s.name, value: { id: s.id, price: s.price, code: s.code, purchaseUnit: s.purchaseUnit } }
            })
        });
        return this.serviceSuggestions;
    }

    @action
    public async searchProducts(query: string, exWorksId: number): Promise<Array<SuggestionItem<ProductSuggestion>>> {
        const result = await this.productsApi.searchProducts(query, exWorksId);
        runInAction(() => {
            this.productSuggestions = result.exWorkses.map(s => {
                return {
                    label: s.name,
                    value: {
                        id: s.id,
                        name: s.name,
                        supplierName: s.supplierName,
                        kind: ProductSuggestionKind.exWorks
                    }
                } as SuggestionItem<ProductSuggestion>
            }).concat(result.products.map(s => {
                return {
                    label: `${s.name} ${s.code}`,
                    value: {
                        id: s.id,
                        productName: s.name,
                        code: s.code,
                        purchaseUnit: s.purchaseUnit,
                        noPerPurchaseUnit: s.noPerPurchaseUnit,
                        unitWeight: s.unitWeight,
                        exWorks: {
                            id: s.exWorks.id,
                            name: s.exWorks.name,
                            supplierName: s.exWorks.supplierName,
                        },
                        kind: ProductSuggestionKind.product,
                    }
                } as SuggestionItem<ProductSuggestion>
            }));
        });
        return this.productSuggestions;
    }
}
