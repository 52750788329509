import { observable, action, runInAction, computed } from 'mobx';
import { Model } from './model';
import { WorkflowStatus } from './workflow-status';
import { WorkflowType } from './workflow-type';
import { Validate } from '../validate';
import { AuthStore } from 'src/stores/auth-store';
import { IWorkflowResponse } from 'src/services/sales-api';

export class Workflow extends Model {
    @observable public approvalGroup: string = '';
    @observable public reason: string = '';
    @observable public workflowStatus: WorkflowStatus = WorkflowStatus.None;
    @observable public workflowType: WorkflowType = WorkflowType.None;

    constructor(workflowType: WorkflowType) {
        super();
        this.workflowType = workflowType;
        runInAction(() => this.errors = {});
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "reason": (val: string) => {
            this.errors.reason = Validate.hasValue(val)
                ? null : "reason required";
            return this.errors.reason === null;
        }
    }

    @action
    public setStatus(workflowStatus: WorkflowStatus): void {
        this.workflowStatus = workflowStatus;
    }

    @action
    public setReason(reason: string): void {
        this.reason = reason;
    }

    @action
    public reset(): void {
        this.reason = '';
        this.workflowStatus = WorkflowStatus.None;
    }

    public canApprove(authStore: AuthStore): boolean {
        return computed(() => {
            return this.workflowStatus === WorkflowStatus.PendingApproval
                && authStore.hasAccess(this.approvalGroup);
        }).get();
    }

    public static fromResponse(r: IWorkflowResponse | null, type: WorkflowType): Workflow {
        const w = new Workflow(type);
        w.approvalGroup = r ? r!.approvalGroup : '';
        w.reason = r ? r!.reason : '';
        w.workflowStatus = r ? r!.workflowStatus : WorkflowStatus.None;
        return w;
    }

    @computed 
    public get workflowName(): string {
        switch (this.workflowType) {
            case WorkflowType.OrderCancellation:
                return "Deletion";
            case WorkflowType.CustomPricing:
                return "Custom Pricing";
            case WorkflowType.InDispute:
                return "Credit Approval"
            case WorkflowType.Refund:
                return "Refund"
            default:
                return "None";
        }
    }

    @computed
    public get workflowText(): string {
        switch (this.workflowType) {
            case WorkflowType.InDispute:
                return "Your order is for a customer requiring credit limit approval, it will need to be approved before it can be processed."
            default:
                return "";
        }
    }

    @computed
    public get workflowTitle(): string {
        switch (this.workflowType) {
            case WorkflowType.InDispute:
                return "Credit Limit Approval"
            default:
                return "";
        }
    }
}