import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../../stores/sales-store';
import { Grid, FormControl, InputLabel, Input, InputAdornment, IconButton, FormGroup, createStyles, Theme, TextField, WithStyles, withStyles } from '@material-ui/core';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Search from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import { RouteComponentProps } from 'react-router';
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete, { SuggestionItem } from 'src/components/Autocomplete/Autocomplete';
import { CustomersStore, ICustomerSummary, IHaveName, IOwnerName } from 'src/stores/customers-store';
import { UiStore } from 'src/stores/ui-store';
import { action, observable, runInAction } from 'mobx';
import tracker from 'src/components/Loader/loader-tracker';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { OrdersFilter } from '../../../models/orders-filter';
import { dateFormatted } from '../../../datetime';
import { globalStyles } from '../../../theme';

export interface ISearchDialogProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    customersStore?: CustomersStore
    uiStore?: UiStore
    active: boolean
    handleClose(): void
}
type Props = ISearchDialogProps & RouteComponentProps<{}>;

@inject('salesStore', 'customersStore', 'uiStore')
@observer
class SearchDialog extends React.Component<Props> {

    @observable private ordersFilter: OrdersFilter;

    constructor(props: Props) {
        super(props);
        this.handleOrderNumberChange = this.handleOrderNumberChange.bind(this);
        this.handleOrderNumberSearch = this.handleOrderNumberSearch.bind(this);
        this.handleToggleFilters = this.handleToggleFilters.bind(this);
        this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
        this.handleAddressSelected = this.handleAddressSelected.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.getCustomerSuggestions = this.getCustomerSuggestions.bind(this);
        this.getAddressSuggestions = this.getAddressSuggestions.bind(this);
        this.handleSiteContactSelected = this.handleSiteContactSelected.bind(this);
        this.getSiteContactSuggestions = this.getSiteContactSuggestions.bind(this);
        this.handleFilterSearch = this.handleFilterSearch.bind(this);
        this.handleCustomerRefChange = this.handleCustomerRefChange.bind(this);
        this.handleLeadIdentifierChange = this.handleLeadIdentifierChange.bind(this);
        this.handleCustomerSearchChange = this.handleCustomerSearchChange.bind(this);
        this.handleSiteContactSearchChanged = this.handleSiteContactSearchChanged.bind(this);
        this.handleOwnerSearchChange = this.handleOwnerSearchChange.bind(this);

        runInAction(() => this.initOrderFilter());
    }

    public componentDidMount() {
        this.initOrderFilter();
    }

    @action
    public initOrderFilter() {
        this.ordersFilter = this.props.salesStore!.ordersFilter.clone();
    }

    public render() {
        const { salesStore, uiStore } = this.props;
        return <div className={"search-dialog " + (this.props.active ? "active" : "")}>
            <Grid container className="search-header">
                <Grid item xs={1} className="arrow">
                    <ArrowBack onClick={this.props.handleClose} className="action" />
                </Grid>
                <Grid item xs={10}>
                    <h2>Search</h2>
                </Grid>
            </Grid>
            <Grid container className="dialog-section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <a className="action filters-action" onClick={this.handleToggleFilters}>{uiStore!.orderSearchUiState.filtersActive ? "Hide Filters" : "Show Filters"}</a>
                </Grid>
            </Grid>
            {
                !uiStore!.orderSearchUiState.filtersActive
                && <Grid container>
                    <Grid item xs={1} />
                    <Grid item xs={10} className="content">
                        <FormControl className={this.props.classes.textField}
                            onKeyPress={event => { if (event.key === "Enter") { this.handleOrderNumberSearch(); } }}>
                            <InputLabel htmlFor="adornment-search">Search for Order / Quote / PO Number</InputLabel>
                            <Input
                                id="adornment-search"
                                type="text"
                                value={salesStore!.orderSearch.orderOrPoNumber}
                                onChange={this.handleOrderNumberChange}
                                name="orderOrPoNumber"
                                autoFocus={true}
                                disableUnderline={true}
                                error={!!salesStore!.orderSearch.errors.orderNumber}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={this.handleOrderNumberSearch} className="action">
                                            <Search />
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            {
                                salesStore!.orderSearch.errors.orderNumber
                                && <FormHelperText error>{salesStore!.orderSearch.errors.orderNumber}</FormHelperText>
                            }
                        </FormControl>
                    </Grid>
                </Grid>
            }
            {
                uiStore!.orderSearchUiState.filtersActive
                && <Grid container className="content dialog-section">
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <Autocomplete
                            label="Customer"
                            onSelect={this.handleCustomerSelected}
                            getSuggestions={this.getCustomerSuggestions}
                            errorText={this.ordersFilter.errors.customerId}
                            selectedItem={uiStore!.orderSearchUiState.selectedCustomerSuggestion}
                            value={uiStore!.orderSearchUiState.customerSearchText}
                            onChange={this.handleCustomerSearchChange}
                            onHandleBlur={() => {
                                if (uiStore!.orderSearchUiState.selectedCustomerSuggestion &&
                                    uiStore!.orderSearchUiState.selectedCustomerSuggestion.label !== uiStore!.orderSearchUiState.customerSearchText) {
                                    this.handleCustomerSearchChange(uiStore!.orderSearchUiState.selectedCustomerSuggestion.label)
                                } else if (!uiStore!.orderSearchUiState.selectedCustomerSuggestion) {
                                    this.handleCustomerSearchChange("")
                                }
                            }}
                            onClear={() => {
                                this.ordersFilter.setCustomerId(0);
                                uiStore!.orderSearchUiState.setCustomerSearchText("");
                                uiStore!.orderSearchUiState.setCustomerSuggestion(undefined);
                            }}
                        />
                        <TextField
                            label="Customer Reference"
                            type="text"
                            value={this.ordersFilter.customerRef}
                            className={this.props.classes.textField}
                            InputProps={{
                                disableUnderline: true
                            }}
                            onChange={this.handleCustomerRefChange}
                        />
                        <TextField
                            label="Lead Identifier"
                            type="text"
                            value={this.ordersFilter.leadIdentifier}
                            className={this.props.classes.textField}
                            InputProps={{
                                disableUnderline: true
                            }}
                            onChange={this.handleLeadIdentifierChange}
                        />
                        <Autocomplete
                            label="Trading-Customer Name"
                            onSelect={this.handleSiteContactSelected}
                            getSuggestions={this.getSiteContactSuggestions}
                            errorText={this.ordersFilter.errors.siteContact}
                            selectedItem={uiStore!.orderSearchUiState.selectedSiteContactSuggestion}
                            value={uiStore!.orderSearchUiState.siteContactSearchText}
                            onChange={this.handleSiteContactSearchChanged}
                            onHandleBlur={() => {
                                if (this.ordersFilter.siteContact && this.ordersFilter.siteContact !== "" &&
                                    this.ordersFilter.siteContact !== uiStore!.orderSearchUiState.addressSearchText) {
                                    this.handleSiteContactSearchChanged(this.ordersFilter.siteContact)
                                } else if (!this.ordersFilter.siteContact || this.ordersFilter.siteContact === "") {
                                    this.handleSiteContactSearchChanged("")
                                }
                            }}
                            onClear={() => {
                                this.ordersFilter.setSiteContact("");
                                uiStore!.orderSearchUiState.setSiteContactSearchText("")
                                uiStore!.orderSearchUiState.setSiteContactSuggestion(undefined);
                            }}
                        />

                        <Autocomplete
                            label="Address"
                            onSelect={(item) => this.handleAddressSelected(item as SuggestionItem<string>)}
                            selectedItem={uiStore!.orderSearchUiState.selectedAddressSuggestion}
                            getSuggestions={this.getAddressSuggestions}
                            errorText={this.ordersFilter.errors.address}
                            value={uiStore!.orderSearchUiState.addressSearchText}
                            onChange={this.handleAddressChange}
                            onHandleBlur={() => {
                                if (this.ordersFilter.address && this.ordersFilter.address !== "" &&
                                    this.ordersFilter.address !== uiStore!.orderSearchUiState.addressSearchText) {
                                    this.handleAddressChange(this.ordersFilter.address)
                                } else if (!this.ordersFilter.address || this.ordersFilter.address === "") {
                                    this.handleAddressChange("")
                                }
                            }}
                            onClear={() => {
                                this.ordersFilter.setAddress("");
                                uiStore!.orderSearchUiState.setAddressSearchText("")
                                uiStore!.orderSearchUiState.setAddressSuggestion(undefined);
                            }}
                        />
                        <FormGroup row>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <Grid container className={this.props.classes.clearableDateContainer} alignItems="flex-end">
                                    <Grid item>
                                        <InlineDatePicker
                                            className={this.props.classes.inlineDateField}
                                            onlyCalendar
                                            label="Date from"
                                            value={this.ordersFilter.startDate}
                                            onChange={(date: DateTime) => this.ordersFilter.setStartDate(date)}
                                            labelFunc={(date: DateTime) => dateFormatted(date)}
                                            InputProps={{ disableUnderline: true }}
                                            leftArrowIcon={<KeyboardArrowLeft />}
                                            rightArrowIcon={<KeyboardArrowRight />}
                                            error={!!this.ordersFilter.errors.startDate}
                                            helperText={this.ordersFilter.errors.startDate || null}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {
                                            this.ordersFilter.startDate
                                            && <Close className="clear-date action" onClick={() => this.ordersFilter.setStartDate(null)} />
                                        }
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                            <MuiPickersUtilsProvider utils={LuxonUtils}>
                                <Grid container className={this.props.classes.clearableDateContainer} alignItems="flex-end">
                                    <Grid item>
                                        <InlineDatePicker
                                            className={this.props.classes.inlineDateField}
                                            onlyCalendar
                                            label="Date to"
                                            value={this.ordersFilter.endDate}
                                            onChange={(date: DateTime) => this.ordersFilter.setEndDate(date)}
                                            labelFunc={(date: DateTime) => dateFormatted(date)}
                                            InputProps={{ disableUnderline: true }}
                                            leftArrowIcon={<KeyboardArrowLeft />}
                                            rightArrowIcon={<KeyboardArrowRight />}
                                            error={!!this.ordersFilter.errors.endDate}
                                            helperText={this.ordersFilter.errors.endDate || null}
                                        />
                                    </Grid>
                                    <Grid item>
                                        {
                                            this.ordersFilter.endDate
                                            && <Close className="clear-date action" onClick={() => this.ordersFilter.setEndDate(null)} />
                                        }
                                    </Grid>
                                </Grid>
                            </MuiPickersUtilsProvider>
                        </FormGroup>
                        <TextField
                            label="Phone Number"
                            type="text"
                            value={this.ordersFilter.phoneNumber}
                            className={this.props.classes.textField}
                            InputProps={{
                                disableUnderline: true
                            }}
                            onChange={(e) => this.handlePhoneNumberChanged(e)}
                        />
                        <Autocomplete
                            label="Owner"
                            onSelect={(bla) => this.handleOwnerSelected(bla as SuggestionItem<IOwnerName>)}
                            getSuggestions={(val) => this.getOwnerSuggestions(val)}
                            errorText={this.ordersFilter.errors.owner}
                            selectedItem={uiStore!.orderSearchUiState.selectedOwnerSuggestion}
                            value={uiStore!.orderSearchUiState.ownerSearchText}
                            onChange={this.handleOwnerSearchChange}
                            onHandleBlur={() => {
                                if (uiStore!.orderSearchUiState.selectedOwnerSuggestion &&
                                    uiStore!.orderSearchUiState.selectedOwnerSuggestion.label !== uiStore!.orderSearchUiState.ownerSearchText) {
                                    this.handleOwnerSearchChange(uiStore!.orderSearchUiState.selectedOwnerSuggestion.label)
                                } else if (!uiStore!.orderSearchUiState.selectedOwnerSuggestion) {
                                    this.handleOwnerSearchChange("")
                                }
                            }}
                            onClear={() => {
                                this.ordersFilter.setOwnerRepCode("");
                                uiStore!.orderSearchUiState.setOwnerSearchText("");
                                uiStore!.orderSearchUiState.setOwnerSuggestion(undefined);
                            }}
                        />
                    </Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11}>
                        <a className="action filters-action" onClick={this.handleFilterSearch}>Apply Filters</a>
                    </Grid>
                </Grid>
            }

            <div className="dialog-actions">
                <div className="close lg-hidden" onClick={this.props.handleClose}>
                    <span>Close</span>
                    <Close className="icon" />
                </div>
            </div>
        </div>
    }

    private async handleFilterSearch() {
        if (!this.ordersFilter.validate()) return;

        if (this.ordersFilter.isFiltered)
            this.ordersFilter.setFilterByRep(false);
        else this.ordersFilter.setFilterByRep(true);

        await tracker.track(this.props.salesStore!.updateOrdersFilter(this.ordersFilter));
        this.props.handleClose();
    }

    private handleOrderNumberChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.orderSearch.update(e.currentTarget.name, e.currentTarget.value);
    }

    private handleCustomerRefChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.ordersFilter.setCustomerRef(e.currentTarget.value);
    }

    private handleLeadIdentifierChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.ordersFilter.setLeadIdentifier(e.currentTarget.value);
    }

    private handleCustomerSelected(selected: SuggestionItem<ICustomerSummary>) {
        this.ordersFilter.setCustomerId(selected.value.id);
        this.props.uiStore!.orderSearchUiState.setCustomerSuggestion(selected);
    }

    private handleSiteContactSelected(selected: SuggestionItem<IHaveName>) {
        this.ordersFilter.setSiteContact(selected.value.name);
        this.props.uiStore!.orderSearchUiState.setSiteContactSuggestion(selected);
    }

    private handleOwnerSelected(selected: SuggestionItem<IOwnerName>) {
        this.ordersFilter.setOwnerRepCode(selected.value.repCode);
        this.props.uiStore!.orderSearchUiState.setOwnerSuggestion(selected);
    }

    private handleCustomerSearchChange(search: string) {
        this.props.uiStore!.orderSearchUiState.setCustomerSearchText(search);
    }

    private handlePhoneNumberChanged(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) {
        this.ordersFilter.setPhoneNumber(e.currentTarget.value);
    }

    private async handleOrderNumberSearch() {
        const { salesStore } = this.props;
        if (!salesStore!.orderSearch.validate()) return;
        const orderNumber = await salesStore!.searchOrderNumberByOrderOrPoNumber();
        if (orderNumber) {
            this.props.history.push(`view-order/${orderNumber}`)
        }
    }

    private getCustomerSuggestions(value: string): Promise<Array<SuggestionItem<ICustomerSummary>>> {
        return this.props.customersStore!.searchCustomers(value, true);
    }

    private getSiteContactSuggestions(value: string): Promise<Array<SuggestionItem<IHaveName>>> {
        return this.props.customersStore!.searchSiteContacts(value);
    }

    private getAddressSuggestions(value: string): Promise<Array<SuggestionItem<string>>> {
        return this.props.salesStore!.searchDeliveryAddresses(value);
    }

    private handleAddressSelected(selected: SuggestionItem<string> | undefined) {
        this.ordersFilter.setAddress(selected!.value);
        this.props.uiStore!.orderSearchUiState.setAddressSearchText(selected!.value);
        this.props.uiStore!.orderSearchUiState.setAddressSuggestion(selected);
    }

    private handleAddressChange(address: string) {
        this.props.uiStore!.orderSearchUiState.setAddressSearchText(address);
    }

    private handleSiteContactSearchChanged(contact: string) {
        this.props.uiStore!.orderSearchUiState.setSiteContactSearchText(contact);
    }

    private getOwnerSuggestions(value: string): Promise<Array<SuggestionItem<IHaveName>>> {
        return this.props.customersStore!.searchOwners(value);
    }

    private handleOwnerSearchChange(owner: string) {
        this.props.uiStore!.orderSearchUiState.setOwnerSearchText(owner);
    }

    private handleToggleFilters() {
        this.props.uiStore!.orderSearchUiState.toggleFiltersActive();
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    clearableDateContainer: {
        width: "315px",
        marginBottom: 30
    },
    inlineDateField: {
        width: "245px"
    }
});

export default withStyles(styles)(SearchDialog);
