import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { ProductsStore, ProductSuggestion, IExWorksDetail, ProductSuggestionKind, IProductDetail } from '../../stores/products-store';
import { UiStore } from '../../stores/ui-store';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { observable, action } from 'mobx';
import { ProductLine } from 'src/models/product-line';
import LocalOffer from '@material-ui/icons/LocalOffer';
import Domain from '@material-ui/icons/Domain';
import tracker from '../Loader/loader-tracker';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

export interface IAddProductProps {
    salesStore?: SalesStore
    uiStore?: UiStore
    productsStore?: ProductsStore
}

@inject('salesStore', 'uiStore', 'productsStore')
@observer
class AddProduct extends React.Component<IAddProductProps> {

    @observable private selection: SuggestionItem<ProductSuggestion>;

    constructor(props: IAddProductProps) {
        super(props);
        this.handleProductSelected = this.handleProductSelected.bind(this);
        this.getProductSuggestions = this.getProductSuggestions.bind(this);
        this.handleMultiSelectDeleted = this.handleMultiSelectDeleted.bind(this);
        this.handleFilterMultiSelect = this.handleFilterMultiSelect.bind(this);
        this.renderSuggestion = this.renderSuggestion.bind(this);
        this.clearSelection();
    }

    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        return <React.Fragment>
            {
                !orderUiState.addProductActive
                && <div className="add-product-button">
                    <Button
                        variant="outlined"
                        onClick={() => orderUiState.setAddProductActive(true)}
                        disabled={!salesStore!.order.readyForProductLines}>
                        Add Product <AddIcon />
                    </Button>
                    {
                        salesStore!.order.canAddProducts && !salesStore!.order.readyForProductLines
                        && <p>Select customer and address first</p>
                    }
                </div>
            }
            {
                orderUiState.addProductActive
                && <Autocomplete
                    label="Add Product"
                    onSelect={this.handleProductSelected}
                    selectedItem={this.selection}
                    getSuggestions={this.getProductSuggestions}
                    autoFocus={true}
                    suggestionToComponent={{
                        containerStyle: { minHeight: "50px", height: "auto" },
                        getComponent: this.renderSuggestion
                    }}
                    multiSelect
                    filterMultiSelect={this.handleFilterMultiSelect}
                    filterDefaults={orderUiState.productFilterExWorks ? [orderUiState.productFilterExWorks!] : []}
                    onSelectionDeleted={this.handleMultiSelectDeleted}
                    onClear={() => orderUiState.setAddProductActive(false)}
                    disabled={!salesStore!.order.readyForProductLines}
                />
            }
        </React.Fragment>
    }

    private handleMultiSelectDeleted(selected: SuggestionItem<ProductSuggestion>) {
        if (selected.value.kind !== ProductSuggestionKind.exWorks) return;
        this.props.uiStore!.orderUiState.setProductFilterExWorks(null);
    }

    private handleFilterMultiSelect(item: SuggestionItem<ProductSuggestion>, filters: Array<SuggestionItem<ProductSuggestion>>) {
        let filter: Array<SuggestionItem<ProductSuggestion>> = [];
        if (item.value.kind === ProductSuggestionKind.exWorks) {
            filter = [...filters, item];
            this.props.uiStore!.orderUiState.setProductFilterExWorks(item);
        }
        if (item.value.kind === ProductSuggestionKind.product) {
            const exWorks = this.getExWorksFromProductSuggestion(item);
            filter = [exWorks];
            this.props.uiStore!.orderUiState.setProductFilterExWorks(exWorks);
        }
        return filter;
    }

    private async handleProductSelected(selected: SuggestionItem<ProductSuggestion>) {
        this.clearSelection();
        if (selected.value.kind === ProductSuggestionKind.product) {
            const { order } = this.props.salesStore!;
            this.props.uiStore!.orderUiState.setAddProductActive(false);
            const product = ProductLine.fromProductDetail(selected.value as IProductDetail, order);
            await tracker.track(order.addProductLine(product, this.props.uiStore!.notificationUiState), "products-area");
        }
    }

    private getProductSuggestions(value: string): Promise<Array<SuggestionItem<ProductSuggestion>>> {
        return this.props.productsStore!.searchProducts(
            value,
            this.props.uiStore!.orderUiState.productFilterExWorksId);
    }

    private renderSuggestion(item: SuggestionItem<ProductSuggestion>) {
        const { orderUiState } = this.props.uiStore!;
        if (item.value.kind === ProductSuggestionKind.exWorks) {
            const exWorks = item.value as IExWorksDetail;
            return (<div className="result-item">
                <span>
                    <div>{exWorks.supplierName}</div>
                    <div className="sub-text"> {exWorks.name}</div>
                </span>
                <span className="icon"><Domain /></span>
            </div>)
        }
        else {
            const product = item.value as IProductDetail;
            return (<div className="result-item">
                <span>
                    <div>{product.productName}</div>
                    <div className="sub-text"><small>{product.code} {!orderUiState.productFilterExWorksId ? ` - ${product.exWorks.supplierName} ${product.exWorks.name}` : ''}</small></div>
                </span>
                <span className="icon">
                    <LocalOffer />
                </span>
            </div>)
        }
    }

    private getExWorksFromProductSuggestion(item: SuggestionItem<ProductSuggestion>): SuggestionItem<ProductSuggestion> {
        const detail = item.value as IProductDetail;
        return {
            label: `${detail.exWorks.supplierName} ${detail.exWorks.name}`,
            value: { id: detail.exWorks.id, name: detail.exWorks.name, supplierName: detail.exWorks.supplierName, kind: ProductSuggestionKind.exWorks }
        };
    }

    @action
    private clearSelection() {
        this.selection = { label: '', value: null } as SuggestionItem<any>;
    }
}

export default AddProduct;