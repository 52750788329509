import { apiFetch } from '../utilities/apiFetch';
import type { UserAppSettings } from 'src/models/user-app-settings';

export interface IUserAppSettingsResponse {
  stopInvoicing: boolean;
  stopStoreToFreight: boolean;
  stopFuelLevy: boolean;
}

export class SettingsApi {
  public async get(): Promise<IUserAppSettingsResponse> {
    const response = await apiFetch('/api/settings');
    return response?.json();
  }

  public async save(settings: UserAppSettings): Promise<boolean> {
    const response = await apiFetch('/api/settings', { method: 'post', body: settings.serialized });
    return response?.ok ?? false;
  }
}
