import createMuiTheme from "@material-ui/core/styles/createMuiTheme";

const mojoeOrange = '#EA592C';
export const initTheme = () => {
    return createMuiTheme({
        typography: {
            useNextVariants: true,
        },
        overrides: {
            MuiButton: {
                root: {
                    borderColor: mojoeOrange,
                    color: mojoeOrange
                },
                outlined: {
                    borderColor: mojoeOrange,
                }
            },
            MuiTableCell: {
                root: {
                    overflow: "hidden",
                    textOverflow: "ellipsis"
                }
            }
        },
    });
}

export const globalStyles = {
    textField: {
        marginBottom: 30,
        width: "315px",
        display: "block"
    },
    inlineDateField: {
        width: "245px",
        marginBottom: 30
    },
    textFieldFullWidth: {
        width: "100%",
        marginBottom: 30
    },
    highlightText: {
        color: mojoeOrange
    }
};