import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import { observer, inject } from 'mobx-react';
import { SubmitType } from './SubmitOrderWithPoDialog';
import { SalesStore } from 'src/stores/sales-store';
import { WorkflowDialogContent } from '../OrderWorkflows/WorkflowDialogContent';

export interface ISubmitOrderDialogProps {
    onClose(): void
    onSubmit(): void
    submitType: SubmitType
    salesStore?: SalesStore
}

@inject('salesStore')
@observer
class SubmitOrderDialog extends React.Component<ISubmitOrderDialogProps> {
    
    public render() {
        return <div className="full-dialog">
            <Loader area="submit-order-dialog" />
            <div className="dialog-content">
                <h3>{`${this.props.submitType === SubmitType.Submit ? "Submit" : "Save"} Order`}</h3>
                {
                    this.props.salesStore!.order.isInDispute &&
                    <WorkflowDialogContent
                        workflow={this.props.salesStore!.order.customerInDispute}
                    />
                }
                <p>You are about to submit this order for processing.</p>
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Close</span>
                    <Close className="icon" />
                </div>
                <div className="done"  onClick={(e: React.MouseEvent<HTMLDivElement>) => this.confirmSubmit()}>
                    <span>Save</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }

    private confirmSubmit() {
        const { salesStore } = this.props;
        if( this.props.salesStore!.order.isInDispute && !salesStore!.order.customerInDispute.validate()){
            return;
        }
        this.props.onSubmit();
    }
}

export default SubmitOrderDialog;