import { action, observable } from 'mobx';
import { Model } from './model';
import { Validate } from '../validate';
import { ISalesRep } from 'src/services/customers-api';

export class SalesRep extends Model {
    @observable public emailAddress: string = '';
    @observable public name: string = '';
    @observable public code?: string;
    @observable public stockingPoint?: string;
    @observable public salesReps: ISalesRep[];

    @action
    public setDetailsFromEmail(r: ISalesRep) {
      this.name = r.name;
      this.code = r.code ?? '';
      this.stockingPoint = r.stockingPoint ?? '';
    }

    @action
    public clearDetails() {
      this.name = '';
      this.code = '';
      this.stockingPoint = undefined;
    }

    @action
    public async setSalesReps(r: ISalesRep[]) {
      this.salesReps = r;
    }

    public isEmailExisting() {
      const selectedSalesRep = this.salesReps.find((s) => s.emailAddress === this.emailAddress);
      return !!selectedSalesRep;
    }

    public doesEmailMatchWithName() {
      const selectedSalesRep = this.salesReps.find((s) => s.emailAddress === this.emailAddress);

      if (this.name === '') return true;
      return this.name && selectedSalesRep?.name === this.name;
    }

    public isCodeUnique() {
      const existingCode = this.salesReps.filter((r) => r.name !== this.name).find((s) => s.code?.toLowerCase() === this.code?.toLowerCase());
      return !existingCode;
    }

    protected validators: {[name: string]: (val: any) => boolean} = {
      "emailAddress": (val: string) => {
        this.errors.emailAddress = null;
        this.errors.code = null;
        this.errors.stockingPoint = null;
        if (!Validate.hasValue(val)) this.errors.emailAddress = "E-mail required";
        else if (!this.isEmailExisting()) this.errors.emailAddress = "E-mail does not exist";
        else if (!this.doesEmailMatchWithName()) this.errors.emailAddress = "E-mail does not match with name. Please click on Search to apply correct data before saving your changes.";
        else this.errors.emailAddress = null;

        return this.errors.emailAddress === null;
      },
      "code": (val: string) => {
        if (!Validate.hasValue(val)) this.errors.code = "Sales Rep Code required";
        else if (!this.isCodeUnique()) this.errors.code = "Sales Rep Code already exists";
        else this.errors.code = null;

        return this.errors.code === null;
      },
      "stockingPoint": (val: string) => {
        this.errors.stockingPoint = val !== null && val !== undefined
            ? null : "Stocking Point required";
        return this.errors.stockingPoint === null;
      }
  }
}

