import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { ProductsStore, IServiceDetail } from '../../stores/products-store';
import { UiStore } from '../../stores/ui-store';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { ServiceLine } from '../../models/service-line';
import { observable, action } from 'mobx';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

export interface IAddServiceProps {
    salesStore?: SalesStore
    uiStore?: UiStore
    productsStore?: ProductsStore
}

@inject('salesStore', 'uiStore', 'productsStore')
@observer
class AddService extends React.Component<IAddServiceProps> {

    @observable private selection: SuggestionItem<IServiceDetail>;

    constructor(props: IAddServiceProps) {
        super(props);
        this.handleServiceSelected = this.handleServiceSelected.bind(this);
        this.getServiceSuggestions = this.getServiceSuggestions.bind(this);
        this.clearSelection();
    }

    public render() {
        const { orderUiState } = this.props.uiStore!;
        return <React.Fragment>
            {
                !orderUiState.addServiceActive
                && <div className="add-service-button">
                    <Button
                        variant="outlined"
                        onClick={() => orderUiState.setAddServiceActive(true)}>
                        Add Service <AddIcon />
                    </Button>
                </div>
            }
            {
                orderUiState.addServiceActive
                && <Autocomplete
                    label="Add Service"
                    autoFocus={true}
                    onSelect={this.handleServiceSelected}
                    selectedItem={this.selection}
                    getSuggestions={this.getServiceSuggestions}
                    suggestionToComponent={{
                        containerStyle: { minHeight: "50px", height: "auto" },
                        getComponent: (item: SuggestionItem<IServiceDetail>) => (
                            <div>
                                <div>{item.label}</div>
                                <div className="sub-text"><small>{item.value.code}</small></div>
                            </div>
                        )
                    }}
                    onClear={() => orderUiState.setAddServiceActive(false)}
                />
            }
        </React.Fragment>
    }

    private handleServiceSelected(selected: SuggestionItem<IServiceDetail>) {
        this.clearSelection();
        this.props.uiStore!.orderUiState.setAddServiceActive(false);
        const { order } = this.props.salesStore!
        const service = new ServiceLine(0, selected.value.id, selected.label, selected.value.code, selected.value.price, selected.value.purchaseUnit, 0, '', undefined, false, undefined, order.isSplitting, 0, 0, 0, order);
        order.addServiceLine(service);
    }

    private getServiceSuggestions(value: string): Promise<Array<SuggestionItem<IServiceDetail>>> {
        return this.props.productsStore!.searchServices(value)
    }

    @action
    private clearSelection() {
        this.selection = { label: '', value: null } as SuggestionItem<any>;
    }
}

export default AddService;