import React from 'react';
import { action } from 'mobx';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import './styles.css';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, MenuItem, Select, TextField, withStyles, WithStyles } from '@material-ui/core';
import { Save, Search } from '@material-ui/icons';

import { Header } from '../Header/Header';
import { reactPlugin } from '../../utilities/appInsightsConfig';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import tracker from '../Loader/loader-tracker';
import { ProductsStore } from 'src/stores/products-store';
import { CustomersStore } from 'src/stores/customers-store';
import { UiStore } from 'src/stores/ui-store';
import { globalStyles } from 'src/theme';

export interface ISalesRepProps extends WithStyles<typeof globalStyles> {
  productsStore?: ProductsStore;
  uiStore?: UiStore;
  customersStore?: CustomersStore;
}

type Props = ISalesRepProps & RouteComponentProps<{}>;

@inject('productsStore', 'customersStore', 'uiStore')
@observer
class SalesRep extends React.Component<Props> {
  private productsStore: ProductsStore;
  private customersStore: CustomersStore;
  private uiStore: UiStore;

  constructor(props: Props) {
    super(props);
    this.productsStore = props.productsStore!;
    this.customersStore = props.customersStore!;
    this.uiStore = props.uiStore!;
    this.handleEmailChange = this.handleEmailChange.bind(this);
    this.handleCodeChange = this.handleCodeChange.bind(this);
    this.handleStockingPointChange = this.handleStockingPointChange.bind(this);
    this.handleUpdateSalesRep = this.handleUpdateSalesRep.bind(this);
    this.handleSalesRepSearch = this.handleSalesRepSearch.bind(this);
    this.handleResetPage = this.handleResetPage.bind(this);
  }

  public async componentDidMount() {
    await tracker.track(this.productsStore!.getStockingPoints());
    await tracker.track(this.customersStore!.getSalesReps());
    await tracker.track(this.customersStore!.selectedSalesRep.setSalesReps(this.customersStore!.salesReps));
  }

  public render() {
    
    return (
      <div>
        <Header title="User" />
        <Grid className="sales-rep" container>
          <Grid item xs={1} />
          <Grid item xs={10} className="content">
            <div className="sales-rep-form">
              <TextField
                label="E-mail"
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={this.handleSalesRepSearch}
                    >
                      <IconButton className="action">
                        <Search />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
                type="email"
                name="email"
                autoFocus={true}
                value={this.customersStore!.selectedSalesRep.emailAddress}
                onChange={this.handleEmailChange}
                error={!!this.customersStore!.selectedSalesRep.errors.emailAddress}
                helperText={this.customersStore!.selectedSalesRep.errors.emailAddress}
              />
              <TextField
                label="Name"
                InputProps={{
                  disableUnderline: true
                }}
                type="text"
                name="name"
                value={this.customersStore!.selectedSalesRep.name}
                disabled
              />
              <TextField
                // User Code = Sales Rep Code
                label="User Code"
                InputProps={{
                  disableUnderline: true
                }}
                type="text"
                name="salesRepCode"
                value={this.customersStore!.selectedSalesRep.code ?? ''}
                onChange={this.handleCodeChange}
                inputProps={{ maxLength: 10 }}
                error={!!this.customersStore!.selectedSalesRep.errors.code}
                helperText={this.customersStore!.selectedSalesRep.errors.code}
                disabled={this.customersStore!.selectedSalesRep.emailAddress === ''}
              />
              <FormControl className='stocking-point-form' error={!!this.customersStore!.selectedSalesRep.errors.stockingPoint}>   
                <InputLabel htmlFor='stockingPoint'>Stocking Point</InputLabel>
                <Select
                  disableUnderline
                  inputProps={{ name: 'stockingPoint', id: 'stockingPoint' }}
                  value={(this.customersStore!.selectedSalesRep.stockingPoint === '' ? 'ARU' : this.customersStore!.selectedSalesRep.stockingPoint) ?? ''}
                  onChange={this.handleStockingPointChange}
                  error={!!this.customersStore!.selectedSalesRep.errors.stockingPoint}
                  disabled={this.customersStore!.selectedSalesRep.emailAddress === ''}
                >
                  {
                    this.productsStore!.stockingPoints.map((s, i) => (<MenuItem value={s.code === '' ? 'ARU' : s.code} key={i}>{s.name}</MenuItem>))
                  }
                </Select>
                <FormHelperText>{this.customersStore!.selectedSalesRep.errors.stockingPoint}</FormHelperText>
              </FormControl>
            </div>
          </Grid>
        </Grid>
        <ActionsMenu>
          <ActionItem
            label="Save changes"
            onClick={() => this.handleUpdateSalesRep()}
          >
            <Save />
          </ActionItem>
        </ActionsMenu>
      </div>
    );
  }

  @action
  private handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.customersStore!.selectedSalesRep.update('emailAddress', e.currentTarget.value);
    
  }

  @action
  private handleCodeChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.customersStore!.selectedSalesRep.update('code', e.currentTarget.value);
  }

  @action
  private handleStockingPointChange(e: React.ChangeEvent<HTMLSelectElement>) {
    this.customersStore!.selectedSalesRep.update('stockingPoint', e.target.value);
  }

  @action
  private async handleSalesRepSearch() {
    const selectedSalesRep = this.customersStore!.salesReps.find((s) => s.emailAddress === this.customersStore!.selectedSalesRep.emailAddress);

    if (selectedSalesRep) {
      this.customersStore!.selectedSalesRep.errors.emailAddress = null;
      this.customersStore!.selectedSalesRep.errors.code = null;
      this.customersStore!.selectedSalesRep.errors.stockingPoint = null;
      this.customersStore!.selectedSalesRep.setDetailsFromEmail(selectedSalesRep);
    } else {
      this.customersStore!.selectedSalesRep.errors.emailAddress = "E-mail does not exist";
      this.customersStore!.selectedSalesRep.clearDetails();
    }
   
  }

  @action
  private async handleUpdateSalesRep() {
    const isValid = this.customersStore!.selectedSalesRep.validate();
    if (isValid) {
      if (this.customersStore!.selectedSalesRep.stockingPoint === 'ARU') this.customersStore!.selectedSalesRep.update('stockingPoint', '');
      await tracker.track(this.customersStore!.updateSalesRep(this.uiStore.notificationUiState));
      this.uiStore!.setActionsMenuActive(false);
      this.handleResetPage();
    }
  }

  @action
  private async handleResetPage() {
    await tracker.track(this.productsStore!.getStockingPoints());
    await tracker.track(this.customersStore!.getSalesReps());
    await tracker.track(this.customersStore!.selectedSalesRep.setSalesReps(this.customersStore!.salesReps));
  }
};

export default withStyles(globalStyles)(withAITracking(reactPlugin, SalesRep, 'SalesRep'));
