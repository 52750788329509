function setWithExpiry(key: string, value: string, ttl: number) {
  if (typeof window !== 'undefined' && window.localStorage) {
      const now = new Date();
      const item = {
          value: value,
          expiry: now.getTime() + ttl,
      };
      localStorage.setItem(key, JSON.stringify(item));
  }
};

function getWithExpiry(key: string) {
  if (typeof window !== 'undefined' && window.localStorage) {
      const itemStr = localStorage.getItem(key);
      // if the item doesn't exist, return null
      if (!itemStr) {
          return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      // compare the expiry time of the item with the current time
      if (now.getTime() > item.expiry) {
          // if the item is expired, delete it from localstorage and return null
          localStorage.removeItem(key);
          return null;
      }
      // otherwise, return the value
      return item.value;
  }
};

export { getWithExpiry, setWithExpiry };
