import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { UiStore } from '../../stores/ui-store';
import { ServiceLine } from '../../models/service-line';
import { observable, action, runInAction } from 'mobx';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import EditServiceDialog from './EditServiceDialog';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { Validate } from '../../validate';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { globalStyles } from '../../theme';
import { ICurrencyChangedEvent } from '../Currency/CurrencyField';
import NumberField, { INumberChangedEvent } from '../NumberField/NumberField';
import {roundedSubtotal} from "../../math";

export interface IAddedServicesProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    uiStore?: UiStore
}

@inject('salesStore', 'uiStore')
@observer
class AddedServices extends React.Component<IAddedServicesProps> {

    @observable private editDialogActive: boolean;
    @observable private serviceLine: ServiceLine;

    constructor(props: IAddedServicesProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveService = this.handleSaveService.bind(this);
        this.handleDeleteService = this.handleDeleteService.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        runInAction(() => this.editDialogActive = false);
    }

    public render() {
        const { salesStore, uiStore } = this.props;
        return <div className="service-lines">
            <div className="lines-container">
                {
                    salesStore!.order.serviceLines.map((s, i) => (
                        <ServiceRow
                            key={i}
                            serviceLine={s}
                            uiStore={uiStore}
                            onOpenDialog={this.handleOpenDialog}
                            onChange={(property: string, value: any) => this.handleChange(property, value, s)}
                            onChangeQuantity={(quantity: number) => this.handleQuantityChange(quantity, s)}
                            onChangePrice={(e: ICurrencyChangedEvent) => this.handlePriceChange(e, s)}
                            {...this.props}
                        />
                    ))
                }
            </div>
            {
                this.editDialogActive
                && <EditServiceDialog
                    active={this.editDialogActive}
                    handleClose={this.handleCloseDialog}
                    handleSave={this.handleSaveService}
                    handleDelete={this.handleDeleteService}
                    serviceLine={this.serviceLine}
                />
            }
        </div>
    }

    @action
    private handleOpenDialog(serviceLine: ServiceLine) {
        this.serviceLine = serviceLine;
        this.editDialogActive = true;
    }

    @action
    private handleSaveService(serviceLine: ServiceLine, parentServiceLine: ServiceLine) {
        const { order } = this.props.salesStore!
        order.updateServiceLine(serviceLine);
        if (parentServiceLine && order.parentOrder)
            order.parentOrder.updateServiceLine(parentServiceLine);
        this.editDialogActive = false;
    }

    @action
    private handleDeleteService(serviceLine: ServiceLine) {
        this.props.salesStore!.order.removeServiceLine(serviceLine);
        this.editDialogActive = false;
    }

    @action
    private handleCloseDialog(e: React.MouseEvent<HTMLDivElement>) {
        this.editDialogActive = false;
    }

    private handleChange(property: string, value: any, serviceLine: ServiceLine) {
        serviceLine.updateAndValidate(property, value);
    }

    private handleQuantityChange(quantity: number, serviceLine: ServiceLine) {
        serviceLine.allocateQuantities(quantity);
    }

    private handlePriceChange(e: ICurrencyChangedEvent, serviceLine: ServiceLine) {
        serviceLine.updateAndValidate('price', e.parsedValue);
    }
}

interface IServiceLineProps extends WithStyles<typeof styles> {
    serviceLine: ServiceLine;
    uiStore?: UiStore;
    onChange(property: string, value: any): void
    onChangeQuantity(quantity: number): void
    onChangePrice(e: ICurrencyChangedEvent): void
    onOpenDialog(serviceLine: ServiceLine): void
}
const ServiceRow: React.SFC<IServiceLineProps> = observer((props) => {
    const { serviceLine } = props;
    const { orderUiState } = props.uiStore!;
    const splitInfo = serviceLine.orderSplitQtyLabel;
    return (<Grid container className="service-body">
        <Grid item xs={11}>
            <span className="item-name">{serviceLine.name}<span className="sub-text"> ({serviceLine.code})</span></span>
        </Grid>
        <Grid item xs={1} className="icon">
            {
                !orderUiState.formReadOnly
                && <MoreHoriz onClick={() => props.onOpenDialog(serviceLine)} />
            }
        </Grid>
        {
            splitInfo
            && <Grid item xs={12}>
                <div className="split-info">{splitInfo}</div>
            </Grid>
        }
        <Hidden mdDown>
            <Grid item lg={4} className="service-label">{serviceLine.unitsLabel}</Grid>
            <Grid item lg={4} className="service-label">Unit Price</Grid>
            <Grid item lg={4} className="service-label">Total</Grid>
        </Hidden>
        <Hidden lgUp>
            <Grid item xs={8} sm={10} className="service-label">{serviceLine.unitsLabel}</Grid>
        </Hidden>
        <Grid item xs={4} sm={2} lg={4}>
            <NumberField
                InputProps={{ classes: { input: props.classes.rowText } }}
                value={serviceLine.quantity}
                error={serviceLine.errors.quantity}
                onChange={(e: INumberChangedEvent) => props.onChangeQuantity(e.parsedValue)}
                textProps={{
                    name: "quantity",
                    fullWidth: true,
                    autoFocus: !serviceLine.isAutoAdded,
                    disabled: orderUiState!.isReadonly("serviceQuantity"),
                    onKeyPress: (e) => {
                        if (e.key === 'Enter') orderUiState.setAddServiceActive(true);
                    }
                }}
            />
        </Grid>
        <Hidden lgUp>
            <Grid item xs={8} sm={10} className="service-label">Unit Price</Grid>
        </Hidden>
        <Grid item xs={4} sm={2} lg={4}>
            <TextField
                InputProps={{
                    disableUnderline: true,
                    classes: { input: props.classes.rowText }
                }}
                type="text"
                name="price"
                value={`$${serviceLine.price.toFixed(2)}`}
                error={!!serviceLine.errors.price}
                helperText={serviceLine.errors.price || null}
                disabled
                fullWidth
            />
        </Grid>
        <Hidden lgUp>
            <Grid item xs={8} sm={10} className="service-label">Total</Grid>
        </Hidden>
        <Grid item xs={4} sm={2} lg={4}>
            <TextField
                InputProps={{
                    disableUnderline: true,
                    classes: { input: props.classes.rowText }
                }}
                type="text"
                name="total"
                value={`$${roundedSubtotal(serviceLine.unroundedSubtotal).toFixed(2)}`}
                disabled
                fullWidth
            />
        </Grid>
        {
            Validate.hasValue(serviceLine.note)
            && <Grid item xs={12}>
                <TextField
                    className="service-note"
                    type="text"
                    name="note"
                    label="Note"
                    value={serviceLine.note}
                    error={!!serviceLine.errors.note}
                    helperText={serviceLine.errors.note || null}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange('note', e.currentTarget.value)}
                    disabled={orderUiState!.isReadonly('serviceNote')}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            input: props.classes.highlightText
                        }
                    }}
                    inputProps={{ maxLength: 60 }}
                    fullWidth
                    multiline
                />
            </Grid>
        }
    </Grid>)
})

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    rowText: {
        textAlign: "right",
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
        }
    }
});

export default withStyles(styles)(AddedServices);