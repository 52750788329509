import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { ServiceLine } from 'src/models/service-line';
import { TextField, WithStyles, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { observable, runInAction } from 'mobx';
import { globalStyles } from 'src/theme';
import { UiStore } from 'src/stores/ui-store';
import CurrencyField, { ICurrencyChangedEvent } from '../Currency/CurrencyField';
import NumberField, { INumberChangedEvent } from '../NumberField/NumberField';
import {roundedSubtotal} from "../../math";
import {AuthStore} from "../../stores/auth-store";

export interface IEditServiceDialogProps extends WithStyles<typeof globalStyles> {
    salesStore?: SalesStore;
    uiStore?: UiStore;
    authStore?: AuthStore,
    active: boolean;
    serviceLine: ServiceLine;
    handleClose(e: React.MouseEvent<HTMLDivElement>): void;
    handleSave(serviceLine: ServiceLine, parentServiceLine: ServiceLine): void;
    handleDelete(serviceLine: ServiceLine): void;
}

@inject('salesStore', 'uiStore', 'authStore')
@observer
class EditServiceDialog extends React.Component<IEditServiceDialogProps> {
    @observable private service: ServiceLine;
    @observable private parentService: ServiceLine;

    constructor(props: IEditServiceDialogProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        runInAction(() => {
            const { order } = this.props.salesStore!;
            this.service = this.props.serviceLine.clone();
            if (order.parentOrder) {
                const parent = order.parentOrder.getServiceLine(this.service.serviceId, this.service.lineNumber);
                if (parent) this.parentService = parent.clone();
            }
        });
    }

    public render() {
        const { orderUiState } = this.props.uiStore!;
        const splitInfo = this.service.orderSplitQtyLabel;
        const isAccountsReceivableOrAdmin = this.props.authStore!.isAccountsReceivable || this.props.authStore!.isAdmin;
        return (
            <div className={'edit-service-dialog ' + (this.props.active ? 'active' : '')}>
                <div className='dialog-content'>
                    {splitInfo && <div className='split-info'>{splitInfo}</div>}
                    <h3>{this.service.name}</h3>
                    <NumberField
                        value={this.service.quantity}
                        onChange={(e: INumberChangedEvent) => this.handleQuantityChange(e.parsedValue)}
                        error={this.service.errors.quantity}
                        textProps={{
                            className: this.props.classes.textField,
                            label: `Units ${this.service.purchaseUnit ? '(' + this.service.purchaseUnit + ')' : ''}`,
                            name: 'quantity',
                            autoFocus: true,
                            disabled: orderUiState!.isReadonly('serviceQuantity') && !isAccountsReceivableOrAdmin
                        }}
                    />

                    <NumberField
                        value={this.service.chosenUnitWeight}
                        onChange={(e: INumberChangedEvent) => this.handleChange('chosenUnitWeight', e.parsedValue)}
                        error={this.service.errors.chosenUnitWeight}
                        textProps={{
                            className: this.props.classes.textField,
                            label: 'Unit Weight(Tonnes)',
                            name: 'chosenUnitWeight',
                            disabled: orderUiState.isReadonly('chosenUnitWeight') && !isAccountsReceivableOrAdmin
                        }}
                    />
                    <NumberField
                        value={this.service.chosenNoOfPacks}
                        onChange={(e: INumberChangedEvent) => this.service.handleChosenPackChange('chosenNoOfPacks', e.parsedValue)}
                        error={this.service.errors.chosenNoOfPacks}
                        textProps={{
                            className: this.props.classes.textField,
                            label: 'Packs',
                            name: 'chosenNoOfPacks',
                            disabled: orderUiState.isReadonly('chosenNoOfPacks') && !isAccountsReceivableOrAdmin
                        }}
                    />
                    <NumberField
                        value={this.service.chosenNoOfPallets}
                        onChange={(e: INumberChangedEvent) => this.service.handleChosenPalletsChange('chosenNoOfPallets', e.parsedValue)}
                        error={this.service.errors.chosenNoOfPallets}
                        textProps={{
                            className: this.props.classes.textField,
                            label: 'Pallets',
                            name: 'chosenNoOfPallets',
                            disabled: orderUiState.isReadonly('chosenNoOfPallets') && !isAccountsReceivableOrAdmin
                        }}
                    />
                    {this.service.isPriceEditable(this.service.code) ? (
                        <CurrencyField
                            className={this.props.classes.textField}
                            label='Unit Cost'
                            name='price'
                            value={this.service.price}
                            error={this.service.errors.price}
                            onChange={this.handlePriceChange}
                            disabled={orderUiState.isReadonly('price')}
                            fullWidth={false}
                        />
                    ) : (
                        <TextField
                            className={this.props.classes.textField}
                            InputProps={{ disableUnderline: true }}
                            label='Unit Cost'
                            type='text'
                            name='price'
                            value={`$${this.service.price.toFixed(2)}`}
                            disabled
                            fullWidth
                        />
                    )}

                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label='Total'
                        type='text'
                        name='total'
                        value={`$${roundedSubtotal(this.service.unroundedSubtotal).toFixed(2)}`}
                        disabled
                        fullWidth
                    />

                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 60 }}
                        label='Note'
                        type='text'
                        name='note'
                        value={this.service.note}
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('note', e.currentTarget.value)}
                        onBlur={this.handleBlur}
                        multiline
                        disabled={orderUiState.isReadonly('serviceNote')}
                    />
                </div>
                <div className='dialog-actions'>
                    {(orderUiState.canDeleteServiceLine(this.service) || isAccountsReceivableOrAdmin) && (
                        <div className='delete' onClick={() => this.props.handleDelete(this.service)}>
                            <span>Delete</span>
                            <DeleteForever className='icon' />
                        </div>
                    )}
                    <div className='close' onClick={this.props.handleClose}>
                        <span>Close</span>
                        <Close className='icon' />
                    </div>
                    <div className='done' onClick={this.handleSave}>
                        <span>Save</span>
                        <Done className='icon' />
                    </div>
                </div>
            </div>
        );
    }

    private handleChange(property: string, value: any) {
        this.service.update(property, value);
    }

    private handlePriceChange(e: ICurrencyChangedEvent) {
        this.service.updateAndValidate('price', e.parsedValue);
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.service.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleSave() {
        if (!this.service.validate()) return;
        this.props.handleSave(this.service, this.parentService);
    }

    private handleQuantityChange(quantity: number) {
        this.service.allocateQuantities(quantity, this.parentService);
    }
}

export default withStyles(globalStyles)(EditServiceDialog);
