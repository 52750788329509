import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { UiStore } from '../../stores/ui-store';
import { action, observable, runInAction } from 'mobx';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { globalStyles } from '../../theme';
import { SupplierCommentLine } from 'src/models/supplier-comment-line';
import EditSupplierCommentDialog from './EditSupplierCommentDialog';

export interface IAddedSupplierCommentsProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    uiStore?: UiStore
}

@inject('salesStore', 'uiStore')
@observer
class AddedSupplierComments extends React.Component<IAddedSupplierCommentsProps> {

     @observable private editDialogActive: boolean;
    @observable private supplierCommentLine: SupplierCommentLine;

    constructor(props: IAddedSupplierCommentsProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSupplierCommentSave = this.handleSupplierCommentSave.bind(this);
        this.handleDeleteSupplierComment = this.handleDeleteSupplierComment.bind(this);
        this.handleChange = this.handleChange.bind(this);
         runInAction(() => this.editDialogActive = false);
    }

    public render() {
        const { salesStore, uiStore } = this.props;
        return <div className="service-lines">
            <div className="lines-container">
                {
                    salesStore!.order.supplierCommentLines.map((s, i) => (
                        <SupplierCommentRow
                            key={i}
                            supplierCommentLine={s}
                            uiStore={uiStore}
                            onOpenDialog={this.handleOpenDialog}
                            onChange={(property: string, value: any) => this.handleChange(property, value, s)}
                            {...this.props}
                        />
                    ))
                }
            </div>
            {
                this.editDialogActive
                && <EditSupplierCommentDialog
                    active={this.editDialogActive}
                    handleClose={this.handleCloseDialog}
                    handleSave={this.handleSupplierCommentSave}
                    handleDelete={this.handleDeleteSupplierComment}
                    supplierComment={this.supplierCommentLine}
                /> 
            }
        </div>
    }

    @action
    private handleOpenDialog(supplierComment: SupplierCommentLine) {
        this.supplierCommentLine = supplierComment;
        this.editDialogActive = true;
    }

     @action
    private handleSupplierCommentSave(supplierCommentLine: SupplierCommentLine, parentSupplierCommentLine: SupplierCommentLine) {
        const { order } = this.props.salesStore!
        order.updateSupplierCommentLine(supplierCommentLine);
        if (parentSupplierCommentLine && order.parentOrder)
            order.parentOrder.updateSupplierCommentLine(parentSupplierCommentLine);
        this.editDialogActive = false;
    }

    @action
    private handleDeleteSupplierComment(supplierComment: SupplierCommentLine) {
        this.props.salesStore!.order.removeSupplierComment(supplierComment);
        this.editDialogActive = false;
    } 

    @action
    private handleCloseDialog(e: React.MouseEvent<HTMLDivElement>) {
        this.editDialogActive = false;
    }

    private handleChange(property: string, value: any, supplierComment: SupplierCommentLine) {
        supplierComment.updateAndValidate(property, value);
    }
}

interface ISupplierCommentProps extends WithStyles<typeof styles> {
    supplierCommentLine: SupplierCommentLine;
    uiStore?: UiStore;
    onChange(property: string, value: any): void
    onOpenDialog(supplierCommentLine: SupplierCommentLine): void
}
const SupplierCommentRow: React.SFC<ISupplierCommentProps> = observer((props) => {
    const { supplierCommentLine } = props;
    const { orderUiState } = props.uiStore!;
    return (<Grid container className="service-body">
        <Grid item xs={11}>
            <span className="item-name">{supplierCommentLine.supplierName ? supplierCommentLine.supplierName : "Stocking Point" } {supplierCommentLine.exWorksName}</span>
        </Grid>
        <Grid item xs={1} className="icon">
            {
                !orderUiState.formReadOnly
                && <MoreHoriz onClick={() => props.onOpenDialog(supplierCommentLine)} />
            }
        </Grid>
        <Grid item xs={12}>
                <TextField
                    className="supplierComment-comment"
                    type="text"
                    name="comment"
                    label="Comment"
                    value={supplierCommentLine.comment}
                    error={Boolean(supplierCommentLine.errors.comment)}
                    helperText={supplierCommentLine.errors.comment}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange('comment', e.currentTarget.value)}
                    disabled={orderUiState!.isReadonly('supplierComment')}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            input: props.classes.highlightText
                        }
                    }}
                    inputProps={{ maxLength: 60 }}
                    fullWidth
                    multiline
                    autoFocus
                />
            </Grid>
    </Grid>)
})

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    rowText: {
        textAlign: "right",
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
        }
    }
});

export default withStyles(styles)(AddedSupplierComments);