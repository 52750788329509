import * as React from 'react';
import './styles.css';
import { observer } from 'mobx-react';
import { Grid, TextField, WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from 'src/theme';
import { Workflow } from 'src/models/workflow';

interface IWorkflowSummaryProps extends WithStyles<typeof globalStyles> {
    workflow: Workflow
}

@observer
class WorkflowSummary extends React.Component<IWorkflowSummaryProps> {
    constructor(props: IWorkflowSummaryProps) {
        super(props);
    }

    public render() {
        return <React.Fragment>
            <Grid container className="section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Workflow</h2>
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Status"
                        type="text"
                        value={`Pending ${this.props.workflow.workflowName} Approval`}
                        disabled
                        fullWidth
                    />
                    <TextField
                        className={this.props.classes.textFieldFullWidth}
                        InputProps={{ 
                            disableUnderline: true,
                            classes: {
                                input: this.props.classes.highlightText
                            }
                        }}
                        label="Approval Request"
                        type="text"
                        value={this.props.workflow.reason}
                        disabled
                        fullWidth
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    }
}

export default withStyles(globalStyles)(WorkflowSummary);