import { apiFetch } from '../utilities/apiFetch';

export interface IAttacheSyncSummary {
  jobName: string;
  jobRunDateTimeUtc: string;
  jobSummary: string;
  isSuccessful: boolean;
}

export class AttacheSyncApi {
  public async attacheSync(): Promise<boolean> {
    const response = await apiFetch('/api/attacheSync', {
      method: 'post',
    });

    return response?.ok ?? false;
  }

  public async getSyncSummary(): Promise<IAttacheSyncSummary[]> {
    const response = await apiFetch('/api/attacheSync');
    const json = await response?.json();
    if (!json) return [];
    return json;
  }
}
