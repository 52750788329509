import React from 'react';
import { MsalAuthenticationResult } from '@azure/msal-react';
import { Typography } from '@material-ui/core';

export const MsalError = (props: MsalAuthenticationResult) => {
  if (!props.error) return null;

  return (
    <div>
      <Typography variant="h3">Microsoft authentication error</Typography>
      <section>
        <Typography variant="subtitle1">Error code: {props.error.errorCode}</Typography>
        <Typography variant="body1">Details: {props.error.errorMessage}</Typography>
      </section>
    </div>
  );
};
