import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { ProductsStore } from '../../stores/products-store';
import { UiStore } from '../../stores/ui-store';
import { observable, action, runInAction } from 'mobx';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import TextField from '@material-ui/core/TextField';
import { ProductLine } from 'src/models/product-line';
import Grid from '@material-ui/core/Grid';
import EditProductDialog from './EditProductDialog';
import Hidden from '@material-ui/core/Hidden';
import { Loader } from '../Loader/Loader';
import { Validate } from '../../validate';
import { WithStyles, withStyles, Theme, createStyles } from '@material-ui/core/styles';
import { globalStyles } from '../../theme';
import NumberField, { INumberChangedEvent } from '../NumberField/NumberField';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { ListAlt, Warning, Home } from '@material-ui/icons';
import {roundedSubtotal} from "../../math";
import tracker from '../Loader/loader-tracker';
import { Order } from '../../models/order';
import { AuthStore } from 'src/stores/auth-store';
import { IStockInfo } from 'src/services/sales-api';

export interface IAddedProductsProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    uiStore?: UiStore
    authStore?: AuthStore
    productsStore?: ProductsStore
    clonedFrom?: string;
    stockInfos: IStockInfo[];
}

@inject('salesStore', 'uiStore', 'productsStore', 'authStore')
@observer
class AddedProducts extends React.Component<IAddedProductsProps> {

    @observable private editDialogActive: boolean;
    @observable private productLine: ProductLine;
    @observable private cloneOriginal: Order;

    constructor(props: IAddedProductsProps) {
        super(props);
        this.handleOpenDialog = this.handleOpenDialog.bind(this);
        this.handleCloseDialog = this.handleCloseDialog.bind(this);
        this.handleSaveProduct = this.handleSaveProduct.bind(this);
        this.handleDeleteProduct = this.handleDeleteProduct.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleQuantityChange = this.handleQuantityChange.bind(this);
        runInAction(() => this.editDialogActive = false);
    }

    public async componentDidMount() {
        if(this.props.clonedFrom) {
            const original = await tracker.track(this.props.salesStore!.loadOrderDirectlyFromApi(this.props.clonedFrom));
            runInAction(() => (this.cloneOriginal = original));
        }
    }

    public render() {
        const { salesStore } = this.props;
        let currentKey = "";
        //console.log('order',salesStore!.order.isParentOrder);
        return <div className="product-lines">
            <div className="lines-container">
                <Loader area="products-area" />
                {
                    salesStore!.order.orderedProductLines.map((product, i) => {
                        const originalProductLine = this.cloneOriginal ? this.cloneOriginal.productLines.find(p => p.id === product.id) : undefined;
                        const key = product.supplierName + " " + product.exWorksName;
                        const stockInfo = this.props.stockInfos.find(si => si.productId === product.productId);
                        if (currentKey !== key) {
                            currentKey = key;
                            return <React.Fragment key={key + i + product.lineNumber}>
                                <div className="product-group-header"><span>{key}</span></div>
                                {
                                    <ProductRow
                                        key={i + key + product.lineNumber}
                                        productLine={product}
                                        stockInfo={stockInfo}
                                        onOpenDialog={this.handleOpenDialog}
                                        onChange={(property: string, value: any) => this.handleChange(property, value, product)}
                                        onChangeQuantity={(quantity: number) => this.handleQuantityChange(quantity, product)}
                                        clonedProductLine={originalProductLine}
                                        {...this.props}
                                    />

                                }

                            </React.Fragment>
                        }
                        return <React.Fragment key={key + i + product.lineNumber}>
                            {
                                <ProductRow
                                    key={i + key + product.lineNumber}
                                    productLine={product}
                                    stockInfo={stockInfo}
                                    onOpenDialog={this.handleOpenDialog}
                                    onChange={(property: string, value: any) => this.handleChange(property, value, product)}
                                    onChangeQuantity={(quantity: number) => this.handleQuantityChange(quantity, product)}
                                    clonedProductLine={originalProductLine}
                                    {...this.props}
                                />
                            }

                        </React.Fragment>

                    })
                }
                {
                    (salesStore!.order.isSplitting && salesStore!.order.totalProductQtyAvailableOnParent <= 0) &&
                    <p className="order-validation-error MuiFormHelperText-error">The parent order cannot be left empty.</p>
                }
            </div>
            {
                this.editDialogActive
                && <EditProductDialog
                    stockInfo={this.props.stockInfos.find(si => si.productId === this.productLine.productId)}
                    active={this.editDialogActive}
                    handleClose={this.handleCloseDialog}
                    handleSave={this.handleSaveProduct}
                    handleDelete={this.handleDeleteProduct}
                    productLine={this.productLine}
                />
            }
        </div>
    }

    @action
    private handleOpenDialog(productLine: ProductLine) {
        this.productLine = productLine;
        this.editDialogActive = true;
    }

    @action
    private handleSaveProduct(productLine: ProductLine, parentProductLine: ProductLine) {
        const { order } = this.props.salesStore!
        order.updateProductLine(productLine);
        if (parentProductLine && order.parentOrder)
            order.parentOrder.updateProductLine(parentProductLine);

        this.editDialogActive = false;
        const { orderUiState } = this.props.uiStore!;
        const { salesStore } = this.props;

        if (salesStore!.order.readyForProductLines && (salesStore && !salesStore.order.isSplitting)) {
            orderUiState.setAddProductActive(true);
        }
    }

    @action
    private handleDeleteProduct(productLine: ProductLine) {
        this.props.salesStore!.order.removeProductLine(productLine);
        this.editDialogActive = false;
    }

    @action
    private handleCloseDialog(e: React.MouseEvent<HTMLDivElement>) {
        this.editDialogActive = false;
    }

    private handleChange(property: string, value: any, productLine: ProductLine) {
        productLine.updateAndValidate(property, value);
    }

    private handleQuantityChange(quantity: number, productLine: ProductLine) {
        productLine.allocateQuantities(quantity);
    }
}

interface IProductRowProps extends WithStyles<typeof styles> {
    productLine: ProductLine;
    uiStore?: UiStore;
    authStore?: AuthStore;
    salesStore?: SalesStore;
    onChange(property: string, value: any): void
    onChangeQuantity(quantity: number): void
    onOpenDialog(serviceLine: ProductLine): void
    // only set when isCloning=true
    clonedProductLine: ProductLine | undefined;
    stockInfo: IStockInfo | undefined;
}
const ProductRow: React.SFC<IProductRowProps> = observer((props: IProductRowProps) => {
    const { productLine } = props;
    const { orderUiState } = props.uiStore!;
    const { authStore, salesStore, stockInfo } = props;
    const relatedOrder = salesStore?.order.isParentOrder;
    const splitInfo = productLine.orderSplitQtyLabel;
    let stockError = undefined;
    if (stockInfo && productLine.quantity > stockInfo.quantityAvailable) stockError = 'Allocation exceeds stock';
    return (<Grid container className="product-body" spacing={8}>
        <Grid item xs={11}>
            <span className="item-name">{productLine.name}
                <span className="sub-text"> ({productLine.code})</span>
                {
                    productLine.raisePurchaseOrder && productLine.quantity > 0
                    && <IconTooltip title="PO raised" placement="right">
                        {{ icon: <ListAlt className="po-raised-icon" /> }}
                    </IconTooltip>
                }
                {
                    productLine.isProductDeleted &&
                    <div>
                        <IconTooltip title="Product code is inactive" placement="left">
                            {{ icon: <Warning color="error" /> }}
                        </IconTooltip>
                    </div>
                }
                {
                    !productLine.quantity && relatedOrder &&
                    <div className="warehouse-div">
                        <IconTooltip title="Warehouse Order" placement="right">
                            {{ icon: <Home className="warehouse-icon" /> }}
                        </IconTooltip>
                        <div className="warehouse-text">({relatedOrder.orderNumber})</div>
                    </div>
                }
            </span>
        </Grid>
        <Grid item xs={1} className="icon">
            {
                !orderUiState!.formReadOnly
                && <MoreHoriz onClick={() => props.onOpenDialog(productLine)} />
            }
        </Grid>
        {
            splitInfo
            && <Grid item xs={12}>
                <div className="split-info">{splitInfo}</div>
            </Grid>
        }

        {
            !splitInfo && stockInfo
            && <Grid item xs={12}>
                <div className='split-info'>{stockInfo.quantityAvailable} in stock ({stockInfo.locationName}) </div>
            </Grid>
        }
        <Hidden mdDown>
            <Grid container style={{ marginLeft: 5 }}>
                <Grid item lg className="product-label">{productLine.unitsLabel}</Grid>
                <Grid item lg className="product-label">Total Pieces</Grid>
                <Grid item lg className="product-label">Unit Price</Grid>
                <Grid item lg className="product-label">Total</Grid>
                {
                    authStore!.isPricingManager
                    && <Grid item lg className="product-label">GP (%)</Grid>
                }

            </Grid>
        </Hidden>
        <Hidden lgUp>
            <Grid item xs={8} sm={10} className="product-label">{productLine.unitsLabel}</Grid>
        </Hidden>
        <Grid item xs={4} sm={2} lg>
            <NumberField
                InputProps={{ classes: { input: props.classes.rowText } }}
                value={productLine.quantity}
                error={productLine.errors.quantity ? productLine.errors.quantity : stockError}
                onChange={(e: INumberChangedEvent) => props.onChangeQuantity(e.parsedValue)}
                textProps={{
                    name: "quantity",
                    fullWidth: true,
                    autoFocus: true,
                    disabled: !orderUiState!.canEditProductQuantity(productLine),
                    onKeyDown: (e) => {
                        if (e.key === 'Enter') {
                            orderUiState.setAddProductActive(true);
                        }
                    }
                }}
            />
            {
                productLine.hasCredit
                && <div className="more-info">{`(credit ${productLine.creditQuantity} units)`}</div>
            }
        </Grid>
        <Hidden lgUp>
            <Grid item xs={8} sm={10} className="product-label">Total Pieces</Grid>
        </Hidden>
        <Grid item xs={4} sm={2} lg>
            <TextField
                InputProps={{
                    disableUnderline: true,
                    classes: { input: props.classes.rowText }
                }}
                type="text"
                name="totalpieces"
                error={!!productLine.errors.totalpieces}
                helperText={productLine.errors.totalpieces || null}
                value={productLine.totalPieces.toFixed(2)}
                disabled
                fullWidth
            />
        </Grid>
        <Hidden lgUp>
            <Grid item xs={8} sm={10} className="product-label">Unit Price</Grid>
        </Hidden>
        <Grid item xs={4} sm={2} lg>
            <TextField
                InputProps={productLine.hasCustomPrice ? {
                    disableUnderline: true,
                    classes: { input: `${props.classes.highlightText} ${props.classes.rowText}` },
                } : {
                    disableUnderline: true,
                    classes: { input: props.classes.rowText }
                }}
                type="text"
                name="unitcost"
                error={!!productLine.errors.price}
                helperText={productLine.errors.price || null}
                value={`$${productLine.price.toFixed(2)}`}
                disabled
                fullWidth
            />
            {
                productLine.hadPreviousCustomPrice
                && <div className="more-info">{`(was $${productLine.initialPrice.toFixed(2)})`}</div>
            }
            {
                props.clonedProductLine && props.clonedProductLine.price !== productLine.price
                && <div className="cloned-info">{`(originally $${props.clonedProductLine.price.toFixed(2)})`}</div>
            }

        </Grid>
        <Hidden lgUp>
            <Grid item xs={8} sm={10} className="product-label">Total</Grid>
        </Hidden>
        <Grid item xs={4} sm={2} lg>
            <TextField
                InputProps={{
                    disableUnderline: true,
                    classes: { input: props.classes.rowText }
                }}
                type="text"
                name="total"
                value={`$${roundedSubtotal(productLine.unroundedSubtotal).toFixed(2)}`}
                disabled
                fullWidth
            />
        </Grid>
        {
            authStore!.isPricingManager
            && <Hidden lgUp>
                  <Grid item xs={8} sm={10} className="product-label">GP(%)</Grid>
                </Hidden>
        }

        {
            authStore!.isPricingManager
            && <Grid item xs={4} sm={2} lg>
                <TextField
                    InputProps={{
                        disableUnderline: true,
                        classes: { input: props.classes.rowText }
                    }}
                    type="text"
                    name="grossProfit"
                    error={!!productLine.errors.grossProfit}
                    helperText={productLine.errors.grossProfit || null}
                    value={productLine.grossProfit}
                    disabled
                    fullWidth
                />
            </Grid>
        }

        {
            Validate.hasValue(productLine.note)
            && <Grid item xs={12}>
                <TextField
                    className="product-note"
                    type="text"
                    name="note"
                    label="Note"
                    value={productLine.note}
                    error={!!productLine.errors.note}
                    helperText={productLine.errors.note || null}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => props.onChange(e.currentTarget.name, e.currentTarget.value)}
                    disabled={orderUiState!.isReadonly("product-note")}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            input: props.classes.highlightText
                        }
                    }}
                    inputProps={{ maxLength: 60 }}
                    fullWidth
                    multiline
                />
            </Grid>
        }
    </Grid>)
})

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    rowText: {
        textAlign: "right",
        [theme.breakpoints.up('sm')]: {
            textAlign: "left",
        }
    }
});

export default withStyles(styles)(AddedProducts);
