import * as React from 'react';
import { DateTime } from 'luxon';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { Payment, ListAlt, PersonAddDisabled, ThumbDown } from '@material-ui/icons';
import { observer } from 'mobx-react';

import { SalesStore } from 'src/stores/sales-store';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { PurchaseOrderStatus } from 'src/models/purhcase-order-status';
import { LoadWhenVisible } from '../LoadWhenVisible/LoadWhenVisible';
import { InactiveProductsIcon } from './InactiveProductsIcon';

interface IOrderProps {
  salesStore?: SalesStore;
}

type Props = IOrderProps & RouteComponentProps<{}>;

export const Orders: React.FC<Props> = observer((props: Props) => {
  const getDateLabel = (date: DateTime, active: boolean): { value: string; style: string } => {
    const today = DateTime.local().toISODate();
    if (date.toISODate() === today && active) return { value: 'Deliver Today', style: 'green ' };

    const label: { value: string; style: string } = {
      value: `Deliver ${date.toLocaleString({ month: 'short', day: '2-digit', year: '2-digit' })}`,
      style: '',
    };

    if (date.toISODate() < today && active) label.style = 'red ';

    return label;
  };

  const scrollTbaBannerToTop = () => {
    const itemHeight = document.getElementsByClassName('item')[0].clientHeight;
    const top = props.salesStore!.readyOrders.length * itemHeight;
    window.scrollTo({ top, left: 0, behavior: 'smooth' });
  };

  return (
    <div>
      {props.salesStore!.readyOrders.map((o) => {
        if (!o.deliveryDate) return null;
        const date = getDateLabel(o.deliveryDate, o.isActive);
        return (
          <Grid
            container
            className={`item ${!o.isActive ? 'cancelled' : ''}`}
            key={o.number}
            onClick={() => props.history.push(`view-order/${o.number}`)}
          >
            <Grid item xs={1} />
            <Grid item xs={7} className={`number ${o.isCustomerInDispute ? 'indispute' : ''}`}>
              {o.customer}
            </Grid>
            <Grid item xs={3} className="icon">
              {!o.isActive && (
                <IconTooltip title="Order cancelled" placement="left">
                  {{ icon: <ThumbDown /> }}
                </IconTooltip>
              )}
              {o.isCustomerInDispute && (
                <IconTooltip title="Customer in Dispute" placement="left">
                  {{ icon: <PersonAddDisabled color="error" /> }}
                </IconTooltip>
              )}
              {o.isActive && o.isPaymentPending && (
                <IconTooltip title="Still requires full payment" placement="left">
                  {{ icon: <Payment color="error" /> }}
                </IconTooltip>
              )}
              {o.isActive && o.purchaseOrderStatus === PurchaseOrderStatus.Delayed && (
                <IconTooltip title="Purchase order delayed" placement="left">
                  {{ icon: <ListAlt color="error" /> }}
                </IconTooltip>
              )}
              {o.isActive && o.purchaseOrderStatus === PurchaseOrderStatus.Failure && (
                <IconTooltip title="Purchase order failure" placement="left">
                  {{ icon: <ListAlt color="error" /> }}
                </IconTooltip>
              )}
              {
                <LoadWhenVisible>
                  <InactiveProductsIcon salesStore={props.salesStore} orderId={o.orderId} />
                </LoadWhenVisible>
              }
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} />
            <Grid item xs={7} className="customer">
              <span>
                <b>{o.number}:</b> {o.address}
              </span>
              {o.customerReference && (
                <span>
                  <b>Customer Reference:</b> {o.customerReference}
                </span>
              )}
            </Grid>
            <Grid item xs={3} className={'date ' + date.style}>
              {date.value}
            </Grid>
          </Grid>
        );
      })}
      {props.salesStore!.countTbaOrders > 0 && (
        <Grid container className="tba-banner" onClick={scrollTbaBannerToTop}>
          <Grid item xs={1} />
          <Grid item xs={11}>
            {props.salesStore!.countTbaOrders === 1 ? '1 order TBA' : `${props.salesStore!.countTbaOrders} orders TBA`}
          </Grid>
        </Grid>
      )}
      {props.salesStore!.tbaOrders.map((o) => {
        const date = o.createdDate.toLocaleString({ month: 'short', day: '2-digit' });
        return (
          <Grid
            container
            className={`item ${!o.isActive ? 'cancelled' : ''} tba-order`}
            key={o.number}
            onClick={() => props.history.push(`view-order/${o.number}`)}
          >
            <Grid item xs={1} />
            <Grid item xs={7} className={`number ${o.isCustomerInDispute ? 'indispute' : ''}`}>
              {o.customer}
            </Grid>
            <Grid item xs={3} className="icon">
              {!o.isActive && (
                <IconTooltip title="Order cancelled" placement="left">
                  {{ icon: <ThumbDown /> }}
                </IconTooltip>
              )}
              {o.isCustomerInDispute && (
                <IconTooltip title="Customer in Dispute" placement="left">
                  {{ icon: <PersonAddDisabled color="error" /> }}
                </IconTooltip>
              )}
              {o.isActive && o.isPaymentPending && (
                <IconTooltip title="Still requires full payment" placement="left">
                  {{ icon: <Payment color="error" /> }}
                </IconTooltip>
              )}
              {
                <LoadWhenVisible>
                  <InactiveProductsIcon orderId={o.orderId} salesStore={props.salesStore} />
                </LoadWhenVisible>
              }
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={1} />
            <Grid item xs={7} className="customer">
              <span>
                <b>{o.number}:</b> {o.address}
              </span>
            </Grid>
            <Grid item xs={3} className="date">
              {' '}
              Created {date}
            </Grid>
          </Grid>
        );
      })}
    </div>
  );
});
