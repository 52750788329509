import type { ISupplierSummary } from '../models/supplier-summary';
import type { ISupplierDetail } from '../models/supplier-detail';
import type { ExWorks } from '../models/ex-works';
import type { IExWorksResponse, IAddressResponse } from './sales-api';
import { apiFetch } from '../utilities/apiFetch';
import type { IRegionSummary } from 'src/models/region-summary';
import { toZonedTime } from '../datetime';

export interface IRegionResponse {
  id: number;
  name: string;
}

export class SuppliersApi {
  public async get(): Promise<ISupplierSummary[]> {
    const response = await apiFetch('/api/suppliers');
    const json = await response?.json();
    return json ?? [];
  }

  public async getExWorksRegions(exWorksId: number): Promise<IRegionSummary[]> {
    const response = await apiFetch(`/api/exworks/allregions/${exWorksId}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async load(id: number): Promise<ISupplierDetail> {
    const response = await apiFetch(`/api/suppliers/${id}`);
    return response?.json();
  }

  public async loadExWorks(id: string): Promise<IExWorksResponse> {
    const response = await apiFetch(`/api/exworks/${id}`);
    return response?.json();
  }

  public async loadRegions(): Promise<IRegionResponse[]> {
    const response = await apiFetch('/api/exworks/regions');
    const json = await response?.json();
    return json ?? [];
  }

  public async saveExWorks(exWorks: ExWorks): Promise<boolean> {
    const response = await apiFetch('/api/exworks', {
      method: 'post',
      body: JSON.stringify({
        ...exWorks,
        openingTime: toZonedTime(exWorks.openingTime),
        closingTime: toZonedTime(exWorks.closingTime),
      }),
    });
    return response?.ok ?? false;
  }

  public async searchPickupLocations(query: string): Promise<IAddressResponse[]> {
    const response = await apiFetch(`/api/pickuplocations?searchQuery=${encodeURIComponent(query)}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async searchSuppliers(query: string): Promise<ISupplierSummary[]> {
    const response = await apiFetch(`/api/suppliers/search?searchQuery=${encodeURIComponent(query)}`);
    const json = await response?.json();
    return json ?? [];
  }
}
