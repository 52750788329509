import { Model } from './model';
import { Validate } from '../validate';
import { observable, action } from 'mobx';
import * as EmailValidator from 'email-validator';
import { DateTime } from 'luxon';

export class QuoteShared extends Model {

    @observable public email: string = '';
    @observable public name: string = '';
    @observable public sharedDate: DateTime | null = null;

    @action 
    public setEmail(email: string) {
        this.email = email;
    }

    @action 
    public setName(name: string) {
        this.name = name;
    }
    
    protected validators: {[name: string]: (val: any) => boolean} = {
        "email": (val: string) => {
            this.errors.email = Validate.hasValue(val) && EmailValidator.validate(val)
                ? null : "Email address is invalid";
            return this.errors.email === null;
        },
        "name": (val: string) => {
            this.errors.name = Validate.hasValue(val)
                ? null : "Customer name is required";
            return this.errors.name === null;
        }
    }
}