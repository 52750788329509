import * as React from 'react';
import './styles.css';
import { inject, observer } from 'mobx-react';
import { WithStyles, withStyles, createStyles, Theme } from '@material-ui/core';
import { DeliveryType } from '../../models/order';
import { UiStore } from '../../stores/ui-store';
import { SalesStore } from '../../stores/sales-store';
import { globalStyles } from '../../theme';
import FreightOnlyTab from './FreightOnlyTab';
import DeliveryTab from './DeliveryTab';
import PickupTab from './PickupTab';

export interface IDeliveryTabsProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    uiStore?: UiStore
}

@inject('uiStore', 'salesStore', 'suppliersStore')
@observer
class DeliveryTabs extends React.Component<IDeliveryTabsProps, {}> {

    private readonly tabs: Array<{ name: string, value: DeliveryType }> = [
        { name: 'Delivery', value: DeliveryType.Delivery },
        { name: 'Pick Up', value: DeliveryType.PickUp },
        { name: 'Freight Only', value: DeliveryType.FreightOnly }
    ];

    constructor(props: IDeliveryTabsProps) {
        super(props);
    }

    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        return (<React.Fragment>
            <div className="delivery-tabs">
                {
                    this.tabs.map(t => (<h2 className={salesStore!.order.deliveryType !== t.value ? 'inactive' : ''}
                        onClick={() => {
                            if (orderUiState!.isReadonly('deliveryType')) return;
                            salesStore!.order.setDeliveryType(t.value);
                        }} key={t.value}>{t.name}</h2>))
                }
            </div>
            {
                salesStore!.order.deliveryType === DeliveryType.Delivery && <DeliveryTab />
            }
            {
                salesStore!.order.deliveryType === DeliveryType.PickUp && <PickupTab />
            }
            {
                salesStore!.order.deliveryType === DeliveryType.FreightOnly && <FreightOnlyTab />
            }
        </React.Fragment>
        );
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    checkBox: {
        marginTop: -14,
        marginRight: 0,
    },
    checkBoxLabel: {
        fontSize: "1rem",
        color: theme.palette.text.secondary
    }
});

export default withStyles(styles)(DeliveryTabs);
