import { observable, action, runInAction } from 'mobx';
import { SettingsApi } from 'src/services/settings-api';
import { UserAppSettings } from 'src/models/user-app-settings';

export class SettingsStore {
    @observable public settings: UserAppSettings = new UserAppSettings({});
    
    constructor(private settingsApi: SettingsApi) { }

    @action
    public async load(): Promise<UserAppSettings> {
        const settings = await this.settingsApi.get();
        runInAction(() => {
            this.settings = UserAppSettings.fromResponse(settings);
            this.settings.track();
        });
        return this.settings;
    }

    @action
    public async save(): Promise<boolean> {
        const ok = await this.settingsApi.save(this.settings);
        this.settings.untrack();
        return ok;
    }
}
