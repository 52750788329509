import './styles.css';

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { ProductsStore } from '../../stores/products-store';
import tracker from '../Loader/loader-tracker';
import { Header } from '../Header/Header';
import { NoRecords } from '../NoRecords/NoRecords';
import Locations from './Locations';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface ILocationIndexProps {
  productsStore?: ProductsStore;
}

type Props = ILocationIndexProps & RouteComponentProps<{}>;

@inject('productsStore')
@observer
class LocationIndex extends React.Component<Props> {
  private productsStore?: ProductsStore;

  constructor(props: Props) {
    super(props);
    this.productsStore = props.productsStore;
  }

  public async componentDidMount() {
    await tracker.track(this.productsStore!.getLocations());
  }

  public render() {
    return (
      <React.Fragment>
        <Header title="Locations" />

        <section className="locations">
          <Locations locations={this.productsStore!.locations} {...this.props} />
          <NoRecords message="No more locations" active />
        </section>
      </React.Fragment>
    );
  }
}

export default withAITracking(reactPlugin, LocationIndex, 'LocationIndex');
