import { observable, runInAction } from 'mobx';
import { Model } from './model';
import { Validate } from '../validate';
import { IAddressResponse } from '../services/sales-api';
import { SuggestionItem } from 'src/components/Autocomplete/Autocomplete';
import { IPickupLocation } from 'src/stores/suppliers-store';

export interface ILatLng { lat: number | null, lng: number | null }

export enum LocationType
{
    None = 0,
    Abc = 1,
    Supplier = 2
}

export class Address extends Model {

    @observable public exWorksId?: number;
    @observable public formattedAddress: string;
    @observable public name: string;
    @observable public street: string;
    @observable public suburb: string;
    @observable public state: string;
    @observable public postcode: string;
    @observable public latLng: ILatLng;
    @observable public locationType: LocationType;

    constructor({formattedAddress = '', name = '', street = '', suburb = '', state = '', postcode = '',
        latLng = { lat: null, lng: null } as ILatLng,
        locationType = LocationType.None,
        exWorksId = 0}){
        super();
        runInAction(() => {
            this.exWorksId = exWorksId === 0 ? undefined : exWorksId;
            this.formattedAddress = formattedAddress;
            this.name = name;
            this.street = street;
            this.suburb = suburb;
            this.state = state;
            this.postcode = postcode;
            this.latLng = latLng;
            this.locationType = locationType;
        });
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "formattedAddress": (val: string) => {
            this.errors.formattedAddress = this.latLng.lat !== null && this.latLng.lng !== null
                ? null : "Address selection is required";
            if (this.errors.formattedAddress === null)  {
                this.errors.formattedAddress = Validate.hasValue(val)
                    ? null : "Address is required";
            }
            return this.errors.formattedAddress === null;
        }
    }

    public static fromResponse(r: IAddressResponse): Address {
        const a = new Address({
            formattedAddress: r.formattedAddress || '',
            name: r.name || '',
            street: r.street || '',
            suburb: r.suburb || '',
            state: r.state || '',
            postcode: r.postcode || '',
            latLng: r.latLng,
            locationType: r.locationType,
            exWorksId: r.exWorksId
        });
        return a;
    }

    public static fromPlace(r: google.maps.GeocoderResult, l: ILatLng): Address {

        const a = new Address({
            formattedAddress: r.formatted_address || '',
            street: `${this.findAddressPart('street_number', r.address_components)} ${this.findAddressPart('route', r.address_components)}`.trim(),
            suburb: this.findAddressPart('locality', r.address_components) === ''
                ? this.findSuburb(r.formatted_address)
                : this.findAddressPart('locality', r.address_components),
            state: this.findAddressPart('administrative_area_level_1', r.address_components),
            postcode: this.findAddressPart('postal_code', r.address_components),
            latLng: l
        });
        return a;
    }

    public toSuggestionItem() : SuggestionItem<IPickupLocation> {
        const label = this.name.length === 0 ? this.formattedAddress : this.name;
        return { label, value: { name: this.name, address: this }};
    }

    public static findAddressPart(part: string, addressComponents: google.maps.GeocoderAddressComponent[]) {
        const p = addressComponents.find(c => c.types.some(t => t === part));
        return p ? p.long_name : '';
    }

    public static findSuburb (formattedAddress: string) {
        const parts = formattedAddress.split(',');
        if (parts && parts.length > 0) {
            return parts[0];
        }
        return '';
    }
}
