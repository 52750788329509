import { observable, action } from 'mobx';
import { Model } from './model';
import { Validate } from 'src/validate';
import { IAbcLocationResponse } from 'src/services/products-api';
import { Address } from './address';

export class AbcLocation extends Model {
    @observable public address: Address = new Address({});
    @observable public code: string = '';
    @observable public glSet: string = '';
    @observable public id: number = 0;
    @observable public name: string = '';
    @observable public errors: { [field: string]: string | null } = {}
    @observable public isDeleted: boolean = false;

    @action
    public static fromResponse(d: IAbcLocationResponse): AbcLocation {
        const location = new AbcLocation();
        location.address = Address.fromResponse(d.address);
        location.code = d.code || '';
        location.glSet = d.glSet || '';
        location.id = d.id;
        location.name = d.name || ''
        location.isDeleted = d.isDeleted;

        return location;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "name": (val: string) => {
            this.errors.name = Validate.hasValue(val)
                ? null : "Name required";
            return this.errors.name === null;
        },
        "glSet": (val: string) => {
            this.errors.glSet = Validate.hasValue(val)
                ? null : "GL Set required";
            return this.errors.glSet === null;
        }
    }
}