
import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';

export interface IWorkflowMenuProps {
    onApprove(): void
    onReject(): void
}

@inject('uiStore')
@observer
export class WorkflowMenu extends React.Component<IWorkflowMenuProps, {}> {
    public render() {
        return <div className="workflow-menu">
            <div className="actions-container">
                <div
                    className="workflow-action reject"
                    onClick={this.props.onReject}>
                    Reject
            </div>
                <div
                    className="workflow-action approve"
                    onClick={this.props.onApprove}>
                    Approve
            </div>
            </div>
        </div>
    }
}