import './styles.css';
import { RouteComponentProps } from 'react-router';

import { withAITracking } from '@microsoft/applicationinsights-react-js';
import {
  Grid,
  TextField,
  InputAdornment,
  WithStyles,
  withStyles,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import * as React from 'react';
import Save from '@material-ui/icons/Save';
import { observer, inject } from 'mobx-react';

import { Header } from '../Header/Header';
import { Markup } from '../../models/markup';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import { ProductsStore } from '../../stores/products-store';
import tracker from '../Loader/loader-tracker';
import { withChangesPrompt } from '../Prompt/Prompt';
import { globalStyles } from 'src/theme';
import { ShippingType } from 'src/models/range';
import { UiStore } from '../../stores/ui-store';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface IRangeDetailProps extends WithStyles<typeof globalStyles> {
  productsStore?: ProductsStore;
  uiStore?: UiStore;
}
type Props = IRangeDetailProps & RouteComponentProps<MatchParams>;
interface MatchParams {
  id: string;
}

@inject('productsStore', 'uiStore')
@observer
class EditRange extends React.Component<Props> {
  private productsStore?: ProductsStore;

  private readonly shippingTypes: Array<{ name: string; value: ShippingType }> = [
    { name: 'Neither', value: ShippingType.Neither },
    { name: 'Pallets', value: ShippingType.Pallets },
    { name: 'Packs', value: ShippingType.Packs },
  ];

  constructor(props: Props) {
    super(props);
    this.productsStore = props.productsStore;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleShippingTypeChanged = this.handleShippingTypeChanged.bind(this);
  }

  public async componentWillMount() {
    const id = this.props.match.params.id;
    await tracker.track(this.productsStore!.loadRange(id));
  }

  public render() {
    const range = this.productsStore!.range;
    return (
      <div className="range">
        <Header title={!range ? '' : range.name} />
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={10} className="name">
            <TextField
              name="name"
              value={range.name}
              error={!!range.errors.name}
              helperText={range.errors.name || null}
              className={this.props.classes.textFieldFullWidth}
              InputProps={{ disableUnderline: true }}
              label="Name"
              onChange={this.handleChange}
              fullWidth
              autoFocus
            />
            <FormControl className={this.props.classes.textField}>
              <InputLabel htmlFor="shippingType">Shipping Type</InputLabel>
              <Select
                disableUnderline
                value={range.shippingType}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleShippingTypeChanged(e.target.value)}
                inputProps={{ name: 'shippingType', id: 'shippingType' }}
              >
                {this.shippingTypes.map((t) => (
                  <MenuItem value={t.value} key={t.value}>
                    {t.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <TextField
              name="code"
              value={range.code}
              className={this.props.classes.textField}
              InputProps={{ disableUnderline: true }}
              label="Code"
              disabled
            />
          </Grid>
        </Grid>
        <Grid container className="markups">
          <Grid item xs={1} />
          <Grid item xs={10}>
            {range.markups.map((markup, idx) => {
              return (
                <Grid container className="item" key={idx}>
                  <EditMarkup
                    index={idx}
                    markup={markup}
                    handleBlur={this.handleMarkupBlur}
                    handleChange={this.handleMarkupChange}
                  />
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <ActionsMenu>
          <ActionItem label="Save Range" onClick={this.handleSubmit}>
            <Save />
          </ActionItem>
        </ActionsMenu>
      </div>
    );
  }

  private handleMarkupBlur(e: React.ChangeEvent<HTMLInputElement>, markup: Markup) {
    markup.update(e.currentTarget.name, Number(e.currentTarget.value));
  }

  private handleMarkupChange(e: React.ChangeEvent<HTMLInputElement>, markup: Markup) {
    markup.update(e.currentTarget.name, Number(e.currentTarget.value));
  }

  private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    this.productsStore!.range.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
  }

  private handleShippingTypeChanged(value: string) {
    this.productsStore!.range.updateAndValidate('shippingType', value);
  }

  private async handleSubmit() {
    const { history, uiStore, productsStore } = this.props;
    const { notificationUiState } = this.props.uiStore!;
    if (!productsStore!.range.validate()) return;

    const ok = await productsStore!.saveRange();
    if (ok) {
      uiStore!.setActionsMenuActive(false);
      history.push(`/ranges`);
    } else {
      notificationUiState.showError('Error saving range...');
    }
  }
}

interface EditMarkupProps {
  index: number;
  markup: Markup;
  uiStore?: UiStore;
  handleChange(e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>, markup: Markup): void;
  handleBlur(e: React.ChangeEvent<HTMLInputElement>, markup: Markup): void;
}
const EditMarkup: React.SFC<EditMarkupProps> = observer((props) => {
  const { index, markup } = props;
  return (
    <React.Fragment>
      <Grid container>
        <Grid item xs={10}>
          <span>{markup.tierName}</span>
        </Grid>
        <Grid item xs={2}>
          <TextField
            InputProps={{ disableUnderline: true, endAdornment: <InputAdornment position="end">%</InputAdornment> }}
            type="number"
            name="percentageMarkup"
            value={markup.percentageMarkup || ''}
            error={!!markup.errors.percentageMarkup}
            helperText={markup.errors.percentageMarkup || null}
            onChange={(e) => props.handleChange(e, markup)}
            onBlur={(e) => props.handleBlur(e, markup)}
            autoFocus={index === 0}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
});

export default withStyles(globalStyles)(withChangesPrompt(withAITracking(reactPlugin, EditRange, 'EditRange')));
