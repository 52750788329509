import './styles.css';

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { Header } from '../Header/Header';
import { RouteComponentProps } from 'react-router';
import { NoRecords } from '../NoRecords/NoRecords';
import Ranges from './Ranges';
import { ProductsStore } from '../../stores/products-store';
import tracker from '../Loader/loader-tracker';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface IRangeIndexProps {
  productsStore?: ProductsStore;
}

type Props = IRangeIndexProps & RouteComponentProps<{}>;

@inject('productsStore')
@observer
class RangeIndex extends React.Component<Props, {}> {
  private productsStore?: ProductsStore;

  constructor(props: Props) {
    super(props);
    this.productsStore = props.productsStore;
  }

  public async componentDidMount() {
    await tracker.track(this.productsStore!.getRanges());
  }

  public render() {
    return (
      <React.Fragment>
        <Header title="Ranges" hideTitleForDesktop />

        <section className="ranges">
          <Ranges ranges={this.productsStore!.ranges} {...this.props} />
          <NoRecords message="No more ranges" active />
        </section>
      </React.Fragment>
    );
  }
}

export default withAITracking(reactPlugin, RangeIndex, 'RangeIndex');
