import { observable, action, runInAction, computed } from 'mobx';
import changeTracker from '../components/Prompt/change-tracker';

export abstract class Model {

    @observable public errors: {[name: string]: string | null};
    protected validators: {[name: string]: (val: any) => boolean } = {};
    [key: string]: any;

    constructor() {
        runInAction(() => this.errors = {});
    }

    @action
    public update(property: string, value: any) {
        this[property] = value;
    }

    @action
    public updateAndValidate(property: string, value: any) {
        this.update(property, value);
        const validator = this.validators[property];
        if (!validator) return;
        validator(value);
    }

    @action
    public validate(): boolean {
        return Object.keys(this)
            .map(k => {
                const validate = this.validators[k];
                if (validate) {
                    const ok = validate(this[k]);
                    if (!ok) console.log(k, ok, this.errors);
                    return ok;
                }
                return true;
            })
            .every(r => r === true);
    }

    @action
    public validateProperty(property: string, value: any) {
        const validator = this.validators[property];
        if (!validator) return;
        validator(value);
    }

    @action
    public setError(property: string, error: string) {
        this.errors[property] = error;
    }

    @computed
    public get serialized(): string {
        return JSON.stringify(this);
    }

    public track() {
        changeTracker.track(this);
    }

    public untrack() {
        changeTracker.reset();
    }
}