import { ProductsStore } from "../../stores/products-store";
import { RangePricing } from "../../models/range-pricing";
import { observer, inject } from 'mobx-react';
import * as React from 'react';
import { Grid, FormControl , Select, MenuItem, WithStyles, withStyles } from '@material-ui/core';
import { globalStyles } from '../../theme';
import { action } from 'mobx';

interface IRangePricingRowProps extends WithStyles<typeof globalStyles> {
    productsStore?: ProductsStore
    rangePricing: RangePricing
    isReadOnly: boolean
}

type Props = IRangePricingRowProps

@inject('productsStore')
@observer
class RangePricingRow extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
        this.handleTierSelectChange = this.handleTierSelectChange.bind(this);
    }

    public render() {
        const { rangeName, code, tierId } = this.props.rangePricing;

        return <div className="range-pricing-row">
            <Grid container>
                <Grid item xs={1} />
                <Grid item xs={8}>
                    {rangeName}
                </Grid>
                <Grid item xs={2} className="tier-selection">
                        {
                            this.props.isReadOnly ? 
                            this.props.productsStore!.tiers.find(t => t.id === tierId)!.name :   
                            <FormControl>
                            <Select
                                disabled={ this.props.isReadOnly }
                                disableUnderline
                                value={tierId}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleTierSelectChange(Number(e.target.value))}
                                inputProps={{ name: 'tierId', id: 'tierId' }}>
                                {
                                    this.props.productsStore!.tiers.map(t => (<MenuItem value={t.id} key={t.id}>{t.name}</MenuItem>))
                                }
                            </Select>
                    </FormControl>
                        }
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={1} />
                <Grid item xs={11} className="range-code">
                    Range Code: {code}
                </Grid>
            </Grid>
        </div>
    }

    @action
    private handleTierSelectChange(tierId: number) {
        this.props.rangePricing.update('tierId', tierId);
    }
}

export default withStyles(globalStyles)(RangePricingRow);