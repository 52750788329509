import './styles.css';

import * as React from 'react';
import { Grid } from '@material-ui/core';

import { Header } from '../Header/Header';

const Unauthorised: React.FC = () => {
  return (
    <div>
      <Header title="Error" hideTitleForDesktop />
      <section className="error-section">
        <Grid container>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <h3>Not Authorized</h3>
          </Grid>
        </Grid>
      </section>
    </div>
  );
};

export default Unauthorised;
