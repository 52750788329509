import { DateTime } from "luxon";
import { action, observable, runInAction } from "mobx";
import { OrderLifetimeStatus } from "./order";
import { OrdersFilter, OrderStatus } from "./orders-filter";

export class ReportsFilter extends OrdersFilter {
    @observable public deliveryStartDate: DateTime | null = null;
    @observable public deliveryEndDate: DateTime | null = null;
    @observable public lifeTimeStatus: OrderLifetimeStatus;

    constructor(customerId: number, filterByRep: boolean, orderStatus: OrderStatus, address: string, siteContact: string,
        startDate: DateTime | null, endDate: DateTime | null, customerRef: string | '', phoneNumber: string | '', owner: string | '', leadIdentifier: string | '',
        deliveryStartDate: DateTime | null, deliveryEndDate: DateTime | null) {
        super(customerId, filterByRep, orderStatus, address, siteContact, startDate, endDate, customerRef, phoneNumber, owner, leadIdentifier);
        runInAction(() => {
            this.deliveryStartDate = deliveryStartDate;
            this.deliveryEndDate = deliveryEndDate;
        });
    }

    @action
    public setDeliveryStartDate(date: DateTime | null) {
        this.updateAndValidate('deliveryStartDate', date ? date.startOf('day') : null);
    }

    @action
    public setDeliveryEndDate(date: DateTime | null) {
        this.updateAndValidate('deliveryEndDate', date ? date.endOf('day') : null);
    }

    @action
    public setLifeTimeStatus(status: OrderLifetimeStatus) {
        this.lifeTimeStatus = status;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "deliveryStartDate": (val: DateTime) => {
            if (!this.deliveryEndDate) return true;
            if(!this.errors.deliveryStartDate) {
                this.errors.deliveryStartDate = this.deliveryEndDate && this.deliveryEndDate > val
                    ? null : "Delivery Date From cannot be greater than Delivery Date To";
            }
            return this.errors.deliveryStartDate === null;
        },

    }
}
