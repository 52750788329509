import * as React from 'react';
import './styles.css';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { inject } from 'mobx-react';
import { Grid, Tooltip } from '@material-ui/core';
import { Sync, Info } from '@material-ui/icons';

import { Header } from '../Header/Header';
import { ActionItem } from '../ActionsMenu/ActionItem';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { AttacheSyncApi, IAttacheSyncSummary } from '../../services/attacheSync-api';
import { UiStore } from '../../stores/ui-store';
import { dateTimeFormatted, utcToLocal } from '../../datetime';
import tracker from '../Loader/loader-tracker';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface IProps {
  uiStore?: UiStore;
}

export interface IState {
  jobSummaries: IAttacheSyncSummary[];
}

@inject('uiStore')
class AttacheSync extends React.Component<IProps, IState> {
  private attacheApi: AttacheSyncApi;

  constructor(props: IProps) {
    super(props);
    this.attacheApi = new AttacheSyncApi();
    this.handleSyncNow = this.handleSyncNow.bind(this);
    this.state = {
      jobSummaries: [],
    };
  }

  public async componentDidMount() {
    const attacheSyncSummary = await tracker.track(this.attacheApi.getSyncSummary());
    this.setState({ jobSummaries: attacheSyncSummary });
  }

  public render() {
    return (
      <React.Fragment>
        <Header title="Unleashed Sync Summary" />
        <section>
          <Grid container className="syncSummary content">
            {this.state.jobSummaries.map((s) => {
              const summaryState = s.isSuccessful ? '' : 'error';
              return (
                <Grid container className="item" key={s.jobName}>
                  <Grid item xs={1} />
                  <Grid item xs={11} className={`name ${summaryState}`}>
                    {s.jobName} {}
                    {!s.isSuccessful && (
                      <Tooltip title="A sync error has occurred. Please refer to your email for more information.">
                        <Info className="info" />
                      </Tooltip>
                    )}
                  </Grid>
                  <Grid item xs={1} />
                  <Grid item xs={11} className="summary">
                    Last Synced: {dateTimeFormatted(utcToLocal(s.jobRunDateTimeUtc))}
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </section>
        <ActionsMenu>
          <ActionItem label="Sync Now" onClick={this.handleSyncNow}>
            <Sync />
          </ActionItem>
        </ActionsMenu>
      </React.Fragment>
    );
  }

  private async handleSyncNow() {
    try {
      this.props.uiStore!.notificationUiState.showInfo('Submitted request to Sync from Unleashed');
      await this.attacheApi.attacheSync();      
    } catch (e) {
      this.props.uiStore!.notificationUiState.showError('Error submitting request to Sync from Unleashed');
    }
  }
}

export default withAITracking(reactPlugin, AttacheSync, 'AttacheSync');
