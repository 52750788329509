import { observable, action, runInAction, computed } from 'mobx';
import { IQuoteSummary } from '../models/quotes-summary';
import { IStockInfo, SalesApi } from '../services/sales-api';
import { IOrderSummary } from '../models/orders-summary';
import { Order, OrderWorkflow } from '../models/order';
import { ProductsApi } from '../services/products-api';
import { OrderSearch } from '../models/order-search';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete';
import { utcToLocal } from '../datetime';
import { OrdersFilter, OrderStatus } from '../models/orders-filter';
import { Refund } from 'src/models/refund';
import { SettingsApi } from 'src/services/settings-api';
import { NotificationUiState } from './ui-state/notification-ui-state';

export class SalesStore {
    @observable public quotes: IQuoteSummary[] = [];
    @observable public orders: IOrderSummary[] = [];
    @observable public order: Order = new Order(this.productsApi, this.saleApi, this.settingsApi);
    @observable public ordersFilter: OrdersFilter = new OrdersFilter(0, true, OrderStatus.Order, '', '', null, null, '', '', '', '');
    @observable public orderSearch: OrderSearch = new OrderSearch();
    @observable public stockInfos: IStockInfo[] = [];

    constructor(private saleApi: SalesApi, private productsApi: ProductsApi, private settingsApi: SettingsApi) {
        this.productsApi = productsApi;
        this.saleApi = saleApi;
        this.settingsApi = settingsApi;
    }

    @action
    public async getQuotes(): Promise<IQuoteSummary[]> {
        const quotes = await this.saleApi.getQuotes(this.ordersFilter);
        runInAction(() => {
            this.quotes = quotes.map(d => {
                return { ...d, createdDate: utcToLocal(d.createdDate) }
            })
            // Decesnding created date
            .sort((a: IQuoteSummary, b: IQuoteSummary) => {
                return b.createdDate.toMillis() - a.createdDate.toMillis();
            });
        });
        return this.quotes;
    }

    @action
    public async getOrders(): Promise<IOrderSummary[]> {
        const orders = await this.saleApi.getOrders(this.ordersFilter);
        runInAction(() => {
            this.orders = orders.map(d => {
                return {
                    ...d,
                    deliveryDate: d.deliveryDate
                        ? utcToLocal(d.deliveryDate)
                        : null,
                    tba: !d.deliveryDate,
                    createdDate: utcToLocal(d.createdDate),
                    customerReference: d.customerReference,
                }
            })
        });
        return this.orders;
    }

    @action
    public createNewOrder() {
        this.order = new Order(this.productsApi, this.saleApi, this.settingsApi);
        this.order.track();
    }

    @action
    public async loadOrder(orderNumber: string): Promise<Order> {
        const order = await this.saleApi.loadOrder(orderNumber);
        return runInAction(() => {
            this.order = Order.fromResponse(order, this.productsApi, this.saleApi, this.settingsApi);
            this.order.track();
            return this.order;
        });
    }

    @action
    public async completePickup() : Promise<boolean> {
        const ok = await this.saleApi.completePickup(this.order.id);
        return ok;
    }

    @action
    public async loadOrderAsClone(orderNumber: string): Promise<Order> {
        const order = await this.saleApi.cloneOrder(orderNumber);
        return runInAction(() => {
            this.order = Order.fromResponse(order, this.productsApi, this.saleApi, this.settingsApi);
            this.order.calculateFuelLevy();
            this.order.orderWorkflow = OrderWorkflow.CloningOrder;
            this.order.parentOrder = null;
            this.order.refund = new Refund(this.order);
            this.order.track();
            return this.order;
        });
    }

    @action
    public async updateOrdersFilter(filter: OrdersFilter) {
        this.ordersFilter = filter;
        this.ordersFilter.orderStatus === OrderStatus.Order
            ? await this.getOrders()
            : await this.getQuotes();
    }

    @action
    public async searchOrderNumberByOrderOrPoNumber(): Promise<string | undefined> {
        const orderNumber = await this.saleApi.searchOrderNumberByOrderOrPoNumber(this.orderSearch.orderOrPoNumber);

        if (!orderNumber) {
            runInAction(() => {
                this.orderSearch.errors.orderNumber = "Order not found";
            })
        }
        return orderNumber;
    }

    @computed
    public get countTbaOrders(): number {
        return this.tbaOrders.length;
    }

    @computed
    public get tbaOrders(): IOrderSummary[] {
        return this.orders.filter(o => o.tba)
            // Decesnding created date
            .sort((a: IOrderSummary, b: IOrderSummary) => {
                return b.createdDate.toMillis() - a.createdDate.toMillis();
            });
    }

    @computed
    public get readyOrders(): IOrderSummary[] {
        return this.orders.filter(o => !o.tba)
            // Ascending delivery date
            .sort((a: IOrderSummary, b: IOrderSummary) => {
                return a.deliveryDate!.toMillis() - b.deliveryDate!.toMillis();
            });
    }

    public async searchDeliveryAddresses(query: string) {
        const suggestions = await this.saleApi.searchDeliveryAddresses(query);
        return suggestions.map(s => {
            return { label: s, value: s } as SuggestionItem<string>
        })
    }

    public async loadOrderDirectlyFromApi(orderNumber: string) {
        const order = await this.saleApi.loadOrder(orderNumber);
        return Order.fromResponse(order, this.productsApi, this.saleApi, this.settingsApi);
    }

    public async hasOrderDeletedProducts(orderId: number) {
        const hasDeletedProducts = await this.saleApi.hasDeletedProducts(orderId);
        return hasDeletedProducts;
    }

    @action
    public async refreshStockInfo(locationCode: string, productIds: number[], notificationUiState: NotificationUiState): Promise<void> {
        try {
            const stockInfos = await this.saleApi.getStockInfo(locationCode, productIds);
            runInAction(() => {
                this.stockInfos = stockInfos;
            });
        } catch (e) {
            console.error(e);
            notificationUiState.showError("Unable to retrieve Stock from Unleashed. Please check Warehouse.");
        }
    }
}
