import React, { type FunctionComponent } from 'react';
import Tooltip, { TooltipProps } from '@material-ui/core/Tooltip';

interface IIconTooltipProps {
  title: string;
  placement?: TooltipProps['placement'];
  children: {
    icon: JSX.Element;
  };
}

export const IconTooltip: FunctionComponent<IIconTooltipProps> = (props: IIconTooltipProps) => {
  return (
    <Tooltip title={props.title} placement={props.placement} disableFocusListener disableTouchListener>
      {props.children.icon}
    </Tooltip>
  );
};
