import * as React from 'react';
import './styles.css';
import { SalesStore } from '../../stores/sales-store';
import Grid from '@material-ui/core/Grid';
import { OrderStatus, IOrderEvent, OrderEventType } from '../../models/order';
import TextField from '@material-ui/core/TextField';
import { WithStyles, withStyles } from '@material-ui/core/styles';
import { observer, inject } from 'mobx-react';
import { UiStore } from 'src/stores/ui-store';
import { globalStyles } from 'src/theme';
import Comment from '@material-ui/icons/Comment';
import Link from '@material-ui/icons/Link';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { RouteComponentProps } from 'react-router';
import { Validate } from 'src/validate';
import { IWorkflowPayload } from 'src/services/sales-api';

interface IOrderInfoProps extends WithStyles<typeof globalStyles> {
    salesStore?: SalesStore;
    uiStore?: UiStore;
}

type Props = IOrderInfoProps & RouteComponentProps<{}>;

@inject('salesStore', 'uiStore')
@observer
class OrderInfo extends React.Component<Props> {
    constructor(props: Props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleClick = this.handleClick.bind(this);
    }

    public render() {
        const { salesStore, uiStore } = this.props;
        const type = salesStore!.order.status === OrderStatus.Order ? "Order" : "Quote";
        return (<Grid container className="section">
            <Grid item xs={1} />
            <Grid item xs={10}>
                <h2>
                    {type}
                    {
                        salesStore!.order.hasNote
                        && <IconTooltip title={`This ${type} has a note`} placement="right">
                            {{ icon: <Comment className="comment-status" /> }}
                        </IconTooltip>
                    }
                </h2>
                {
                    salesStore!.order.statusText !== ''
                    && <TextField
                        className={this.props.classes.textField}
                        InputProps={{
                            disableUnderline: true,
                            classes: {
                                input: this.props.classes.highlightText
                            }
                        }}
                        label="Status"
                        type="text"
                        value={salesStore!.order.statusText}
                        disabled
                        fullWidth
                    />
                }
                <TextField
                    className={this.props.classes.textFieldFullWidth}
                    InputProps={{
                        disableUnderline: true,
                        classes: {
                            input: this.props.classes.highlightText
                        }
                    }}
                    inputProps={{ maxLength: 200 }}
                    label="Internal Note"
                    type="text"
                    name="note"
                    error={!!salesStore!.order.errors.note}
                    helperText={salesStore!.order.errors.note || null}
                    value={salesStore!.order.note}
                    onChange={this.handleChange}
                    disabled={uiStore!.orderUiState.isReadonly("note")}
                    fullWidth
                    multiline
                />
                <ul className="approval-notes">
                    {
                        salesStore!.order.approvalNotes.map((e, i) => (<li key={i}>{this.getApprovalNote(e)}</li>))
                    }
                </ul>
            </Grid>
            <Grid item xs={1} />
            {
                (salesStore!.order.isParentOrder || salesStore!.order.isSplitOrder || Validate.hasValue(salesStore!.order.clonedFrom))
                && <React.Fragment>
                    <Grid item xs={1} />
                    <Grid item xs={10} className="related-orders">
                        <h2>Related Orders <IconTooltip title={`Select order number to view`} placement="right">
                            {{ icon: <Link className="order-links" /> }}
                        </IconTooltip></h2>
                        {
                            (salesStore!.order.isParentOrder || salesStore!.order.isSplitOrder)
                            && <React.Fragment>
                                <label>Split Orders</label>
                                {
                                    salesStore!.order.isParentOrder
                                    && <ul> { salesStore!.order.splitOrders.map((o, i) => <li key={i} onClick={() => this.handleClick(o.orderNumber)}>{o.orderNumber}</li>) } </ul>
                                }
                                {
                                    salesStore!.order.isSplitOrder
                                    && <ul> { <li onClick={() => this.handleClick(salesStore!.order.parentOrder!.orderNumber)}>{salesStore!.order.parentOrder!.orderNumber}</li> }
                                    {
                                        salesStore!.order.splitOrders
                                            .filter(o => o.orderNumber !== salesStore!.order.orderNumber)
                                            .map((o, i) => <li key={i} onClick={() => this.handleClick(o.orderNumber)}>{o.orderNumber}</li>)
                                    }
                                    </ul>
                                }
                            </React.Fragment>
                        }
                        {
                            Validate.hasValue(salesStore!.order.clonedFrom)
                            && <React.Fragment>
                                <label>Cloned From</label>
                                <ul> { <li onClick={() => this.handleClick(salesStore!.order.clonedFrom)}>{salesStore!.order.clonedFrom}</li> } </ul>
                            </React.Fragment>
                        }
                    </Grid>
                </React.Fragment>
            }
        </Grid>)
    }

    private handleClick(orderNumber: string) {
        window.location.href = `/view-order/${orderNumber}`;
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.order.update(e.currentTarget.name, e.currentTarget.value);
    }

    private getApprovalNote = (event: IOrderEvent): string => {
        const getReason = (payload: any) => {
            return payload ? (payload as IWorkflowPayload).reason : '';
        }
        const getComment = (payload: any) => {
            const comment = (payload as IWorkflowPayload).comment;
            return payload && comment ? `, comment: ${comment}` : '';
        }
        switch (event.eventType) {
            case OrderEventType.CustomPricingApproved:
                return `Custom pricing was approved by ${event.repCode}. ${getReason(event.payload)}`;
            case OrderEventType.CustomPricingRejected:
                return `Custom pricing was rejected by ${event.repCode}. ${getReason(event.payload)}`;
            case OrderEventType.OrderDeletionApproved:
                return `Order deletion was approved by ${event.repCode}. ${getReason(event.payload)}${getComment(event.payload)}`;
            case OrderEventType.OrderDeletionRejected:
                return `Order deletion was rejected by ${event.repCode}. ${getReason(event.payload)}`;
            case OrderEventType.InDisputeCustomerApproved:
                return `Credit limit was approved by ${event.repCode}. ${getReason(event.payload)}`;
            case OrderEventType.InDisputeCustomerRejected:
                return `Credit limit was rejected by ${event.repCode}. ${getReason(event.payload)}`;
            case OrderEventType.RefundApproved:
                return `Refund was approved by ${event.repCode}. ${getReason(event.payload)}`;
            case OrderEventType.RefundRejected:
                return `Refund was rejected by ${event.repCode}. ${getReason(event.payload)}`;
            case OrderEventType.ProductChecked:
                return `Product checked by ${event.repCode}.`;
        }
        return '';
    }
}

export default withStyles(globalStyles)(OrderInfo);