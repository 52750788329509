import {Model} from './model';
import {observable, action, computed} from 'mobx';
import {ServiceLine} from './service-line';
import {ProductLine} from './product-line';
import {PaymentLine} from './payment-line';
import {IRelatedOrderResponse} from '../services/sales-api';
import {Order, OrderLifetimeStatus} from './order';
import {round, roundedGst, roundedSubtotal, roundedTotal} from '../math';
import {SupplierCommentLine} from './supplier-comment-line';
import {DateTime} from 'luxon';

export class RelatedOrder extends Model {

    @observable public id: number;
    @observable public orderNumber: string;
    @observable public serviceLines: ServiceLine[] = [];
    @observable public supplierCommentLines: SupplierCommentLine[] = [];
    @observable public productLines: ProductLine[] = [];
    @observable public paymentLines: PaymentLine[] = [];
    @observable public totalCost: number = 0;
    @observable public customerAdjustment: number = 0;
    @observable public orderDeliveryDate: DateTime | null | undefined;
    @observable public orderDeliveryDateTba: boolean | undefined;
    @observable public fuelLevyServicePrice: number = 0;
    public lifetimeStatus: OrderLifetimeStatus;
    public active: boolean;
    public lastModifiedTimeStamp?: string;

    constructor(id: number, orderNumber: string) {
        super();
        this.id = id;
        this.orderNumber = orderNumber;
    }

    @action
    public static copyOrderToParent(order: Order) {
        const parent = new RelatedOrder(order.id, order.orderNumber);
        parent.productLines = order.productLines.map(p => p.clone());
        parent.serviceLines = order.serviceLines.map(s => s.clone());
        parent.supplierCommentLines = order.supplierCommentLines.map(s => s.clone());
        parent.paymentLines = order.paymentLines.map(p => p.clone());
        parent.totalCost = order.totalCost;
        parent.customerAdjustment = order.customerAdjustment;
        parent.lastModifiedTimeStamp = order.lastModifiedTimeStamp;
        parent.fuelLevyServicePrice = order.fuelLevyServicePrice;
        return parent;
    }

    @action
    public updateOrderDeliveryDateTba(tba: boolean | undefined) {
        this.orderDeliveryDateTba = tba;
    }

    @action
    public updateOrderDeliveryDate(d: DateTime | null | undefined) {
        this.orderDeliveryDate = d;
    }

    @action
    public updateProductLine(product: ProductLine) {
        this.productLines = this.productLines.map(p => p.productId === product.productId && p.lineNumber === product.lineNumber ? product : p);
    }

    @action
    public updateServiceLine(service: ServiceLine) {
        this.serviceLines = this.serviceLines.map(s => s.serviceId === service.serviceId && s.lineNumber === service.lineNumber ? service : s);
    }

    @action
    public updateSupplierCommentLine(supplierComment: SupplierCommentLine) {
        this.supplierCommentLines = this.supplierCommentLines.map(s => s.exWorksId === supplierComment.exWorksId ? supplierComment : s);
    }

    public getProductLine(productId: number, lineNumber: number) {
        return this.productLines.find(p => p.productId === productId && p.lineNumber === lineNumber);
    }

    public getServiceLine(serviceId: number, lineNumber: number) {
        return this.serviceLines.find(p => p.serviceId === serviceId && p.lineNumber === lineNumber);
    }

    public getSupplierCommentLine(exworksId: number | undefined) {
        if (!exworksId) {
            return this.supplierCommentLines.find(s => !s.exWorksId);
        }
        return this.supplierCommentLines.find(s => s.exWorksId === exworksId);
    }

    @computed
    public get totalPaid(): number {
        return round(this.paymentLines.reduce((total, current) => total + current.amount, 0));
    }

    @computed
    public get subtotal(): number {
        const services = this.serviceLines
            .map(s => roundedSubtotal(s.unroundedSubtotal))
            .reduce((acc, val) => acc + val, 0);
        const products = this.productLines
            .map(s => roundedSubtotal(s.unroundedSubtotal))
            .reduce((acc, val) => acc + val, 0);
        return round(products + services);
    }

    @computed
    public get gst() {
        const services = this.serviceLines
            .map(s => roundedGst(s.unroundedSubtotal))
            .reduce((acc, val) => acc + val, 0);
        const products = this.productLines
            .map(s => roundedGst(s.unroundedSubtotal))
            .reduce((acc, val) => acc + val, 0);
        return round(products + services);
    }

    @computed
    public get total() {
        const services = this.serviceLines
            .map(s => roundedTotal(s.unroundedSubtotal))
            .reduce((acc, val) => acc + val, 0);
        const products = this.productLines
            .map(s => roundedTotal(s.unroundedSubtotal))
            .reduce((acc, val) => acc + val, 0);
        return round(products + services);
    }

    public static fromResponse(o: IRelatedOrderResponse): RelatedOrder {
        const order = new RelatedOrder(o.id, o.orderNumber);
        order.serviceLines = o.serviceLines ? o.serviceLines.map(s => ServiceLine.fromResponse(s)) : [];
        order.productLines = o.productLines ? o.productLines.map(p => ProductLine.fromResponse(p)) : [];
        order.paymentLines = o.paymentLines ? o.paymentLines.map(p => PaymentLine.fromResponse(p)) : [];
        order.supplierCommentLines = o.supplierCommentLines ? o.supplierCommentLines.map(s => SupplierCommentLine.fromResponse(s)) : [];
        order.totalCost = o.totalCost;
        order.customerAdjustment = o.customerAdjustment;
        order.lifetimeStatus = o.lifetimeStatus;
        order.active = o.active;
        return order;
    }
}