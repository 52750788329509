import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { TextField, WithStyles, withStyles } from '@material-ui/core';
import { observable, runInAction } from 'mobx';
import { globalStyles } from 'src/theme';
import { UiStore } from 'src/stores/ui-store';
import { SupplierCommentLine } from 'src/models/supplier-comment-line';
import { DeleteForever, Done, Close } from '@material-ui/icons';

export interface IEditSupplierCommentDialogProps extends WithStyles<typeof globalStyles> {
    salesStore?: SalesStore
    uiStore?: UiStore
    active: boolean
    supplierComment: SupplierCommentLine
    handleClose(e: React.MouseEvent<HTMLDivElement>): void
    handleSave(supplierCommentLine: SupplierCommentLine, parentSupplierCommentLine: SupplierCommentLine): void
    handleDelete(supplierCommentLine: SupplierCommentLine): void
}

@inject('salesStore', 'uiStore')
@observer
class EditSupplierCommentDialog extends React.Component<IEditSupplierCommentDialogProps> {

    @observable private supplierComment: SupplierCommentLine;
    @observable private parentSupplierComment: SupplierCommentLine;

    constructor(props: IEditSupplierCommentDialogProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSave = this.handleSave.bind(this);
        runInAction(() => {
            const { order } = this.props.salesStore!;
            this.supplierComment = this.props.supplierComment.clone();
            if (order.parentOrder) {
                const parent = order.parentOrder.getSupplierCommentLine(this.supplierComment.exWorksId);
                if (parent) this.parentSupplierComment = parent.clone();
            }
        });
    }

    public render() {
        const { orderUiState } = this.props.uiStore!;
        return <div className={"edit-service-dialog " + (this.props.active ? "active" : "")}>
            <div className="dialog-content">
                <h3>{this.supplierComment.supplierName}</h3>
                <TextField
                    className={this.props.classes.textField}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 60 }}
                    label="Comment"
                    type="text"
                    name="comment"
                    value={this.supplierComment.comment}
                    fullWidth
                    onChange={this.handleChange}
                    onBlur={this.handleBlur}
                    multiline
                    disabled={orderUiState.isReadonly('supplierComment')}
                />
            </div>
            <div className="dialog-actions">
                {
                    !orderUiState.isReadonly('deleteSupplierComment')
                    && <div className="delete" onClick={() => this.props.handleDelete(this.supplierComment)}>
                        <span>Delete</span>
                        <DeleteForever className="icon" />
                    </div>
                }
                <div className="close" onClick={this.props.handleClose}>
                    <span>Close</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={this.handleSave}>
                    <span>Save</span>
                    <Done className="icon" />
                </div>
            </div>
        </div>

    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.supplierComment.update(e.currentTarget.name, e.currentTarget.value);
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.supplierComment.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleSave() {
        this.props.handleSave(this.supplierComment, this.parentSupplierComment);
    }
}

export default withStyles(globalStyles)(EditSupplierCommentDialog);
