import * as EmailValidator from 'email-validator';
import { ExWorksFreightCost } from './ex-works-freight-cost';
import { observable, action } from 'mobx';
import { Model } from './model';
import { IExWorksResponse } from 'src/services/sales-api';
import { Validate } from 'src/validate';
import { Address } from './address';
import { DateTime } from 'luxon';
import { toZoned } from '../datetime';

export class ExWorks extends Model {
    @observable public address: Address = new Address({});
    @observable public code: string = '';
    @observable public emailAddress: string = '';
    @observable public dispatchEmailAddress: string = '';
    @observable public openingTime: DateTime | null | undefined;
    @observable public closingTime: DateTime | null | undefined;
    @observable public id: number = 0;
    @observable public name: string = '';
    @observable public errors: {[field: string]: string | null} = {}
    @observable public supplierId: number = 0;
    @observable public freightCosts: ExWorksFreightCost[] = [];
    
    @action 
    public static fromResponse(d: IExWorksResponse): ExWorks {
        const exWorks = new ExWorks();
        exWorks.address = Address.fromResponse(d.address);
        exWorks.code = d.code || '';
        exWorks.emailAddress = d.emailAddress || '';
        exWorks.dispatchEmailAddress = d.dispatchEmailAddress || '';
        exWorks.id = d.id;
        exWorks.name = d.name || '';
        exWorks.openingTime = toZoned(d.openingTime);
        exWorks.closingTime = toZoned(d.closingTime);
        exWorks.supplierId = d.supplierId;
        exWorks.freightCosts = d.freightCosts.map(fc => new ExWorksFreightCost(fc.id, fc.region,fc.regionId,fc.costPerTonne))
        return exWorks;
    }

    @action
    public validate(): boolean {
        return super.validate() && this.validateFreightCost();
    }

    private validateFreightCost(): boolean {
        return (this.freightCosts.every(k => k.validate()))
    }

    protected validators: {[name: string]: (val: any) => boolean} = {
        "code": (val: string) => {
            this.errors.code = Validate.hasValue(val)
                ? null : "Code required";
            return this.errors.code === null;
        },
        "emailAddress": (val: string) => {
            this.errors.emailAddress = Validate.hasValue(val)
                ? EmailValidator.validate(val) ? null : "Invalid Email Address"
                : "Email Address Required";
            return this.errors.emailAddress === null;
        },
        "dispatchEmailAddress": (val: string) => {
            this.errors.dispatchEmailAddress = !Validate.hasValue(val)
                || EmailValidator.validate(val) ? null : "Invalid Email Address";
            return this.errors.dispatchEmailAddress === null;
        },
        "name": (val: string) => {
            this.errors.name = Validate.hasValue(val)
                ? null : "Name required";
            return this.errors.name === null;
        },
        "address": (val: string) => {
            return this.address.validate();
        },
        "closingTime": (val: DateTime) => {
            this.errors.closingTime = this.openingTime && val <= this.openingTime  ?
                "Closing time must be later then Opening Time" : null;

            return this.errors.closingTime === null;
        },
        "openingTime": (val: DateTime) => {
            this.errors.openingTime = this.closingTime && val >= this.closingTime  ?
                "Opening time must be before Closing Time" : null;

            return this.errors.openingTime === null;
        },
    }
}