import * as React from 'react';
import { Route, Switch } from 'react-router';

import { ProtectedRoute } from './ProtectedRoute';
import Sales from '../components/Sales/Sales';
import NewOrder from '../components/Order/NewOrder';
import ViewOrder from '../components/Order/ViewOrder';
import SupplierDetail from '../components/Supplier/SupplierDetail';
import SupplierIndex from '../components/Suppliers/SupplierIndex';
import EditExWorks from '../components/ExWorks/EditExWorks';
import EditRange from '../components/Range/EditRange';
import Error from '../components/Error/Error';
import CustomerIndex from '../components/Customers/CustomerIndex';
import CustomerDetail from '../components/Customers/CustomerDetail';
import AttacheSync from '../components/AttacheSync/AttacheSync';
import RangeIndex from '../components/Range/RangeIndex';
import LocationIndex from '../components/Locations/LocationIndex';
import EditLocation from '../components/Locations/EditLocation';
import PriceListIndex from '../components/PriceList/PriceListIndex';
import Settings from '../components/Settings/Settings';
import Reports from '../components/Reports/reports';
import SalesRep from '../components/SalesRep/SalesRep';
import { UserGroup } from '../stores/auth-store';

export const MojoeRoutes = () => {
  return (
    <Switch>
      <Route exact path="/" component={Sales} />
      <Route exact path="/norepcode" component={Error} />
      <Route exact path="/new-order" component={NewOrder} />
      <Route exact path="/clone-order/:id" component={NewOrder} />
      <Route exact path="/view-order/:id" component={ViewOrder} />
      <Route exact path="/price-lists" component={PriceListIndex} />
      <Route exact path="/customers" component={CustomerIndex} />
      <Route exact path="/customers/:id" component={CustomerDetail} />
      <Route exact path="/reports" component={Reports} />
      <ProtectedRoute exact path="/locations" component={LocationIndex} userGroups={[UserGroup.PricingManager]} />
      <ProtectedRoute exact path="/user" component={SalesRep} userGroups={[UserGroup.Administrator]} />
      <ProtectedRoute exact path="/locations/:id" component={EditLocation} userGroups={[UserGroup.PricingManager]} />
      <ProtectedRoute exact path="/ranges" component={RangeIndex} userGroups={[UserGroup.PricingManager]} />
      <ProtectedRoute exact path="/ranges/:id" component={EditRange} userGroups={[UserGroup.PricingManager]} />
      <ProtectedRoute
        exact
        path="/suppliers/:code/exworks/:id"
        component={EditExWorks}
        userGroups={[UserGroup.PricingManager]}
      />
      <ProtectedRoute exact path="/suppliers" component={SupplierIndex} userGroups={[UserGroup.PricingManager]} />
      <ProtectedRoute exact path="/suppliers/:id" component={SupplierDetail} userGroups={[UserGroup.PricingManager]} />
      <ProtectedRoute
        exact
        path="/attacheSync"
        component={AttacheSync}
        userGroups={[UserGroup.Administrator, UserGroup.PricingManager]}
      />
      <ProtectedRoute exact path="/settings" component={Settings} userGroups={[UserGroup.Administrator]} />
    </Switch>
  );
};
