import { InteractionRequiredAuthError } from '@azure/msal-browser';

import { msalInstance } from './msalConfig';
import { stores } from '../index';

export async function apiFetch(url: string, options: RequestInit = {}): Promise<Response | undefined> {
  try {
    const account = msalInstance.getActiveAccount();

    if (!account) {
      console.error('No active account found.');
      return;
    }

    const scopes = [`${window.config.clientId}/.default`];

    const tokenResponse = await msalInstance
      .acquireTokenSilent({
        scopes,
      })
      .catch(async (error) => {
        if (error instanceof InteractionRequiredAuthError) {
          await msalInstance.acquireTokenRedirect({ scopes });
        }
      });

    if (!tokenResponse?.idToken || !tokenResponse?.accessToken) return;

    const headers = new Headers();
    const bearer = `Bearer ${tokenResponse?.accessToken}`;

    if (options?.method !== 'post') {
      headers.append('Pragma', 'no-cache');
      headers.append('Cache-Control', 'no-cache');
    }

    headers.append('Content-Type', 'application/json');
    headers.append('Authorization', bearer);

    const { headers: optHeaders, ...otherOpts } = options;

    if (optHeaders) {
      const keys = Object.keys(optHeaders);
      for (const key of keys) {
        const header = headers.get(key);
        if (header) headers.append(key, header);
      }
    }

    const opts: RequestInit = {
      method: 'GET',
      headers,
      ...otherOpts,
    };

    const fullUrl = `${window.config.apiUrl}${url}`;

    return fetch(fullUrl, opts).then(async (response) => {
      if (response.ok) return response;
      if (response.status === 403 && window.location.pathname !== '/norepcode') {
        window.location.href = '/norepcode';
      }
      const errorText = await response.text();
      const text = errorText ?? response.statusText;

      if (response.status === 500) {
        stores.uiStore.notificationUiState.showError(`${response.status}: ${text}`);
      }

      throw new Error(`${text} (${response.status})`);
    });
  } catch (error) {
    console.error(error);
    return;
  }
}
