import { observable, action } from 'mobx';
import { Model } from './model';
import { ICustomerResponse } from '../services/customers-api';
import { RangePricing } from './range-pricing';

export class Customer extends Model {
    @observable public address: string = '';
    @observable public rangePricings: RangePricing[] = [];
    @observable public code: string = '';
    @observable public email: string = '';
    @observable public id: number = 0;
    @observable public isCashSaleAccount: boolean = false;
    @observable public isOnlineStore: boolean = false;
    @observable public name: string = '';
    @observable public defaultTierId: number = 0;

    @action
    public static fromResponse(src: ICustomerResponse) {
        const tgt = new Customer();
        tgt.address = src.address;
        tgt.code = src.code;
        tgt.email = src.email;
        tgt.id = src.id;
        tgt.isCashSaleAccount = src.isCashSaleAccount;
        tgt.isOnlineStore = src.isOnlineStore;
        tgt.name = src.name;
        tgt.rangePricings = src.rangePricings.map((range) => RangePricing.fromResponse(range));
        tgt.defaultTierId = src.defaultTierId;
        return tgt;
    }
}

