import { observable, runInAction } from 'mobx';
import { Model } from './model';
import { Validate } from 'src/validate';

export class ExWorksFreightCost extends Model {
    @observable public id: number;
    @observable public region: string;
    @observable public regionId: number;
    @observable public costPerTonne: number | null;
    @observable public errors: { [field: string]: string | null } = {}

    constructor(id: number, region: string, regionId: number, costPerTonne: number | null) {
        super();
        runInAction(() => {
            this.id = id;
            this.region = region;
            this.regionId = regionId;
            this.costPerTonne = costPerTonne;
        })
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "costPerTonne": (val: number) => {
            this.errors.costPerTonne = !val || Validate.decimal(val)
                ? null : "Must be a number";
            return this.errors.costPerTonne === null;
        }
    }
}