import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import TextField from '@material-ui/core/TextField';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { globalStyles } from 'src/theme';
import { inject, observer } from 'mobx-react';
import { SalesStore } from 'src/stores/sales-store';

export interface IShareInvoiceDialogProps extends WithStyles<typeof globalStyles> {
    onClose(): void
    onSubmit(): void,
    salesStore?: SalesStore;
}

@inject('salesStore')
@observer
class ShareInoviceDialog extends React.Component<IShareInvoiceDialogProps> {

    constructor(props: IShareInvoiceDialogProps) {
        super(props);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public componentDidMount() {
        const { salesStore } = this.props;
        salesStore!.order.quoteShared.setEmail(salesStore!.order.email);
        salesStore!.order.quoteShared.setName(salesStore!.order.name);
    }

    public render() {
        const { salesStore } = this.props;
        return <div className="full-dialog">
            <Loader area="share-quote-dialog" />
            <div className="dialog-content">
                <h3>Share Pro Forma Invoice</h3>
                <p>
                    Please enter the customer name and email address to send the pro forma invoice to
                </p>
                <TextField
                    className={this.props.classes.textField}
                    InputProps={{ disableUnderline: true }}
                    label="Name"
                    type="text"
                    name="name"
                    autoComplete="no"
                    error={!!salesStore!.order.quoteShared.errors.name}
                    helperText={salesStore!.order.quoteShared.errors.name || null}
                    value={salesStore!.order.quoteShared.name}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    fullWidth
                    autoFocus
                />
                <TextField
                    className={this.props.classes.textField}
                    InputProps={{ disableUnderline: true }}
                    label="Email"
                    type="text"
                    name="email"
                    autoComplete="no"
                    error={!!salesStore!.order.quoteShared.errors.email}
                    helperText={salesStore!.order.quoteShared.errors.email || null}
                    value={salesStore!.order.quoteShared.email}
                    onBlur={this.handleBlur}
                    onChange={this.handleChange}
                    fullWidth
                />
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Close</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={this.props.onSubmit}>
                    <span>Share</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.order.quoteShared.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }
    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.order.quoteShared.update(e.currentTarget.name, e.currentTarget.value);
    }
}

export default withStyles(globalStyles)(ShareInoviceDialog);