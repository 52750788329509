import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import { inject, observer } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { TextField, Grid, Checkbox, WithStyles, withStyles } from '@material-ui/core';
import { WorkflowDialogContent } from '../OrderWorkflows/WorkflowDialogContent';
import CurrencyField, { ICurrencyChangedEvent } from '../Currency/CurrencyField';
import { RaisePurchaseOrderOn } from 'src/models/order';
import { globalStyles } from '../../theme';
import { ProductLine } from 'src/models/product-line';
import { Warning } from '@material-ui/icons';
import { IconTooltip } from '../IconTooltip/IconTooltip';

export enum SubmitType {
    Submit = 0,
    Save = 1
}

export interface ISubmitOrderWithPoDialogProps extends WithStyles<typeof globalStyles> {
    onClose(): void
    onSubmit(): void
    salesStore?: SalesStore
    submitType: SubmitType
}

@inject('salesStore')
@observer
class SubmitOrderWithPoDialog extends React.Component<ISubmitOrderWithPoDialogProps> {

    public render() {
        const { salesStore } = this.props;
        const disablePoCheckBox = (s: ProductLine) => s.isProductDeleted || (salesStore !== undefined && salesStore.order.hasActiveChildOrders);
        return <div className="full-dialog">
            <Loader area="submit-order-po-dialog" />
            <div className="dialog-content">
                <h3>{`${this.props.submitType === SubmitType.Submit ? "Submit" : "Save"} Order`}</h3>
                {
                    salesStore!.order.isInDispute &&
                    <WorkflowDialogContent
                        workflow={salesStore!.order.customerInDispute}
                    />
                }
                {
                    !salesStore!.order.productLines.length
                        ? <p>You are about to submit this order for processing.</p>
                        : <React.Fragment>
                            <p>Will a PO need to be raised for any of these items?</p>
                            {
                                salesStore!.order.isOrderInDispute
                                && <p>
                                    As credit approval is required, POs for selected products below will be raised on credit approval
                                    or removed if approval is rejected.
                                </p>
                            }
                            <div className="po-lines">
                                {
                                    salesStore!.order.productLines.map((s, i) => {
                                        return <Grid container className="item" key={i}>
                                            <Grid item xs={10} className="po-line">
                                                <span className="product-name">{s.name}</span>
                                                <span className="product-code">({s.code})</span>
                                                {
                                                    salesStore!.order.isInDispute && s.raisePurchaseOrderIsDeferred
                                                    && <span className="warn">PO will be raised after credit approval</span>
                                                }
                                                {   s.isProductDeleted &&
                                                    <span className="warn">Product code is inactive - unable to create a PO.</span>
                                                }
                                            </Grid>
                                            <Grid item xs={2} className="check">
                                                {
                                                    (salesStore!.order.isInDispute || s.raisePurchaseOrderIsDeferred) && !s.raisePurchaseOrder
                                                        // If now requires credit approval and hasn't raised a po, raise it on credit approval or when its saved as an order after approval
                                                        ? <Checkbox
                                                            disabled={disablePoCheckBox(s)}
                                                            checked={s.raisePurchaseOrderIsDeferred}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                                                s.update("raisePurchaseOrderOn", checked ? RaisePurchaseOrderOn.Approve : RaisePurchaseOrderOn.None)}
                                                        />
                                                        : <Checkbox
                                                            disabled={!salesStore!.order.canEditPurchaseOrder || disablePoCheckBox(s)}
                                                            checked={s.raisePurchaseOrder}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                                                s.setRaisePurchaseOrderOn(checked)}
                                                        />
                                                }
                                                {
                                                disablePoCheckBox(s) &&
                                                    <div className="stockingpoint-disabled-warning">
                                                        <IconTooltip title="The PO cannot be changed - this order has active split orders." placement="left">
                                                            {{ icon: <Warning color="error" /> }}
                                                        </IconTooltip>
                                                    </div>
                                                }
                                            </Grid>
                                            {
                                                (s.raisePurchaseOrder || s.raisePurchaseOrderIsDeferred)
                                                &&
                                                <Grid container xs={12}>
                                                    <Grid item xs={12}>
                                                        <CurrencyField
                                                            label="Unit Buy Price"
                                                            name="buyUnitCost"
                                                            value={s.buyUnitCost || 0}
                                                            onChange={(e: ICurrencyChangedEvent) => s.updateAndValidate("buyUnitCost", e.parsedValue)}
                                                            fullWidth
                                                            error={Boolean(s.errors.buyUnitCost)}
                                                            disabled={!salesStore!.order.canEditPurchaseOrder}
                                                            placeholder={''}
                                                        />
                                                    Leave blank to use the original price
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            className={this.props.classes.textFieldFullWidth}
                                                            InputProps={{ disableUnderline: true }}
                                                            inputProps={{ maxLength: 60 }}
                                                            label="Comment"
                                                            type="text"
                                                            name="comment"
                                                            autoComplete="no"
                                                            disabled={!salesStore!.order.canEditPurchaseOrder}
                                                            value={s.purchaseOrderNote}
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => s.updateAndValidate('purchaseOrderNote', e.currentTarget.value)}
                                                            fullWidth
                                                            multiline
                                                        />
                                                    </Grid>
                                                </Grid>
                                            }
                                            <Grid item xs={12}>
                                            {
                                                !salesStore!.order.isNew && s.showWarningMessageWhenPOUnchecked
                                                && <p className="powarn">
                                                    This product will be removed from the Purchase Order.
                                                </p>
                                            }
                                            </Grid>
                                        </Grid>
                                    })
                                }
                            </div>
                        </React.Fragment>
                }
                {
                    !salesStore!.order.ableToSubmitOnlineOrder && salesStore!.order.isStockingPointOnline
                    && <p className="warn">
                        Online orders need a stocking point selected if any item does not need a Purchase Order.
                    </p>
                }
                {
                    salesStore!.order.isInDisputeButPreviouslyApproved
                    && <p className="warn">
                        The customer is over their credit limit. However re-approval is not required.
                    </p>
                }
                {
                    this.orderHasPoLinesAndInactiveProducts
                    && <p className="warn">
                        This order contains products that are inactive. Raising a PO is not possible.
                    </p>
                }
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Close</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={(e: React.MouseEvent<HTMLDivElement>) => this.confirmSubmit()}>
                    <span>Save</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }

    private confirmSubmit() {
        const { salesStore } = this.props;
        if (salesStore!.order.productLines.some(x => !x.validate())) {
            return;
        }

        if (!salesStore!.order.ableToSubmitOnlineOrder) {
            return;
        }

        if (this.props.salesStore!.order.isInDispute && !salesStore!.order.customerInDispute.validate()) {
            return;
        }
        if (this.orderHasPoLinesAndInactiveProducts) {
            return;
        }
        this.props.onSubmit();
    }

    private get orderHasPoLinesAndInactiveProducts(): boolean {
        const p =  this.props.salesStore!.order.productLines.find(pl => pl.isProductDeleted) &&
        this.props.salesStore!.order.productLines.find(pl => pl.raisePurchaseOrder);
        return !!p;
    }
}

export default withStyles(globalStyles)(SubmitOrderWithPoDialog);
