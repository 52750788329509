import { apiFetch } from '../utilities/apiFetch';

export interface IUserGroupResponse {
  id: string;
  name: string;
}

export class UserApi {
  public async getUserGroups(): Promise<IUserGroupResponse[]> {
    const response = await apiFetch('/api/user/groups');
    const json = await response?.json();
    return json ?? [];
  }
}
