import * as React from 'react';
import './styles.css';
import { inject, observer } from 'mobx-react';
import { WithStyles, withStyles, createStyles, Theme, Grid, InputAdornment, Checkbox, FormHelperText } from '@material-ui/core';
import { UiStore } from '../../stores/ui-store';
import { SalesStore } from '../../stores/sales-store';
import { globalStyles } from 'src/theme';
import CurrencyField, { ICurrencyChangedEvent } from '../Currency/CurrencyField';
import { Address } from 'src/models/address';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import AddressMap from '../AddressSearch/AddressMap';
import { IReactionDisposer, reaction } from 'mobx';

export interface IOrderSummaryProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    uiStore?: UiStore
}

@inject('uiStore', 'salesStore')
@observer
class CreditSummary extends React.Component<IOrderSummaryProps, {}> {

    private subTotalReaction: IReactionDisposer;

    constructor(props: IOrderSummaryProps) {
        super(props);
        this.handleCurrencyChange = this.handleCurrencyChange.bind(this);
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.handleChange = this.handleChange.bind(this);

        this.subTotalReaction = reaction(
            () => this.props.salesStore!.order.refund.subTotalCredited,
            () => this.props.salesStore!.order.refund.resetDefaulRestockingFee()
        );
    }

    public componentWillUnmount() {
        this.subTotalReaction();
    }

    public render() {
        const { orderUiState } = this.props.uiStore!;
        const { refund } = this.props.salesStore!.order;
        return <Grid container className="section">
            <Grid item xs={1} />
            <Grid item xs={10}>
                <h2>Credit Summary</h2>
                <Grid container>
                    <Grid item xs={9}>
                        <p>Sub Total Credited</p>
                        {
                            !!refund.errors.hasCredits && refund.subTotalCredited === 0
                            && <FormHelperText error>{refund.errors.hasCredits}</FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={3} className="total number-col">
                        <p>${refund.subTotalCredited.toFixed(2)}</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>Restocking Fee</p>
                    </Grid>
                    <Grid item xs={3} className="total number-col">
                        <CurrencyField
                            InputProps={{
                                classes: { input: this.props.classes.paymentTextField },
                                startAdornment: <InputAdornment position="start">$ -</InputAdornment>
                            }}
                            name="restockingFee"
                            value={refund.restockingFee}
                            error={refund.errors.restockingFee}
                            onChange={(e: ICurrencyChangedEvent) => this.handleCurrencyChange('restockingFee', e)}
                            disabled={orderUiState.isReadonly('restockingFee')}
                            fullWidth={false}
                        />
                    </Grid>
                    <Grid item xs={9}>
                        <p>Total Credited</p>
                    </Grid>
                    <Grid item xs={3} className="total number-col">
                        <p>${refund.totalCredited.toFixed(2)}</p>
                    </Grid>
                </Grid>
                <p>
                    Products to be returned
                    <Checkbox
                        checked={refund.pickupRequired}
                        disabled={orderUiState.isReadonly('pickupRequired')}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => refund.setPickupRequired(checked)}
                    />
                </p>
                {
                    refund.pickupRequired
                    && <React.Fragment>
                        <AddressSearchBox
                            address={refund.fromAddress.formattedAddress}
                            handleAddressSelected={(address: Address) => this.handleAddressChange('fromAddress', address)}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressChange('fromAddress', new Address(
                                { ...refund.fromAddress, formattedAddress: e.currentTarget.value })
                            )}
                            label="Pickup Address"
                            fieldName="refundFromAddress"
                            error={refund.fromAddress.errors.formattedAddress}
                            toggleMap
                            disabled={orderUiState!.isReadonly('refundFromAddress')}>
                            <AddressMap
                                forFieldName="refundFromAddress"
                                moveMarker={!orderUiState.isReadonly('refundFromAddress')}
                                lat={refund.fromAddress.latLng.lat}
                                lng={refund.fromAddress.latLng.lng}
                                handleClick={(lat: number, lng: number) => {
                                    orderUiState.setLatLng("refundFromAddress", { lat, lng });
                                    this.handleAddressChange('fromAddress', new Address({ ...refund.fromAddress, latLng: { lat, lng } }));
                                }}
                            />
                        </AddressSearchBox>
                        <AddressSearchBox
                            address={refund.toAddress.formattedAddress}
                            handleAddressSelected={(address: Address) => this.handleAddressChange('toAddress', address)}
                            handleChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressChange('toAddress', new Address(
                                { ...refund.toAddress, formattedAddress: e.currentTarget.value })
                            )}
                            label="Return Address"
                            fieldName="refundToAddress"
                            error={refund.toAddress.errors.formattedAddress}
                            toggleMap
                            disabled={orderUiState!.isReadonly('refundToAddress')}>
                            <AddressMap
                                forFieldName="refundToAddress"
                                moveMarker={!orderUiState.isReadonly('refundToAddress')}
                                lat={refund.toAddress.latLng.lat}
                                lng={refund.toAddress.latLng.lng}
                                handleClick={(lat: number, lng: number) => {
                                    orderUiState.setLatLng("refundToAddress", { lat, lng });
                                    this.handleAddressChange('toAddress', new Address({ ...refund.toAddress, latLng: { lat, lng } }));
                                }}
                            />
                        </AddressSearchBox>
                        <CurrencyField
                            className={this.props.classes.textField}
                            label="Pickup Fee"
                            name="pickupFee"
                            value={refund.pickupFee}
                            onChange={(e: ICurrencyChangedEvent) => this.handleCurrencyChange('pickupFee', e)}
                            disabled={orderUiState!.isReadonly('pickupFee')}
                        />
                    </React.Fragment>
                }
            </Grid>
        </Grid>
    }

    private handleCurrencyChange(property: string, e: ICurrencyChangedEvent) {
        this.props.salesStore!.order.refund.updateAndValidate(property, e.parsedValue);
    }

    private handleAddressChange(property: string, address: Address) {
        this.props.salesStore!.order.refund.update(property, address);
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.order.refund.update(e.currentTarget.name, e.currentTarget.value);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    paymentTextField: {
        marginTop: 16,
        marginBottom: 16,
        textAlign: "right",
        padding: 0,
        width: 50
    }
});

export default withStyles(styles)(CreditSummary);
