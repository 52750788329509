import * as React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { ISupplierSummary } from '../../models/supplier-summary';

interface ISuppliersProps {
    suppliers: ISupplierSummary[];
}

type Props = ISuppliersProps & RouteComponentProps<{}>;

const Suppliers: React.SFC<Props> = (props) => {
    return <div>
        {
            props.suppliers.map(s => {
                return <Grid container className="item" key={s.id}
                    onClick={() => { props.history.push(`suppliers/${s.id}`); }}>
                    <Grid item xs={1} />
                    <Grid item xs={10} className="name">{s.name}</Grid>
                    <Grid item xs={1} />
                </Grid>
            })
        }
    </div>
};

export default Suppliers;