import './styles.css';

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import AddIcon from '@material-ui/icons/Add';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { observable, runInAction, action } from 'mobx';

import { Header } from '../Header/Header';
import { SalesStore } from '../../stores/sales-store';
import { UiStore } from '../../stores/ui-store';
import { Orders } from './Orders';
import { NoRecords } from '../NoRecords/NoRecords';
import SearchDialog from './Search/SearchDialog';
import { FilterStatus } from './Search/FilterStatus';
import tracker from '../Loader/loader-tracker';
import { OrderStatus } from '../../models/orders-filter';
import { scrollToTop } from '../../scroll-to';
import Quotes from './Quotes';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface ISalesProps {
  salesStore?: SalesStore;
  uiStore?: UiStore;
}

type Props = ISalesProps & RouteComponentProps<{}>;

@inject('salesStore', 'uiStore')
@observer
class Sales extends React.Component<Props, {}> {
  private salesStore?: SalesStore;
  @observable private searchDialogActive: boolean;

  constructor(props: Props) {
    super(props);
    this.handleGetQuotes = this.handleGetQuotes.bind(this);
    this.handleGetOrders = this.handleGetOrders.bind(this);
    this.handleSearchDialogOpen = this.handleSearchDialogOpen.bind(this);
    this.handleSearchDialogClose = this.handleSearchDialogClose.bind(this);
    this.handleUpdateFilter = this.handleUpdateFilter.bind(this);
    this.salesStore = props.salesStore;
    runInAction(() => {
      this.searchDialogActive = false;
    });
  }

  public async componentDidMount() {
    scrollToTop();
    this.salesStore!.ordersFilter.setOrderStatus(OrderStatus.Order);
    await tracker.track(this.salesStore!.getOrders());
  }

  public render() {
    const { salesStore } = this.props;
    return (
      <React.Fragment>
        <Header title="Sales" hideTitleForDesktop onClickSearch={this.handleSearchDialogOpen}>
          {{
            tabs: (
              <ul>
                <li>
                  <a
                    onClick={this.handleGetOrders}
                    className={salesStore!.ordersFilter.orderStatus === OrderStatus.Order ? 'active' : ''}
                  >
                    Orders
                  </a>
                </li>
                <li>
                  <a
                    onClick={this.handleGetQuotes}
                    className={salesStore!.ordersFilter.orderStatus === OrderStatus.Quote ? 'active' : ''}
                  >
                    Quotes
                  </a>
                </li>
              </ul>
            ),
          }}
        </Header>

        <FilterStatus onFilterRemoved={this.handleUpdateFilter} />

        <section className="sales">
          {salesStore!.ordersFilter.orderStatus === OrderStatus.Quote && (
            <React.Fragment>
              <Quotes quotes={this.salesStore!.quotes} {...this.props} />
              <NoRecords active />
            </React.Fragment>
          )}
          {salesStore!.ordersFilter.orderStatus === OrderStatus.Order && (
            <React.Fragment>
              <Orders {...this.props} />
              <NoRecords active />
            </React.Fragment>
          )}
        </section>
        <Link to="new-order">
          <div className="action-container">
            <AddIcon className="icon" />
          </div>
        </Link>

        {this.searchDialogActive && (
          <SearchDialog active={this.searchDialogActive} handleClose={this.handleSearchDialogClose} {...this.props} />
        )}
      </React.Fragment>
    );
  }

  private async handleGetQuotes() {
    this.salesStore!.ordersFilter.setOrderStatus(OrderStatus.Quote);
    await tracker.track(this.salesStore!.getQuotes());
  }

  private async handleGetOrders() {
    this.salesStore!.ordersFilter.setOrderStatus(OrderStatus.Order);
    await tracker.track(this.salesStore!.getOrders());
  }

  private async handleUpdateFilter() {
    if (!this.salesStore!.ordersFilter.isFiltered) this.salesStore!.ordersFilter.setFilterByRep(true);

    this.salesStore!.ordersFilter.orderStatus === OrderStatus.Order
      ? await tracker.track(this.salesStore!.getOrders())
      : await tracker.track(this.salesStore!.getQuotes());
  }

  @action
  private handleSearchDialogOpen() {
    this.searchDialogActive = true;
  }

  @action
  private handleSearchDialogClose() {
    this.searchDialogActive = false;
  }
}

export default withAITracking(reactPlugin, Sales, 'Sales');
