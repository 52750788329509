import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { globalStyles } from 'src/theme';
import { observer } from 'mobx-react';
import FormGroup from '@material-ui/core/FormGroup';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import { KeyboardArrowRight, KeyboardArrowLeft } from '@material-ui/icons';
import { DateTime } from 'luxon';
import { dateFormatted } from '../../datetime';
import LuxonUtils from '@date-io/luxon';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, Theme } from '@material-ui/core/styles';
import { OrderSplit } from 'src/models/order-split';
import { DeliveryDate } from 'src/models/delivery-date';

export interface ISplitOrderDialogProps extends WithStyles<typeof styles> {
    onClose(): void
    onSubmit(orderSplit: OrderSplit): void,
    parentOrderDelivery: DeliveryDate;
}

@observer
class SplitOrderDialog extends React.Component<ISplitOrderDialogProps> {

    private orderSplit: OrderSplit;

    constructor(props: ISplitOrderDialogProps) {
        super(props);
        this.orderSplit = new OrderSplit();
        this.orderSplit.updateParentOrderDelivery(props.parentOrderDelivery);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {
        return <div className="full-dialog">
            <Loader area="split-order-dialog" />
            <div className="dialog-content">
                <h3>Split Order</h3>
                <p>
                    What date did you want the split order to be delivered?
                </p>
                <FormGroup row>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <InlineDatePicker
                            className={this.props.classes.inlineDateField}
                            onlyCalendar
                            label={this.orderSplit.deliveryDateTba ? "Date is TBA" : "Date"}
                            value={this.orderSplit.deliveryDate}
                            onChange={(date: DateTime) => this.orderSplit.setDeliveryDate(date.startOf('day'))}
                            labelFunc={(date: DateTime) => dateFormatted(date)}
                            InputProps={{ disableUnderline: true }}
                            leftArrowIcon={<KeyboardArrowLeft />}
                            rightArrowIcon={<KeyboardArrowRight />}
                            error={!!this.orderSplit.errors.deliveryDate}
                            helperText={this.orderSplit.errors.deliveryDate || null}
                            maxDate={DateTime.local().plus({ months: 6 })}
                            minDate={DateTime.local().minus({ days: 1 })}
                            disabled={this.orderSplit.deliveryDateTba}
                        />
                    </MuiPickersUtilsProvider>
                    <FormControlLabel
                        className={this.props.classes.checkBox}
                        control={
                            <Checkbox
                                checked={this.orderSplit.deliveryDateTba}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.orderSplit.setDeliveryDateTba(checked)}
                            />
                        }
                        label="TBA"
                    />
                </FormGroup>

                <h3>Parent Order</h3>
                <p>
                    What date would you like the parent docket to be placed on?
                </p>
                <FormGroup row>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <InlineDatePicker
                            className={this.props.classes.inlineDateField}
                            onlyCalendar
                            label={this.orderSplit.parentDeliveryDateTba ? "Date is TBA" : "Date"}
                            value={this.orderSplit.parentDeliveryDate}
                            onChange={(date: DateTime) => this.orderSplit.setParentDeliveryDate(date.startOf('day'))}
                            labelFunc={(date: DateTime) => dateFormatted(date)}
                            InputProps={{ disableUnderline: true }}
                            leftArrowIcon={<KeyboardArrowLeft />}
                            rightArrowIcon={<KeyboardArrowRight />}
                            error={!!this.orderSplit.errors.parentDeliveryDate}
                            helperText={this.orderSplit.errors.parentDeliveryDate || null}
                            maxDate={DateTime.local().plus({ months: 6 })}
                            minDate={DateTime.local().minus({ days: 1 })}
                            disabled={this.orderSplit.parentDeliveryDateTba}
                        />
                    </MuiPickersUtilsProvider>
                    <FormControlLabel
                        className={this.props.classes.checkBox}
                        control={
                            <Checkbox
                                checked={this.orderSplit.parentDeliveryDateTba}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.orderSplit.setParentDeliveryDateTba(checked)}
                            />
                        }
                        label="TBA"
                    />
                </FormGroup>
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Close</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={this.handleSubmit}>
                    <span>Continue</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }

    private handleSubmit() {
        if (!this.orderSplit.validate()) return;
        this.props.onSubmit(this.orderSplit);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    checkBox: {
        marginTop: -14,
        marginRight: 0
    }
});

export default withStyles(styles)(SplitOrderDialog);