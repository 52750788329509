import { DateTime } from 'luxon';

export const utcToLocal = (datetime: string): DateTime => {
  return DateTime.fromISO(datetime, { zone: 'utc' }).toLocal();
};

export const toDate = (datetime: string): DateTime => {
  return DateTime.fromISO(datetime);
};

export const toZoned = (datetime: string): DateTime => {
  return DateTime.fromISO(datetime, { zone: 'utc' });
};

export const toISODate = (datetime: DateTime | null | undefined): string | null => {
  if (!datetime) return null;
  return datetime.toISODate();
};

export const toZonedTime = (datetime: DateTime | null | undefined): string | null => {
  if (!datetime) return null;
  return datetime.toISOTime({ includeOffset: false });
};

export const dateTimeFormatted = (datetime: DateTime | null): string => {
  return datetime ? datetime.toFormat('cccc dd LLLL yyyy HH:mm') : '';
};

export const dateFormatted = (date: DateTime | null): string => {
  return date ? date.toFormat('cccc dd LLLL yyyy') : '';
};

export const isSameDateToday = (date: DateTime): boolean => {
  const dateToday = DateTime.local();
  return dateToday.hasSame(date, 'day');
};
