import { observable } from 'mobx';
import { Model } from './model';
import { IUserAppSettingsResponse } from 'src/services/settings-api';

export class UserAppSettings extends Model {
    @observable public stopInvoicing: boolean = false;
    @observable public stopStoreToFreight: boolean = false;
    @observable public stopFuelLevy: boolean = false;
    
    constructor(obj: Partial<UserAppSettings>) {
        super();
        Object.assign(this, obj);
    }

    public static fromResponse(r: IUserAppSettingsResponse) {
        return new UserAppSettings({...r});
    }
}

