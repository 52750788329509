import * as React from 'react';
import './styles.css';
import { SalesStore } from '../../stores/sales-store';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import { inject, observer } from 'mobx-react';
import { dateFormatted, dateTimeFormatted, toDate } from '../../datetime';
import { globalStyles } from 'src/theme';
import { IOrderEvent, OrderEventType, OrderStatus } from 'src/models/order';
import {
  IConsignmentNotesPayload,
  IGrnRaisedEventPayload,
  IPaymentPayload,
  IAllocationPayload,
  IPurchaseOrderFailurePayload,
  IPurchaseOrderSuccessPayload,
  ISentToDispatchPayload,
  IShareQuoteEventPayload,
  IUpdatePurchaseOrderPayloadPayload as IUpdatePurchaseOrderPayload,
  IUpdatePurchaseOrderPayloadPayload,
  IWorkflowPayload,
} from 'src/services/sales-api';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import LuxonUtils from '@date-io/luxon';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { DateTime } from 'luxon';
import { UiStore } from 'src/stores/ui-store';

interface IOrderAuditInfoProps extends WithStyles<typeof styles> {
  salesStore?: SalesStore;
  uiStore?: UiStore;
}

@inject('salesStore', 'uiStore')
@observer
class OrderAuditInfo extends React.Component<IOrderAuditInfoProps> {
  constructor(props: IOrderAuditInfoProps) {
    super(props);
    this.handleExipryDateChanged = this.handleExipryDateChanged.bind(this);
  }

  public render() {
    const { salesStore } = this.props;
    return (
      <Grid container className="section audit-info">
        <Grid item xs={1} />
        <Grid item xs={10}>
          <h2>Audit Information</h2>
          <TextField
            className={this.props.classes.textField}
            InputProps={{ disableUnderline: true }}
            label="Owner"
            type="text"
            value={salesStore!.order.owner}
            disabled
            fullWidth
          />
          {salesStore!.order.status === OrderStatus.Quote && (
            <MuiPickersUtilsProvider utils={LuxonUtils}>
              <InlineDatePicker
                className={this.props.classes.inlineDateField}
                onlyCalendar
                label="Quote Expiry"
                value={salesStore!.order.quoteExpiryDate}
                onChange={this.handleExipryDateChanged}
                labelFunc={(date: DateTime) => dateFormatted(date)}
                InputProps={{ disableUnderline: true }}
                leftArrowIcon={<KeyboardArrowLeft />}
                rightArrowIcon={<KeyboardArrowRight />}
                minDate={DateTime.local().minus({ days: 1 })}
                disabled={this.props.uiStore!.orderUiState.formReadOnly}
              />
            </MuiPickersUtilsProvider>
          )}
          <ExpansionPanel className={this.props.classes.expansion}>
            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />} className={this.props.classes.expansionSection}>
              Event log
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={this.props.classes.expansionSection}>
              <div className="event-items">
                {salesStore!.order.eventsOrderedByDate.map((e, i) => {
                  if (e.eventType === OrderEventType.Modified) {
                    return <ModifiedEventRow event={e} key={i} />;
                  }
                  return <EventRow event={e} key={i} />;
                })}
              </div>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      </Grid>
    );
  }

  private handleExipryDateChanged(date: DateTime) {
    this.props.salesStore!.order.update('quoteExpiryDate', date);
  }
}

interface IEventRowProps {
  event: IOrderEvent;
}
interface ProductLineChange {
  description: string;
  code: string;
  supplierName: string;
  price: string;
  quantity: string;
  purchaseOrderNumber: string;
}
interface OrderChanges {
  name: string;
  oldValue: string;
  newValue: string;
}
interface ModifiedPayload {
  orderChanges: OrderChanges[];
  productChanges: {
    deletes: ProductLineChange[];
    adds: ProductLineChange[];
    modified: ProductLineChange[];
  };
}
const ModifiedEventRow: React.SFC<IEventRowProps> = (props) => {
  const payload = props.event.payload as ModifiedPayload;
  return (
    <div className="event-item">
      <Grid container>
        <Grid item xs={6}>
          {`Modified by ${props.event.repCode}`}
          {payload &&
            payload.orderChanges.length > 0 &&
            payload.orderChanges.map((change) => (
              <Grid key={change.name} item xs={12}>
                <span>{change.name}: </span>
                <span style={{ textDecoration: 'line-through' }}>{change.oldValue}</span>
                <span> - {change.newValue}</span>
              </Grid>
            ))}
          {payload && payload.productChanges.adds.length > 0 && (
            <Grid style={{ paddingTop: '5px' }} item xs={12}>
              Added Product Lines
            </Grid>
          )}
          {payload &&
            payload.productChanges.adds.length > 0 &&
            payload.productChanges.adds.map((change) => (
              <Grid style={{ paddingLeft: '2px' }} key={change.code} item xs={12}>
                <span>
                  {change.description}, {change.code}, {change.supplierName}, {change.price}, {change.quantity},{' '}
                  {change.purchaseOrderNumber}{' '}
                </span>
              </Grid>
            ))}
          {payload && payload.productChanges.deletes.length > 0 && (
            <Grid style={{ paddingTop: '5px' }} item xs={12}>
              Deleted Product Lines
            </Grid>
          )}
          {payload &&
            payload.productChanges.deletes.length > 0 &&
            payload.productChanges.deletes.map((change) => (
              <Grid style={{ paddingLeft: '2px' }} key={change.code} item xs={12}>
                <span style={{ textDecoration: 'line-through' }}>
                  {change.description}, {change.code}, {change.supplierName}, {change.price}, {change.quantity},{' '}
                  {change.purchaseOrderNumber}{' '}
                </span>
              </Grid>
            ))}
          {payload && payload.productChanges.modified.length > 0 && (
            <Grid style={{ paddingTop: '5px' }} item xs={12}>
              Modified Product Lines
            </Grid>
          )}
          {payload &&
            payload.productChanges.modified.length > 0 &&
            payload.productChanges.modified.map((change) => (
              <Grid style={{ paddingLeft: '2px' }} key={change.code} item xs={12}>
                <span>
                  {change.description}, {change.code}, {change.supplierName}, {change.price}, {change.quantity},{' '}
                  {change.purchaseOrderNumber}{' '}
                </span>
              </Grid>
            ))}
        </Grid>
        <Grid item xs={6}>
          {dateTimeFormatted(props.event.date)}
        </Grid>
      </Grid>
    </div>
  );
};

const EventRow: React.SFC<IEventRowProps> = (props) => {
  const getDescription = (event: IOrderEvent): string => {
    switch (event.eventType) {
      case OrderEventType.Created:
        return `Created by ${event.repCode}`;
      // case OrderEventType.Modified:
      //     return `Modified by ${event.repCode}`;
      case OrderEventType.SharedQuote: {
        const payload = event.payload as IShareQuoteEventPayload;
        return `${event.repCode} shared quote with ${payload.name} - ${payload.email}`;
      }
      case OrderEventType.PurchaseOrderRaised:
        return `PO raised by ${event.repCode}`;
      case OrderEventType.CustomPricingApproved:
        return `Custom pricing approved by ${event.repCode} ${
          event.payload ? (event.payload as IWorkflowPayload).reason : ''
        }`;
      case OrderEventType.CustomPricingRejected:
        return `Custom pricing rejected by ${event.repCode} ${
          event.payload ? (event.payload as IWorkflowPayload).reason : ''
        }`;
      case OrderEventType.OrderDeletionApproved:
        return `Order deletion approved by ${event.repCode} ${
          event.payload ? (event.payload as IWorkflowPayload).reason : ''
        }`;
      case OrderEventType.OrderDeletionRejected:
        return `Order deletion rejected by ${event.repCode} ${
          event.payload ? (event.payload as IWorkflowPayload).reason : ''
        }`;
      case OrderEventType.PurchaseOrderSuccess: {
        const success = event.payload as IPurchaseOrderSuccessPayload;
        return `Purchase Order ${success.purchaseOrderNumber} created for ${success.supplierName}`;
      }
      case OrderEventType.PurchaseOrderFailure: {
        const failure = event.payload as IPurchaseOrderFailurePayload;
        return `Purchase Order Failed for ${failure.supplierName}`;
      }
      case OrderEventType.PurchaseOrderingComplete:
        return `Purchase Ordering complete`;
      case OrderEventType.OrderSplit:
        return `Order split by ${event.repCode}`;
      case OrderEventType.ConsignmentNoteSent: {
        const consignment = event.payload as IConsignmentNotesPayload;
        return `Consignment note(s) emailed by ${event.repCode}. Delivery date: ${
          consignment.dateTba ? 'TBA' : dateFormatted(toDate(consignment.deliveryDate || ''))
        }`;
      }
      case OrderEventType.InDisputeCustomerApproved:
        return `Credit limit approved by ${event.repCode} ${
          event.payload ? (event.payload as IWorkflowPayload).reason : ''
        }`;
      case OrderEventType.SentToDispatch: {
        const dispatch = event.payload as ISentToDispatchPayload;
        return `Sent JobId: ${dispatch.jobId} to dispatch by ${event.repCode}`;
      }
      case OrderEventType.InDisputeCustomerRejected:
        return `Credit limit rejected by ${event.repCode} ${
          event.payload ? (event.payload as IWorkflowPayload).reason : ''
        }`;
      case OrderEventType.InDisputeCustomerAutoApproved:
        return `Credit limit approved automatically`;
      case OrderEventType.InvoiceSent:
        return `Invoice sent to Unleashed`;
      case OrderEventType.InvoiceFailure:
        return `Invoice failure in Unleashed`;
      case OrderEventType.RefundApproved:
        return `Refund approved by ${event.repCode} ${event.payload ? (event.payload as IWorkflowPayload).reason : ''}`;
      case OrderEventType.RefundRejected:
        return `Refund rejected by ${event.repCode} ${event.payload ? (event.payload as IWorkflowPayload).reason : ''}`;
      case OrderEventType.PurchaseOrderEmailed: {
        const p = event.payload as IPurchaseOrderSuccessPayload;
        return `Purchase Order ${p.purchaseOrderNumber} emailed to ${p.supplierName}`;
      }
      case OrderEventType.PaymentSent: {
        const paymentPayload = event.payload as IPaymentPayload;
        if (paymentPayload) {
          return `Payment Id ${paymentPayload!.Id} sent for amount $${parseFloat(paymentPayload!.Amount).toFixed(2)}`;
        }
        return 'Payment Sent';
      }
      case OrderEventType.PaymentComplete: {
        const paymentCompletePayload = event.payload as IPaymentPayload;
        if (paymentCompletePayload) {
          return `Payment Id ${paymentCompletePayload!.Id} complete for amount $${parseFloat(
            paymentCompletePayload!.Amount,
          ).toFixed(2)}`;
        }
        return 'Payment Complete';
      }
      case OrderEventType.PaymentError: {
        const paymentErrorPayload = event.payload as IPaymentPayload;
        if (paymentErrorPayload) {
          return `Payment Id ${paymentErrorPayload!.Id} errored for amount $${parseFloat(
            paymentErrorPayload!.Amount,
          ).toFixed(2)}`;
        }
        return 'Payment Errored';
      }

      case OrderEventType.AllocationSent: {
        const payload = event.payload as IAllocationPayload;

        if (payload) {
          return `Allocation sent for invoice auditNo: ${payload!.invoiceAuditNo} 
            and receipt auditNo: ${payload!.receiptAuditNo}`;
        }

        return 'Allocation Sent';
      }
      case OrderEventType.AllocationComplete: {
        const payload = event.payload as IAllocationPayload;

        if (payload) {
          return `Allocation complete for invoice auditNo: ${payload!.invoiceAuditNo} 
            and receipt auditNo: ${payload!.receiptAuditNo}`;
        }

        return 'Allocation Complete';
      }
      case OrderEventType.AllocationError: {
        const payload = event.payload as IAllocationPayload;

        if (payload) {
          return `Allocation failed for invoice auditNo: ${payload!.invoiceAuditNo} 
            and receipt auditNo: ${payload!.receiptAuditNo}`;
        }

        return 'Allocation Failed';
      }

      case OrderEventType.PurchaseOrderUpdateRaised: {
        const updatePoRaisedPayload = event.payload as IUpdatePurchaseOrderPayload;
        if (updatePoRaisedPayload) {
          return `Update Purchase Order request raised for ${updatePoRaisedPayload.purchaseOrderNumber} for supplier ${updatePoRaisedPayload.supplierName}.`;
        }
        return 'Update Purchase Order Request Raised';
      }
      case OrderEventType.PurchaseOrderUpdateFailure: {
        const updatePoFailedPayload = event.payload as IUpdatePurchaseOrderPayload;
        if (updatePoFailedPayload) {
          return `Update Purchase Order request failed for ${updatePoFailedPayload.purchaseOrderNumber} for supplier ${updatePoFailedPayload.supplierName}.`;
        }
        return 'Update Purchase Order Failed';
      }
      case OrderEventType.PurchaseOrderUpdateSuccess: {
        const updatePoSuccessPayload = event.payload as IUpdatePurchaseOrderPayload;
        if (updatePoSuccessPayload) {
          return `Purchase Order ${updatePoSuccessPayload.purchaseOrderNumber} for supplier ${updatePoSuccessPayload.supplierName} updated.`;
        }
        return 'Updated Purchase Order';
      }
      case OrderEventType.PurchaseOrderUpdateEmailed: {
        const updatePoEmailedPayload = event.payload as IUpdatePurchaseOrderPayload;
        if (updatePoEmailedPayload) {
          return `Updated Purchase Order emailed to supplier ${updatePoEmailedPayload.supplierName} for purchase order ${updatePoEmailedPayload.purchaseOrderNumber}.`;
        }
        return 'Updated Purchase Order emailed.';
      }
      case OrderEventType.QuoteCancelled:
        return `Quote cancelled by ${event.repCode} ${event.payload ? (event.payload as IWorkflowPayload).reason : ''}`;
      case OrderEventType.ManualCompletion:
        return `Order manually completed by ${event.repCode}.`;
      case OrderEventType.Complete:
        return 'Order complete';
      case OrderEventType.GrnRaised: {
        const pl = event.payload as IGrnRaisedEventPayload;
        return `GRN Raised for PO '${pl.purchaseOrderNumber}', ASAP Job ID: '${pl.asapJobGuid || '---'}', Supplier '${
          pl.supplierName
        }'`;
      }
      case OrderEventType.GrnError: {
        const pl2 = event.payload as IUpdatePurchaseOrderPayloadPayload;
        return `GRN Failed for PO '${pl2.purchaseOrderNumber}', Supplier '${pl2.supplierName}'`;
      }
      case OrderEventType.ProductChecked:
        return `Product checked by ${event.repCode}.`;
      case OrderEventType.UnleashedOrderFailed:
        return `Order failed to be sent to Unleashed.`;
    }
    return '';
  };
  return (
    <div className="event-item">
      <Grid container>
        <Grid item xs={6}>
          {getDescription(props.event)}
        </Grid>
        <Grid item xs={6}>
          {dateTimeFormatted(props.event.date)}
        </Grid>
      </Grid>
    </div>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    ...globalStyles,
    expansion: {
      boxShadow: 'none',
      marginBottom: 30,
    },
    expansionSection: {
      padding: 0,
    },
  });

export default withStyles(styles)(OrderAuditInfo);
