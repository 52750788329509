import * as React from 'react';
import './styles.css';
import Grid from "@material-ui/core/Grid";

interface IActionItemProps {
    label: string;
    onClick: React.MouseEventHandler<Element>;
}
export const ActionItem: React.SFC<IActionItemProps> = (props) => {
    return <Grid container className="action-item">
        <Grid item xs={1} />
        <Grid item xs={10} className="text-right">
            <span className="action"
                onClick={props.onClick}>
                <span className="action-label">
                    {props.label}
                </span>
                <span className="icons-col">
                    {props.children}
                </span>
            </span>
        </Grid>
    </Grid>
};