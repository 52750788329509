import { observable, action } from 'mobx';

export class NotificationUiState {
    @observable public notificationActive: boolean = false;
    @observable public notification: string;
    @observable public type: NotificationType;
    @observable public confirmationActive: boolean = false;
    @observable public okResult: boolean = false;
    private onConfirmationOkHandler: (() => void) | undefined = undefined;

    @action
    public close() {
        this.notificationActive = false;
        this.confirmationActive = false;
        this.okResult = false;
        this.onConfirmationOkHandler = undefined;
    }

    @action
    public ok() {
        this.confirmationActive = false;
        this.okResult = true;
        if(this.onConfirmationOkHandler) {
            this.onConfirmationOkHandler();
            this.onConfirmationOkHandler = undefined;
        }
    }

    @action
    public showInfo(message: string) {
        this.notificationActive = true;
        this.notification = message;
        this.type = NotificationType.info;
    }

    @action
    public showError(message: string) {
        this.notificationActive = true;
        this.notification = message;
        this.type = NotificationType.error;
    }

    @action
    public showQuestion(message: string) {
        this.confirmationActive = true;
        this.notification = message;
        this.type = NotificationType.question
    }

    public onConfirmationOk(onConfirmationOkHandler: () => void) {
        this.onConfirmationOkHandler = onConfirmationOkHandler;
    }
}

export enum NotificationType {
    error = 'error',
    info = 'info',
    question = 'question'
}

