import './styles.css';

import * as React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { RouteComponentProps } from 'react-router';
import {
  ArrowForward,
  Archive,
  ThumbDown,
  Edit,
  LocalShipping,
  Save,
  Share,
  GetApp,
  FileCopy,
  CallSplit,
  SentimentDissatisfied,
} from '@material-ui/icons';
import { observer, inject } from 'mobx-react';
import { Header } from '../Header/Header';
import { SalesStore } from '../../stores/sales-store';
import { UiStore } from '../../stores/ui-store';
import OrderForm from './OrderForm';
import tracker from '../Loader/loader-tracker';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { Loader } from '../Loader/Loader';

import { ActionItem } from '../ActionsMenu/ActionItem';
import SubmitOrderDialog from './SubmitOrderDialog';
import OrderInfo from './OrderInfo';
import { SubmitWorkflowDialog } from '../OrderWorkflows/SubmitWorkflowDialog';
import { WorkflowMenu } from '../OrderWorkflows/WorkflowMenu';
import { RejectionDialog } from '../OrderWorkflows/RejectionDialog';
import { ApprovalDialog } from '../OrderWorkflows/ApprovalDialog';
import WorkflowSummary from '../OrderWorkflows/WorkflowSummary';
import CancelQuoteDialog from './CancelQuoteDialog';
import OrderAuditInfo from './OrderAuditInfo';
import ShareQuoteDialog from './ShareQuoteDialog';
import { AuthStore } from '../../stores/auth-store';
import SplitOrderDialog from './SplitOrderDialog';
import { OrderWorkflow, OrderStatus, DeliveryType } from '../../models/order';
import { OrderSplit } from '../../models/order-split';
import { OrderUiState } from '../../stores/ui-state/order-ui-state';
import ShareInvoiceDialog from './ShareInvoiceDialog';
import { withChangesPrompt } from '../Prompt/Prompt';
import SubmitOrderWithPoDialog, { SubmitType } from './SubmitOrderWithPoDialog';
import { ManuallyCompleteOrderDialog } from './ManuallyCompleteOrderDialog';
import { ProductCheckDialog } from './ProductCheckDialog';
import { ResendInvoiceDialog } from './ResendInvoiceDialog';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface IViewOrderProps {
  salesStore?: SalesStore;
  uiStore?: UiStore;
  authStore?: AuthStore;
}

interface MatchParams {
  id: string;
}

type Props = IViewOrderProps & RouteComponentProps<MatchParams>;

@inject('salesStore', 'uiStore', 'authStore')
@observer
class ViewOrder extends React.Component<Props> {
  private downloadLinkRef: React.RefObject<any>;
  private salesStore: SalesStore;
  private uiStore: UiStore;
  private orderUiState: OrderUiState;
  private authStore: AuthStore;

  constructor(props: Props) {
    super(props);
    this.salesStore = props.salesStore!;
    this.uiStore = props.uiStore!;
    this.orderUiState = props.uiStore!.orderUiState;
    this.authStore = props.authStore!;
    this.handleCompletePickup = this.handleCompletePickup.bind(this);
    this.handleSaveOrder = this.handleSaveOrder.bind(this);
    this.handleCancelOrder = this.handleCancelOrder.bind(this);
    this.handleApproveWorkflow = this.handleApproveWorkflow.bind(this);
    this.handleRejectWorkflow = this.handleRejectWorkflow.bind(this);
    this.handleCancelOrderSubmit = this.handleCancelOrderSubmit.bind(this);
    this.handleCustomPricingSubmit = this.handleCustomPricingSubmit.bind(this);
    this.handleInDisputeCustomerSubmit = this.handleInDisputeCustomerSubmit.bind(this);
    this.handleConfirmQuoteToOrder = this.handleConfirmQuoteToOrder.bind(this);
    this.handleSaveOrderOrRaisePo = this.handleSaveOrderOrRaisePo.bind(this);
    this.handleSaveQuote = this.handleSaveQuote.bind(this);
    this.handleQuoteCancellation = this.handleQuoteCancellation.bind(this);
    this.handleShareQuote = this.handleShareQuote.bind(this);
    this.handleDownloadQuote = this.handleDownloadQuote.bind(this);
    this.handleSplitOrder = this.handleSplitOrder.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.handleSubmitOrderSplit = this.handleSubmitOrderSplit.bind(this);
    this.handleSubmitRefund = this.handleSubmitRefund.bind(this);
    this.validateAndHandle = this.validateAndHandle.bind(this);
    this.reloadPage = this.reloadPage.bind(this);
    this.resetUiState = this.resetUiState.bind(this);
    this.downloadLinkRef = React.createRef();
  }

  public async componentDidMount() {
    await this.loadOrder();
  }

  private async loadOrder() {
    this.orderUiState.setFormReadOnly(true);
    const orderNumber = this.props.match.params.id;
    const order = await tracker.track(this.props.salesStore!.loadOrder(orderNumber));
    this.orderUiState.initViewOrder(order);
  }

  public render() {
    return (
      <React.Fragment>
        <div className="content">
          <Header title={this.salesStore.order.orderNumber || this.props.match.params.id} />
          <OrderInfo {...this.props} />
          {this.salesStore.order.activeWorkflow && <WorkflowSummary workflow={this.salesStore.order.activeWorkflow!} />}
          <OrderForm />
          <OrderAuditInfo />
          {!this.salesStore.order.activeWorkflow ? (
            <ActionsMenu>
              <Loader area="view-order-actions" />
              {this.authStore.isAdmin && this.orderUiState.canCompletePickup && (
                <ActionItem label="Complete pickup" onClick={this.handleCompletePickup}>
                  <LocalShipping />
                </ActionItem>
              )}
              {this.orderUiState.canEdit && (
                <ActionItem label="Edit" onClick={() => this.orderUiState.setFormReadOnly(false)}>
                  <Edit />
                </ActionItem>
              )}
              {this.orderUiState.canSaveOrderChanges && (
                <ActionItem label="Save changes" onClick={() => this.handleSaveOrderOrRaisePo()}>
                  <Save />
                </ActionItem>
              )}
              {this.orderUiState.canSaveQuoteChanges && (
                <ActionItem label="Save changes" onClick={this.handleSaveQuote}>
                  <Save />
                </ActionItem>
              )}
              {this.orderUiState.canSubmitCustomPricing && (
                <ActionItem
                  label="Submit custom pricing"
                  onClick={() => this.validateAndHandle(() => this.orderUiState.setCustomPricingDialogActive(true))}
                >
                  <ArrowForward />
                </ActionItem>
              )}
              {this.orderUiState.canSaveAsOrder && (
                <ActionItem label="Save as order" onClick={this.handleConfirmQuoteToOrder}>
                  <ArrowForward />
                </ActionItem>
              )}
              {this.orderUiState.formReadOnly && this.salesStore.order.canCancelOrder && this.orderUiState.canDelete && (
                <ActionItem label="Delete order" onClick={this.handleCancelOrder}>
                  <ThumbDown />
                </ActionItem>
              )}
              {this.orderUiState.canCancelQuote && (
                <ActionItem
                  label="Cancel quote"
                  onClick={() => this.uiStore.orderUiState.setQuoteCancellationDialogActive(true)}
                >
                  <ThumbDown />
                </ActionItem>
              )}
              {this.orderUiState.canShareQuote && (
                <ActionItem
                  label="Share via email"
                  onClick={() => this.uiStore.orderUiState.setQuoteShareDialogActive(true)}
                >
                  <Share />
                </ActionItem>
              )}
              {this.orderUiState.canShareQuote && (
                <ActionItem label="Download quote" onClick={() => this.handleDownloadQuote(false)}>
                  <GetApp />
                </ActionItem>
              )}
              {this.orderUiState.canShareOrder && (
                <ActionItem
                  label="Share via email"
                  onClick={() => this.uiStore.orderUiState.setInvoiceShareDialogActive(true)}
                >
                  <Share />
                </ActionItem>
              )}
              {this.orderUiState.canShareOrder && (
                <ActionItem label="Download pro forma invoice" onClick={() => this.handleDownloadQuote(true)}>
                  <GetApp />
                </ActionItem>
              )}
              {this.orderUiState.canShareOrder && (
                <ActionItem label="Download order confirmation" onClick={() => this.handleDownloadOrderConfirmation()}>
                  <GetApp />
                </ActionItem>
              )}
              {this.orderUiState.canSplitOrder && (
                <ActionItem
                  label="Split order"
                  onClick={() => this.uiStore.orderUiState.setSplitOrderSubmissionDialogActive(true)}
                >
                  <CallSplit />
                </ActionItem>
              )}
              {this.orderUiState.canSaveSplitOrder && (
                <ActionItem label="Save split order" onClick={this.handleSubmitOrderSplit}>
                  <ArrowForward />
                </ActionItem>
              )}
              {/* Temporarily remove clone button, may be returned in the future */}
              {/* {this.orderUiState.canClone && (
                <ActionItem
                  label="Clone"
                  onClick={() => this.props.history.push(`/clone-order/${this.salesStore.order.orderNumber}`)}
                >
                  <FileCopy />
                </ActionItem>
              )} */}
              {this.orderUiState.canRefund && (
                <ActionItem
                  label="Refund"
                  onClick={() => {
                    this.orderUiState.setFormReadOnly(false);
                    this.salesStore!.order.setOrderWorkflow(OrderWorkflow.RefundingOrder);
                  }}
                >
                  <SentimentDissatisfied />
                </ActionItem>
              )}
              {this.orderUiState.canSubmitRefund && (
                <ActionItem
                  label="Submit refund"
                  onClick={() => {
                    if (this.salesStore!.order.validate()) this.orderUiState.setRefundDialogActive(true);
                  }}
                >
                  <ArrowForward />
                </ActionItem>
              )}
              {this.authStore.isAdmin && this.orderUiState.canManuallyComplete && (
                <ActionItem
                  label="Manually Complete"
                  onClick={() => this.orderUiState.setManuallyCompleteDialogActive(true)}
                >
                  <Archive />
                </ActionItem>
              )}
              {
                // orders that are "ABC Yard Stock Orders" need to be
                this.orderUiState.needsProductCheck && (
                  <ActionItem
                    label="Product Checked"
                    onClick={() => this.orderUiState.setProductCheckDialogActive(true)}
                  >
                    <Archive />
                  </ActionItem>
                )
              }
              {this.authStore.isAdmin && this.orderUiState.canResendInvoice && (
                <ActionItem label="Resend Invoice" onClick={() => this.orderUiState.setResendInvoiceDialogActive(true)}>
                  <Archive />
                </ActionItem>
              )}
            </ActionsMenu>
          ) : (
            this.salesStore.order.activeWorkflow &&
            !this.salesStore.order.activeWorkflow!.canApprove(this.authStore) && (
              <ActionsMenu>
                <Loader area="view-order-actions" />
                {this.orderUiState.canShareQuote && (
                  <ActionItem label="Download quote" onClick={() => this.handleDownloadQuote(false)}>
                    <GetApp />
                  </ActionItem>
                )}
                {this.orderUiState.canShareOrder && (
                  <ActionItem label="Download pro forma invoice" onClick={() => this.handleDownloadQuote(true)}>
                    <GetApp />
                  </ActionItem>
                )}
              </ActionsMenu>
            )
          )}
          {this.salesStore.order.activeWorkflow && this.salesStore.order.activeWorkflow!.canApprove(this.authStore) && (
            <WorkflowMenu
              onApprove={() => this.orderUiState.setApprovalDialogActive(true)}
              onReject={() => this.orderUiState.setRejectionDialogActive(true)}
            />
          )}
        </div>
        {this.orderUiState.orderPoSubmissionDialogActive && (
          <SubmitOrderWithPoDialog
            onSubmit={() =>
              this.salesStore.order.isQuoteInDispute
                ? this.handleSaveQuote()
                : this.handleSaveOrder('submit-order-po-dialog')
            }
            onClose={() => this.orderUiState.setOrderPoSubmissionDialogActive(false)}
            submitType={this.salesStore.order.status === OrderStatus.Quote ? SubmitType.Submit : SubmitType.Save}
          />
        )}
        {this.orderUiState.orderSubmissionDialogActive && (
          <SubmitOrderDialog
            onSubmit={() =>
              this.salesStore.order.isQuoteInDispute
                ? this.handleSaveQuote()
                : this.handleSaveOrder('submit-order-dialog')
            }
            onClose={() => this.orderUiState.setOrderSubmissionDialogActive(false)}
            submitType={this.salesStore.order.status === OrderStatus.Quote ? SubmitType.Submit : SubmitType.Save}
          />
        )}
        {this.orderUiState.approvalDialogActive && (
          <ApprovalDialog
            onSubmit={this.handleApproveWorkflow}
            onClose={() => this.orderUiState.setApprovalDialogActive(false)}
            workflow={this.salesStore.order.activeWorkflow!}
            orderStatus={this.salesStore.order.status}
          />
        )}
        {this.orderUiState.cancelDialogActive && (
          <SubmitWorkflowDialog
            onSubmit={this.handleCancelOrderSubmit}
            onClose={() => this.orderUiState.setCancelDialogActive(false)}
            prompt="What is the reason for deleting this order?"
            title="Delete Order"
            workflow={this.salesStore.order.orderCancellation}
          />
        )}
        {this.orderUiState.customPricingDialogActive && (
          <SubmitWorkflowDialog
            onSubmit={this.handleCustomPricingSubmit}
            onClose={() => this.orderUiState.setCustomPricingDialogActive(false)}
            prompt="Your order includes custom pricing, a manager will need to approve this before it can be processed"
            title="Custom Pricing"
            workflow={this.salesStore.order.customPricing}
          />
        )}
        {this.orderUiState.rejectionDialogActive && (
          <RejectionDialog
            onSubmit={this.handleRejectWorkflow}
            onClose={() => this.orderUiState.setRejectionDialogActive(false)}
            workflow={this.salesStore.order.activeWorkflow!}
          />
        )}
        {this.orderUiState.quoteCancellationDialogActive && (
          <CancelQuoteDialog
            onSubmit={this.handleQuoteCancellation}
            onClose={() => this.orderUiState.setQuoteCancellationDialogActive(false)}
          />
        )}
        {this.orderUiState.quoteShareDialogActive && (
          <ShareQuoteDialog
            onSubmit={() => this.handleShareQuote(false)}
            onClose={() => this.orderUiState.setQuoteShareDialogActive(false)}
          />
        )}
        {this.orderUiState.invoiceShareDialogActive && (
          <ShareInvoiceDialog
            onSubmit={() => this.handleShareQuote(true)}
            onClose={() => this.orderUiState.setInvoiceShareDialogActive(false)}
          />
        )}
        {this.orderUiState.splitOrderSubmissionDialogActive && (
          <SplitOrderDialog
            onSubmit={this.handleSplitOrder}
            onClose={() => this.orderUiState.setSplitOrderSubmissionDialogActive(false)}
            parentOrderDelivery={
              this.salesStore.order.isFreightOnly ? this.salesStore.order.freightOnly : this.salesStore.order.delivery
            }
          />
        )}
        {this.orderUiState.refundDialogActive && (
          <SubmitWorkflowDialog
            onSubmit={this.handleSubmitRefund}
            onClose={() => this.orderUiState.setRefundDialogActive(false)}
            prompt="What is the reason for the refund?"
            title="Refund Request"
            workflow={this.salesStore.order.orderRefund}
          />
        )}
        {this.orderUiState.resendInvoiceDialogActive && (
          <ResendInvoiceDialog
            onOk={() => this.handleResendInvoice()}
            onCancel={() => this.orderUiState.setResendInvoiceDialogActive(false)}
            prompt="When you continue, Mojoe will retry to send the invoice for this order."
            title="Resend Invoice"
          />
        )}
        {this.orderUiState.manuallyCompleteDialogActive && (
          <ManuallyCompleteOrderDialog
            onOk={() => this.handleManualOrderCompletion()}
            onCancel={() => this.orderUiState.setManuallyCompleteDialogActive(false)}
            prompt="Attention - this action will complete the order without going through the automatic workflow. Please seek advice first if you're unsure."
            title="Manual Completion of the Order"
          />
        )}
        {this.orderUiState.productCheckDialogActive && (
          <ProductCheckDialog
            onOk={() => this.handleProductCheck()}
            onCancel={() => this.orderUiState.setProductCheckDialogActive(false)}
            prompt="Please confirm the product has been checked and the order can be cleared for GRN / completion."
            title="Product Checked"
          />
        )}
        <a ref={this.downloadLinkRef} />
      </React.Fragment>
    );
  }

  private handleCancelOrder() {
    this.salesStore.order.orderCancellation.reset();
    this.uiStore!.orderUiState.setCancelDialogActive(true);
  }

  private async handleApproveWorkflow() {
    const { saveAsQuoteForInDispute } = this.props.uiStore!.orderUiState;
    const ok = await tracker.track(this.salesStore!.order.approveWorkflow(saveAsQuoteForInDispute), 'approval-dialog');
    if (ok) {
      await this.reloadPage();
    }
  }

  private async handleRejectWorkflow() {
    this.orderUiState.setRejectionDialogActive(false);
    const ok = await tracker.track(this.salesStore!.order.rejectWorkflow(), 'rejection-dialog');
    if (ok) await this.reloadPage();
  }

  private async validateAndHandle(func: () => void) {
    if (!this.salesStore!.order.validate()) return;

    func();
  }

  private async handleCustomPricingSubmit() {
    const ok =
      this.salesStore!.order.status === OrderStatus.Order
        ? await tracker.track(
            this.salesStore!.order.updateOrder(this.uiStore.notificationUiState),
            'submit-workflow-dialog',
          )
        : await tracker.track(
            this.salesStore!.order.updateQuote(this.uiStore.notificationUiState),
            'submit-workflow-dialog',
          );
    if (ok) await this.reloadPage();
  }

  private async handleInDisputeCustomerSubmit() {
    const ok = await tracker.track(
      this.salesStore!.order.updateQuote(this.uiStore.notificationUiState),
      'submit-workflow-dialog',
    );
    if (ok) await this.reloadPage();
  }

  private async handleCancelOrderSubmit() {
    const ok = await tracker.track(this.salesStore.order.submitCancellation(), 'submit-workflow-dialog');
    if (ok) await this.reloadPage();
  }

  private async handleCompletePickup() {
    const ok = await tracker.track(this.salesStore.completePickup(), 'view-order-actions');
    if (ok) {
      await this.reloadPage();
    }
  }

  private async handleSaveQuote() {
    if (this.salesStore!.order.hasCustomPricing) {
      this.orderUiState.setCustomPricingDialogActive(true);
    } else {
      const ok = await tracker.track(
        this.salesStore.order.updateQuote(this.uiStore.notificationUiState),
        'view-order-actions',
      );
      if (ok) {
        await this.reloadPage();
      }
    }
  }

  private async handleConfirmQuoteToOrder() {
    this.salesStore.order.setOrderWorkflow(OrderWorkflow.TransitioningToOrder);
    if (!this.salesStore.order.validateAsOrder()) {
      if (this.salesStore.order.requiresTbaConfirmation) this.orderUiState.setFormReadOnly(false);
      return;
    }
    if (this.salesStore.order.deliveryType === DeliveryType.FreightOnly)
      this.orderUiState.setOrderSubmissionDialogActive(true);
    else await this.handleSaveOrderOrRaisePo();
  }

  private async handleSaveOrderOrRaisePo() {
    if (this.salesStore.order.canRaisePurchaseOrder) this.orderUiState.setOrderPoSubmissionDialogActive(true);
    else this.handleSaveOrder('view-order-actions');
  }

  private async handleSaveOrder(loadingArea: string) {
    const ok = await tracker.track(this.salesStore.order.updateOrder(this.uiStore.notificationUiState), loadingArea);
    if (ok) {
      await this.reloadPage();
    } else {
      if (this.salesStore.order.requiresTbaConfirmation) this.orderUiState.setFormReadOnly(false);
    }
  }

  private async handleSubmitRefund() {
    const ok = await tracker.track(
      this.salesStore.order.submitRefund(this.uiStore.notificationUiState),
      'submit-workflow-dialog',
    );
    if (ok) await this.reloadPage();
  }

  private async handleSubmitOrder() {
    const ok = await tracker.track(
      this.salesStore.order.saveOrder(this.uiStore.notificationUiState),
      'view-order-actions',
    );
    if (ok) {
      await this.reloadPage();
    } else {
      if (this.salesStore.order.requiresTbaConfirmation) {
        this.orderUiState.setFormReadOnly(false);
      }
    }
  }

  private async handleSubmitOrderSplit() {
    const ok = await tracker.track(
      this.salesStore!.order.splitOrder(this.uiStore.notificationUiState),
      'view-order-actions',
    );
    if (ok) await this.reloadPage();
  }

  private async handleManualOrderCompletion() {
    this.uiStore.orderUiState.setManuallyCompleteDialogActive(false);
    const ok = await tracker.track(
      this.salesStore!.order.manuallyCompleteOrder(this.salesStore.order.orderNumber),
      'view-order-actions',
    );
    if (ok) {
      this.uiStore.notificationUiState.showInfo('Order has been manually completed.');
      await this.reloadPage();
    }
  }

  private async handleResendInvoice() {
    this.uiStore.orderUiState.setResendInvoiceDialogActive(false);
    const ok = await tracker.track(
      this.salesStore!.order.resendInvoice(this.salesStore.order.orderNumber),
      'view-order-actions',
    );
    if (ok) {
      this.uiStore.notificationUiState.showInfo('Invoice has been resent.');
      await this.reloadPage();
    }
  }

  private async handleProductCheck() {
    this.uiStore.orderUiState.setProductCheckDialogActive(false);
    const ok = await tracker.track(
      this.salesStore!.order.confirmProductCheck(this.salesStore.order.orderNumber),
      'view-order-actions',
    );
    if (ok) {
      this.uiStore.notificationUiState.showInfo('Product Check has been confirmed.');
      await this.reloadPage();
    }
  }

  private async handleQuoteCancellation() {
    const ok = await tracker.track(this.salesStore.order.cancelQuote(), 'cancel-quote-dialog');
    if (ok) await this.reloadPage();
  }

  private async handleShareQuote(asInvoice: boolean) {
    const ok = await tracker.track(
      this.salesStore.order.shareQuote(this.uiStore.notificationUiState, asInvoice),
      'share-quote-dialog',
    );
    if (ok) {
      this.orderUiState.setQuoteShareDialogActive(false);
      this.orderUiState.setInvoiceShareDialogActive(false);
    }
  }

  private async handleDownloadQuote(asInvoice: boolean) {
    const blob = await tracker.track(this.salesStore.order.downloadQuote(this.uiStore.notificationUiState, asInvoice));
    if (!blob) return;

    const filename = this.getSafeFilename();
    const a = this.downloadLinkRef.current;
    a.download = filename;
    a.target = '_self';
    a.href = window.URL.createObjectURL(blob);
    a.click();
    a.href = '';
    this.uiStore.notificationUiState.showInfo(
      `Generated ${asInvoice ? 'invoice' : 'quote'} ${filename}. Please check your downloads`,
    );
  }

  private async handleDownloadOrderConfirmation() {
    const blob = await tracker.track(this.salesStore.order.downloadOrderConfirmation(this.uiStore.notificationUiState));
    if (!blob) return;

    const filename = this.getSafeFilename();
    const a = this.downloadLinkRef.current;
    a.download = filename;
    a.target = '_self';
    a.href = window.URL.createObjectURL(blob);
    a.click();
    a.href = '';
    this.uiStore.notificationUiState.showInfo(`Generated Order confirmation ${filename}. Please check your downloads`);
  }

  private getSafeFilename() {
    return `${this.salesStore.order.orderNumber}_${this.salesStore.order.customerName
      .replace(/ /g, '')
      .replace(/\//g, '')}.pdf`;
  }

  private async handleSplitOrder(orderSplit: OrderSplit) {
    const { order } = this.salesStore;

    const sameDayDelivery = !!orderSplit.deliveryDate
      && !!orderSplit.parentDeliveryDate
      && orderSplit.deliveryDate == orderSplit.parentDeliveryDate;

    if (!order.isFreightOnly) {
      order.delivery.update('date', orderSplit.deliveryDate);
      order.delivery.updateDateTba(orderSplit.deliveryDateTba);
    } else {
      order.freightOnly.update('date', orderSplit.deliveryDate);
      order.freightOnly.updateDateTba(orderSplit.deliveryDateTba);
    }

    order.split(sameDayDelivery);

    if (order.parentOrder) {
      order.parentOrder.updateOrderDeliveryDate(orderSplit.parentDeliveryDate);
      order.parentOrder.updateOrderDeliveryDateTba(orderSplit.parentDeliveryDateTba);
    }

    this.orderUiState.setFormReadOnly(false);
    this.orderUiState.setSplitOrderSubmissionDialogActive(false);
  }

  private async reloadPage() {
    this.resetUiState();
    await this.loadOrder();
  }

  private resetUiState() {
    this.uiStore.setActionsMenuActive(false);
    this.orderUiState.setOrderSubmissionDialogActive(false);
    this.orderUiState.setOrderPoSubmissionDialogActive(false);
    this.orderUiState.setQuoteCancellationDialogActive(false);
    this.orderUiState.setCancelDialogActive(false);
    this.orderUiState.setQuoteShareDialogActive(false);
    this.orderUiState.setInvoiceShareDialogActive(false);
    this.orderUiState.setSplitOrderSubmissionDialogActive(false);
    this.orderUiState.setApprovalDialogActive(false);
    this.orderUiState.setRejectionDialogActive(false);
    this.orderUiState.setCustomPricingDialogActive(false);
    this.orderUiState.setInDisputeDialogActive(false);
    this.orderUiState.setRefundDialogActive(false);
  }

  public componentWillUnmount() {
    this.resetUiState();
  }
}

export default withChangesPrompt(withAITracking(reactPlugin, ViewOrder, 'ViewOrder'));
