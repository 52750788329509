import * as React from "react";
import { Warning } from "@material-ui/icons";
import { SalesStore } from "src/stores/sales-store";
import { IconTooltip } from "../IconTooltip/IconTooltip";
import { useEffect, useState } from "react";

export interface InactiveProductsIconProps {
  orderId: number;
  salesStore?: SalesStore;
}

export const InactiveProductsIcon: React.FunctionComponent<InactiveProductsIconProps> =
  (props) => {
    const [hasInactiveProducts, setHasInactiveProducts] = useState(false);
    useEffect(() => {
      if (props.salesStore) {
        props.salesStore.hasOrderDeletedProducts(props.orderId).then((doesIt) => {
          if (doesIt) {
            setHasInactiveProducts(true);
          }
        });
      }
    }, [props.salesStore, props.orderId]);
    return (
      <div>
        {hasInactiveProducts && (
          <IconTooltip
            title="Order contains inactive products"
            placement="left"
          >
            {{ icon: <Warning color="error" /> }}
          </IconTooltip>
        )}
      </div>
    );
  };
