import * as React from 'react';
import './styles.css';
import { TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Workflow } from '../../models/workflow';

export interface IWorkflowDialogContentProps {
    workflow: Workflow
}

@observer
export class WorkflowDialogContent extends React.Component<IWorkflowDialogContentProps> {
    constructor(props: IWorkflowDialogContentProps) {
        super(props);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        return <div className="workflow-dialog-content" >
                <h3>{this.props.workflow.workflowTitle}</h3>
                <div className="po-lines">
                <p>{this.props.workflow.workflowText}</p>
                <TextField
                        InputProps={{ disableUnderline: true }}
                        label="Reason"
                        type="text"
                        name="reason"
                        autoComplete="no"
                        error={!!this.props.workflow.errors.reason}
                        helperText={this.props.workflow.errors.reason || null}
                        value={this.props.workflow.reason}
                        onChange={this.handleChange}
                        fullWidth
                />
                <br />
                </div>
           </div>
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.workflow.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.workflow.update(e.currentTarget.name, e.currentTarget.value);
    }
}