import './styles.css';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { DateTime } from 'luxon';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { GetApp, PersonAddDisabled } from '@material-ui/icons';
import * as React from 'react';
import { WithStyles, withStyles, Grid, FormControl } from '@material-ui/core';

import { CustomersStore, ICustomerSummary, ICustomerWithTiersSummary } from '../../stores/customers-store';
import { UiStore } from '../../stores/ui-store';
import { globalStyles } from '../../theme';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { Header } from '../Header/Header';
import tracker from '../Loader/loader-tracker';
import { ProductsStore } from 'src/stores/products-store';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface ICustomerIndexProps extends WithStyles<typeof globalStyles> {
  customersStore?: CustomersStore;
  productsStore?: ProductsStore;
  uiStore?: UiStore;
}

type Props = ICustomerIndexProps & RouteComponentProps<{}>;

@inject('customersStore', 'uiStore', 'productsStore')
@observer
class CustomerIndex extends React.Component<Props> {
  private downloadLinkRef: React.RefObject<any>;

  constructor(props: Props) {
    super(props);
    this.getCustomerSuggestions = this.getCustomerSuggestions.bind(this);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    this.downloadTierPricingCsv = this.downloadTierPricingCsv.bind(this);
    this.downloadLinkRef = React.createRef();
  }

  public async componentDidMount() {
    await tracker.track(this.props.productsStore!.getTiers());
  }

  public render() {
    return (
      <React.Fragment>
        <Header title="Customers" />
        <Grid className="customers" container>
          <Grid item xs={1} />
          <Grid item xs={10} className="content">
            <FormControl className={this.props.classes.textFieldFullWidth}>
              <Autocomplete
                label="Search for customers by code or name"
                onSelect={this.handleCustomerSelected}
                getSuggestions={this.getCustomerSuggestions}
                suggestionToComponent={{
                  containerStyle: { height: '50px' },
                  getComponent: (item: SuggestionItem<ICustomerWithTiersSummary>) => (
                    <Grid container>
                      <Grid item xs={11} className={item.value.isInDispute ? 'dispute' : ''}>
                        {item.label} {item.value.isInDispute && <span> (In Dispute)</span>}
                      </Grid>
                      <Grid item xs={1}>
                        {item.value.isInDispute && (
                          <IconTooltip title="Customer in Dispute" placement="left">
                            {{ icon: <PersonAddDisabled color="error" /> }}
                          </IconTooltip>
                        )}
                      </Grid>
                      <Grid item xs={12} className="sub-text">
                        <small>
                          {item.value.nonDefaultTierIds.length ? (
                            item.value.nonDefaultTierIds.map((t, i) => (
                              <span className="tier" key={i}>
                                {this.props.productsStore!.tiersMap[t]}{' '}
                              </span>
                            ))
                          ) : (
                            <span>
                              <span className="tier">
                                {this.props.productsStore!.tiersMap[item.value.defaultTierId]}
                              </span>{' '}
                              default
                            </span>
                          )}
                        </small>
                      </Grid>
                    </Grid>
                  ),
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <a ref={this.downloadLinkRef} />
        <ActionsMenu>
          <ActionItem label="Export customer tier pricing" onClick={this.downloadTierPricingCsv}>
            <GetApp />
          </ActionItem>
        </ActionsMenu>
      </React.Fragment>
    );
  }

  private getCustomerSuggestions(value: string): Promise<Array<SuggestionItem<ICustomerWithTiersSummary>>> {
    return this.props.customersStore!.searchCustomersWithTiers(value);
  }

  private handleCustomerSelected(selected: SuggestionItem<ICustomerSummary>) {
    this.props.history.push(`customers/${selected.value.id}`);
  }

  private async downloadTierPricingCsv() {
    const blob = await tracker.track(
      this.props.customersStore!.downloadTierPricingCsv(this.props.uiStore!.notificationUiState),
    );
    if (!blob) return;

    const filename = `customer-tier-pricing-${DateTime.local().toFormat('ddMMyyyyHHmm')}.csv`;
    const a = this.downloadLinkRef.current;
    a.download = filename;
    a.target = '_self';
    a.href = window.URL.createObjectURL(blob);
    a.click();
    a.href = '';
    this.props.uiStore!.notificationUiState.showInfo(`Generated CSV ${filename}. Please check your downloads`);
  }
}

export default withStyles(globalStyles)(withAITracking(reactPlugin, CustomerIndex, 'CustomerIndex'));
