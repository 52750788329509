import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { UiStore } from '../../stores/ui-store';
import { ActionItem } from './ActionItem';

export interface IActionsMenuProps {
  uiStore?: UiStore;
}

@inject('uiStore')
@observer
export class ActionsMenu extends React.Component<IActionsMenuProps, {}> {
  private uiStore?: UiStore;
  private actionHeight: number = 35;

  constructor(props: IActionsMenuProps) {
    super(props);
    this.toggleActions = this.toggleActions.bind(this);
    this.uiStore = this.props.uiStore;
  }

  public render() {
    return (
      <div className="actions-menu">
        <div
          className={`icon-container ${this.uiStore!.actionsMenuActive ? 'active ' : ''}`}
          onClick={this.toggleActions}
          style={this.uiStore!.actionsMenuActive ? { bottom: this.actionMenuButtonBottom } : {}}
        >
          <ArrowForward className="icon" />
        </div>
        <div
          className={`actions ${this.uiStore!.actionsMenuActive ? 'active ' : ''}`}
          style={{ height: this.actionContainerHeight }}
        >
          <div className="items">{this.props.children}</div>
        </div>
      </div>
    );
  }

  private toggleActions() {
    this.uiStore!.setActionsMenuActive(!this.uiStore!.actionsMenuActive);
  }

  public componentWillUnmount() {
    this.uiStore!.setActionsMenuActive(false);
  }

  private get numOfActionItem(): number {
    const children = React.Children.map(this.props.children, (child) => {
      return React.isValidElement(child) && child.type === ActionItem ? 1 : 0;
    });

    if (!Array.isArray(children)) return 0;

    return children.reduce<number>((acc, curr) => acc + curr, 0);
  }

  private get actionContainerHeight() {
    const paddingItems = 2;
    return (this.numOfActionItem + paddingItems) * this.actionHeight;
  }

  private get actionMenuButtonBottom() {
    const offSet = 45;
    return this.numOfActionItem * this.actionHeight + offSet;
  }
}
