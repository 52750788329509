import * as React from 'react';
import './styles.css';
import { observer } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import LuxonUtils from '@date-io/luxon';
import { KeyboardArrowLeft, KeyboardArrowRight, DeleteForever } from '@material-ui/icons';
import { PaymentLine } from 'src/models/payment-line';
import { Grid, Hidden, MenuItem, Select } from '@material-ui/core';
import { DateTime } from 'luxon';
import CurrencyField, { ICurrencyChangedEvent } from '../Currency/CurrencyField';
import { ProductsStore } from 'src/stores/products-store';
import { IAbcLocationResponse } from 'src/services/products-api';
import { PaymentType } from 'src/models/order';

interface IPaymentLineProps {
    index: number;
    paymentLine: PaymentLine;
    isReadOnly: boolean;
    salesStore?: SalesStore;
    productsStore? : ProductsStore;
    handlePaymentLinePaymentLocationChange(value: string): void;
    handlePaymentLineDateChange(date: DateTime): void;
    handleDeletePaymentLine(paymentLine: PaymentLine): void;
    handlePaymentLineAmountChange(name: string, value: number): void;
}


const availablePayments: Array<{ name: string, value: PaymentType }> = [
    { name: 'Card', value: PaymentType.Card },
    { name: 'Cash', value: PaymentType.Cash },
    { name: 'Cheque', value: PaymentType.Cheque },
    { name: 'Direct Deposit', value: PaymentType.DirectDeposit }
];

const PaymentRow: React.SFC<IPaymentLineProps> = observer((props) => {
    const { paymentLine, index, isReadOnly } = props;
    const getType = (line: PaymentLine) => {
        const type = availablePayments.find(p => p.value === line.type);
        return type ? type.name : '';
    }

    return (<React.Fragment key={index}>

        <Grid container className="payment-line">

            <Grid item xs={11} lg={2} className="payment-type" >{getType(paymentLine)}</Grid>
            <Hidden lgUp>
                <Grid item xs={1} className="icon">
                    {
                        !isReadOnly
                        && <DeleteForever onClick={() => props.handleDeletePaymentLine(paymentLine)} className="action"/>
                    }
                </Grid>
                <Grid item xs={6}>Amount</Grid>
            </Hidden>
            <Grid item xs={6} lg={3}>
                <CurrencyField
                    name="amount"
                    value={paymentLine.amount}
                    error={paymentLine.errors.amount}
                    onChange={(e: ICurrencyChangedEvent) => props.handlePaymentLineAmountChange('amount', e.parsedValue)}
                    fullWidth
                    disabled={isReadOnly}
                    autoFocus
                />
            </Grid>
            <Hidden lgUp>
                <Grid item xs={6}>Date</Grid>
            </Hidden>
            <Grid item xs={6} lg={3}>
                <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <InlineDatePicker
                        name="receivedDate"
                        onlyCalendar
                        onChange={props.handlePaymentLineDateChange}
                        value={paymentLine.receivedDate}
                        labelFunc={(date: DateTime) => (date) ? date.toFormat('dd LLLL yyyy') : ''}
                        InputProps={{ disableUnderline: true }}
                        leftArrowIcon={<KeyboardArrowLeft />}
                        rightArrowIcon={<KeyboardArrowRight />}
                        maxDate={DateTime.local()}
                        disabled={isReadOnly}
                    />
                </MuiPickersUtilsProvider>
            </Grid>
            <Hidden lgUp>
                <Grid item xs={6}>Location</Grid>
            </Hidden>
            <Grid item xs={6} lg={3}>
            <Select
                disableUnderline
                displayEmpty={true}
                value={paymentLine.paymentLocation}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => props.handlePaymentLinePaymentLocationChange(e.target.value)}
                inputProps={{ name: 'paymentLocation', id: 'paymentLocation' }}
                disabled={isReadOnly}>
                {
                    props.productsStore!.paymentLocations.map((s: IAbcLocationResponse, i : number) => (<MenuItem value={s.code} key={i}>{s.name}</MenuItem>))
                }
            </Select>
            </Grid>
            <Hidden mdDown>
                <Grid item lg={1} className="icon">
                    {
                        !isReadOnly
                        && <DeleteForever className="action" onClick={() => props.handleDeletePaymentLine(paymentLine)} />
                    }
                </Grid>
            </Hidden>
        </Grid>


    </React.Fragment>)
})

export default PaymentRow;