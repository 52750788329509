import './styles.css';

import { FormControl, Grid, InputLabel, MenuItem, Select, withStyles, WithStyles, FormGroup } from '@material-ui/core';
import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Header } from '../Header/Header';
import { inject, observer } from 'mobx-react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { action, observable, runInAction } from 'mobx';
import MuiPickersUtilsProvider from 'material-ui-pickers/MuiPickersUtilsProvider';
import { InlineDatePicker } from 'material-ui-pickers/DatePicker';
import { DateTime } from 'luxon';
import LuxonUtils from '@date-io/luxon';
import { Close, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';

import { ProductsStore } from 'src/stores/products-store';
import { OrderStatus } from 'src/models/orders-filter';
import { globalStyles } from 'src/theme';
import { dateFormatted } from 'src/datetime';
import { ReportsStore } from 'src/stores/reports-store';
import { ReportsFilter } from 'src/models/reports-filter';
import tracker from '../Loader/loader-tracker';
import { UiStore } from 'src/stores/ui-store';
import { OrderLifetimeStatus } from 'src/models/order';
import { reactPlugin } from '../../utilities/appInsightsConfig';
import Autocomplete, { SuggestionItem } from 'src/components/Autocomplete/Autocomplete';
import { ICustomerSummary, IOwnerName } from 'src/stores/customers-store';

export interface IReportProps extends WithStyles<typeof globalStyles> {
  productsStore?: ProductsStore;
  reportsStore?: ReportsStore;
  uiStore?: UiStore;
}

type Props = IReportProps & RouteComponentProps<{}>;

@inject('productsStore', 'reportsStore', 'uiStore')
@observer
class Reports extends React.Component<Props> {
  private downloadLinkRef: React.RefObject<any>;
  @observable private reportsFilter: ReportsFilter;

  constructor(props: Props) {
    super(props);
    this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
    this.handleAddressSelected = this.handleAddressSelected.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.getCustomerSuggestions = this.getCustomerSuggestions.bind(this);
    this.getAddressSuggestions = this.getAddressSuggestions.bind(this);
    this.handleReportChanged = this.handleReportChanged.bind(this);
    this.handleOwnerSearchChange = this.handleOwnerSearchChange.bind(this);
    this.handleCustomerSearchChange = this.handleCustomerSearchChange.bind(this);
    this.printReport = this.printReport.bind(this);
    this.handleStatusChanged = this.handleStatusChanged.bind(this);
    this.handleBranchBankChanged = this.handleBranchBankChanged.bind(this);
    this.downloadLinkRef = React.createRef();
    runInAction(() => this.init());
  }

  @action
  public init() {
    this.reportsFilter = new ReportsFilter(0, true, OrderStatus.Order, '', '', null, null, '', '', '', '', null, null);
    this.reportsFilter.setDatesRequired(false);
    this.props.reportsStore!.selectedReport = 'TBA Orders Report';
    this.handleFilterEnabling(this.props.reportsStore!.selectedReport);
    this.props.reportsStore!.selectedFileFormat = 'CSV';
    this.reportsFilter.setLifeTimeStatus(OrderLifetimeStatus.None);
    this.props.productsStore!.getStockingPoints();
  }

  public render() {
    const { reportsStore, uiStore } = this.props;
    return (
      <>
        <Header title="Reports" />

        <Grid container className="reports">
          <Grid item xs={1} />
          <Grid item xs={10}>
            <section>
              <FormControl className={this.props.classes.textField}>
                <InputLabel htmlFor="report">Select Report</InputLabel>

                <Select
                  disableUnderline
                  disabled={false}
                  style={{ minWidth: 205 }}
                  value={reportsStore!.selectedReport}
                  onChange={this.handleReportChanged}
                  inputProps={{ name: 'report', id: 'report' }}
                >
                  {[
                    <MenuItem value={'TBA Orders Report'} key={0}>
                      TBA Orders Report
                    </MenuItem>,
                    <MenuItem value={'Status Report'} key={1}>
                      Status Report
                    </MenuItem>,
                    <MenuItem value={'Pricing Approvals Report'} key={2}>
                      Pricing Approvals Report
                    </MenuItem>,
                    <MenuItem value={'Branch Banking Report'} key={3}>
                      Branch Banking Report
                    </MenuItem>,
                      <MenuItem value={'Complete and Failed Invoices Report'} key={8}>
                      Complete and Failed Invoices Report
                    </MenuItem>,
                  ]}
                </Select>
              </FormControl>
            </section>

            <section>
              {reportsStore!.ownerLookupIsVisible && (
                <Grid container>
                  <Grid item style={{ minWidth: 350 }}>
                    <Autocomplete
                      label="Owner"
                      onSelect={(item) => this.handleOwnerSelected(item as SuggestionItem<IOwnerName>)}
                      getSuggestions={(val) => this.getOwnerSuggestions(val)}
                      errorText={this.reportsFilter.errors.owner}
                      onChange={this.handleOwnerSearchChange}
                      selectedItem={uiStore!.orderSearchUiState.selectedOwnerSuggestion}
                      value={uiStore!.orderSearchUiState.ownerSearchText}
                      onHandleBlur={() => {
                        if (
                          uiStore!.orderSearchUiState.selectedOwnerSuggestion &&
                          uiStore!.orderSearchUiState.selectedOwnerSuggestion.label !==
                            uiStore!.orderSearchUiState.ownerSearchText
                        ) {
                          this.handleOwnerSearchChange(uiStore!.orderSearchUiState.selectedOwnerSuggestion.label);
                        } else if (!uiStore!.orderSearchUiState.selectedOwnerSuggestion) {
                          this.handleOwnerSearchChange('');
                        }
                      }}
                      onClear={() => {
                        this.reportsFilter.setOwnerRepCode('');
                        uiStore!.orderSearchUiState.setOwnerSearchText('');
                        uiStore!.orderSearchUiState.setOwnerSuggestion(undefined);
                      }}
                    />
                  </Grid>
                </Grid>
              )}

              {reportsStore!.customerLookupIsVisible && (
                <>
                  <Grid container>
                    <Grid item style={{ minWidth: 350 }}>
                      <Autocomplete
                        label="Customer"
                        onSelect={(item) => this.handleCustomerSelected(item as SuggestionItem<ICustomerSummary>)}
                        getSuggestions={this.getCustomerSuggestions}
                        onChange={this.handleCustomerSearchChange}
                        errorText={this.reportsFilter.errors.customerId}
                        selectedItem={uiStore!.orderSearchUiState.selectedCustomerSuggestion}
                        value={uiStore!.orderSearchUiState.customerSearchText}
                        onHandleBlur={() => {
                          if (
                            uiStore!.orderSearchUiState.selectedCustomerSuggestion &&
                            uiStore!.orderSearchUiState.selectedCustomerSuggestion.label !==
                              uiStore!.orderSearchUiState.customerSearchText
                          ) {
                            this.handleCustomerSearchChange(
                              uiStore!.orderSearchUiState.selectedCustomerSuggestion.label,
                            );
                          } else if (!uiStore!.orderSearchUiState.selectedCustomerSuggestion) {
                            this.handleCustomerSearchChange('');
                          }
                        }}
                        onClear={() => {
                          this.reportsFilter.setCustomerId(0);
                          uiStore!.orderSearchUiState.setCustomerSearchText('');
                          uiStore!.orderSearchUiState.setCustomerSuggestion(undefined);
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              {reportsStore!.addressLookupIsVisible && (
                <>
                  <Grid container>
                    <Grid item style={{ minWidth: 350 }}>
                      <Autocomplete
                        label="Address"
                        onSelect={(item) => this.handleAddressSelected(item as SuggestionItem<string>)}
                        selectedItem={uiStore!.orderSearchUiState.selectedAddressSuggestion}
                        getSuggestions={this.getAddressSuggestions}
                        errorText={this.reportsFilter.errors.address}
                        value={uiStore!.orderSearchUiState.addressSearchText}
                        onChange={this.handleAddressChange}
                        onHandleBlur={() => {
                          if (
                            this.reportsFilter.address &&
                            this.reportsFilter.address !== '' &&
                            this.reportsFilter.address !== uiStore!.orderSearchUiState.addressSearchText
                          ) {
                            this.handleAddressChange(this.reportsFilter.address);
                          } else if (!this.reportsFilter.address || this.reportsFilter.address === '') {
                            this.handleAddressChange('');
                          }
                        }}
                        onClear={() => {
                          this.reportsFilter.setAddress('');
                          uiStore!.orderSearchUiState.setAddressSearchText('');
                          uiStore!.orderSearchUiState.setAddressSuggestion(undefined);
                        }}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <section>
                {reportsStore!.branchBankingIsVisible && (
                  <FormControl className={this.props.classes.textField}>
                    <InputLabel htmlFor="branchBank">Branch Bank Code</InputLabel>

                    <Select
                      disableUnderline
                      disabled={false}
                      style={{ minWidth: 205 }}
                      value={reportsStore!.selectedBranchBank ?? ''}
                      onChange={this.handleBranchBankChanged}
                      inputProps={{ name: 'branchBank', id: 'branchBank' }}
                    >
                      {
                        this.props.productsStore!.stockingPoints.map((s, i) => (<MenuItem value={i + 1} key={i}>{s.name}</MenuItem>))
                      }
                    </Select>
                  </FormControl>
                )}
              </section>

              <section>
                {reportsStore!.statusIsVisible && (
                  <FormControl className={this.props.classes.textField}>
                    <InputLabel htmlFor="status">Status</InputLabel>

                    <Select
                      disableUnderline
                      disabled={false}
                      style={{ minWidth: 205 }}
                      value={this.reportsFilter!.lifeTimeStatus}
                      onChange={this.handleStatusChanged}
                      inputProps={{ name: 'status', id: 'status' }}
                    >
                      <MenuItem value={OrderLifetimeStatus.None} key={OrderLifetimeStatus.None}>
                        None
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.PoRaised} key={OrderLifetimeStatus.PoRaised}>
                        PO Raised
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.PoError} key={OrderLifetimeStatus.PoError}>
                        PO Error
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.AwaitingDispatch} key={OrderLifetimeStatus.AwaitingDispatch}>
                        Awaiting Dispatch
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.DispatchError} key={OrderLifetimeStatus.DispatchError}>
                        Dispatch Error
                      </MenuItem>
                      ,
                      <MenuItem
                        value={OrderLifetimeStatus.ReceiptedIntoStock}
                        key={OrderLifetimeStatus.ReceiptedIntoStock}
                      >
                        Receipted into Stock
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.Delivered} key={OrderLifetimeStatus.Delivered}>
                        Delivered
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.InvoiceSent} key={OrderLifetimeStatus.InvoiceSent}>
                        Invoice Sent
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.InvoiceError} key={OrderLifetimeStatus.InvoiceError}>
                        Invoice Error
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.Complete} key={OrderLifetimeStatus.Complete}>
                        Complete
                      </MenuItem>
                      ,
                      <MenuItem value={OrderLifetimeStatus.Cancelled} key={OrderLifetimeStatus.Cancelled}>
                        Cancelled
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </section>

              {reportsStore!.dateRangeIsVisible && (
                <FormGroup row>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Grid container>
                      <Grid item style={{ minWidth: 250 }}>
                        <InlineDatePicker
                          className={this.props.classes.inlineDateField}
                          onlyCalendar
                          label="Order Date from"
                          value={this.reportsFilter.startDate}
                          onChange={(date: DateTime) => this.reportsFilter.setStartDate(date)}
                          labelFunc={(date: DateTime) => dateFormatted(date)}
                          InputProps={{ disableUnderline: true }}
                          leftArrowIcon={<KeyboardArrowLeft />}
                          rightArrowIcon={<KeyboardArrowRight />}
                          error={!!this.reportsFilter.errors.startDate}
                          helperText={this.reportsFilter.errors.startDate || null}
                        />
                      </Grid>
                      <Grid item>
                        {this.reportsFilter.startDate && (
                          <Close
                            className="clearTextStyle action"
                            onClick={() => this.reportsFilter.setStartDate(null)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Grid container>
                      <Grid item style={{ minWidth: 250 }}>
                        <InlineDatePicker
                          className={this.props.classes.inlineDateField}
                          onlyCalendar
                          label="Order Date to"
                          value={this.reportsFilter.endDate}
                          onChange={(date: DateTime) => this.reportsFilter.setEndDate(date)}
                          labelFunc={(date: DateTime) => dateFormatted(date)}
                          InputProps={{ disableUnderline: true }}
                          leftArrowIcon={<KeyboardArrowLeft />}
                          rightArrowIcon={<KeyboardArrowRight />}
                          error={!!this.reportsFilter.errors.endDate}
                          helperText={this.reportsFilter.errors.endDate || null}
                        />
                      </Grid>
                      <Grid item>
                        {this.reportsFilter.endDate && (
                          <Close
                            className="clearTextStyle action"
                            onClick={() => this.reportsFilter.setEndDate(null)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </FormGroup>
              )}

              {reportsStore!.deliveryDateRangeIsVisible && (
                <FormGroup row>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Grid container>
                      <Grid item style={{ minWidth: 250 }}>
                        <InlineDatePicker
                          className={this.props.classes.inlineDateField}
                          onlyCalendar
                          label="Delivery Date from"
                          value={this.reportsFilter.deliveryStartDate}
                          onChange={(date: DateTime) => this.reportsFilter.setDeliveryStartDate(date)}
                          labelFunc={(date: DateTime) => dateFormatted(date)}
                          InputProps={{ disableUnderline: true }}
                          leftArrowIcon={<KeyboardArrowLeft />}
                          rightArrowIcon={<KeyboardArrowRight />}
                          error={!!this.reportsFilter.errors.deliveryStartDate}
                          helperText={this.reportsFilter.errors.deliveryStartDate || null}
                        />
                      </Grid>
                      <Grid item>
                        {this.reportsFilter.deliveryStartDate && (
                          <Close
                            className="clearTextStyle action"
                            onClick={() => this.reportsFilter.setDeliveryStartDate(null)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                  <MuiPickersUtilsProvider utils={LuxonUtils}>
                    <Grid container>
                      <Grid item style={{ minWidth: 250 }}>
                        <InlineDatePicker
                          className={this.props.classes.inlineDateField}
                          onlyCalendar
                          label="Delivery Date to"
                          value={this.reportsFilter.deliveryEndDate}
                          onChange={(date: DateTime) => this.reportsFilter.setDeliveryEndDate(date)}
                          labelFunc={(date: DateTime) => dateFormatted(date)}
                          InputProps={{ disableUnderline: true }}
                          leftArrowIcon={<KeyboardArrowLeft />}
                          rightArrowIcon={<KeyboardArrowRight />}
                          error={!!this.reportsFilter.errors.deliveryEndDate}
                          helperText={this.reportsFilter.errors.deliveryEndDate || null}
                        />
                      </Grid>
                      <Grid item>
                        {this.reportsFilter.deliveryEndDate && (
                          <Close
                            className="clearTextStyle action"
                            onClick={() => this.reportsFilter.setDeliveryEndDate(null)}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </MuiPickersUtilsProvider>
                </FormGroup>
              )}
            </section>

            <section>
              <FormControl className={this.props.classes.textField}>
                <InputLabel htmlFor="fileFormat">Select File Format</InputLabel>

                <Select
                  disableUnderline
                  disabled={reportsStore!.disableSelectedFileFormat}
                  style={{ minWidth: 205 }}
                  value={reportsStore!.selectedFileFormat}
                  onChange={(e) => this.handleFileFormatChanged(e)}
                  inputProps={{ name: 'fileFormat', id: 'fileFormat' }}
                >
                  {[
                    <MenuItem value={'CSV'} key={'CSV'}>
                      CSV
                    </MenuItem>,
                    <MenuItem value={'PDF'} key={'PDF'}>
                      PDF
                    </MenuItem>,
                  ]}
                </Select>
              </FormControl>
            </section>
            <br />
            <br />
            <section>
              <a className="action print-action" onClick={this.printReport} style={{ marginTop: 50 }}>
                Print Report
              </a>
            </section>

            <a ref={this.downloadLinkRef} />
          </Grid>
        </Grid>
      </>
    );
  }

  private async printReport() {
    if (!this.reportsFilter.validate()) return;

    const blob = await tracker.track(
      this.props.reportsStore!.PrintCSVReport(
        this.props.reportsStore!.selectedReport,
        this.reportsFilter,
        this.props.reportsStore!.selectedFileFormat,
      ),
    );

    if (!blob) {
      this.props.uiStore!.notificationUiState.showInfo(`No Orders Found`);
      return;
    }

    const filename = `${this.props.reportsStore!.selectedReport}-${DateTime.local().toFormat(
      'ddMMyyyyHHmm',
    )}.${this.props.reportsStore!.selectedFileFormat.toLowerCase()}`;
    console.log(filename);
    const a = this.downloadLinkRef.current;
    a.download = filename;
    a.target = '_self';
    a.href = window.URL.createObjectURL(blob);
    a.click();
    a.href = '';
    this.props.uiStore!.notificationUiState.showInfo(
      `Generated ${this.props.reportsStore!.selectedFileFormat} ${filename}. Please check your downloads`,
    );
  }

  private handleFileFormatChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    this.props.reportsStore!.setSelectedFileFormat(e.target.value);
  }

  private handleReportChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    this.props.reportsStore!.setSelectedReport(e.target.value);
    this.handleFilterEnabling(this.props.reportsStore!.selectedReport);
  }

  private handleBranchBankChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    this.props.reportsStore!.setSelectedBranchBank(Number(e.target.value));
  }

  private handleStatusChanged(e: React.ChangeEvent<HTMLSelectElement>) {
    const value = Number(e.target.value);

    if (value) {
      this.reportsFilter.setLifeTimeStatus(value as OrderLifetimeStatus);
    }
  }

  private handleFilterEnabling(report: string) {
    this.props.reportsStore!.setCustomerLookupIsVisible(false);
    this.props.reportsStore!.setOwnerLookupIsVisible(false);
    this.props.reportsStore!.setAddressLookupIsVisible(false);
    this.props.reportsStore!.setDateRangeIsVisible(false);
    this.props.reportsStore!.setDeliveryDateRangeIsVisible(false);
    this.props.reportsStore!.setStatusIsVisible(false);
    this.props.reportsStore!.setBranchBankingIsVisible(false);
    this.props.reportsStore!.setDisableFileFormatSelect(false);
    switch (report) {
      case 'TBA Orders Report':
        this.props.reportsStore!.setCustomerLookupIsVisible(true);
        this.props.reportsStore!.setOwnerLookupIsVisible(true);
        this.props.reportsStore!.setAddressLookupIsVisible(true);
        this.props.reportsStore!.setDateRangeIsVisible(true);
        break;
      case 'Status Report':
        this.props.reportsStore!.setOwnerLookupIsVisible(true);
        this.props.reportsStore!.setDateRangeIsVisible(true);
        this.props.reportsStore!.setStatusIsVisible(true);
        this.props.reportsStore!.setDeliveryDateRangeIsVisible(true);
        break;
      case 'Pricing Approvals Report':
        this.props.reportsStore!.setCustomerLookupIsVisible(true);
        this.props.reportsStore!.setOwnerLookupIsVisible(true);
        this.props.reportsStore!.setDateRangeIsVisible(true);
        this.props.reportsStore!.setDeliveryDateRangeIsVisible(true);
        break;
      case 'Branch Banking Report':
        this.props.reportsStore!.setCustomerLookupIsVisible(false);
        this.props.reportsStore!.setOwnerLookupIsVisible(false);
        this.props.reportsStore!.setAddressLookupIsVisible(false);
        this.props.reportsStore!.setBranchBankingIsVisible(true);
        this.props.reportsStore!.setDateRangeIsVisible(true);
        break;
      case 'Complete and Failed Invoices Report':
        this.props.reportsStore!.setDisableFileFormatSelect(true);
        this.props.reportsStore!.setDeliveryDateRangeIsVisible(true);
        this.props.reportsStore!.setSelectedFileFormat('CSV');
        break;
       
    }
  }

  // Customer
  private getCustomerSuggestions(value: string): Promise<Array<SuggestionItem<ICustomerSummary>>> {
    return this.props.reportsStore!.searchCustomers(value, true);
  }

  private handleCustomerSelected(selected: SuggestionItem<ICustomerSummary> | undefined) {
    this.reportsFilter.setCustomerId(selected!.value.id);
    this.props.uiStore!.orderSearchUiState.setCustomerSearchText(selected!.label);
    this.props.uiStore!.orderSearchUiState.setCustomerSuggestion(selected);
  }

  private handleCustomerSearchChange(search: string) {
    this.props.uiStore!.orderSearchUiState.setCustomerSearchText(search);
  }

  // Owner
  private getOwnerSuggestions(value: string): Promise<Array<SuggestionItem<IOwnerName>>> {
    return this.props.reportsStore!.searchOwners(value);
  }

  private handleOwnerSelected(selected: SuggestionItem<IOwnerName>) {
    this.reportsFilter.setOwnerRepCode(selected.value.repCode);
    this.props.uiStore!.orderSearchUiState.setOwnerSearchText(selected.value.name);
    this.props.uiStore!.orderSearchUiState.setOwnerSuggestion(selected);
  }

  private handleOwnerSearchChange(owner: string) {
    this.props.uiStore!.orderSearchUiState.setOwnerSearchText(owner);
  }

  // Address
  private handleAddressSelected(selected: SuggestionItem<string> | undefined) {
    this.reportsFilter.setAddress(selected!.value);
    this.props.uiStore!.orderSearchUiState.setAddressSearchText(selected!.value);
    this.props.uiStore!.orderSearchUiState.setAddressSuggestion(selected);
  }

  private handleAddressChange(address: string) {
    this.props.uiStore!.orderSearchUiState.setAddressSearchText(address);
  }

  private getAddressSuggestions(value: string): Promise<Array<SuggestionItem<string>>> {
    return this.props.reportsStore!.searchDeliveryAddresses(value);
  }
}

export default withStyles(globalStyles)(withAITracking(reactPlugin, Reports, 'Reports'));
