import { apiFetch } from '../utilities/apiFetch';
import type { IRangeSummary } from '../models/range-summary';
import type { Range, ShippingType } from '../models/range';
import type { IPriceList } from 'src/models/price-list';
import type { AbcLocation } from '../models/abc-location';
import type { IAddressResponse } from './sales-api';

export interface IPricingResponse {
  price: number;
  exWorksPrice: number;
  freightCost: number;
  tierId: number;
  calculationResult: CalculationResult;
}

export interface ITiersResponse {
  id: number;
  name: string;
}

export interface IServiceSearchResponse {
  id: number;
  name: string;
  code: string;
  price: number;
  purchaseUnit: string;
}

export interface IExWorksesAndProductsSearchResponse {
  exWorkses: IExWorksesSearchResponse[];
  products: IProductsSearchResponse[];
}

export interface IExWorksesSearchResponse {
  id: number;
  name: string;
  supplierId: number;
  supplierName: string;
}

export interface IProductsSearchResponse {
  id: number;
  name: string;
  code: string;
  purchaseUnit: string;
  noPerPurchaseUnit: number;
  unitWeight: number;
  exWorksPrice: number;
  exWorks: IExWorksesSearchResponse;
}

export enum ProductSearchResultType {
  ExWorks = 1,
  Product = 2,
}

export interface IMarkupDetail {
  id: number;
  name: string;
  percentageMarkup: number;
  tierId: number;
  tierName: string;
}

export interface IRangeDetail {
  id: number;
  name: string;
  code: string;
  shippingType: ShippingType;
  markups: IMarkupDetail[];
}

export interface IAbcLocationResponse {
  id: number;
  code: string;
  name: string;
  address: IAddressResponse;
  glSet: string;
  isDeleted: boolean;
}

export interface IStockingPointResponse {
  code: string;
  name: string;
}

export enum CalculationResult {
  None = 0,
  Ok = 1,
  NoMarkupForRange = 2,
  NoFreight = 3,
  Failed = 4,
  UnknownRegion = 5,
}

export class ProductsApi {
  public async getPriceList(exWorksId: number, regionId: number): Promise<IPriceList> {
    const response = await apiFetch(`/api/pricing?exWorksId=${exWorksId}&regionId=${regionId}`);
    return await response?.json();
  }

  public async getRanges(): Promise<IRangeSummary[]> {
    const response = await apiFetch('/api/ranges');
    const json = await response?.json();
    return json ?? [];
  }

  public async loadRange(id: string): Promise<IRangeDetail> {
    const response = await apiFetch(`/api/ranges/${id}`);
    return response?.json();
  }

  public async saveRange(range: Range): Promise<boolean> {
    const response = await apiFetch('/api/ranges', {
      method: 'post',
      body: JSON.stringify(range),
    });
    return response?.ok ?? false;
  }

  public async searchServices(query: string): Promise<IServiceSearchResponse[]> {
    const response = await apiFetch(`/api/services?searchQuery=${encodeURIComponent(query)}`);
    const json = response?.json();
    return json ?? [];
  }

  public async searchProducts(query: string, exWorksId: number): Promise<IExWorksesAndProductsSearchResponse> {
    const searchQuery = `${encodeURIComponent(query)}${exWorksId ? `&exWorksId=${exWorksId}` : ''}`;
    const response = await apiFetch(`/api/products?searchQuery=${searchQuery}`);
    return response?.json();
  }

  public async getProductPriceDetails(
    lat: number,
    lng: number,
    productId: number,
    exWorksId: number,
    customerId: number,
    tierId: number = 0,
  ): Promise<IPricingResponse> {
    const response = await apiFetch('/api/pricing', {
      method: 'post',
      body: JSON.stringify({
        address: { lat, lng },
        productId,
        exWorksId,
        customerId,
        tierId,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.json();
  }

  public async getService(code: string): Promise<IServiceSearchResponse> {
    const response = await apiFetch(`/api/services/code/${code}`);
    return response?.json();
  }

  public async getTiers(): Promise<ITiersResponse[]> {
    const response = await apiFetch('/api/tiers');
    const json = await response?.json();
    return json ?? [];
  }

  public async getTiersForProduct(productId: number): Promise<ITiersResponse[]> {
    const response = await apiFetch(`/api/tiers/${productId}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async getStockingPoints(): Promise<IStockingPointResponse[]> {
    const response = await apiFetch('/api/locations/stockingPoints/');
    const json = await response?.json();
    return json ?? [];
  }

  public async getPaymentLocations(): Promise<IAbcLocationResponse[]> {
    const response = await apiFetch('/api/locations/paymentLocations/');
    const json = await response?.json();
    return json ?? [];
  }

  public async getLocations(): Promise<IAbcLocationResponse[]> {
    const response = await apiFetch('/api/locations');
    const json = await response?.json();
    return json ?? [];
  }

  public async saveLocation(location: AbcLocation): Promise<boolean> {
    const response = await apiFetch('/api/locations', {
      method: 'post',
      body: JSON.stringify(location),
    });

    return response?.ok ?? false;
  }

  public async loadLocation(id: string): Promise<IAbcLocationResponse> {
    const response = await apiFetch(`/api/locations/${id}`);
    return response?.json();
  }
}
