import * as React from 'react';
import './styles.css';
import { inject, observer } from 'mobx-react';
import { WithStyles, withStyles, createStyles, Theme, Grid, FormHelperText } from '@material-ui/core';
import { UiStore } from '../../stores/ui-store';
import { SalesStore } from '../../stores/sales-store';
import { globalStyles } from 'src/theme';
import CurrencyField, { ICurrencyChangedEvent } from '../Currency/CurrencyField';
import { round } from 'src/math';

export interface IOrderSummaryProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    uiStore?: UiStore
}

@inject('uiStore', 'salesStore')
@observer
class OrderSummary extends React.Component<IOrderSummaryProps, {}> {

    constructor(props: IOrderSummaryProps) {
        super(props);
        this.handleCustomerAdjustmentChange = this.handleCustomerAdjustmentChange.bind(this);
    }
    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;

        return <Grid container className="section">
            <Grid item xs={1} />
            <Grid item xs={10}>
                <h2>{salesStore!.order.isParentOrder ? "Balance Summary" : "Summary"}</h2>
                <Grid container className="summary">
                    {
                        salesStore!.order.totalWeight > 0
                        && <React.Fragment>
                            <Grid item xs={9}>
                                <p>Weight</p>
                            </Grid>
                            <Grid item xs={3} className="number-col">
                                <p>{salesStore!.order.totalWeightFormatted}</p>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        <React.Fragment>
                            <Grid item xs={9}>
                                <p>Fuel Levy</p>
                            </Grid>
                            <Grid item xs={3} className="number-col">
                                <p>${salesStore!.order.fuelLevySurcharge.toFixed(2)}</p>
                            </Grid>
                            </React.Fragment>
                    }
                    <Grid item xs={9}>
                        <p>Subtotal</p>
                    </Grid>
                    <Grid item xs={3} className="number-col">
                        <p>${salesStore!.order.subtotal.toFixed(2)}</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p>GST</p>
                    </Grid>
                    <Grid item xs={3} className="number-col">
                        <p>${salesStore!.order.gst.toFixed(2)}</p>
                    </Grid>
                    {
                        !salesStore!.order.isSplitOrder && !salesStore!.order.isParentOrder
                        && <React.Fragment>
                            <Grid item xs={9}>
                                <p>Total</p>
                            </Grid>
                            <Grid item xs={3} className="total number-col">
                                <p>${salesStore!.order.total.toFixed(2)}</p>
                            </Grid>
                        </React.Fragment>
                    }
                    {
                        salesStore!.order.isCashSalesAccount
                        && <React.Fragment>
                            <Grid item xs={9}>
                                <p>Customer Adjustment</p>
                                {
                                    !!salesStore!.order.errors.customerAdjustment
                                    && <FormHelperText error>{this.props.salesStore!.order.errors.customerAdjustment}</FormHelperText>
                                }
                            </Grid>
                            <Grid item xs={3} className="number-col">
                                <CurrencyField
                                    InputProps={{ classes: { input: this.props.classes.paymentTextField } }}
                                    name="customerAdjustment"
                                    value={salesStore!.order.customerAdjustment}
                                    onChange={this.handleCustomerAdjustmentChange}
                                    disabled={orderUiState.isReadonly('customerAdjustment')}
                                    fullWidth={false}
                                />
                            </Grid>
                            {
                                (!salesStore!.order.isSplitOrder && !salesStore!.order.isParentOrder)
                                && <React.Fragment>
                                    <Grid item xs={9}>
                                        <p>Balance Due</p>
                                    </Grid>
                                    <Grid item xs={3} className="total number-col">
                                        <p>${salesStore!.order.balanceDue.toFixed(2)}</p>
                                    </Grid>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }
                    {
                        salesStore!.order.isSplitOrder || salesStore!.order.isParentOrder
                            ? <React.Fragment>
                                {
                                    salesStore!.order.isCashSalesAccount &&
                                    <React.Fragment>
                                        <Grid item xs={9}>
                                            <p>Total Paid for this Order</p>
                                        </Grid>
                                        <Grid item xs={3} className="total number-col">
                                            <p>${salesStore!.order.actualPaid.toFixed(2)}</p>
                                        </Grid>
                                    </React.Fragment>
                                }
                                <Grid item xs={9}>
                                    <p>Total of this Order</p>
                                </Grid>
                                <Grid item xs={3} className="total number-col">
                                    <p>${salesStore!.order.total.toFixed(2)}</p>
                                </Grid>
                                {
                                    salesStore!.order.isCashSalesAccount && salesStore!.order.parentOrder &&
                                    <React.Fragment>
                                        <Grid item xs={9}>
                                            <p>Order Balance</p>
                                        </Grid>
                                        <Grid item xs={3} className="total number-col">
                                            <p>${round(salesStore!.order.total - salesStore!.order.totalPaid).toFixed(2)}</p>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    salesStore!.order.isCashSalesAccount && salesStore!.order.splitOrders && !salesStore!.order.isParentOrder &&
                                    <React.Fragment>
                                        <Grid item xs={9}>
                                            <p>Total Payments Allocated</p>
                                        </Grid>
                                        <Grid item xs={3} className="total number-col">
                                            <p>${salesStore!.order.totalAllocated.toFixed(2)}</p>
                                        </Grid>
                                    </React.Fragment>
                                }
                                {
                                    !(salesStore!.order.isCashSalesAccount && salesStore!.order.parentOrder) &&
                                    <React.Fragment>
                                        <Grid item xs={9}>
                                            <p>Grand Total</p>
                                        </Grid>
                                        <Grid item xs={3} className="total number-col">
                                            <p>${salesStore!.order.grandTotal.toFixed(2)}</p>
                                        </Grid>
                                        <Grid item xs={9} className="grand-balance">
                                            <p>Grand Total Balance Due</p>
                                        </Grid>
                                        <Grid item xs={3} className="grand-balance number-col">
                                            <p>${salesStore!.order.grandTotalBalanceDue.toFixed(2)}</p>
                                        </Grid>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            : null
                    }
                </Grid>
            </Grid>
        </Grid>
    }

    private handleCustomerAdjustmentChange(e: ICurrencyChangedEvent) {
        this.props.salesStore!.order.updateAndValidate('customerAdjustment', e.parsedValue);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    paymentTextField: {
        marginTop: 16,
        marginBottom: 16,
        textAlign: "right",
        padding: 0,
        width: 50
    }
});

export default withStyles(styles)(OrderSummary);
