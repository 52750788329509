import './styles.css';

import React from 'react';
import { observer } from 'mobx-react';

export const Environment: React.FC = observer(() => {
  const getEnvironment = () => {
    if (window.location.host.includes('mojoe-ci')) return 'CI';
    if (window.location.host.includes('mojoe-uat')) return 'UAT';
    if (window.location.host.includes('local')) return 'DEV';
    return null;
  };
  const env = getEnvironment();
  return env && <div className="environment">This is the {env} environment</div>;
});
