import { observable, runInAction, computed, action } from 'mobx';
import { Model } from './model';
import { Validate } from '../validate';
import { IServiceLineResponse } from '../services/sales-api';
import { Order } from './order';

export class ServiceLine extends Model {
  public static editableServiceLinePrices: string[] = [
    'FTS',
    'D',
    'C T',
    'C',
    'X',
    'CUSTADJ',
    'B T',
    'VHXLM',
    'CUT',
    'RECHARGE',
    'ZBBPOADMIN',
  ];

  @observable public id: number;
  @observable public serviceId: number;
  @observable public name: string;
  @observable public quantity: number;
  @observable public purchaseUnit: string;
  @observable public price: number;
  @observable public code: string;
  @observable public isAutoAdded: boolean;
  @observable public note: string;
  @observable public totalSplitableQuantity: number;
  @observable public isSupplementary: boolean;
  @observable public lineNumber: number;
  @observable private order?: Order;
  @observable public chosenUnitWeight: number;
  @observable public chosenNoOfPacks: number;
  @observable public chosenNoOfPallets: number;

  constructor(
    id: number,
    serviceId: number,
    name: string,
    code: string,
    price: number,
    purchaseUnit: string,
    lineNumber: number = 0,
    note: string = '',
    quantity: number = 0,
    isAutoAdded: boolean = false,
    totalSplitableQuantity: number = 0,
    isSupplementary = false,
    chosenUnitWeight: number = 0,
    chosenNoOfPacks: number = 0,
    chosenNoOfPallets: number = 0,
    order?: Order,
  ) {
    super();
    runInAction(() => {
      this.id = id;
      this.serviceId = serviceId;
      this.name = name;
      this.code = code;
      this.price = price;
      this.quantity = quantity;
      this.purchaseUnit = purchaseUnit;
      this.isAutoAdded = isAutoAdded;
      this.note = note;
      this.totalSplitableQuantity = totalSplitableQuantity;
      this.isSupplementary = isSupplementary;
      this.order = order;
      this.lineNumber = lineNumber;
      this.chosenUnitWeight = chosenUnitWeight;
      this.chosenNoOfPacks = chosenNoOfPacks;
      this.chosenNoOfPallets = chosenNoOfPallets;
    });
  }

  @computed
  public get unroundedSubtotal(): number {
    return this.price * this.quantity;
  }

  @computed
  public get chosenUnitWeightInKilos(): number {
    return this.chosenUnitWeight * 1000;
  }

  @computed
  public get isNew(): boolean {
    return this.id === 0;
  }

  public get excludeFromSplitOrder(): boolean {
    return this.code === this.order!.bodyTruckCode || this.code === this.order!.deliveryCode;
  }

  public isPriceEditable(code: string) {
    return ServiceLine.editableServiceLinePrices.some((c) => c === code);
  }

  @action
  public setQuantity(qty: number) {
    this.quantity = qty;
  }

  // When adding quantities, we need to consider noPerPurchaseUnit as quantity can be < 1,
  // which sometimes causes precision loss when adding / subtracting
  // therefore, we always achieve whole numbers when multiplying with the quantity*noPerPurchaseUnit before calculating...
  // and after we divide again - this method helps subtract 2 qty values safely
  // returns qa - qb
  public subtractQuantities(qa: number, qb: number) {
    const result = Number((qa - qb).toFixed(10));
    return result;
  }

  public calculateAvailableQuantity(allocatedQuantity: number) {
    const availableQuantity = this.subtractQuantities(this.totalSplitableQuantity, allocatedQuantity);
    return Math.max(availableQuantity, 0);
  }

  @action
  public handleChosenPalletsChange(property: string, value: any) {
    this.update(property, value);

    const error = this.oneOrOtherButNotBoth(value, this.chosenNoOfPacks);
    if (error) {
      this.setError('chosenNoOfPallets', error);
    } else {
      this.errors.chosenNoOfPallets = null;
      this.errors.chosenNoOfPacks = null;
    }
    return this.errors.chosenNoOfPallets === null;
  }

  @action
  public handleChosenPackChange(property: string, value: any) {
    this.update(property, value);

    const error = this.oneOrOtherButNotBoth(value, this.chosenNoOfPallets);
    if (error) {
      this.setError('chosenNoOfPacks', error);
    } else {
      this.errors.chosenNoOfPallets = null;
      this.errors.chosenNoOfPacks = null;
    }
    return this.errors.chosenNoOfPacks === null;
  }

  @action
  public allocateQuantities(quantity: number, parentServiceLine?: ServiceLine) {
    this.updateAndValidate('quantity', quantity);
    const parentLine = parentServiceLine || this.order!.getParentServiceLine(this.serviceId, this.lineNumber);
    const allocatedQuantity = this.order!.serviceQtyAllocated(this.serviceId, this.lineNumber);
    if (!parentLine || this.isNew) {
      // This is a parent order, update quantity available across entire split
      this.totalSplitableQuantity = allocatedQuantity + quantity;
      return;
    }
    // This is a child order, update quantity remaining on the parent
    parentLine.updateAndValidate('quantity', parentLine.totalSplitableQuantity - allocatedQuantity - quantity);
  }

  public clone(): ServiceLine {
    return new ServiceLine(
      this.id,
      this.serviceId,
      this.name,
      this.code,
      this.price,
      this.purchaseUnit,
      this.lineNumber,
      this.note,
      this.quantity,
      this.isAutoAdded,
      this.totalSplitableQuantity,
      this.isSupplementary,
      this.chosenUnitWeight,
      this.chosenNoOfPacks,
      this.chosenNoOfPallets,
      this.order,
    );
  }

  public static fromResponse(s: IServiceLineResponse, order?: Order): ServiceLine {
    return new ServiceLine(
      s.id,
      s.serviceId,
      s.name,
      s.code,
      s.price,
      s.purchaseUnit,
      s.lineNumber,
      s.note,
      s.quantity,
      false,
      s.totalSplitableQuantity,
      s.isSupplementary,
      s.chosenUnitWeight,
      s.chosenNoOfPacks,
      s.chosenNoOfPallets,
      order,
    );
  }

  @computed
  public get unitsLabel() {
    return `Units ${this.purchaseUnit ? '(' + this.purchaseUnit + ')' : ''}`;
  }

  @computed
  public get orderSplitQtyLabel() {
    if (!this.order || this.isNew || this.isSupplementary) return;
    if (!this.order.isFreightOnly && this.excludeFromSplitOrder) {
      return null;
    }
    const parent = this.order.getParentServiceLine(this.serviceId, this.lineNumber);
    const allocatedQty = this.order.serviceQtyAllocated(this.serviceId, this.lineNumber);
    return this.order.orderSplitQtyLabel(parent, allocatedQty, this.quantity, this.totalSplitableQuantity);
  }

  @computed
  public get quantityAvailable(): number {
    if (!this.order) return 0;
    const parent = this.order.getParentServiceLine(this.serviceId, this.lineNumber);
    const allocatedQty = this.order.serviceQtyAllocated(this.serviceId, this.lineNumber);
    return this.order.quantityAvailable(parent, allocatedQty, this.quantity);
  }

  protected validators: { [name: string]: (val: any) => boolean } = {
    quantity: (val: number) => {
      if (!this.order) return true;

      const remaining = this.order.serviceQtyAvailable(this.serviceId, this.lineNumber);
      if (this.order.isParentOrder && remaining === 0 && val === 0) return true;

      this.errors.quantity =
        !Validate.decimal(val) || val <= 0
          ? `Must be more than 0${this.order.isSplitting ? ' or delete item' : ''}`
          : null;

      if (this.errors.quantity === null) {
        this.errors.quantity =
          this.order.isSplitOrder &&
          !this.isNew &&
          !this.isSupplementary &&
          (this.order!.isFreightOnly || !this.excludeFromSplitOrder) &&
          val > remaining
            ? 'Allocation too large'
            : null;
      }
      return this.errors.quantity === null;
    },
    price: (val: number) => {
      this.errors.price = Validate.decimal(val) && val > 0 ? null : 'Must be more than 0';
      return this.errors.price === null;
    },
  };

  private oneOrOtherButNotBoth(a: number, b: number) {
    if (a && b) return 'Enter only Packs or Pallets';
    else return Validate.moreThanZeroIfHasValue(a);
  }
}
