import * as React from 'react';
import './styles.css';
import { inject, observer } from 'mobx-react';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { WithStyles, withStyles, FormGroup, FormControlLabel, Checkbox, TextField, createStyles, Theme, Typography, Grid } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TruckType, TimeslotType } from '../../models/order';
import { UiStore } from '../../stores/ui-store';
import { SalesStore } from '../../stores/sales-store';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import AddressMap from '../AddressSearch/AddressMap';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { SuppliersStore, IPickupLocation } from 'src/stores/suppliers-store';
import { dateFormatted } from '../../datetime';
import { globalStyles } from '../../theme';
import { Address, LocationType } from 'src/models/address';

export interface IFreightOnlyTabsProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    uiStore?: UiStore
    suppliersStore?: SuppliersStore
}

@inject('uiStore', 'salesStore', 'suppliersStore')
@observer
class FreightOnlyTabs extends React.Component<IFreightOnlyTabsProps, {}> {

    private readonly tabs: Array<{ name: string, value: boolean }> = [
        { name: 'Depot/Exworks', value: false },
        { name: 'Google', value: true }
    ];

    private readonly truckTypes: Array<{ name: string, value: TruckType }> = [
        { name: 'Standard', value: TruckType.Standard },
        { name: 'Body Truck', value: TruckType.BodyTruck }
    ];

    constructor(props: IFreightOnlyTabsProps) {
        super(props);
        this.getPickupSuggestions = this.getPickupSuggestions.bind(this);
        this.handleChanged = this.handleChanged.bind(this);
        this.handleFreightAddressChanged = this.handleFreightAddressChanged.bind(this);
    }

    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        const freightOnly = this.props.salesStore!.order.freightOnly;

        return (<div className="freight">
                <div className="inline-tabs">
                    <ul>
                    {
                        this.tabs.map((t, idx) => (<li key={idx} ><a className={(salesStore!.order.freightOnly.fromAddress.locationType === LocationType.None) === t.value ? 'active' : ''}
                        onClick={() => {
                            if (orderUiState!.isReadonly('address')) return;
                            this.handleFreightAddressChanged('fromAddress', new Address({ locationType: t.value ? LocationType.None : LocationType.Supplier }));
                        }} key={t.value[0]}>{t.name}</a></li>))
                    }
                    </ul>
                </div>
                    {salesStore!.order.freightOnly.fromAddress.locationType === LocationType.None && <AddressSearchBox
                        address={salesStore!.order.freightOnly.fromAddress.formattedAddress}
                        handleAddressSelected={(address: Address) => this.handleFreightAddressChanged('fromAddress', address)}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleFreightAddressChanged('fromAddress', new Address(
                            { ...salesStore!.order.freightOnly.fromAddress, formattedAddress: e.currentTarget.value })
                        )}
                        label="From Address"
                        fieldName="fromAddress"
                        error={salesStore!.order.freightOnly.fromAddress.errors.formattedAddress}
                        toggleMap
                        disabled={orderUiState!.isReadonly('address')}>
                        <AddressMap
                            forFieldName="fromAddress"
                            moveMarker={!orderUiState.isReadonly('address')}
                            lat={salesStore!.order.freightOnly.fromAddress.latLng.lat}
                            lng={salesStore!.order.freightOnly.fromAddress.latLng.lng}
                            handleClick={(lat: number, lng: number) => {
                                orderUiState.setLatLng("fromAddress", { lat, lng });
                                this.handleFreightAddressChanged('fromAddress', new Address({ ...salesStore!.order.freightOnly.fromAddress, latLng: { lat, lng } }));
                            }}
                        />
                    </AddressSearchBox>}
                    {salesStore!.order.freightOnly.fromAddress.locationType !== LocationType.None && <Autocomplete
                        label="From Address"
                        onSelect={(item: SuggestionItem<IPickupLocation>) => this.handleFreightAddressChanged('fromAddress', item.value.address)}
                        getSuggestions={this.getPickupSuggestions}
                        errorText={salesStore!.order.freightOnly.fromAddress.errors.formattedAddress}
                        selectedItem={salesStore!.order.freightOnly.fromAddress.toSuggestionItem()}
                        disabled={orderUiState!.isReadonly('address')}
                        suggestionToComponent={{
                            containerStyle: { height: "50px" },
                            getComponent: (item: SuggestionItem<IPickupLocation>) => (
                                <div>
                                    <div>{item.value.name}</div>
                                    <div className="sub-text"><small>{item.value.address.formattedAddress}</small></div>
                                </div>
                            )
                        }}
                    />}
                    <AddressSearchBox
                        address={salesStore!.order.freightOnly.toAddress.formattedAddress}
                        handleAddressSelected={(address: Address) => this.handleFreightAddressChanged('toAddress', address)}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleFreightAddressChanged('toAddress', new Address(
                            { ...salesStore!.order.freightOnly.toAddress, formattedAddress: e.currentTarget.value })
                        )}
                        label="To Address"
                        fieldName="toAddress"
                        error={salesStore!.order.freightOnly.toAddress.errors.formattedAddress}
                        toggleMap
                        disabled={orderUiState!.isReadonly('address')}>
                        <AddressMap
                            forFieldName="toAddress"
                            moveMarker={!orderUiState.isReadonly('address')}
                            lat={salesStore!.order.freightOnly.toAddress.latLng.lat}
                            lng={salesStore!.order.freightOnly.toAddress.latLng.lng}
                            handleClick={(lat: number, lng: number) => {
                                orderUiState.setLatLng("toAddress", { lat, lng });
                                this.handleFreightAddressChanged('toAddress', new Address({ ...salesStore!.order.freightOnly.toAddress, latLng: { lat, lng } }));
                            }}
                        />
                    </AddressSearchBox>
                    <FormGroup row>
                        <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <InlineDatePicker
                                onlyCalendar
                                className={this.props.classes.inlineDateField}
                                label={salesStore!.order.freightOnly.dateTba ? "Date is TBA" : "Date"}
                                clearable
                                value={salesStore!.order.freightOnly.date}
                                onChange={(date: DateTime) => salesStore!.order.freightOnly.updateAndValidate('date', date.startOf('day'))}
                                labelFunc={(date: DateTime) => dateFormatted(date)}
                                InputProps={{ disableUnderline: true }}
                                leftArrowIcon={<KeyboardArrowLeft />}
                                rightArrowIcon={<KeyboardArrowRight />}
                                error={!!salesStore!.order.freightOnly.errors.date}
                                helperText={salesStore!.order.freightOnly.errors.date || null}
                                maxDate={DateTime.local().plus({ months: 6 })}
                                minDate={DateTime.local().minus({ days: 1 })}
                                disabled={salesStore!.order.freightOnly.dateTba || orderUiState.isReadonly('freightDate')}
                            />
                        </MuiPickersUtilsProvider>
                        <FormControlLabel
                            className={this.props.classes.checkBox}
                            control={
                                <Checkbox
                                    checked={salesStore!.order.freightOnly.dateTba}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => salesStore!.order.freightOnly.updateDateTba(checked)}
                                    disabled={orderUiState.isReadonly('freightDate')}
                                />
                            }
                            label="TBA"
                        />
                    </FormGroup>
                    <FormGroup row>
                        <FormControl
                            disabled={orderUiState!.isReadonly('freightTimeslot')}
                            className={this.props.classes.textField}>
                            <InputLabel htmlFor="freightTimeslot">Timeslot</InputLabel>
                            <Select
                                disableUnderline
                                value={salesStore!.order.freightOnly.timeslot}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => salesStore!.order.freightOnly.updateAndValidate('timeslot', e.target.value)}
                                inputProps={{ name: 'freightTimeslot', id: 'freightTimeslot' }}>
                                {
                                    this.props.uiStore!.timeslots.map(t => (<MenuItem value={t.value} key={t.value}>{t.name}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                        {
                            salesStore!.order.freightOnly.timeslot === TimeslotType.Custom &&
                            <React.Fragment>
                                <TextField
                                    className={this.props.classes.textField}
                                    error={!!freightOnly.errors.deliveryStartTime}
                                    helperText={freightOnly.errors.deliveryStartTime || null}
                                    InputProps={{ disableUnderline: true }}
                                    inputProps={{ maxLength: 5 }}
                                    label="Start Time"
                                    type="text"
                                    name="DeliveryStartTime"
                                    autoComplete="no"
                                    value={salesStore!.order.freightOnly.deliveryStartTime}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('deliveryStartTime', e.currentTarget.value)}
                                    disabled={orderUiState!.isReadonly('deliveryTimeslot')}
                                />
                                <TextField
                                    className={this.props.classes.textField}
                                    error={!!freightOnly.errors.deliveryEndTime}
                                    helperText={freightOnly.errors.deliveryEndTime || null}
                                    InputProps={{ disableUnderline: true }}
                                    inputProps={{ maxLength: 5 }}
                                    label="End Time"
                                    type="text"
                                    name="DeliveryEndTime"
                                    autoComplete="no"
                                    value={salesStore!.order.freightOnly.deliveryEndTime}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('deliveryEndTime', e.currentTarget.value)}
                                    disabled={orderUiState!.isReadonly('deliveryTimeslot')}
                                />
                            </React.Fragment>
                        }
                    </FormGroup>
                        <FormControl
                            disabled={orderUiState!.isReadonly('truckType')}
                            className={this.props.classes.textField}>
                            <InputLabel htmlFor="truckType">Truck Type</InputLabel>
                            <Select
                                disableUnderline
                                value={salesStore!.order.freightOnly.truckType}
                                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    salesStore!.order.freightOnly.update('truckType', e.target.value);
                                    salesStore!.order.checkTruckType(salesStore!.order.freightOnly.truckType, this.props.uiStore!.notificationUiState);
                                }}
                                inputProps={{ name: 'truckType', id: 'truckType' }}>
                                {
                                    this.truckTypes.map(t => (<MenuItem value={t.value} key={t.value}>{t.name}</MenuItem>))
                                }
                            </Select>
                        </FormControl>
                    <TextField
                        className={this.props.classes.textFieldFullWidth}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 200 }}
                        label="Special Instructions"
                        type="text"
                        name="freightInstruction"
                        autoComplete="no"
                        value={salesStore!.order.freightOnly.instruction}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('instruction', e.currentTarget.value)}
                        disabled={orderUiState!.isReadonly('freightInstruction')}
                        fullWidth
                        multiline
                    />
                    <Grid container>
                        <Grid item xs={6} md={4} xl={3}>
                            <FormControlLabel
                                className={this.props.classes.checkBox}
                                control={
                                    <Checkbox
                                        name="subcontract"
                                        checked={salesStore!.order.freightOnly.subcontract}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                            salesStore!.order.freightOnly.updateAndValidate(e.currentTarget.name, checked)}
                                        disabled={orderUiState.isReadonly('subcontract')}
                                    />
                                }
                                label={<Typography className={this.props.classes.checkBoxLabel}>Is this job for a sub-contractor?</Typography>}
                            />
                        </Grid>
                        <Grid item xs={6} md={4} xl={3}>
                            <FormControlLabel
                                className={this.props.classes.checkBox}
                                control={
                                    <Checkbox
                                        checked={salesStore!.order.freightOnly.isCrane}
                                        name="isCrane"
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                            salesStore!.order.freightOnly.update('isCrane', checked);
                                        }}
                                        disabled={orderUiState.isReadonly('isCrane')}
                                    />
                                }
                                label={<Typography className={this.props.classes.checkBoxLabel}>Meet crane on site?</Typography>}
                            />
                        </Grid>
                    </Grid>
                </div>);
    }

    private getPickupSuggestions(value: string): Promise<Array<SuggestionItem<IPickupLocation>>> {
        return this.props.suppliersStore!.searchPickupLocations(value);
    }

    private handleChanged(property: string, value: any) {
        this.props.salesStore!.order.freightOnly.updateAndValidate(property, value);
    }

    private handleFreightAddressChanged(property: 'fromAddress' | 'toAddress', address: Address) {
        this.props.salesStore!.order.freightOnly.updateAndValidate(property, address);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    checkBox: {
        marginTop: -14,
        marginRight: 0,
    },
    checkBoxLabel: {
        fontSize: "1rem",
        color: theme.palette.text.secondary
    }
});

export default withStyles(styles)(FreightOnlyTabs);
