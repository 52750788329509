import { observable } from 'mobx';
import { Model } from './model';
import { Validate } from '../validate';

export class QuoteCancellation extends Model {
    @observable public reason: string = '';

    protected validators: { [name: string]: (val: any) => boolean } = {
        "reason": (val: string) => {
            this.errors.reason = Validate.hasValue(val)
                ? null : "reason required";
            return this.errors.reason === null;
        }
    }
}