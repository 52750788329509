export abstract class Validate {
  public static hasValue(val: string): boolean {
    return val !== null && val !== undefined && val.trim().length > 0;
  }

  public static moreThanZero = (val: number): string | null => {
    return val > 0 ? null : 'Must be more than 0';
  };

  public static moreThanZeroIfHasValue = (val: number) => {
    if (!val) return null;
    else return Validate.moreThanZero(val);
  };

  public static getTime(val: string): number {
    if (!this.hasValue(val)) {
      return NaN;
    }

    const split = val.split(':');
    if (split.length !== 2 || split[1].length !== 2) {
      return NaN;
    }

    const hours = parseInt(split[0], 0);
    const minutes = parseInt(split[1], 0);

    if (isNaN(hours) || hours > 23 || hours < 0 || isNaN(minutes) || minutes > 59 || minutes < 0) {
      return NaN;
    }

    const totalMinutes = hours * 60 + minutes;

    return totalMinutes > 1439 ? NaN : totalMinutes;
  }

  public static maxLength(val: string, maxLength: number): boolean {
    return val ? val.length <= maxLength : true;
  }

  public static decimal(val: any): boolean {
    return /^(\d*\.)?\d+$/.test(val);
  }
}
