import * as React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { IAbcLocationResponse } from 'src/services/products-api';

interface ILocationsProps {
    locations: IAbcLocationResponse[];
}

type Props = ILocationsProps & RouteComponentProps<{}>;

const Locations: React.SFC<Props> = (props) => {
    return <div>
        {
            props.locations.map(s => {
                const deletedClassName = s.isDeleted ? 'deleted' : '';
                return <Grid container className={`item ${deletedClassName}`} key={s.id}
                    onClick={() => { props.history.push(`locations/${s.id}`); }} >
                    <Grid item xs={1} />
                    <Grid item xs={11} className="name">{s.name}</Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11} className="summary">
                        Code: {s.code}
                    </Grid>
                </Grid>
            })
        }
    </div>
};

export default Locations;