import { Model } from './model';
import { Validate } from '../validate';
import { observable } from 'mobx';

export class OrderSearch extends Model {

    @observable public orderOrPoNumber: string = '';

    protected validators: {[name: string]: (val: any) => boolean} = {
        "orderNumber": (val: string) => {
            this.errors.orderNumber = Validate.hasValue(val)
                ? null : "Order number required";
            return this.errors.orderNumber === null;
        }
    }
}