import { observable, computed, action } from 'mobx';
import { Address } from './address';
import { Model } from './model';
import { Validate } from '../validate';
import { round } from '../math';
import { Order } from './order';
import { IRefundResponse } from 'src/services/sales-api';

export class Refund extends Model {

    @observable public fromAddress: Address = new Address({});
    @observable public toAddress: Address = new Address({});
    @observable public pickupFee: number = 150;
    @observable public pickupRequired: boolean = false;
    @observable public restockingFee: number = 0;
    @observable private order: Order;

    constructor(order: Order) {
        super();
        this.order = order;
    }

    @action
    public setPickupRequired(required: boolean) {
        this.pickupRequired = required;
    }

    @action
    public resetDefaulRestockingFee() {
        this.restockingFee = round(this.subTotalCredited * .30);
    }

    @computed
    public get subTotalCredited(): number {
        return round(this.order.productLines.map(p => p.totalCredited)
            .reduce((acc, curr) => acc + curr, 0));
    }

    @computed
    public get totalCredited(): number {
        return this.subTotalCredited - this.restockingFee;
    }

    public static fromResponse(r: IRefundResponse, order: Order) {
        const refund = new Refund(order);
        if (!r) return refund;
        refund.fromAddress = Address.fromResponse(r.fromAddress);
        refund.toAddress = Address.fromResponse(r.toAddress);
        refund.restockingFee = r.restockingFee;
        refund.pickupFee = r.pickupFee;
        refund.pickupRequired = r.pickupRequired;
        return refund;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "pickupFee": (val: number) => {
            this.errors.pickupFee = Validate.decimal(val) && val > 0
                ? null : "Must be more than 0";
            return this.errors.pickupFee === null;
        },
        "restockingFee": (val: number) => {
            this.errors.restockingFee = Validate.decimal(val) && val >= 0
                ? null : "Must be more than 0";
            return this.errors.restockingFee === null;
        },
        "fromAddress": () => {
            if (!this.pickupRequired) return true;
            return this.fromAddress.validate();
        },
        "toAddress": () => {
            if (!this.pickupRequired) return true;
            return this.toAddress.validate();
        },
        "order": () => {
            if (!this.order.isRefunding) return true;
            this.errors.hasCredits = this.order.productLines.some(p => p.creditQuantity > 0)
                ? null : "Credit amount is $0. Select units to credit against products";
            return this.errors.hasCredits === null;
        }
    }
}