import { SalesStore } from './sales-store';
import { ProductsStore } from './products-store';
import { CustomersStore } from './customers-store';
import { UiStore } from './ui-store';
import { SuppliersStore } from './suppliers-store';
import { AuthStore } from './auth-store';
import { SettingsStore } from './settings-store';
import { ReportsStore } from './reports-store';
import { ReportsApi } from '../services/reports-api';
import { SettingsApi } from '../services/settings-api';
import { SalesApi } from '../services/sales-api';
import { ProductsApi } from '../services/products-api';
import { UserApi } from '../services/user-api';
import { SuppliersApi } from '../services/suppliers-api';
import { CustomersApi } from '../services/customers-api';

export function initialiseStores() {
  const authStore = new AuthStore(new UserApi());
  const uiStore = new UiStore(authStore);
  const productsApi = new ProductsApi();
  const customersApi = new CustomersApi();
  const salesApi = new SalesApi();
  const salesStore = new SalesStore(salesApi, productsApi, new SettingsApi());
  const reportsStore = new ReportsStore(customersApi, salesApi, new ReportsApi());
  const productsStore = new ProductsStore(productsApi);
  const customersStore = new CustomersStore(customersApi);
  const suppliersStore = new SuppliersStore(new SuppliersApi());
  const settingsStore = new SettingsStore(new SettingsApi());

  return {
    salesStore,
    uiStore,
    productsStore,
    customersStore,
    suppliersStore,
    authStore,
    settingsStore,
    reportsStore,
  };
}
