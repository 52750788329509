import type { IHaveName, IOwnerName } from 'src/stores/customers-store';
import { apiFetch } from '../utilities/apiFetch';
import type { Customer } from '../models/customer';
import { SalesRep } from 'src/models/sales-rep';

export interface ICustomerResponse {
  address: string;
  code: string;
  email: string;
  id: number;
  isCashSaleAccount: boolean;
  isOnlineStore: boolean;
  name: string;
  rangePricings: IRangePricing[];
  defaultTierId: number;
}

export interface IRangePricing {
  rangeName: string;
  code: string;
  rangeId: number;
  tierId: number;
}

export interface ICustomerSearchResponse {
  name: string;
  code: ICustomerCode;
  id: number;
  email: string;
  isInDispute: boolean;
  maxOrderAmount: number;
  phoneNumber: string;
}

export interface ICustomerCode {
  value: number;
  isCashSaleAccount: boolean;
  isOnlineStore: boolean;
  isAbcFreightJobsAccount: boolean;
}

export interface ICustomerWithTiersSearchResponse {
  name: string;
  code: ICustomerCode;
  id: number;
  isInDispute: boolean;
  nonDefaultTierIds: number[];
  defaultTierId: number;
}

export interface ISalesRep {
  emailAddress: string;
  name: string;
  code?: string;
  stockingPoint?: string;
}

export class CustomersApi {
  public async get(code: string): Promise<ICustomerResponse> {
    const response = await apiFetch(`/api/customers/${code}`);
    return await response?.json();
  }

  public async saveCustomer(customer: Customer): Promise<boolean> {
    const response = await apiFetch('/api/customers', {
      method: 'post',
      body: JSON.stringify(customer),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return response?.ok ?? false;
  }

  public async searchCustomers(query: string): Promise<ICustomerSearchResponse[]> {
    const response = await apiFetch(`/api/customers/newOrder?searchQuery=${encodeURIComponent(query)}`);
    const json = await response?.json();
    if (!json) return [];
    return json;
  }

  public async searchAllCustomers(query: string): Promise<ICustomerSearchResponse[]> {
    const response = await apiFetch(`/api/customers/search-all?searchQuery=${encodeURIComponent(query)}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async searchSiteContacts(query: string): Promise<IHaveName[]> {
    const response = await apiFetch(`/api/customers/siteContacts?searchQuery=${encodeURIComponent(query)}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async searchOwners(query: string): Promise<IOwnerName[]> {
    const response = await apiFetch(`/api/sales-reps/search/?searchQuery=${encodeURIComponent(query)}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async searchCustomersWithTiers(query: string): Promise<ICustomerWithTiersSearchResponse[]> {
    const response = await apiFetch(`/api/customers/search?searchQuery=${encodeURIComponent(query)}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async downloadTierPricingCsv(): Promise<Blob | null> {
    const response = await apiFetch('/api/customers/tierpricing');
    const blob = await response?.blob();
    return blob ?? null;
  }

  public async getSalesReps(): Promise<SalesRep[]> {
    const response = await apiFetch(`/api/sales-reps`);
    const json = await response?.json();
    return json ?? [];
  }

  public async updateSalesRep(salesRep: SalesRep): Promise<boolean> {
    const salesRepObj = {
      emailAddress: salesRep.emailAddress,
      name: salesRep.name,
      code: salesRep.code?.toUpperCase(),
      stockingPoint: salesRep.stockingPoint
    };
    const response = await apiFetch('/api/sales-reps', {
      method: 'post',
      body: JSON.stringify(salesRepObj),
      headers: {
        'Content-Type': 'application/json',
      },
    });
    
    return response?.ok ?? false;
  }
}
