import { observable, action } from 'mobx';
import { DateTime } from 'luxon';
import { Order, DeliveryType, OrderStatus } from './order';
import { toDate } from '../datetime';
import { IPickupResponse } from '../services/sales-api';
import { IPickupLocation } from '../stores/suppliers-store';
import { DeliveryDate } from './delivery-date';
import { Address } from './address';
import { Validate } from '../validate';

export class Pickup extends DeliveryDate {

    @observable public address: Address = new Address({});
    @observable public date: DateTime | null | undefined = null;
    @observable public dateTba: boolean | undefined = false;
    @observable public instruction: string = '';
    @observable public regionId: number | null = null;
    @observable public noRegionFreightMessage: string = '';
    @observable public subcontract: boolean = false;

    constructor(order: Order) {
        super(order);
    }

    @action
    public updatePickupAddress(detail: IPickupLocation) {
        this.updateAndValidate('address', detail.address);
    }

    public static fromResponse(r: IPickupResponse, order: Order): Pickup {
        const p = new Pickup(order);
        if (!r) return p;
        p.address = Address.fromResponse(r.address);
        p.date = !r.date ? null : toDate(r.date)
        p.dateTba = r.dateTba;
        p.instruction = r.instruction || '';
        p.noRegionFreightMessage = r.noRegionFreightMessage || '';
        p.subcontract = r.subcontract
        return p;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "date": (val: DateTime) => {
            if (this.order.deliveryType !== DeliveryType.PickUp || this.order.status !== OrderStatus.Order) {
                this.errors.date = null;
                return true;
            }
            super.validateDate(val);
            return this.errors.date === null;
        },
        "noRegionFreightMessage": (val: string) => {
            this.order.deliveryType === DeliveryType.PickUp && this.order.hasNoRegion && !Validate.hasValue(val)
                ? this.errors.noRegionFreightMessage = "required"
                : this.errors.noRegionFreightMessage = null;

            return this.errors.noRegionFreightMessage === null;
        },
        "address": (val: string) => {
            if (this.order.deliveryType !== DeliveryType.PickUp) return true;
            return this.address.validate();
        }
    }
}