export const round = (val: number, places: number = 2) : number => {
    const c = Math.pow(10, places);
    const m = Number((Math.abs(val) * c).toPrecision(15));
    return Math.round(m) / c * Math.sign(val);
}

export const roundedGst = (unroundedSubtotal: number): number => {
    return round(unroundedSubtotal * 0.1);
}

export const roundedTotal = (unroundedSubtotal: number): number => {
    return (round(unroundedSubtotal + roundedGst(unroundedSubtotal)));
}

export const roundedSubtotal = (unroundedSubtotal: number): number => {
    return roundedTotal(unroundedSubtotal) - roundedGst(unroundedSubtotal);
}

export const parseAsCurrency = (s: string): number => {
    const num = Number(s);
    return Number.isNaN(num) ? 0.00 : (Math.floor(num * 100) / 100);
}