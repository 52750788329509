import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { observer } from 'mobx-react';
import { Workflow } from '../../models/workflow';
import { WorkflowType } from '../../models/workflow-type';
import { Order } from '../../models/order';

export interface ISubmitWorkflowDialogProps {
    workflow: Workflow
    onClose(): void
    onSubmit(): void
    prompt: string
    title: string
}

@observer
export class SubmitWorkflowDialog extends React.Component<ISubmitWorkflowDialogProps> {
    constructor(props: ISubmitWorkflowDialogProps) {
        super(props);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    public render() {
        return <div className="full-dialog">
            <Loader area="submit-workflow-dialog" />
            <div className="dialog-content">
                <h3>{this.props.title}</h3>
                <p>{this.props.prompt}</p>
                {this.props.workflow.workflowType !== WorkflowType.OrderCancellation ?
                    <TextField
                        InputProps={{disableUnderline: true}}
                        label="Reason"
                        type="text"
                        name="reason"
                        autoComplete="no"
                        error={!!this.props.workflow.errors.reason}
                        helperText={this.props.workflow.errors.reason || null}
                        value={this.props.workflow.reason}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            this.handleChange(e.currentTarget.name, e.currentTarget.value)
                        }}
                        fullWidth
                    /> : <FormControl className="reason-select">
                        <InputLabel htmlFor='reason'>Reason</InputLabel>
                        <Select
                            disableUnderline
                            value={this.props.workflow.reason}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                this.handleChange(e.target.name, e.target.value)
                            }}
                            inputProps={{name: 'reason', id: 'reason'}}
                            autoWidth>
                            {
                                Order.cancellationReasons.map(c => (
                                    <MenuItem value={c.value} key={c.value}>
                                        {c.name}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                }
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Close</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={this.handleSubmit}>
                    <span>Submit</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.workflow.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChange(name:string, value: string) {
        this.props.workflow.update(name, value);
    }

    private handleSubmit() {
        if (this.props.workflow.validate()) this.props.onSubmit();
    }

    public componentWillUnmount() {
        this.props.workflow.reset();
    }
}