import './styles.css';

import * as React from 'react';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { observer, inject } from 'mobx-react';
import Save from '@material-ui/icons/Save';
import { RouteComponentProps } from 'react-router';

import { Header } from '../Header/Header';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { UiStore } from '../../stores/ui-store';
import LocationForm from './LocationForm';
import { ActionItem } from '../ActionsMenu/ActionItem';
import tracker from '../Loader/loader-tracker';
import { ProductsStore } from 'src/stores/products-store';
import { withChangesPrompt } from '../Prompt/Prompt';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface IEditLocationProps {
  productsStore?: ProductsStore;
  uiStore?: UiStore;
}
interface MatchParams {
  id: string;
}

type Props = IEditLocationProps & RouteComponentProps<MatchParams>;

@inject('productsStore', 'uiStore')
@observer
class EditLocation extends React.Component<Props> {
  constructor(props: Props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  public async componentDidMount() {
    const locationId = this.props.match.params.id;
    await tracker.track(this.props.productsStore!.loadLocation(locationId));
  }

  public render() {
    return (
      <div className="content">
        <Header title={'Edit Location'} />

        <LocationForm>
          <ActionsMenu>
            <ActionItem label="Save Location" onClick={this.handleSubmit}>
              <Save />
            </ActionItem>
          </ActionsMenu>
        </LocationForm>
      </div>
    );
  }

  private async handleSubmit(e: React.FormEvent) {
    const { history, uiStore, productsStore } = this.props;
    const { notificationUiState } = this.props.uiStore!;
    if (productsStore!.location.validate()) {
      const ok = await productsStore!.saveLocation();
      if (ok) {
        uiStore!.setActionsMenuActive(false);
        history.push(`/locations`);
      } else {
        notificationUiState.showError('Error saving location...');
      }
    }
  }
}

export default withChangesPrompt(withAITracking(reactPlugin, EditLocation, 'EditLocation'));
