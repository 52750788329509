import { observable, action } from 'mobx';
import { OrderSearchUiState } from './ui-state/order-search-ui-state';
import { NotificationUiState } from './ui-state/notification-ui-state';
import { OrderUiState } from './ui-state/order-ui-state';
import { AddressMapUiState } from './ui-state/address-map-ui-state';
import { CustomerUiState } from './ui-state/customer-ui-state';
import { TimeslotType } from 'src/models/order';
import { AuthStore } from './auth-store';

export class UiStore {
    constructor(private authStore: AuthStore) {

    }
    @observable public actionsMenuActive: boolean = false;
    
    @observable public addressMapUiState: AddressMapUiState = new AddressMapUiState();
    @observable public navActive: boolean = false;
    @observable public notificationUiState: NotificationUiState = new NotificationUiState();
    @observable public orderSearchUiState: OrderSearchUiState = new OrderSearchUiState();
    @observable public orderUiState: OrderUiState = new OrderUiState(this.addressMapUiState, this.authStore);
    @observable public customerUiState: CustomerUiState = new CustomerUiState();

    public readonly timeslots: Array<{ name: string, value: TimeslotType }> = [
        { name: 'Any Time', value: TimeslotType.AnyTime },
        { name: 'First Up', value: TimeslotType.FirstUp },
        { name: 'AM', value: TimeslotType.AM },
        { name: 'PM', value: TimeslotType.PM },
        { name: 'ASAP', value: TimeslotType.ASAP },
        { name: 'Custom', value: TimeslotType.Custom },
    ];
    
    @action
    public setNavActive(active: boolean) {
        this.navActive = active;
    }

    @action
    public setActionsMenuActive(active: boolean) {
        this.actionsMenuActive = active;
    }
}