import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { globalStyles } from 'src/theme';
import { inject, observer } from 'mobx-react';
import { SalesStore } from 'src/stores/sales-store';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Order } from '../../models/order';

export interface ICancelQuoteDialogProps extends WithStyles<typeof globalStyles> {
    onClose(): void

    onSubmit(): void,

    salesStore?: SalesStore;
}

@inject('salesStore')
@observer
class CancelQuoteDialog extends React.Component<ICancelQuoteDialogProps> {

    constructor(props: ICancelQuoteDialogProps) {
        super(props);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    public render() {
        const {salesStore} = this.props;
        return <div className="full-dialog">
            <Loader area="cancel-quote-dialog"/>
            <div className="dialog-content">
                <h3>Cancel Quote</h3>
                <p>
                    What is the reason for cancelling this quote?
                </p>

                <FormControl className="reason-select">
                    <InputLabel htmlFor='reason'>Reason</InputLabel>
                    <Select
                        disableUnderline
                        value={salesStore!.order.quoteCancellation.reason}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                            this.handleChange(e.target.name, e.target.value)
                        }}
                        inputProps={{name: 'reason', id: 'reason'}}
                        autoWidth>
                        {
                            Order.cancellationReasons.map(c => (
                                <MenuItem value={c.value} key={c.value}>
                                    {c.name}
                                </MenuItem>
                            ))
                        }
                    </Select>
                </FormControl>


            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Close</span>
                    <Close className="icon"/>
                </div>
                <div className="done" onClick={this.props.onSubmit}>
                    <span>Save</span>
                    <ArrowForward className="icon"/>
                </div>
            </div>
        </div>
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.order.quoteCancellation.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChange(name: string, value: string) {
        this.props.salesStore!.order.quoteCancellation.update(name, value);
    }
}

export default withStyles(globalStyles)(CancelQuoteDialog);