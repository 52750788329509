import { observable } from 'mobx';
import { DateTime } from 'luxon';
import { TimeslotType, TruckType, Order, DeliveryType, OrderStatus } from './order';
import { IDeliveryResponse } from 'src/services/sales-api';
import { toDate } from '../datetime';
import { DeliveryDate } from './delivery-date';
import { Address } from './address';
import { Validate } from '../validate';

export class Delivery extends DeliveryDate {

    @observable public address: Address = new Address({});
    @observable public deliveryEndTime: string = '';
    @observable public deliveryStartTime: string = '';
    @observable public isCrane: boolean = false;
    @observable public timeslot: TimeslotType = TimeslotType.AnyTime;
    @observable public truckType: TruckType = TruckType.Standard;
    @observable public instruction: string = '';
    @observable public regionId: number | null = null;
    @observable public noRegionFreightMessage: string = '';
    @observable public subcontract: boolean = false;

    constructor(order: Order) {
        super(order);
    }

    public static fromResponse(r: IDeliveryResponse, order: Order): Delivery {
        const d = new Delivery(order);
        if (!r) return d;
        d.address = Address.fromResponse(r.address);
        d.date = !r.date ? null : toDate(r.date)
        d.dateTba = r.dateTba;
        d.deliveryEndTime = r.deliveryEndTime;
        d.deliveryStartTime = r.deliveryStartTime;
        d.isCrane = r.isCrane;
        d.timeslot = r.timeslot;
        d.instruction = r.instruction || '';
        d.truckType = r.truckType;
        d.noRegionFreightMessage = r.noRegionFreightMessage || '';
        d.subcontract = r.subcontract;
        return d;
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "date": (val: DateTime) => {
            if (this.order.deliveryType !== DeliveryType.Delivery ||
                    this.order.status !== OrderStatus.Order && this.order.status !== OrderStatus.Quote) {
                this.errors.date = null;
                return true;
            }
            super.validateDate(val);
            return this.errors.date === null;
        },
        "deliveryEndTime": (val: string) => {
            if (!Validate.hasValue(this.deliveryStartTime) && !Validate.hasValue(val)) {
                this.errors.deliveryEndTime = null;
                return true;
            }

            const endTime = Validate.getTime(val);
            const startTime = Validate.getTime(this.deliveryStartTime);

            if (isNaN(endTime)) {
                this.errors.deliveryEndTime = "not a valid 24hr time (eg. 9:00)";
                return false;    
            }
            
            if (isNaN(startTime)) {
                this.errors.deliveryStartTime = "invalid start time";
                this.errors.deliveryEndTime = null;
                return true;
            }

            if ((endTime - startTime) < 60) {
                this.errors.deliveryEndTime = "minimum delivery window is 1 hr"
            } else {
                this.errors.deliveryEndTime = null;
            }

            return this.errors.deliveryEndTime === null;
        },
        "deliveryStartTime": (val: string) => {
            if (!Validate.hasValue(val) && !Validate.hasValue(this.deliveryEndTime)) {
                this.errors.deliveryStartTime = null;
                return true;
            }

            const endTime = Validate.getTime(this.deliveryEndTime);
            const startTime = Validate.getTime(val);

            if (isNaN(startTime)) {
                this.errors.deliveryStartTime = "not a valid 24hr time (eg. 7:00)";
                return false;
            }

            if (isNaN(endTime)) {
                this.errors.deliveryEndTime = "invalid end time";
                this.errors.deliveryStartTime = null;
                return true;
            }

            if ((endTime - startTime) < 60) {
                this.errors.deliveryStartTime = "minimum delivery window is 1 hr"
            } else {
                this.errors.deliveryStartTime = null;
            }

            return this.errors.deliveryStartTime === null;
        },
        "noRegionFreightMessage": (val: string) => {
            this.order.deliveryType === DeliveryType.Delivery && this.order.hasNoRegion && !Validate.hasValue(val)
                ? this.errors.noRegionFreightMessage = "required"
                : this.errors.noRegionFreightMessage = null;

            return this.errors.noRegionFreightMessage === null;
        },
        "address": (val: Address) => {
            if (this.order.deliveryType !== DeliveryType.Delivery) return true;
            return this.address.validate();
        }
    }
}