import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { UiStore } from '../../stores/ui-store';
import { Add } from '@material-ui/icons';
import { PaymentType } from '../../models/order';
import { PaymentLine } from 'src/models/payment-line';
import { Grid, FormHelperText, Hidden, Button, Menu, MenuItem } from '@material-ui/core';
import { DateTime } from 'luxon';
import { ProductsStore } from 'src/stores/products-store';
import tracker from '../Loader/loader-tracker';
import PaymentRow from './PaymentRow';
import { runInAction } from 'mobx';

interface IAddChildPaymentProps {
  salesStore?: SalesStore;
  productsStore?: ProductsStore;
  uiStore?: UiStore;
}

interface IState {
  anchorEl: any;
}

const availablePayments: Array<{ name: string; value: PaymentType }> = [
  { name: 'Card', value: PaymentType.Card },
  { name: 'Cash', value: PaymentType.Cash },
  { name: 'Cheque', value: PaymentType.Cheque },
  { name: 'Direct Deposit', value: PaymentType.DirectDeposit }
];

@inject('salesStore', 'uiStore', 'productsStore')
@observer
class AddChildPayments extends React.Component<IAddChildPaymentProps, IState> {
  constructor(props: IAddChildPaymentProps) {
    super(props);
    this.state = {
      anchorEl: null
    };

    this.handleClick = this.handleClick.bind(this);
    this.AddChildPayment = this.AddChildPayment.bind(this);
    this.handleDeletePaymentLine = this.handleDeletePaymentLine.bind(this);
    this.handlePaymentLinePaymentLocationChange = this.handlePaymentLinePaymentLocationChange.bind(this);
  }

  public async componentDidMount() {
    await tracker.track(this.props.productsStore!.getPaymentLocations());
  }

  public render() {
    const { anchorEl } = this.state;
    const hasPaymentLine = this.props.salesStore!.order.paymentLines.length > 0;
    const isReadOnly = this.props.uiStore!.orderUiState.formReadOnly;

    return (
      <div className='payment-lines'>
        {!isReadOnly && (
          <div>
            {this.props.salesStore!.order.errors.paymentLines && (
              <FormHelperText className='payment-item' error>
                {this.props.salesStore!.order.errors.paymentLines}
              </FormHelperText>
            )}

            <Button variant='outlined' onClick={this.handleClick} className='payment-item payment-button'>
              Allocate Payment <Add />
            </Button>
            <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => this.AddChildPayment(PaymentType.None)}>
              {availablePayments.map(p => (
                <MenuItem key={p.value} onClick={() => this.AddChildPayment(p.value)}>
                  {p.name}
                </MenuItem>
              ))}
            </Menu>
          </div>
        )}
        {hasPaymentLine && (
          <Grid container>
            <Hidden mdDown>
              <Grid container className='payment-header'>
                <Grid item lg={2}>
                  Type
                </Grid>
                <Grid item lg={3}>
                  Amount
                </Grid>
                <Grid item lg={3}>
                  Date
                </Grid>
                <Grid item lg={3}>
                  Location
                </Grid>
                <Grid item lg={1} />
              </Grid>
            </Hidden>
            {this.props.salesStore!.order.paymentLines.map((p, i) => (
              <PaymentRow
                key={i}
                index={i}
                salesStore={this.props.salesStore}
                productsStore={this.props.productsStore}
                isReadOnly={this.props.uiStore!.orderUiState.isReadonly('payments')}
                paymentLine={p}
                handleDeletePaymentLine={this.handleDeletePaymentLine}
                handlePaymentLineDateChange={(date: DateTime) => this.handlePaymentLineDateChange(date, p)}
                handlePaymentLinePaymentLocationChange={(value: string) => this.handlePaymentLinePaymentLocationChange(value, p)}
                handlePaymentLineAmountChange={(name: string, value: number) => this.handlePaymentLineChange(name, value, p)}
              />
            ))}
          </Grid>
        )}
      </div>
    );
  }

  private handleClick(event: any) {
    this.setState({ anchorEl: event.currentTarget });
  }

  private AddChildPayment(paymentType: PaymentType) {
    this.setState({ anchorEl: null });
    if (paymentType !== PaymentType.None) {
      const today = DateTime.local().toISODate();
      const locationId = this.props.productsStore!.paymentLocations.some(x => x.code === this.props.salesStore!.order.stockingPoint)
        ? this.props.productsStore!.paymentLocations.find(x => x.code === this.props.salesStore!.order.stockingPoint)!.id
        : 0;
      this.props.salesStore!.order.addPaymentLine(new PaymentLine(0, paymentType, 0, today, this.props.salesStore!.order.stockingPoint, locationId));
    }
  }

  private handlePaymentLineChange(name: string, value: number, paymentLine: PaymentLine) {
    const currentPool = this.props.salesStore!.order.totalPaymentPoolRemaining + paymentLine.amount;
    if (currentPool >= value) {
      paymentLine.updateAndValidate(name, value);
    } else {
      runInAction(() => (paymentLine.errors.amount = 'Must be less than ' + currentPool.toFixed(2)));
    }
  }

  private handlePaymentLineDateChange(value: DateTime, paymentLine: PaymentLine) {
    paymentLine.update('receivedDate', value);
  }

  private handlePaymentLinePaymentLocationChange(value: string, paymentLine: PaymentLine) {
    const location = this.props.productsStore!.paymentLocations.find(x => x.code === value);

    if (location) {
      paymentLine.update('paymentLocation', location!.code);
      paymentLine.update('paymentLocationId', location!.id);
    }
  }

  private handleDeletePaymentLine(paymentLine: PaymentLine) {
    this.props.salesStore!.order.removePaymentLine(paymentLine);
  }
}

export default AddChildPayments;
