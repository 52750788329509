import { Model } from './model';
import { Validate } from '../validate';
import { observable } from 'mobx';

export class CustomerSearch extends Model {

    @observable public searchText: string = '';

    protected validators: {[name: string]: (val: any) => boolean} = {
        "searchText": (val: string) => {
            this.errors.orderNumber = Validate.hasValue(val)
                ? null : "Search text required";
            return this.errors.searchText === null;
        }
    }
}