import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import { observer } from 'mobx-react';
import { Workflow } from 'src/models/workflow';
import TextField from '@material-ui/core/TextField';

export interface IRejectionDialogProps {
    onClose(): void
    onSubmit(): void
    workflow: Workflow
}

@observer
export class RejectionDialog extends React.Component<IRejectionDialogProps> {
    constructor(props: IRejectionDialogProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        if (!this.props.workflow) return;
        this.props.workflow!.setReason('');
    }

    public render() {
        return <div className="full-dialog">
            <Loader area="rejection-dialog" />
            <div className="dialog-content">
                <h3>Reject Request</h3>
                <p>
                    You are about to reject this request
                </p>
                <TextField
                    InputProps={{ disableUnderline: true }}
                    label="Reason"
                    type="text"
                    name="reason"
                    error={!!this.props.workflow!.errors.reason}
                    helperText={this.props.workflow!.errors.reason || null}
                    value={this.props.workflow.reason}
                    onChange={this.handleChange}
                    fullWidth
                />
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Cancel</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={this.props.onSubmit}>
                    <span>Reject</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.workflow.update(e.currentTarget.name, e.currentTarget.value);
    }
}