import * as React from 'react';
import './styles.css';
import { TextField, Grid, FormGroup, FormHelperText, WithStyles, withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import DeliveryTabs from './DeliveryTabs';
import { CustomersStore, ICustomerSummary } from '../../stores/customers-store';
import { SalesStore } from '../../stores/sales-store';
import { UiStore } from '../../stores/ui-store';
import AddPayments from './AddPayments';
import { IReactionDisposer, reaction } from 'mobx';
import tracker from '../Loader/loader-tracker';
import AddProduct from './AddProduct';
import StockingPoint from './StockingPoint';
import AddService from './AddService';
import AddedProducts from './AddedProducts';
import AddedServices from './AddedServices';
import { globalStyles } from '../../theme';
import OrderSummary from './OrderSummary';
import { scrollToError, scrollToTop } from 'src/scroll-to';
import { PersonAddDisabled } from '@material-ui/icons'
import { IconTooltip } from '../IconTooltip/IconTooltip';
import CreditSummary from './CreditSummary';
import { ILatLng, Address } from 'src/models/address';
import AddSupplierComment from './AddSupplierComment';
import AddedSupplierComments from './AddedSupplierComments';
import AddChildPayments from './AddChildPayments';
import {AuthStore} from "../../stores/auth-store";

export interface IOrderFormProps extends WithStyles<typeof globalStyles> {
    salesStore?: SalesStore
    uiStore?: UiStore
    authStore?: AuthStore,
    customersStore?: CustomersStore
    clonedFrom?: string;
}

@inject('salesStore', 'uiStore', 'authStore', 'customersStore')
@observer
class OrderForm extends React.Component<IOrderFormProps> {

    private customerReaction: IReactionDisposer;
    private deliveryAddressReaction: IReactionDisposer;
    private latlonReaction: IReactionDisposer;
    private pickupAddressReaction: IReactionDisposer;
    private deliveryTypeReaction: IReactionDisposer;
    private balanceDueReaction: IReactionDisposer;
    private productsQuantityReaction: IReactionDisposer;
    private productsSelectionReaction: IReactionDisposer;
    private stockingPointChangedReaction: IReactionDisposer;

    constructor(props: IOrderFormProps) {
        super(props);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChanged = this.handleSelectChanged.bind(this);
        this.handleCustomerSelected = this.handleCustomerSelected.bind(this);
        this.getCustomerSuggestions = this.getCustomerSuggestions.bind(this);

        this.customerReaction = reaction(
            () => this.props.salesStore!.order.customer,
            async (customer) => {
                this.recalculatePrice(customer);
                this.checkProductWeights();
                this.calculatFuelLevy();
            }
        );
        this.deliveryAddressReaction = reaction(
            () => this.props.salesStore!.order.delivery.address,
            async (address) => {
                this.recalculatePrice(address);
            },
            { equals: (oldValue: Address, newValue: Address) => oldValue.suburb === newValue.suburb }
        );
        this.latlonReaction = reaction(
            () => this.props.salesStore!.order.delivery.address.latLng,
            async (latlon) => {
                this.reloadRegion(latlon);
            }
        );

        this.pickupAddressReaction = reaction(
            () => this.props.salesStore!.order.pickup.address.latLng,
            async (address) => {
                this.recalculatePrice(address);
                this.reloadRegion(address);
            }
        );
        this.deliveryTypeReaction = reaction(
            () => this.props.salesStore!.order.deliveryType,
            async (deliveryType) => {
                this.checkProductWeights();
                this.calculatFuelLevy();
                if (!this.props.salesStore!.order.shouldRecalculatePrices) return;
                this.recalculatePrice(deliveryType);
            }
        );
        this.productsQuantityReaction = reaction(
            () => this.props.salesStore!.order.productLines.map(p => p.quantity),
            async () => {
                this.checkProductWeights()
            }, { delay: 1500 },
        );

        this.productsSelectionReaction =  this.balanceDueReaction = reaction(
            () => this.props.salesStore!.order.productLines!.length,
            async () => {
                if(this.props.salesStore!.order.productLines.length > 0)
                {
                    await this.props.salesStore!.refreshStockInfo(
                        this.props.salesStore!.order.stockingPoint,
                        this.props.salesStore!.order.productLines.map(pl => pl.productId),
                        this.props.uiStore!.notificationUiState
                    )
                }
            }
        );

        this.balanceDueReaction = reaction(
            () => this.props.salesStore!.order.balanceDue,
            () => this.props.salesStore!.order.validatePaymentLines()
        );
        this.stockingPointChangedReaction = reaction(
            () => this.props.salesStore!.order.stockingPoint,
            async () => {
                await this.props.salesStore!.refreshStockInfo(
                    this.props.salesStore!.order.stockingPoint,
                    this.props.salesStore!.order.productLines.map(pl => pl.productId),
                    this.props.uiStore!.notificationUiState
                )
            }
        );
    }

    public componentDidUpdate() {
        this.props.salesStore!.order.handleErrors(scrollToError);
    }

    public componentDidMount() {
        scrollToTop();
    }

    private async checkProductWeights() {
        if (this.props.uiStore!.orderUiState.formReadOnly) return;
        await this.props.salesStore!.order.checkProductWeights(this.props.uiStore!.notificationUiState);
    }

    private async calculatFuelLevy() {
        if (this.props.uiStore!.orderUiState.formReadOnly) return;
        await this.props.salesStore!.order.calculateFuelLevy();
    }

    private async reloadRegion(address: ILatLng) {
        if (this.props.uiStore!.orderUiState.formReadOnly && (!address || !address.lat || !address.lng))
            return;

        await tracker.track(this.props.salesStore!.order.getRegion(address));
    }

    private async recalculatePrice(data: any) {
        if (this.props.uiStore!.orderUiState.formReadOnly) return;
        const { notificationUiState } = this.props.uiStore!;
        console.log("Data changed to ", data, "recalculating prices");
        await tracker.track(this.props.salesStore!.order.calculatePriceForAllProducLines(notificationUiState), "products-area");
    }

    public componentWillUnmount() {
        this.deliveryTypeReaction();
        this.customerReaction();
        this.deliveryAddressReaction();
        this.latlonReaction();
        this.pickupAddressReaction();
        this.balanceDueReaction();
        this.productsQuantityReaction();
        this.productsSelectionReaction();
        this.stockingPointChangedReaction();
    }

    public render() {
        const isEditing = !this.props.uiStore!.orderUiState.formReadOnly;
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        const isAccountsReceivableOrAdmin = this.props.authStore!.isAccountsReceivable || this.props.authStore!.isAdmin;
        return <form onKeyDown={(e) => {if(e.key === "Enter"){e.preventDefault();}}}>
            <Grid container className="section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Customer</h2>{
                        (salesStore!.order.isInDispute || salesStore!.order.isInDisputeButPreviouslyApproved) &&
                        <div>
                            <IconTooltip title="Customer in Dispute" placement="left">
                                {{ icon: <PersonAddDisabled color="error" /> }}
                            </IconTooltip>
                        </div>
                    }
                    <Autocomplete
                        label="Customer"
                        onSelect={this.handleCustomerSelected}
                        getSuggestions={this.getCustomerSuggestions}
                        errorText={salesStore!.order.errors.customer}
                        selectedItem={orderUiState!.selectedCustomer}
                        suggestionToComponent={{
                            containerStyle: {},
                            getComponent: (item: SuggestionItem<ICustomerSummary>) => (
                                <Grid container>
                                    <Grid item xs={11} className={item.value.isInDispute ? "dispute" : ""}>
                                        {item.label}{item.value.isInDispute && <span> (In Dispute)</span>}
                                    </Grid>
                                    <Grid item xs={1}>
                                        {
                                            item.value.isInDispute
                                            && <IconTooltip title="Customer in Dispute" placement="left">
                                                {{ icon: <PersonAddDisabled color="error" /> }}
                                            </IconTooltip>
                                        }
                                    </Grid>
                                </Grid>
                            )
                        }}
                        disabled={orderUiState!.isReadonly('customer')}
                    />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Customer Reference"
                        type="text"
                        name="customerRef"
                        error={!!salesStore!.order.errors.customerRef}
                        helperText={salesStore!.order.errors.customerRef || null}
                        value={salesStore!.order.customerRef}
                        onChange={this.handleChange}
                        disabled={orderUiState!.isReadonly('customerRef')}
                        fullWidth
                        inputProps={{ maxLength: 15 }}
                    />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Lead Identifier"
                        type="text"
                        name="leadIdentifier"
                        error={!!salesStore!.order.errors.leadIdentifier}
                        helperText={salesStore!.order.errors.leadIdentifier || null}
                        value={salesStore!.order.leadIdentifier}
                        onChange={this.handleChange}
                        disabled={orderUiState!.isReadonly('leadIdentifier')}
                        fullWidth
                        inputProps={{ maxLength: 50 }}
                    />
                </Grid>
            </Grid>
            <Grid container className="section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Customer Details</h2>
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Trading-Customer Name"
                        type="text"
                        name="name"
                        autoComplete="no"
                        error={!!salesStore!.order.errors.name}
                        helperText={salesStore!.order.errors.name || null}
                        value={salesStore!.order.name}
                        onChange={this.handleChange}
                        onBlur={this.handleBlur}
                        disabled={orderUiState!.isReadonly('name')}
                        fullWidth
                    />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Phone"
                        type="text"
                        name="phone"
                        autoComplete="no"
                        error={!!salesStore!.order.errors.phone}
                        helperText={salesStore!.order.errors.phone || null}
                        value={salesStore!.order.phone ?? ''}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        disabled={orderUiState!.isReadonly('phone')}
                    />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Email"
                        type="text"
                        name="email"
                        autoComplete="no"
                        error={!!salesStore!.order.errors.email}
                        helperText={salesStore!.order.errors.email || null}
                        value={salesStore!.order.email}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        disabled={orderUiState!.isReadonly('email')}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container className="section tabs">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <DeliveryTabs />
                </Grid>
            </Grid>
            <Grid container className="section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Order</h2>
                    <FormGroup row>
                        {
                            (!orderUiState.canEditStockingPoint ||
                                salesStore!.order.productLines.length > 0)
                            && <StockingPoint />
                        }
                    </FormGroup>
                    <FormGroup row>
                        {
                            orderUiState.canAddProduct
                            && <AddProduct />
                        }
                        {
                            (orderUiState.canAddService || isAccountsReceivableOrAdmin)
                            && <AddService />
                        }
                        {
                            orderUiState.canAddSupplierComment
                            && <AddSupplierComment />
                        }
                    </FormGroup>
                    {  salesStore!.order.errors.lines &&
                        <FormHelperText error>
                            {salesStore!.order.errors.lines}
                        </FormHelperText>
                    }
                    <FormGroup row>
                        <AddedProducts clonedFrom={this.props.clonedFrom} stockInfos={this.props.salesStore!.stockInfos} />
                        <AddedSupplierComments />
                        <AddedServices />
                    </FormGroup>
                </Grid>
            </Grid>
            {
                salesStore!.order.isCashSalesAccount && !salesStore!.order.parentOrder
                && <Grid container className="section">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <h2>Payments</h2>
                        <AddPayments />
                    </Grid>
                </Grid>
            }
            {
                salesStore!.order.isCashSalesAccount && salesStore!.order.parentOrder
                && <Grid container className="section">
                    <Grid item xs={1} />
                    <Grid item xs={10}>
                        <h2>Payments Allocated</h2>
                        <AddChildPayments />
                    </Grid>
                </Grid>
            }
            {
                salesStore!.order.hasRefund
                && <CreditSummary />
            }
            <OrderSummary />
            {this.props.children}
            {this.props.salesStore!.order.hasErrors}
        </form>
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.order.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.props.salesStore!.order.update(e.currentTarget.name, e.currentTarget.value);
    }

    private handleSelectChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.salesStore!.order.updateAndValidate(e.target.name, e.target.value);
    }

    private handleCustomerSelected(selected: SuggestionItem<ICustomerSummary>) {
        this.props.salesStore!.order.updateCustomer(selected.value);
    }

    private getCustomerSuggestions(value: string): Promise<Array<SuggestionItem<ICustomerSummary>>> {
        return this.props.customersStore!.searchCustomers(value, false);
    }
}

export default withStyles(globalStyles)(OrderForm);
