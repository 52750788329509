import { observable, action, runInAction } from 'mobx';
import { ISupplierSummary } from '../models/supplier-summary';
import { SuppliersApi } from '../services/suppliers-api';
import { ISupplierDetail } from '../models/supplier-detail';
import { ExWorks } from '../models/ex-works';
import { SuggestionItem } from '../components/Autocomplete/Autocomplete';
import { IRegionSummary } from 'src/models/region-summary';
import { Address } from 'src/models/address';

export interface IPickupLocation {
    name: string;
    address: Address;
}

export class SuppliersStore {
    @observable public exWorksRegions: IRegionSummary[] = [];
    @observable public exWorks: ExWorks = new ExWorks();
    @observable public supplier: ISupplierDetail = { code: '', id: 0, name: '', exWorkses: [], ranges: [] };
    @observable public suppliers: ISupplierSummary[] = [];
    @observable public pickupSuggestions: Array<SuggestionItem<IPickupLocation>> = [];
    @observable public supplierSuggestions: Array<SuggestionItem<ISupplierSummary>> = [];

    constructor(private suppliersApi: SuppliersApi) {}

    @action
    public async getExWorksRegions(exWorksId: number): Promise<IRegionSummary[]> {
        const exWorksRegions = await this.suppliersApi.getExWorksRegions(exWorksId);
        runInAction(() => this.exWorksRegions = exWorksRegions);
        return exWorksRegions;
    }

    @action
    public async getSuppliers(): Promise<ISupplierSummary[]> {
        const suppliers = await this.suppliersApi.get();
        runInAction(() => {
            this.suppliers = suppliers.sort((a: ISupplierSummary, b: ISupplierSummary) => {
                return a.name < b.name ? -1 : 1;
            });
        });
        return this.suppliers;
    }

    @action
    public async load(id: number): Promise<ISupplierDetail> {
        const supplier = await this.suppliersApi.load(id);
        return runInAction(() => {
            this.supplier = supplier;
            return this.supplier;
        });
    }

    @action
    public async searchSuppliers(query: string): Promise<Array<SuggestionItem<ISupplierSummary>>> {
        const suppliers = await this.suppliersApi.searchSuppliers(query);
        runInAction(() => {
            this.supplierSuggestions = suppliers.map(s => {
                return {
                    label: s.name,
                    value: {
                        code: s.code,
                        id: s.id,
                        name: s.name
                    }
                } as SuggestionItem<ISupplierSummary>;
            })
        });
        return this.supplierSuggestions;
    }

    @action
    public async loadExWorks(id: string): Promise<ExWorks> {
        const exWorksResponse = await this.suppliersApi.loadExWorks(id);
        return runInAction(() => {
            this.exWorks = ExWorks.fromResponse(exWorksResponse);
            this.exWorks.track();
            return this.exWorks;
        });
    }

    @action
    public async saveExWorks(): Promise<boolean> {
        const ok = await this.suppliersApi.saveExWorks(this.exWorks);
        this.exWorks.untrack();
        return ok;
    }

    public async searchPickupLocations(query: string): Promise<Array<SuggestionItem<IPickupLocation>>>  {
        const locations = await this.suppliersApi.searchPickupLocations(query);
        runInAction(() => {
            this.pickupSuggestions = locations.map(a => Address.fromResponse(a).toSuggestionItem())
        });
        return this.pickupSuggestions;
    }
}
