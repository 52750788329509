import { action, observable, runInAction } from "mobx";
import { SuggestionItem } from "src/components/Autocomplete/Autocomplete";
import { OrderReportType } from "src/models/order";
import { IOrderReportQuery } from 'src/models/reports-query';
import { ReportsFilter } from "src/models/reports-filter";
import { CustomersApi } from "src/services/customers-api";
import { ReportsApi } from "src/services/reports-api";
import { SalesApi } from "src/services/sales-api";
import { ICustomerSummary, IOwnerName } from "./customers-store";

export class ReportsStore {
    @observable public customerSuggestions: Array<SuggestionItem<ICustomerSummary>> = [];
    @observable public ownerSuggestions: Array<SuggestionItem<IOwnerName>> = [];
    @observable public customerLookupIsVisible: boolean = false;
    @observable public ownerLookupIsVisible: boolean = false;
    @observable public addressLookupIsVisible: boolean = false;
    @observable public dateRangeIsVisible: boolean = false;
    @observable public statusIsVisible: boolean = false;
    @observable public deliveryDateRangeIsVisible: boolean = false;
    @observable public branchBankingIsVisible: boolean = false;
    @observable public selectedReport: string;
    @observable public selectedFileFormat: string;
    @observable public selectedBranchBank: number;
    @observable public disableSelectedFileFormat: boolean = false;
    constructor(private customersApi: CustomersApi, private saleApi: SalesApi, private reportsApi: ReportsApi) { }

    @action
    public setCustomerLookupIsVisible(isVisible: boolean) {
        this.customerLookupIsVisible = isVisible;
    }

    @action
    public setOwnerLookupIsVisible(isVisible: boolean) {
        this.ownerLookupIsVisible = isVisible;
    }

    @action
    public setAddressLookupIsVisible(isVisible: boolean) {
        this.addressLookupIsVisible = isVisible;
    }

    @action
    public setDateRangeIsVisible(isVisible: boolean) {
        this.dateRangeIsVisible = isVisible;
    }

    @action
    public setStatusIsVisible(isVisible: boolean) {
        this.statusIsVisible = isVisible;
    }

    @action
    public setDeliveryDateRangeIsVisible(isVisible: boolean) {
        this.deliveryDateRangeIsVisible = isVisible;
    }

    @action
    public setBranchBankingIsVisible(isVisible: boolean) {
        this.branchBankingIsVisible = isVisible;
    }

    @action
    public setSelectedReport(report: string) {
        this.selectedReport = report;
    }

    @action
    public setSelectedFileFormat(fileFormat: string) {
        this.selectedFileFormat = fileFormat;
    }

    @action
    public setSelectedBranchBank(branchBank: number) {
        this.selectedBranchBank = branchBank;
    }

    @action 
    public setDisableFileFormatSelect(disable: boolean){
        this.disableSelectedFileFormat = disable;
    }

    @action
    public async searchCustomers(
        query: string,
        includeDeleted: boolean
    ): Promise<Array<SuggestionItem<ICustomerSummary>>> {
        const customers = !includeDeleted
            ? await this.customersApi.searchCustomers(query)
            : await this.customersApi.searchAllCustomers(query);

        runInAction(() => {
            this.customerSuggestions = customers.map((c) => {
                return {
                    label: c.name,
                    value: {
                        code: c.code.value,
                        id: c.id,
                        isCashSaleAccount: c.code.isCashSaleAccount,
                        isOnlineStore: c.code.isOnlineStore,
                        isAbcFrieghtJobsAccount: c.code.isAbcFreightJobsAccount,
                        email: c.email,
                        isInDispute: c.isInDispute,
                        maxOrderAmount: c.maxOrderAmount,
                        phone: c.phoneNumber
                    },
                } as SuggestionItem<ICustomerSummary>;
            });
        });
        return this.customerSuggestions;
    }

    @action
    public async searchDeliveryAddresses(query: string) {
        const suggestions = await this.saleApi.searchDeliveryAddresses(query);
        return suggestions.map((s) => {
            return { label: s, value: s } as SuggestionItem<string>;
        });
    }

    @action
    public async searchOwners(
        query: string
    ): Promise<Array<SuggestionItem<IOwnerName>>> {
        const owners = await this.customersApi.searchOwners(query);
        runInAction(() => {
            this.ownerSuggestions = owners.map((c) => {
                return {
                    label: c.name,
                    value: c,
                } as SuggestionItem<IOwnerName>;
            });
        });
        return this.ownerSuggestions;
    }

    @action
    public async PrintCSVReport(report: string, reportsFilter: ReportsFilter, fileType: string) {
        let reportTypeSelected: OrderReportType = OrderReportType.OrderStatus;

        switch (report) {
            case "TBA Orders Report":
                reportTypeSelected = OrderReportType.Tba;
                break;
            case "Status Report":
                reportTypeSelected = OrderReportType.OrderStatus;
                break;
            case "Pricing Approvals Report":
                reportTypeSelected = OrderReportType.PricingApproval;
                break;
            case "Branch Banking Report":
                reportTypeSelected = OrderReportType.BranchBanking;
                break;
            case "Complete and Failed Invoices Report":
                    reportTypeSelected = OrderReportType.CompletedAndFailedInvoices;
                break;
        }

        const payload: IOrderReportQuery = {
            customerId: reportsFilter.customerId,
            createdRange: {
                startDate: reportsFilter.startDate,
                endDate: reportsFilter.endDate
            },
            deliveryRange: {
                startDate: reportsFilter.deliveryStartDate,
                endDate: reportsFilter.deliveryEndDate
            },
            repCode: reportsFilter.repCode,
            address: reportsFilter.address,
            reportType: reportTypeSelected,
            status: reportsFilter.lifeTimeStatus,
            branchId: this.selectedBranchBank
        }

        let response: Blob | null;

        if (fileType === "CSV") {
            response = await this.reportsApi.getCSV(payload);
        } else {
            response = await this.reportsApi.getPDF(payload);
        }

        return response;
    }
}
