import * as React from 'react';
import './styles.css';
import { observer, inject } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { TextField, InputAdornment, FormControl, InputLabel, Select, MenuItem, WithStyles, withStyles } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import Done from '@material-ui/icons/Done';
import DeleteForever from '@material-ui/icons/DeleteForever';
import { observable, runInAction } from 'mobx';
import { ProductLine } from 'src/models/product-line';
import { globalStyles } from 'src/theme';
import tracker from '../Loader/loader-tracker';
import { Loader } from '../Loader/Loader';
import { UiStore } from '../../stores/ui-store';
import { ProductsStore } from '../../stores/products-store';
import CurrencyField, { ICurrencyChangedEvent } from '../Currency/CurrencyField';
import { ITiersResponse } from 'src/services/products-api';
import NumberField, { INumberChangedEvent } from '../NumberField/NumberField';
import { OrderLifetimeStatus } from 'src/models/order';
import {roundedSubtotal} from "../../math";
import { IStockInfo } from 'src/services/sales-api';

export interface IEditProductDialogProps extends WithStyles<typeof globalStyles> {
    salesStore?: SalesStore;
    uiStore?: UiStore;
    productsStore?: ProductsStore;
    active: boolean;
    productLine: ProductLine;
    handleClose(e: React.MouseEvent<HTMLDivElement>): void;
    handleSave(productLine: ProductLine, parentProductLine: ProductLine): void;
    handleDelete(productLine: ProductLine): void;
    stockInfo: IStockInfo | undefined;
}

@inject('salesStore', 'productsStore', 'uiStore')
@observer
class EditProductDialog extends React.Component<IEditProductDialogProps> {
    @observable private product: ProductLine;
    @observable private parentProduct: ProductLine;
    @observable private tiers: ITiersResponse[];


    constructor(props: IEditProductDialogProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handlePriceChange = this.handlePriceChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleSave = this.handleSave.bind(this);
        this.handleTierSelectChange = this.handleTierSelectChange.bind(this);
        runInAction(() => {
            this.tiers = [];
            this.product = this.props.productLine.clone();
            const { order } = this.props.salesStore!;
            if (order.parentOrder) {
                const parent = order.parentOrder.getProductLine(this.product.productId, this.product.lineNumber);
                if (parent) this.parentProduct = parent.clone();
            }
        });
    }

    public async componentDidMount() {
        const productTiers = await tracker.track(this.props.productsStore!.getTiersForProduct(this.product.productId));
        runInAction(() => (this.tiers = productTiers));
    }

    public render() {
        const { orderUiState } = this.props.uiStore!;
        const { order } = this.props.salesStore!;
        const { stockInfo } = this.props;
        const splitInfo = this.product.orderSplitQtyLabel;
        return (
            <div className={'edit-service-dialog ' + (this.props.active ? 'active' : '')}>
                <div className='dialog-content'>
                    <Loader area='edit-service-dialog' />
                    {splitInfo && <div className='split-info'>{splitInfo}</div>}
                    {!splitInfo && stockInfo && <div className='split-info'>{stockInfo.quantityAvailable} in stock ({stockInfo.locationName}) </div>}
                    <h3>{this.product.name}</h3>
                    <NumberField
                        value={this.product.quantity}
                        onChange={(e: INumberChangedEvent) => this.handleQuantityChange(e.parsedValue)}
                        error={this.product.errors.quantity}
                        textProps={{
                            className: this.props.classes.textField,
                            label: this.product.unitsLabel,
                            name: 'quantity',
                            autoFocus: true,
                            disabled: !orderUiState!.canEditProductQuantity(this.product)
                        }}
                    />

                    <NumberField
                        value={this.product.chosenUnitWeight}
                        onChange={(e: INumberChangedEvent) => this.handleChange('chosenUnitWeight', e.parsedValue)}
                        error={this.product.errors.chosenUnitWeight}
                        textProps={{
                            className: this.props.classes.textField,
                            label: 'Unit Weight(Tonnes)',
                            name: 'chosenUnitWeight',
                            disabled: orderUiState.isReadonly('chosenUnitWeight')
                        }}
                    />
                    <NumberField
                        value={this.product.chosenNoOfPacks}
                        onChange={(e: INumberChangedEvent) => this.product.handleChosenPackChange('chosenNoOfPacks', e.parsedValue)}
                        error={this.product.errors.chosenNoOfPacks}
                        textProps={{
                            className: this.props.classes.textField,
                            label: 'Packs',
                            name: 'chosenNoOfPacks',
                            disabled: orderUiState.isReadonly('chosenNoOfPacks')
                        }}
                    />
                    <NumberField
                        value={this.product.chosenNoOfPallets}
                        onChange={(e: INumberChangedEvent) => this.product.handleChosenPalletsChange('chosenNoOfPallets', e.parsedValue)}
                        error={this.product.errors.chosenNoOfPallets}
                        textProps={{
                            className: this.props.classes.textField,
                            label: 'Pallets',
                            name: 'chosenNoOfPallets',
                            disabled: orderUiState.isReadonly('chosenNoOfPallets')
                        }}
                    />
                    {order.hasRefund && (
                        <NumberField
                            InputProps={{ classes: { input: this.props.classes.highlightText } }}
                            value={this.product.creditQuantity}
                            error={this.product.errors.creditQuantity}
                            onChange={(e: INumberChangedEvent) => this.handleChange('creditQuantity', e.parsedValue)}
                            textProps={{
                                className: this.props.classes.textField,
                                label: `Credit ${this.product.unitsLabel}`,
                                name: 'creditQuantity',
                                autoFocus: true,
                                disabled: orderUiState.isReadonly('credit'),
                                fullWidth: true
                            }}
                        />
                    )}

                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label='Total Pieces'
                        type='text'
                        name='totalpieces'
                        error={!!this.product.errors.totalpieces}
                        helperText={this.product.errors.totalpieces || null}
                        value={this.product.totalPieces.toFixed(2)}
                        disabled
                        fullWidth
                    />

                    <FormControl className={this.props.classes.textField}>
                        <InputLabel htmlFor='tier'>Pricing Tier</InputLabel>
                        <Select
                            disabled={orderUiState.isReadonly('tierId')}
                            disableUnderline
                            value={this.product.tierId}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleTierSelectChange(Number(e.target.value))}
                            inputProps={{ name: 'tierId', id: 'tierId' }}>
                            {this.tiers.map(t => (
                                <MenuItem value={t.id} key={t.id}>
                                    {t.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <CurrencyField
                        className={this.props.classes.textField}
                        InputProps={{ ...(this.product.hasCustomPrice ? { classes: { input: this.props.classes.highlightText } } : {}) }}
                        label='Unit Cost'
                        name='price'
                        value={this.product.price}
                        error={this.product.errors.price}
                        onChange={this.handlePriceChange}
                        fullWidth
                        disabled={orderUiState.isReadonly('price')}
                    />

                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true, startAdornment: <InputAdornment position='start'>$</InputAdornment> }}
                        label='Total'
                        type='text'
                        name='total'
                        value={roundedSubtotal(this.product.unroundedSubtotal).toFixed(2)}
                        disabled
                        fullWidth
                    />

                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        inputProps={{ maxLength: 60 }}
                        label='Note'
                        type='text'
                        name='note'
                        value={this.product.note}
                        fullWidth
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChange('note', e.currentTarget.value)}
                        onBlur={this.handleBlur}
                        multiline
                        disabled={orderUiState.isReadonly('product-note')}
                    />
                </div>
                {(window.config.enableApplyFirstPickupEta && this.product.jobPickedUp) ? (
                    <div className='dialog-actions'>
                        {order.lifetimeStatus !== OrderLifetimeStatus.Complete && (
                            <p className={this.props.classes.highlightText}>Product has been picked up for delivery</p>
                        )}
                        <div className='close' onClick={this.props.handleClose}>
                            <span>Close</span>
                            <Close className='icon' />
                        </div>
                    </div>
                ) : (
                    <div className='dialog-actions'>
                        {orderUiState.canDeleteProduct && (
                            <div className='delete' onClick={() => this.props.handleDelete(this.product)}>
                                <span>Delete</span>
                                <DeleteForever className='icon' />
                            </div>
                        )}
                        <div className='close' onClick={this.props.handleClose}>
                            <span>Close</span>
                            <Close className='icon' />
                        </div>
                        <div className='done' onClick={this.handleSave}>
                            <span>Save</span>
                            <Done className='icon' />
                        </div>
                    </div>
                )}
            </div>
        );
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.product.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChange(property: string, value: any) {
        this.product.updateAndValidate(property, value);
    }

    private handlePriceChange(e: ICurrencyChangedEvent) {
        const price = e.parsedValue;
        if (this.product.acceptPriceChange(price)) {
            this.product.setAcceptedPrice(price);
        } else {
            this.product.overridePrice(price);
        }
    }

    private handleQuantityChange(quantity: number) {
        this.product.allocateQuantities(quantity, this.parentProduct);
    }

    private async handleTierSelectChange(tierId: number) {
        try {
            const price = await tracker.track(this.props.salesStore!.order.getProductPriceByTier(this.product.productId, this.product.exWorksId, tierId), 'edit-service-dialog');
            if (price.price >= this.product.initialPrice) {
                this.product.setAcceptedPrice(price.price);
            } else {
                this.product.overridePrice(price.price);
            }

            this.product.setTier(tierId);
            this.product.setExWorksPrice(price.exWorksPrice);
            this.product.setFreightCost(price.freightCost);
        } catch {
            const tier = this.props.productsStore!.tiers.find(t => t.id === tierId);
            this.props.uiStore!.notificationUiState.showError(`Error getting price for selected tier ${tier!.name}...`);
        }
    }

    private handleSave() {
        if (!this.product.validate()) return;
        this.props.handleSave(this.product, this.parentProduct);
    }
}

export default withStyles(globalStyles)(EditProductDialog);
