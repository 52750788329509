import * as React from 'react';
import './styles.css';
import { Header } from '../Header/Header';
import { observer, inject } from 'mobx-react';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import Save from '@material-ui/icons/Save';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { SalesStore } from '../../stores/sales-store';
import { RouteComponentProps } from 'react-router';
import { UiStore } from '../../stores/ui-store';
import OrderForm from './OrderForm';
import tracker from '../Loader/loader-tracker';
import { Loader } from '../Loader/Loader';
import { ActionItem } from '../ActionsMenu/ActionItem';
import SubmitOrderDialog from './SubmitOrderDialog';
import { SubmitWorkflowDialog } from '../OrderWorkflows/SubmitWorkflowDialog';
import { OrderWorkflow, DeliveryType } from '../../models/order';
import { OrderUiState } from '../../stores/ui-state/order-ui-state';
import { withChangesPrompt } from '../Prompt/Prompt';
import SubmitOrderWithPoDialog, { SubmitType } from './SubmitOrderWithPoDialog';
import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface INewOrderProps {
  salesStore?: SalesStore;
  uiStore?: UiStore;
}
interface MatchParams {
  id: string;
}
type Props = INewOrderProps & RouteComponentProps<MatchParams>;

@inject('salesStore', 'uiStore')
@observer
class NewOrder extends React.Component<Props> {
  private salesStore: SalesStore;
  private uiStore: UiStore;
  private orderUiState: OrderUiState;
  private get isCloning() {
    return !!this.props.match.params.id;
  }

  constructor(props: Props) {
    super(props);
    this.salesStore = props.salesStore!;
    this.uiStore = props.uiStore!;
    this.orderUiState = props.uiStore!.orderUiState;
    this.handleCustomPricing = this.handleCustomPricing.bind(this);
    this.handleInDispute = this.handleInDispute.bind(this);
    this.handleSubmitQuote = this.handleSubmitQuote.bind(this);
    this.handleSubmitOrder = this.handleSubmitOrder.bind(this);
    this.handleConfirmSubmitOrder = this.handleConfirmSubmitOrder.bind(this);
    this.props.salesStore!.createNewOrder();
    this.uiStore.orderUiState.initNewOrder(this.salesStore.order);
  }

  public async componentDidMount() {
    if (this.isCloning) {
      const orderNumber = this.props.match.params.id;
      this.orderUiState.setFormReadOnly(true);
      const order = await tracker.track(this.props.salesStore!.loadOrderAsClone(orderNumber));
      this.orderUiState.initCloneOrder(order);
    }
  }

  public render() {
    const getTitle = () => {
      const { order } = this.props.salesStore!;
      return order.orderWorkflow === OrderWorkflow.CloningOrder ? `Cloning Order (${order.clonedFrom})` : 'New Order';
    };
    return (
      <React.Fragment>
        <div className="content">
          <Header title={getTitle()} />
          <OrderForm clonedFrom={this.props.match.params.id} />
          <ActionsMenu>
            <Loader area="new-order-actions" />
            {this.salesStore.order.hasCustomPricing && (
              <ActionItem label="Submit custom pricing" onClick={this.handleCustomPricing}>
                <ArrowForward />
              </ActionItem>
            )}
            {!this.salesStore.order.hasCustomPricing && (
              <ActionItem label="Save as quote" onClick={() => this.handleSubmitQuote('new-order-actions')}>
                <Save />
              </ActionItem>
            )}
            {!this.salesStore.order.hasCustomPricing && (
              <ActionItem label="Save as order" onClick={this.handleConfirmSubmitOrder}>
                <ArrowForward />
              </ActionItem>
            )}
          </ActionsMenu>
        </div>
        {this.orderUiState.orderSubmissionDialogActive && (
          <SubmitOrderDialog
            onSubmit={() =>
              !this.salesStore.order.isInDispute
                ? this.handleSubmitOrder('submit-order-dialog')
                : this.handleSubmitQuote('submit-order-dialog')
            }
            onClose={() => this.orderUiState.setOrderSubmissionDialogActive(false)}
            submitType={SubmitType.Submit}
          />
        )}
        {this.orderUiState.orderPoSubmissionDialogActive && (
          <SubmitOrderWithPoDialog
            onSubmit={() =>
              !this.salesStore.order.isInDispute
                ? this.handleSubmitOrder('submit-order-po-dialog')
                : this.handleSubmitQuote('submit-order-po-dialog')
            }
            onClose={() => this.orderUiState.setOrderPoSubmissionDialogActive(false)}
            submitType={SubmitType.Submit}
          />
        )}
        {this.orderUiState.customPricingDialogActive && (
          <SubmitWorkflowDialog
            onSubmit={() => this.handleSubmitQuote('submit-workflow-dialog')}
            onClose={() => this.orderUiState.setCustomPricingDialogActive(false)}
            prompt="Your order includes custom pricing, a manager will need to approve this before it can be processed"
            title="Custom Pricing"
            workflow={this.salesStore.order.customPricing}
          />
        )}
      </React.Fragment>
    );
  }

  private handleConfirmSubmitOrder() {
    const { order } = this.salesStore;
    order.setOrderWorkflow(OrderWorkflow.TransitioningToOrder);
    if (!order.validateAsOrder()) {
      if (order.requiresTbaConfirmation) this.orderUiState.setFormReadOnly(false);
      return;
    }
    if (order.deliveryType === DeliveryType.FreightOnly) this.orderUiState.setOrderSubmissionDialogActive(true);
    else this.orderUiState.setOrderPoSubmissionDialogActive(true);
  }

  private handleCustomPricing() {
    if (!this.salesStore.order.validate()) return;

    this.orderUiState.setCustomPricingDialogActive(true);
  }

  private handleInDispute() {
    if (!this.salesStore.order.validate()) return;

    this.orderUiState.setInDisputeDialogActive(true);
  }

  private async handleSubmitQuote(areaName: string) {
    const ok = await tracker.track(this.salesStore.order.saveQuote(this.uiStore.notificationUiState), areaName);
    if (ok) this.props.history.push(`/view-order/${this.salesStore.order.orderNumber}`);
  }

  private async handleSubmitOrder(areaName: string) {
    const ok = await tracker.track(this.salesStore.order.saveOrder(this.uiStore.notificationUiState), areaName);
    if (ok) this.props.history.push(`/view-order/${this.salesStore.order.orderNumber}`);
    else {
      if (this.salesStore.order.requiresTbaConfirmation) {
        this.orderUiState.setFormReadOnly(false);
      }
    }
  }

  public componentWillUnmount() {
    this.uiStore.setActionsMenuActive(false);
    this.uiStore.orderUiState.setOrderSubmissionDialogActive(false);
    this.uiStore.orderUiState.setOrderPoSubmissionDialogActive(false);
    this.uiStore.orderUiState.setCustomPricingDialogActive(false);
    this.uiStore.orderUiState.setInDisputeDialogActive(false);
  }
}

export default withChangesPrompt(withAITracking(reactPlugin, NewOrder, 'NewOrder'));
