import { Model } from './model';
import { observable, action, runInAction, computed } from 'mobx';
import { DateTime } from 'luxon';

export enum OrderStatus {
    None = 0,
    Quote = 1,
    Order = 2
}

export class OrdersFilter extends Model {

    @observable public customerId: number = 0;
    @observable public address: string = '';
    @observable public siteContact: string = '';
    @observable public filterByRep: boolean = true;
    @observable public orderStatus: OrderStatus = OrderStatus.Order;
    @observable public startDate: DateTime | null = null;
    @observable public endDate: DateTime | null = null;
    @observable public customerRef: string = '';
    @observable public phoneNumber: string = '';
    @observable public repCode: string = '';
    @observable public leadIdentifier: string = '';
    @observable public datesRequired: boolean = true;

    constructor(customerId: number, filterByRep: boolean, orderStatus: OrderStatus, address: string, siteContact: string,
        startDate: DateTime | null, endDate: DateTime | null, customerRef: string | '', phoneNumber: string | '', ownerRepCode: string | '', leadIdentifier: string | '') {
        super();
        runInAction(() => {
            this.customerId = customerId;
            this.filterByRep = filterByRep;
            this.orderStatus = orderStatus;
            this.address = address;
            this.siteContact = siteContact;
            this.startDate = startDate;
            this.endDate = endDate;
            this.customerRef = customerRef;
            this.phoneNumber = phoneNumber;
            this.repCode = ownerRepCode;
            this.leadIdentifier = leadIdentifier;
        });
    }

    @action
    public setCustomerRef(customerRef: string){
        this.customerRef = customerRef;
    }

    @action
    public setLeadIdentifier(leadIdentifier: string) {
        this.leadIdentifier = leadIdentifier;
    }

    @action
    public setOrderStatus(status: OrderStatus) {
        this.orderStatus = status;
    }

    @action
    public setFilterByRep(active: boolean) {
        this.filterByRep = active;
    }

    @action
    public setStartDate(date: DateTime | null) {
        this.updateAndValidate('startDate', date ? date.startOf('day') : null);
    }

    @action
    public setEndDate(date: DateTime | null) {
        this.updateAndValidate('endDate', date ? date.endOf('day') : null);
    }

    @action
    public setCustomerId(customerId: number) {
        this.customerId = customerId;
    }

    @action
    public setAddress(address: string) {
        this.address = address;
        console.log("Set address", address);
    }

    @action
    public setSiteContact(siteContact: string) {
        this.siteContact = siteContact;
    }

    @action
    public setOwnerRepCode(repCode: string) {
        this.repCode = repCode;
    }

    @action
    public setPhoneNumber(phoneNumber: string) {
        this.phoneNumber = phoneNumber;
    }

    @action
    public setDatesRequired(required: boolean) {
        this.datesRequired = required;
    }

    @computed
    public get isFiltered(): boolean {
        return this.customerId !== 0
            || this.address !== ''
                || this.siteContact !== ''
                    || (this.startDate !== null && this.endDate !== null)
                    || !!this.customerRef
                    || this.leadIdentifier !== ''
                    || this.repCode !== ''
                    || this.phoneNumber !== '';
    }

    public clone() {
        return new OrdersFilter(this.customerId, this.filterByRep, this.orderStatus, this.address, this.siteContact, this.startDate, this.endDate, this.customerRef, this.phoneNumber, this.repCode, '');
    }

    public static toJson(filter: OrdersFilter) {
        return JSON.stringify({
            ...filter,
            dateRange: (filter.startDate && filter.endDate)
                ? { startDate: filter.startDate, endDate: filter.endDate }
                : null
        });
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "startDate": (val: DateTime) => {
            if (!this.endDate) return true;
            this.errors.startDate = this.datesRequired === false || val
                ? null : "Date from is required";
            if(!this.errors.startDate) {
                this.errors.startDate = this.endDate && this.endDate > val
                    ? null : "From date cannot be greater than To date";
            }
            return this.errors.startDate === null;
        },
        "endDate": (val: DateTime) => {
            if (!this.startDate) return true;
            this.errors.endDate = this.datesRequired === false || val
                ? null : "Date to is required";
            return this.errors.endDate === null;
        }
    }
}
