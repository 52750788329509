import './styles.css';

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { Save, Edit } from '@material-ui/icons';
import { Grid, Switch, FormControlLabel } from '@material-ui/core';
import { observable, action, runInAction } from 'mobx';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { Header } from '../Header/Header';
import tracker from '../Loader/loader-tracker';
import { SettingsStore } from 'src/stores/settings-store';
import { withChangesPrompt } from '../Prompt/Prompt';
import { ActionsMenu } from '../ActionsMenu/ActionsMenu';
import { ActionItem } from '../ActionsMenu/ActionItem';
import { UiStore } from 'src/stores/ui-store';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface ISettingsProps {
  settingsStore?: SettingsStore;
  uiStore?: UiStore;
}

@inject('settingsStore', 'uiStore')
@observer
class Settings extends React.Component<ISettingsProps> {
  @observable private readonly: boolean;

  constructor(props: ISettingsProps) {
    super(props);
    this.handleSwitchChange = this.handleSwitchChange.bind(this);
    this.handleSave = this.handleSave.bind(this);
    this.toggleReadonly = this.toggleReadonly.bind(this);
    runInAction(() => (this.readonly = true));
  }

  public async componentDidMount() {
    await tracker.track(this.props.settingsStore!.load());
  }

  public render() {
    const { settings } = this.props.settingsStore!;
    return (
      <React.Fragment>
        <Header title="Settings" hideTitleForDesktop />
        <Grid container className="section settings">
          <Grid item xs={1} />
          <Grid item xs={11}>
            <h2>Invoicing</h2>
            <p>Only send invoices up to June 30</p>
            <FormControlLabel
              control={
                <Switch
                  checked={settings.stopInvoicing}
                  disabled={this.readonly}
                  onChange={(event, checked) => this.handleSwitchChange('stopInvoicing', checked)}
                  value={true}
                />
              }
              label={settings.stopInvoicing ? 'ON' : 'OFF'}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <h2>Stop Freight to Store Automation</h2>
            <p />
            <FormControlLabel
              control={
                <Switch
                  checked={settings.stopStoreToFreight}
                  disabled={this.readonly}
                  onChange={(event, checked) => this.handleSwitchChange('stopStoreToFreight', checked)}
                  value={true}
                />
              }
              label={settings.stopStoreToFreight ? 'ON' : 'OFF'}
            />
          </Grid>
          <Grid item xs={1} />
          <Grid item xs={11}>
            <h2>Stop Fuel Levy</h2>
            <p />
            <FormControlLabel
              control={
                <Switch
                  checked={settings.stopFuelLevy}
                  disabled={this.readonly}
                  onChange={(event, checked) => this.handleSwitchChange('stopFuelLevy', checked)}
                  value={true}
                />
              }
              label={settings.stopFuelLevy ? 'ON' : 'OFF'}
            />
          </Grid>
        </Grid>
        <ActionsMenu>
          {!this.readonly && (
            <ActionItem label="Save changes" onClick={this.handleSave}>
              <Save />
            </ActionItem>
          )}
          {this.readonly && (
            <ActionItem label="Edit settings" onClick={this.toggleReadonly}>
              <Edit />
            </ActionItem>
          )}
        </ActionsMenu>
      </React.Fragment>
    );
  }

  private handleSwitchChange(property: string, value: boolean) {
    this.props.settingsStore!.settings.update(property, value);
  }

  private async handleSave() {
    const { uiStore, settingsStore } = this.props;
    const { notificationUiState } = this.props.uiStore!;
    if (!settingsStore!.settings.validate()) return;
    const ok = await tracker.track(settingsStore!.save());
    if (ok) {
      uiStore!.setActionsMenuActive(false);
      this.toggleReadonly();
      notificationUiState.showInfo('Saved settings');
    } else {
      notificationUiState.showError('Error saving settings...');
    }
  }

  @action
  private toggleReadonly() {
    this.readonly = !this.readonly;
  }
}

export default withChangesPrompt(withAITracking(reactPlugin, Settings, 'Settings'));
