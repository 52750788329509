import { inject, observer } from "mobx-react";
import { globalStyles } from 'src/theme';
import { withStyles, WithStyles } from '@material-ui/core';
import * as React from 'react';
import { ProductsStore } from 'src/stores/products-store';
import MUIDataTable, { MUIDataTableColumnDef } from "mui-datatables";

export interface IPriceListProps extends WithStyles<typeof globalStyles> {
    productsStore?: ProductsStore;
}

@inject('productsStore')
@observer
class PriceList extends React.Component<IPriceListProps> {
  
    private hiddenCols = ['Purchase Unit', '# Per Pallet', 'Weight (kg)'];

    constructor(props: IPriceListProps) {
        super(props);
    }

    public render() {
        const { productsStore } = this.props;
        if (productsStore!.priceList.exWorksId === 0) return <React.Fragment />;

        return (

        <MUIDataTable
                title={"Prices (ex-GST)"}
                data={productsStore!.priceList.pricing}
                columns={productsStore!.priceList.headerRow.map(c => {
                    return {
                        name: c,
                        options: {
                            display: String(!this.hiddenCols.some(h => h === c))
                        }
                    } as MUIDataTableColumnDef
                })}
                options={{
                    downloadOptions: { filename: `${productsStore!.priceList.exWorks}-${productsStore!.priceList.region}-Pricing-exGST.csv`, separator: ',' },
                    elevation: 0,
                    rowsPerPage: 100,
                    selectableRows: false,
                    print: false,
                    responsive: 'scroll'
                }}
            />
        );
    }
}

export default withStyles(globalStyles)(PriceList);