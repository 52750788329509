import * as React from 'react';
import { CircularProgress } from '@material-ui/core';
import tracker from './loader-tracker';
import { observer } from 'mobx-react';
import './styles.css';

interface ILoaderProps {
    area?: string;
    message?: string;
}
@observer
export class Loader extends React.Component<ILoaderProps> {
    private area: string;
    private divelement: HTMLDivElement;

    constructor(props: ILoaderProps) {
        super(props);
        this.area = props.area ? props.area : 'main';
    }

    public componentDidUpdate() {
        // we should not be using this class name. need to pass it in
        const fullDialog = document.getElementsByClassName('full-dialog');
        const height: string | null = fullDialog.length === 0 ? '100%' : `${fullDialog[0].scrollHeight}px`;
        if (this.divelement) {
            this.divelement.style.height = height;
        }
    }

    public render() {
        return !tracker.complete(this.area) ? (
            <div ref={ref => (this.divelement = ref!)} className={`loading-panel ${this.area}`}>
                {this.props.message && <div className='message'>{this.props.message}</div>}
                <CircularProgress className='loader' />
            </div>
        ) : null;
    }

    public componentWillUnmount() {
        tracker.deleteTracker(this.area);
    }
}
