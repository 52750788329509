import { Model } from './model';
import { DateTime } from 'luxon';
import { Validate } from '../validate';
import * as EmailValidator from 'email-validator';
import { observable, action, computed, runInAction } from 'mobx';
import {
  IOrderResponse,
  SalesApi,
  IShareQuoteEventPayload,
  IPurchaseOrderFailurePayload,
  IPurchaseOrderSuccessPayload,
  IConsignmentNotesPayload,
  ISentToDispatchPayload,
  IWorkflowApproval,
} from '../services/sales-api';
import { ServiceLine } from './service-line';
import { ProductsApi, IPricingResponse, CalculationResult } from '../services/products-api';
import { ProductLine } from './product-line';
import { PaymentLine } from './payment-line';
import { NotificationUiState } from '../stores/ui-state/notification-ui-state';
import { utcToLocal, toISODate, isSameDateToday } from '../datetime';
import { round, roundedGst, roundedSubtotal, roundedTotal } from '../math';
import { Workflow } from './workflow';
import { QuoteCancellation } from './quote-cancellation';
import { WorkflowStatus } from './workflow-status';
import { QuoteShared } from './quote-shared';
import { getClaimValue } from '../utilities/msalConfig';
import { ICustomerSummary } from '../stores/customers-store';
import { WorkflowType } from './workflow-type';
import { RelatedOrder } from './related-order';
import { FreightOnly } from './freight-only';
import { Delivery } from './delivery';
import { Pickup } from './pickup';
import { Refund } from './refund';
import { ILatLng, LocationType } from './address';
import { SuggestionItem } from 'src/components/Autocomplete/Autocomplete';
import { SupplierCommentLine } from './supplier-comment-line';
import { IExWorksDetail } from 'src/stores/products-store';
import { SettingsApi } from 'src/services/settings-api';

export class Order extends Model {
  public readonly bodyTruckCode: string = 'B T';
  public readonly deliveryCode: string = 'D';
  private readonly freightToStoreCode: string = 'FTS';
  private readonly onlineStockingPoint: string = 'OL';
  private readonly sixTonnesInKg: number = 6000;
  private readonly oneAndHalfTonnesInKg: number = 1500;
  public readonly fuelLevyProductCode: string = 'ZFUELLEVYPDT';
  public readonly fuelLevyFreightCode: string = 'ZFUELLEVYTPT';

  @observable private bodyTruckService: ServiceLine = new ServiceLine(0, 0, '', this.bodyTruckCode, 0, '');
  @observable public canSplit: boolean = false;
  @observable private deliveryService: ServiceLine | null;
  @observable public fuelLevyService: ServiceLine | null;
  @observable private maxOrderAmountForCustomer: number | null;
  @observable private isCustomerInDispute: boolean = false;
  @observable public firstPickupEtaLocal: DateTime | null = null;
  @observable public firstPickupEtaUtc: DateTime | null = null;
  @observable public freightToStoreService: ServiceLine | null;
  @observable public id: number = 0;
  @observable public parentOrder: RelatedOrder | null;
  @observable public parentOrderCopy: RelatedOrder | null;
  @observable public splitOrders: RelatedOrder[] = [];
  @observable public orderNumber: string = '';
  @observable public customer: number = 0;
  @observable public isCashSalesAccount: boolean = false;
  @observable public isActiveCustomer: boolean = false;
  @observable public isOnlineStore: boolean = false;
  @observable public isAbcFrieghtJobsAccount: boolean = false;
  @observable public customerName: string = '';
  @observable public customerRef: string = '';
  @observable public leadIdentifier: string = '';
  @observable public name: string = '';
  @observable public phone: string = '';
  @observable public email: string = '';
  @observable public deliveryType: DeliveryType = DeliveryType.Delivery;
  @observable public delivery: Delivery = new Delivery(this);
  @observable public pickup: Pickup = new Pickup(this);
  @observable public freightOnly: FreightOnly = new FreightOnly(this);
  @observable public isFreightOnly: boolean;
  @observable public serviceLines: ServiceLine[] = [];
  @observable public productLines: ProductLine[] = [];
  @observable public paymentLines: PaymentLine[] = [];
  @observable public supplierCommentLines: SupplierCommentLine[] = [];
  @observable public status: OrderStatus = OrderStatus.Quote;
  @observable public createdDate: DateTime | null = null;
  @observable public modifiedDate: DateTime | null = null;
  @observable public owner: string = '';
  @observable public quoteExpiryDate: DateTime | null = null;
  @observable public note: string = '';
  @observable public customerAdjustment: number = 0;
  @observable public fuelLevyAdjustment: number = 0;
  @observable public fuelLevyServicePrice: number = 0;
  @observable public customPricing: Workflow = new Workflow(WorkflowType.CustomPricing);
  @observable public customerInDispute: Workflow = new Workflow(WorkflowType.InDispute);
  @observable public orderCancellation: Workflow = new Workflow(WorkflowType.OrderCancellation);
  @observable public quoteCancellation: QuoteCancellation = new QuoteCancellation();
  @observable public quoteShared: QuoteShared = new QuoteShared();
  @observable public active: boolean = true;
  @observable public hasErrors: boolean = false;
  @observable public events: IOrderEvent[] = [];
  @observable public orderWorkflow: OrderWorkflow = OrderWorkflow.None;
  @observable public totalCost: number = 0;
  @observable public stockingPoint: string = getClaimValue<string>('extn.stockingPoint') || '';
  @observable public clonedFrom: string = '';
  @observable public lifetimeStatus: OrderLifetimeStatus = OrderLifetimeStatus.None;
  @observable public refund: Refund = new Refund(this);
  @observable public orderRefund: Workflow = new Workflow(WorkflowType.Refund);
  @observable public purchaseOrderPending: boolean = false;
  @observable public previousTotalWeight: number = -1;
  @observable public autoDeliveryRemoved: boolean = false;
  public lastModifiedTimeStamp?: string;
  private readonly productsApi: ProductsApi;
  private readonly saleApi: SalesApi;
  private readonly settingsApi: SettingsApi;

  constructor(productsApi: ProductsApi, saleApi: SalesApi, settingsApi: SettingsApi) {
    super();
    this.productsApi = productsApi;
    this.saleApi = saleApi;
    this.settingsApi = settingsApi;
  }

  public static cancellationReasons: Array<{ name: string; value: string }> = [
    { name: 'Lost to a competitor', value: 'Lost to a competitor' },
    { name: 'Project cancelled/delayed', value: 'Project cancelled/delayed' },
    { name: 'Customer chose another product', value: 'Customer chose another product' },
    { name: 'Ordered on another docket', value: 'Ordered on another docket' },
    { name: 'Balance not required', value: 'Balance not required' },
    { name: 'Other', value: 'Other' },
  ];

  @action
  public addPaymentLine(payment: PaymentLine) {
    this.paymentLines.push(payment);
  }

  @action
  public addServiceLine(service: ServiceLine): boolean {
    service.lineNumber = this.nextServiceLineNumber;
    this.serviceLines.push(service);
    this.validateProductAndSeviceLines();
    return true;
  }

  @action
  public addSupplierCommentLine(
    supplierComment: SupplierCommentLine,
    notificationUiState: NotificationUiState,
  ): boolean {
    if (this.supplierCommentExists(supplierComment)) {
      notificationUiState.showInfo(`${supplierComment.supplierName} already added to order.`);
      return false;
    }
    this.supplierCommentLines.push(supplierComment);
    return true;
  }

  @action
  public async addProductLine(product: ProductLine, notificationUiState: NotificationUiState) {
    if (!this.productsFromSameSupplierExWorks(product)) {
      notificationUiState.showInfo('Cannot add products from different ex-works for the same supplier...');
      return;
    }

    try {
      const latLng = this.getAddressForPricing();
      const price = await this.productsApi!.getProductPriceDetails(
        latLng.lat!,
        latLng.lng!,
        product.productId,
        product.exWorksId,
        this.customer,
      );
      if (!price) return;
      if (
        price.calculationResult === CalculationResult.Ok ||
        price.calculationResult === CalculationResult.UnknownRegion
      ) {
        runInAction(() => {
          product.setInitialPrice(price.price);
          product.setTier(price.tierId);
          product.setExWorksPrice(price.exWorksPrice);
          product.setFreightCost(price.freightCost);

          if (this.productLines.length > 0) {
            product.lineNumber = Math.max(...this.productLines.map((x) => x.lineNumber)) + 1;
          } else {
            product.lineNumber = 0;
          }
          this.productLines.push(product);
          this.validateProductAndSeviceLines();
        });
      }
      this.handlePricingResponses([price], notificationUiState);
    } catch (e) {
      console.error(e);
      notificationUiState.showError('Error getting price...');
    }
  }

  @action
  public async checkProductWeights(notificationUiState: NotificationUiState) {
    if (this.isRefunding) return;
    if (this.requiresExtraDeliveryFee) {
      if (this.deliveryService) return;
      if (this.totalWeight === this.previousTotalWeight) return;
      const service = await this.getAutoAddService(this.deliveryCode);
      if (this.autoDeliveryRemoved) {
        notificationUiState.onConfirmationOk(() => {
          runInAction(() => {
            this.deliveryService = service;
            this.autoDeliveryRemoved = false;
            if (this.addServiceLine(service)) {
              notificationUiState.showInfo('Delivery service added. Products for delivery under 6 tonnes.');
              this.previousTotalWeight = this.totalWeight;
            }
          });
        });
        notificationUiState.showQuestion('Do you want to add the delivery service for products under 6 tonnes?');
        return;
      } else {
        runInAction(() => {
          this.deliveryService = service;
          if (this.addServiceLine(service)) {
            notificationUiState.showInfo('Delivery service added. Products for delivery under 6 tonnes.');
            this.previousTotalWeight = this.totalWeight;
          }
        });
      }
      return;
    }
    if (this.requiresFreightToStoreFee) {
      const appSettings = await this.settingsApi.get();
      if (appSettings.stopStoreToFreight || this.freightToStoreService) return;

      const service = await this.getAutoAddService(this.freightToStoreCode);
      runInAction(() => {
        this.freightToStoreService = service;
        if (this.addServiceLine(service))
          notificationUiState.showInfo('Freight to Store service. Products for ABC pickup under 1.5 tonnes');
      });
      return;
    }
    if (this.exceedsExtraDeliveryFeeRequirement) {
      if (this.previousTotalWeight === this.totalWeight) return;

      // Check if manually added service delivery exists
      const manualServiceDelivery = this.serviceLines.filter((s) => s.code === this.deliveryCode && !s.isAutoAdded);

      if (manualServiceDelivery.length > 0) {
        notificationUiState.onConfirmationOk(() => {
          runInAction(() => {
            const index = this.serviceLines.findIndex((s) => s.code === this.deliveryCode && !s.isAutoAdded);
            this.serviceLines.splice(index, 1);
          });
        });
        notificationUiState.showQuestion('Do you want to remove the delivery service for products over 6 tonnes?');
        return;
      } else {
        this.removeServiceLineByCode(this.deliveryCode);
        notificationUiState.showInfo('Delivery service removed. Products for delivery over 6 tonnes.');
        this.previousTotalWeight = this.totalWeight;
        return;
      }
    }
    if (this.exceedsFreightToStoreRequirement) {
      if (this.previousTotalWeight === this.totalWeight) return;

      this.removeServiceLineByCode(this.freightToStoreCode);
      notificationUiState.showInfo('Freight to Store service removed. Products for ABC pickup over 1.5 tonnes');
      this.previousTotalWeight = this.totalWeight;
      return;
    }
  }

  @action
  public async calculateFuelLevy() {
    if (this.isRefunding) return;
    const appSettings = await this.settingsApi.get();
    if (appSettings.stopFuelLevy) return;
    if (this.isOnlineStore) {
      this.clearFuelLevy();
      return;
    }
    if (this.deliveryType === DeliveryType.FreightOnly) {
      const service = await this.getAutoAddService(this.fuelLevyFreightCode);
      runInAction(() => {
        this.fuelLevyService = service;
        this.fuelLevyServicePrice = service.price;
      });
      return;
    } else if (this.deliveryType === DeliveryType.Delivery || this.deliveryType === DeliveryType.PickUp) {
      const service = await this.getAutoAddService(this.fuelLevyProductCode);
      runInAction(() => {
        this.fuelLevyService = service;
        this.fuelLevyServicePrice = service.price;
      });
    }
    return;
  }

  @action
  public clearFuelLevy() {
    this.fuelLevyService = null;
    this.fuelLevyServicePrice = 0;
  }

  @action
  public async approveWorkflow(saveAsQuoteForInDispute: boolean = false): Promise<boolean> {
    let ok = true;
    const request: IWorkflowApproval = {
      orderNumber: this.orderNumber,
      reason: this.activeWorkflow!.reason,
    };
    switch (this.activeWorkflow!.workflowType) {
      case WorkflowType.OrderCancellation: {
        ok = await this.saleApi.approveOrderCancellation(request);
        if (ok) runInAction(() => (this.active = false));
        break;
      }
      case WorkflowType.CustomPricing: {
        ok = await this.saleApi.approveCustomPricing(request);
        if (ok) runInAction(() => this.productLines.forEach((p) => p.setAcceptedPrice(p.price)));
        break;
      }
      case WorkflowType.InDispute: {
        this.status = saveAsQuoteForInDispute ? OrderStatus.Quote : OrderStatus.Order;
        ok = await this.saleApi.approveCustomerInDispute(this, this.activeWorkflow!.reason);
        break;
      }
      case WorkflowType.Refund: {
        ok = await this.saleApi.approveOrderRefund(request);
        break;
      }
    }
    this.activeWorkflow!.reset();
    return ok;
  }

  @action
  public async rejectWorkflow(): Promise<boolean> {
    let ok = true;
    const request = {
      orderNumber: this.orderNumber,
      reason: this.activeWorkflow!.reason,
      lastModifiedTimeStamp: this.lastModifiedTimeStamp,
    };
    switch (this.activeWorkflow!.workflowType) {
      case WorkflowType.OrderCancellation:
        ok = await this.saleApi.rejectOrderCancellation(request);
        break;
      case WorkflowType.CustomPricing:
        ok = await this.saleApi.rejectCustomPricing(request);
        if (ok) runInAction(() => this.productLines.forEach((p) => p.setAcceptedPrice(p.acceptedPrice)));
        break;
      case WorkflowType.InDispute:
        ok = await this.saleApi.rejectCustomerInDispute(request);
        break;
      case WorkflowType.Refund:
        ok = await this.saleApi.rejectOrderRefund(request);
        break;
    }
    this.activeWorkflow!.reset();
    return ok;
  }

  @action
  public async submitCancellation(): Promise<boolean> {
    if (!this.orderCancellation.validate()) return false;
    this.orderCancellation.setStatus(WorkflowStatus.PendingApproval);
    return await this.saleApi.submitOrderCancellation({
      orderNumber: this.orderNumber,
      reason: this.orderCancellation.reason,
    });
  }

  @action
  public async checkTruckType(truckType: TruckType, notificationUiState: NotificationUiState) {
    if (truckType === TruckType.BodyTruck) {
      if (!this.serviceLines.find((s) => s.code === this.bodyTruckCode)) {
        if (this.bodyTruckService.id === 0) {
          const bodyTruckService = await this.getAutoAddService(this.bodyTruckCode);
          runInAction(() => {
            this.bodyTruckService = bodyTruckService;
          });
        }

        runInAction(() => {
          this.serviceLines.push(
            new ServiceLine(
              0,
              this.bodyTruckService.serviceId,
              this.bodyTruckService.name,
              this.bodyTruckService.code,
              this.bodyTruckService.price,
              this.bodyTruckService.purchaseUnit,
              this.nextServiceLineNumber,
              '',
              1,
              true,
              1,
              this.isSplitting,
              this.bodyTruckService.chosenUnitWeight,
              this.bodyTruckService.chosenNoOfPacks,
              this.bodyTruckService.chosenNoOfPallets,
              this,
            ),
          );
          notificationUiState.showInfo('Body Truck service added');
        });
      }
    } else {
      this.removeServiceLineByCode(this.bodyTruckCode);
      notificationUiState.showInfo('Body Truck service removed');
    }
  }

  @action
  private removeServiceLineByCode(code: string) {
    this.serviceLines = this.serviceLines.filter((s) => s.code !== code);
    if (this.deliveryService) if (this.deliveryService.code === code) this.deliveryService = null;
  }

  @action
  public async saveQuote(notificationUiState: NotificationUiState): Promise<boolean> {
    this.status = OrderStatus.Quote;
    return this.save(this.saleApi.saveOrder, notificationUiState);
  }

  @action
  public async updateQuote(notificationUiState: NotificationUiState): Promise<boolean> {
    this.status = OrderStatus.Quote;
    return this.save(this.saleApi.updateOrder, notificationUiState);
  }

  @action
  public async updateOrder(notificationUiState: NotificationUiState): Promise<boolean> {
    this.status = OrderStatus.Order;
    return this.save(this.saleApi.updateOrder, notificationUiState);
  }

  @action
  public async saveOrder(notificationUiState: NotificationUiState): Promise<boolean> {
    this.status = OrderStatus.Order;
    return this.save(this.saleApi.saveOrder, notificationUiState);
  }

  @action
  public async splitOrder(notificationUiState: NotificationUiState): Promise<boolean> {
    return this.save(this.saleApi.splitOrder, notificationUiState);
  }

  @action
  public async cancelQuote(): Promise<boolean> {
    if (!this.quoteCancellation.validate()) return false;
    return await this.saleApi.cancelQuote(this.orderNumber, this.quoteCancellation);
  }

  @action
  public async submitRefund(notificationUiState: NotificationUiState): Promise<boolean> {
    if (!this.validate()) return false;
    try {
      await this.saleApi.submitRefund(this);
      notificationUiState.showInfo(`Refund request submitted for ${this.orderNumber}`);
      return true;
    } catch (error) {
      console.error(error);
      notificationUiState.showError('Error saving...');
    }
    return false;
  }

  @action
  public async manuallyCompleteOrder(orderNumber: string): Promise<boolean> {
    const result = await this.saleApi.manuallyCompleteOrder(orderNumber);
    return result;
  }

  @action
  public async resendInvoice(orderNumber: string): Promise<boolean> {
    const result = await this.saleApi.resendInvoice(orderNumber);
    return result;
  }

  @action
  public async confirmProductCheck(orderNumber: string): Promise<boolean> {
    const result = await this.saleApi.confirmProductCheck(orderNumber);
    return result;
  }

  @action
  public async shareQuote(notificationUiState: NotificationUiState, asInvoice: boolean): Promise<boolean> {
    if (!this.quoteShared.validate()) return false;
    let ok = false;
    try {
      ok = await this.saleApi.shareQuote(this.orderNumber, this.quoteShared, asInvoice);
      if (ok) notificationUiState.showInfo(`Shared quote ${this.orderNumber} with ${this.quoteShared.email}`);
    } catch (e) {
      console.error(e);
      notificationUiState.showError('Error sharing quote');
      return false;
    }
    return ok;
  }

  public async downloadQuote(
    notificationUiState: NotificationUiState,
    asInvoice: boolean = false,
  ): Promise<Blob | null> {
    try {
      notificationUiState.showInfo(`Generating ${asInvoice ? 'invoice' : 'quote'}. This may take a moment...`);
      return await this.saleApi.downloadQuote(this.orderNumber, asInvoice);
    } catch (e) {
      console.error(e);
      notificationUiState.showError(`Error downloading ${asInvoice ? 'invoice' : 'quote'}`);
      return null;
    }
  }

  public async downloadOrderConfirmation(notificationUiState: NotificationUiState): Promise<Blob | null> {
    try {
      notificationUiState.showInfo(`Generating order confirmation. This may take a moment...`);
      return await this.saleApi.downloadOrderConfirmation(this.orderNumber);
    } catch (e) {
      console.error(e);
      notificationUiState.showError(`Error downloading Order confirmation.`);
      return null;
    }
  }

  private async save(
    func: (order: Order) => Promise<boolean | string>,
    notificationUiState: NotificationUiState,
  ): Promise<boolean> {
    if (!this.validate()) return false;
    try {
      const result = await func(this);

      const type = this.status === OrderStatus.Order ? 'order' : 'quote';

      let orderNumber = this.orderNumber;
      if (!this.orderNumber && typeof result === 'string') {
        orderNumber = result;
        runInAction(() => (this.orderNumber = orderNumber));
      }

      notificationUiState.showInfo(`Saved ${type} ${orderNumber}`);
      this.untrack();
      return true;
    } catch (error) {
      console.error(error);
      if (error instanceof Error) {
        const orderHasBeenModifiedInTheMeantime = error && error.message && error.message.indexOf('409') >= 0;
        let message = `Error saving...\r\n${error.message}`;
        if (orderHasBeenModifiedInTheMeantime) {
          message =
            'The order has been modified since it was last loaded in this browser window / tab. Please reload the order before saving.';
        }
        notificationUiState.showError(message);
      }
    }
    return false;
  }

  public orderSplitQtyLabel(
    parent: ProductLine | ServiceLine | undefined,
    allocatedQuantity: number,
    quantity: number,
    totalSplitableQuantity: number,
  ) {
    if (!this.isParentOrder && !this.isSplitOrder) return null;

    if (parent && this.isSplitOrder) {
      const available = Number(
        (Number((parent.totalSplitableQuantity - allocatedQuantity).toFixed(10)) - quantity).toFixed(10),
      );
      const allocated = Number((allocatedQuantity + quantity).toFixed(10));

      const allocatedLabel = allocated > parent.totalSplitableQuantity || quantity < 0 ? 'xx' : allocated.toFixed(3);
      const availableLabel = available < 0 || quantity < 0 ? 'xx' : available.toFixed(3);

      return ` Total units: ${parent.totalSplitableQuantity.toFixed(
        3,
      )}, Allocated in split: ${allocatedLabel}, Available: ${availableLabel}`;
    }
    if (this.isParentOrder) {
      return ` Total units: ${totalSplitableQuantity.toFixed(3)}, Allocated in split: ${allocatedQuantity.toFixed(
        3,
      )}, Remaining: ${quantity.toFixed(3)}`;
    }
    return null;
  }

  public quantityAvailable(parent: ProductLine | ServiceLine | undefined, allocatedQty: number, quantity: number) {
    if (!this.isParentOrder && !this.isSplitOrder) return 0;

    if (parent && this.isSplitOrder)
      return Number((parent.calculateAvailableQuantity(allocatedQty) - quantity).toFixed(10));
    if (this.isParentOrder) return quantity;
    return 0;
  }

  @action
  public split(sameDayDelivery?: boolean) {
    this.parentOrder = RelatedOrder.copyOrderToParent(this);
    this.parentOrderCopy = RelatedOrder.copyOrderToParent(this);
    this.orderNumber = `${this.orderNumber}${String.fromCharCode(65 + this.splitOrders.length)}`;
    this.id = 0;
    this.productLines.forEach((p) => p.split());
    this.productLines = this.productLines.filter((p) => p.quantityAvailable > 0);
    this.serviceLines.forEach((s) => {
      if (this.isFreightOnly || !s.excludeFromSplitOrder) {
        s.setQuantity(0);
        s.totalSplitableQuantity = 0;
      }

      if (sameDayDelivery && s.isAutoAdded)
      {
        s.totalSplitableQuantity = 0;
      }
      
    });
    this.serviceLines = this.serviceLines.filter(
      (s) => (s.quantityAvailable > 0 || s.excludeFromSplitOrder)
    );
    this.paymentLines = [];
    this.customerAdjustment = 0;
    this.setOrderWorkflow(OrderWorkflow.SplittingOrder);
  }

  @action
  public async getRegion(address: ILatLng) {
    const regionId = await this.saleApi.getRegion(address.lat!, address.lng!);
    runInAction(() => {
      if (this.deliveryType === DeliveryType.Delivery) this.delivery.regionId = regionId;
      else if (this.deliveryType === DeliveryType.PickUp) this.pickup.regionId = regionId;
    });
  }

  @action
  public async calculatePriceForAllProducLines(notificationUiState: NotificationUiState) {
    if (!this.productLines.length) return;
    const latLng = this.getAddressForPricing();
    const calls = this.productLines.map((p) =>
      this.productsApi.getProductPriceDetails(latLng.lat!, latLng.lng!, p.productId, p.exWorksId, this.customer),
    );
    try {
      await Promise.all(calls).then((values) => {
        this.handlePricingResponses(values, notificationUiState, true);
        values.forEach((p, i) => {
          this.productLines[i].setInitialPrice(p.price);
          this.productLines[i].setTier(p.tierId);
          this.productLines[i].setExWorksPrice(p.exWorksPrice);
          this.productLines[i].setFreightCost(p.freightCost);
        });
      });
    } catch (e) {
      console.error(e);
      notificationUiState.showError('Error re-calculating price...');
    }
  }

  private handlePricingResponses(
    prices: IPricingResponse[],
    notificationUiState: NotificationUiState,
    recalculated: boolean = false,
  ) {
    if (prices.every((p) => p.calculationResult === CalculationResult.Ok)) {
      if (recalculated) notificationUiState.showInfo('Prices have been re-calculated');
    } else if (prices.some((p) => p.calculationResult === CalculationResult.UnknownRegion))
      notificationUiState.showError('Cannot get price... unknown region. Enter price manually.');
    else if (prices.some((p) => p.calculationResult === CalculationResult.NoMarkupForRange))
      notificationUiState.showError('Cannot get price... no markup for range.');
    else if (prices.some((p) => p.calculationResult === CalculationResult.NoFreight))
      notificationUiState.showError('Cannot get price... no freight for region.');
    else notificationUiState.showError('Error getting price...');
  }

  @action
  public updateServiceLine(service: ServiceLine) {
    this.serviceLines = this.serviceLines.map((s) =>
      s.serviceId === service.serviceId && s.lineNumber === service.lineNumber ? service : s,
    );
  }

  @action
  public updateSupplierCommentLine(supplierComment: SupplierCommentLine) {
    this.supplierCommentLines = this.supplierCommentLines.map((s) =>
      s.exWorksId === supplierComment.exWorksId ? supplierComment : s,
    );
  }

  public getSupplierNames(value: string): Promise<Array<SuggestionItem<IExWorksDetail>>> {
    return computed(() => {
      const productLines = this.productLines;
      value = value.toLocaleLowerCase();
      return new Promise<Array<SuggestionItem<IExWorksDetail>>>((resolve, reject) => {
        const supplierNames = productLines
          .filter((x) => x.supplierName.toLocaleLowerCase().includes(value))
          .map((x) => ({
            label: x.supplierName,
            value: { name: x.exWorksName, id: x.exWorksId, supplierName: x.supplierName } as IExWorksDetail,
          }))
          .filter((elem, pos, arr) => {
            return arr.findIndex((x) => x.label === elem.label) === pos;
          });
        supplierNames.push({ label: 'Stocking Point', value: {} as IExWorksDetail });
        resolve(supplierNames);
      });
    }).get();
  }

  @computed
  public get isStockingPointOnline() {
    return this.stockingPoint === this.onlineStockingPoint;
  }

  @action
  public removeServiceLine(service: ServiceLine) {
    if (service.isAutoAdded) this.autoDeliveryRemoved = true;

    this.serviceLines = this.serviceLines
      .filter((s) => s.serviceId !== service.serviceId || s.lineNumber !== service.lineNumber)
      .slice()
      .sort((x) => x.lineNumber);
    if (this.deliveryService) if (this.deliveryService.serviceId === service.serviceId) this.deliveryService = null;
    if (!this.parentOrderCopy || !this.parentOrder || !this.isSplitting) return;
    const initalState = this.parentOrderCopy.getServiceLine(service.serviceId, service.lineNumber);
    if (initalState) this.parentOrder.updateServiceLine(initalState);
  }

  @action
  public removeSupplierComment(supplierComment: SupplierCommentLine) {
    this.supplierCommentLines = this.supplierCommentLines.filter((s) => s.exWorksId !== supplierComment.exWorksId);

    if (!this.parentOrderCopy || !this.parentOrder || !this.isSplitting) return;
    const initalState = this.parentOrderCopy.getSupplierCommentLine(supplierComment.exWorksId);
    if (initalState) this.parentOrder.updateSupplierCommentLine(initalState);
  }

  @action
  public updateProductLine(product: ProductLine) {
    this.productLines = this.productLines.map((p) =>
      p.productId === product.productId && p.lineNumber === product.lineNumber ? product : p,
    );
  }

  @action
  public removeProductLine(product: ProductLine) {
    this.productLines = this.productLines.filter(
      (p) => p.productId !== product.productId || p.lineNumber !== product.lineNumber,
    );
    this.productLines = this.productLines.slice().sort((x) => x.lineNumber);
    if (!this.parentOrderCopy || !this.parentOrder || !this.isSplitting) return;
    const initalState = this.parentOrderCopy.getProductLine(product.productId, product.lineNumber);
    if (initalState) this.parentOrder.updateProductLine(initalState);
  }

  @action
  public removePaymentLine(paymentLine: PaymentLine) {
    this.paymentLines = this.paymentLines.filter((pl) => pl !== paymentLine);
  }

  @computed get activeWorkflow(): Workflow | null {
    if (this.orderCancellation.workflowStatus === WorkflowStatus.PendingApproval) return this.orderCancellation;
    if (this.customPricing.workflowStatus === WorkflowStatus.PendingApproval) return this.customPricing;
    if (this.customerInDispute.workflowStatus === WorkflowStatus.PendingApproval) return this.customerInDispute;
    if (this.orderRefund.workflowStatus === WorkflowStatus.PendingApproval) return this.orderRefund;
    return null;
  }

  @computed
  public get orderedProductLines(): ProductLine[] {
    return this.productLines.slice().sort((x) => x.lineNumber);
  }

  @computed
  public get canCancelOrder(): boolean {
    return (
      this.active &&
      this.status === OrderStatus.Order &&
      this.orderCancellation.workflowStatus === WorkflowStatus.None &&
      !this.hasChildOrdersThatAreNotcancelledOrCompleted
    );
  }

  @computed
  public get hasActiveChildOrders(): boolean {
    const hasActiveSplits =
      this.splitOrders.find((split) => split.active && split.lifetimeStatus !== OrderLifetimeStatus.Cancelled) !==
      undefined;
    return !!this.isParentOrder && hasActiveSplits;
  }

  @computed
  public get hasChildOrdersThatAreNotcancelledOrCompleted(): boolean {
    const hasNonCancelledSplits =
      this.splitOrders.find(
        (split) =>
          split.active &&
          split.lifetimeStatus !== OrderLifetimeStatus.Cancelled &&
          split.lifetimeStatus !== OrderLifetimeStatus.Complete,
      ) !== undefined;
    return !!this.isParentOrder && hasNonCancelledSplits;
  }

  @action
  public updateCustomer(detail: ICustomerSummary) {
    this.updateAndValidate('customer', detail.id);
    this.updateAndValidate('isCashSalesAccount', detail.isCashSaleAccount);
    this.updateAndValidate('email', detail.email);
    this.updateAndValidate('isCustomerInDispute', detail.isInDispute);
    this.updateAndValidate('maxOrderAmountForCustomer', detail.maxOrderAmount);
    this.updateAndValidate('isAbcFrieghtJobsAccount', detail.isAbcFrieghtJobsAccount);
    this.updateAndValidate('phone', detail.phone);
    this.stockingPoint = detail.isOnlineStore ? 'OL' : getClaimValue<string>('extn.stockingPoint') || '';
    this.isOnlineStore = detail.isOnlineStore;
  }

  @action
  public validate(): boolean {
    const validation = [
      super.validate(),
      this.delivery.validate(),
      this.pickup.validate(),
      this.freightOnly.validate(),
      this.serviceLines.every((s) => s.validate()),
      this.productLines.every((s) => s.validate()),
      this.paymentLines.every((p) => p.validate()),
      !this.isSplitOrder || this.totalProductQtyAvailableOnParent > 0,
    ];
    const ok = validation.every((v) => v);
    this.hasErrors = !ok;
    return ok;
  }

  @computed
  public get ableToSubmitOnlineOrder(): boolean {
    if (this.isStockingPointOnline) {
      return !this.productLines.some((x) => !x.raisePurchaseOrder);
    }
    return true;
  }

  @action
  public handleErrors(func: () => void) {
    if (!this.hasErrors) return;
    func();
    this.hasErrors = false;
  }

  @action
  public validateAsOrder(): boolean {
    this.status = OrderStatus.Order;
    return this.validate();
  }

  @action
  public setOrderWorkflow(workflow: OrderWorkflow) {
    this.orderWorkflow = workflow;
  }

  @action
  public resetOrderWorkflow() {
    this.orderWorkflow = OrderWorkflow.None;
  }

  @action
  public setDeliveryType(type: DeliveryType) {
    this.deliveryType = type;
  }

  public async getProductPriceByTier(productId: number, exWorksId: number, tierId: number): Promise<IPricingResponse> {
    const latLng = this.getAddressForPricing();
    return await this.productsApi.getProductPriceDetails(
      latLng.lat!,
      latLng.lng!,
      productId,
      exWorksId,
      this.customer,
      tierId,
    );
  }

  @computed
  public get requiresTbaConfirmation() {
    return (
      this.status === OrderStatus.Order &&
      (this.delivery.errors.date || this.pickup.errors.date || this.freightOnly.errors.date)
    );
  }

  @computed
  public get statusText(): string {
    if (this.lifetimeStatus === OrderLifetimeStatus.Complete) return 'Order is completed';

    if (this.events.length > 0 && this.eventsOrderedByDate[0].eventType === OrderEventType.CustomPricingRejected)
      return 'Custom Pricing Rejected';

    if (this.events.length > 0 && this.eventsOrderedByDate[0].eventType === OrderEventType.InDisputeCustomerRejected)
      return 'Credit Approval Rejected';

    if (!this.active) return `${this.status === OrderStatus.Order ? 'Order' : 'Quote'} is cancelled`;

    if (this.lifetimeStatus < OrderLifetimeStatus.ReceiptedIntoStock && this.firstPickupEtaUtc) {
      return `Scheduled for pickup at ${this.firstPickupEtaLocal!.toFormat('yyyy-MM-dd HH:mm')}`;
    }

    switch (this.lifetimeStatus) {
      case OrderLifetimeStatus.None:
        return '';
      case OrderLifetimeStatus.PoRaised:
        return 'Purchase Order Raised';
      case OrderLifetimeStatus.AwaitingDispatch:
        return 'Awaiting Dispatch';
      case OrderLifetimeStatus.ReceiptedIntoStock:
        return 'Receipted Into Stock';
      case OrderLifetimeStatus.Delivered:
        return 'Delivered';
      case OrderLifetimeStatus.InvoiceSent:
        return 'Invoice Sent';
      case OrderLifetimeStatus.InvoiceError:
        return 'Invoice Error';
      case OrderLifetimeStatus.Cancelled:
        return 'Cancelled';
    }

    return OrderLifetimeStatus[this.lifetimeStatus];
  }

  @computed
  public get shouldRecalculatePrices(): boolean {
    if (this.deliveryType === DeliveryType.FreightOnly) return false;

    if (
      this.deliveryType === DeliveryType.PickUp &&
      !Validate.hasValue(this.pickup.address.formattedAddress) &&
      this.productLines.length
    ) {
      this.pickup.address.setError('formattedAddress', 'Provide an address to allow prices to be recaculated');
      return false;
    }
    if (
      this.deliveryType === DeliveryType.Delivery &&
      !Validate.hasValue(this.delivery.address.formattedAddress) &&
      this.productLines.length
    ) {
      this.delivery.address.setError('formattedAddress', 'Provide an address to allow prices to be recaculated');
      return false;
    }
    return true;
  }

  @computed
  public get canRaisePurchaseOrder(): boolean {
    if (this.deliveryType === DeliveryType.FreightOnly) return false;

    if (window.config.editOrderItemsEnabled) {
      return !this.isSplitOrder;
    } else {
      return this.productLines.filter((p) => p.raisePurchaseOrder).length === 0 && !this.isSplitOrder;
    }
  }

  @computed
  public get canCompletePickup(): boolean {
    return (
      window.config.enableCompletePickup &&
      this.status === OrderStatus.Order &&
      this.lifetimeStatus === OrderLifetimeStatus.AwaitingDispatch
    );
  }

  @computed
  public get canEditPurchaseOrder(): boolean {
    return (
      !window.config.enableApplyFirstPickupEta ||
      this.productLines.every((productLine) => productLine.receiptedIntoStockQuantity === 0)
    );
  }

  @computed
  public get totalPaid(): number {
    const totalBalance =
      !this.isParentOrder && this.parentOrder && this.parentOrder?.totalPaid > this.parentOrder?.totalCost
        ? this.totalCost
        : round(this.paymentLines.reduce((total, current) => total + current.amount, 0));
    
    return totalBalance;
  }

  @computed
  public get actualPaid(): number {
    const totalBalance =
      !this.isParentOrder && this.parentOrder && this.parentOrder?.totalPaid > this.parentOrder?.totalCost
        ? 0
        : round(this.paymentLines.reduce((total, current) => total + current.amount, 0));
    
    return totalBalance;
  }

  @computed
  public get totalAllocated(): number {
    if (!this.parentOrder && this.splitOrders) {
      return this.splitOrders
        .filter((x) => x.id !== this.id)
        .map((x) => x.paymentLines.map((z) => z.amount).reduce((acc, val) => acc + val, 0))
        .reduce((x) => x);
    }
    return this.parentOrder &&
      this.parentOrder?.totalPaid > this.parentOrder?.totalCost
        ? this.totalCost
        : 0;
  }

  @computed
  public get totalRemaining(): number {
    if (!this.parentOrder && this.splitOrders) {
      return this.totalPaid - this.totalAllocated;
    }
    return this.totalPaid;
  }

  @computed
  public get totalPaymentPoolRemaining(): number {
    if (this.parentOrder && this.splitOrders) {
      let pool = this.total;
      pool -= this.paymentLines.map((y) => y.amount).reduce((acc, val) => acc + val, 0);
      return pool;
    }
    return 0;
  }

  @computed
  public get balanceDue(): number {
    return round(this.total - this.totalPaid + this.customerAdjustment);
  }

  @computed
  public get readyForProductLines(): boolean {
    return (
      this.customer !== 0 &&
      ((this.deliveryType === DeliveryType.Delivery && Validate.hasValue(this.delivery.address.formattedAddress)) ||
        (this.deliveryType === DeliveryType.PickUp && Validate.hasValue(this.pickup.address.formattedAddress)))
    );
  }

  @computed
  public get canAddProducts(): boolean {
    return this.deliveryType !== DeliveryType.FreightOnly;
  }

  @computed
  public get fuelLevySurcharge(): number {
    if (this.fuelLevyServicePrice) {
      const servicesSubtotal = this.serviceLines
        .map((s) => roundedSubtotal(s.unroundedSubtotal))
        .reduce((acc, val) => acc + val, 0);
      const productsSubtotal = this.productLines
        .map((s) => roundedSubtotal(s.unroundedSubtotal))
        .reduce((acc, val) => acc + val, 0);
      return round((productsSubtotal + servicesSubtotal) * this.fuelLevyServicePrice);
    }
    return 0;
  }

  @computed
  public get subtotal(): number {
    const servicesSubtotal = this.serviceLines
      .map((s) => roundedSubtotal(s.unroundedSubtotal))
      .reduce((acc, val) => acc + val, 0);
    const productsSubtotal = this.productLines
      .map((s) => roundedSubtotal(s.unroundedSubtotal))
      .reduce((acc, val) => acc + val, 0);
    return productsSubtotal + servicesSubtotal + this.fuelLevySurcharge;
  }

  @computed
  public get gst(): number {
    const servicesGst = this.serviceLines
      .map((s) => roundedGst(s.unroundedSubtotal))
      .reduce((acc, val) => acc + val, 0);
    const productsGst = this.productLines
      .map((s) => roundedGst(s.unroundedSubtotal))
      .reduce((acc, val) => acc + val, 0);
    const fuelLevyGst = roundedGst(this.fuelLevySurcharge);
    return productsGst + servicesGst + fuelLevyGst;
  }

  @computed
  public get hasCustomPricing(): boolean {
    return this.productLines.some((p) => p.hasCustomPrice);
  }

  @computed
  public get pendingApproval(): boolean {
    return this.orderCancellation!.workflowStatus === WorkflowStatus.PendingApproval;
  }

  @computed
  public get total() {
    const servicesTotal = this.serviceLines
      .map((s) => roundedTotal(s.unroundedSubtotal))
      .reduce((acc, val) => round(acc + val), 0);
    const productsTotal = this.productLines
      .map((s) => roundedTotal(s.unroundedSubtotal))
      .reduce((acc, val) => round(acc + val), 0);
    const fuelLevyTotal = roundedTotal(this.fuelLevySurcharge);
    return productsTotal + servicesTotal + fuelLevyTotal;
  }

  @computed
  public get isInDispute() {
    return (
      !this.isCashSalesAccount &&
      !this.delivery.dateTba &&
      this.customerInDispute.workflowStatus !== WorkflowStatus.Approved &&
      (this.isCustomerInDispute || (this.maxOrderAmountForCustomer && this.maxOrderAmountForCustomer < this.total))
    );
  }

  @computed
  public get isInDisputeButPreviouslyApproved() {
    return (
      !this.isCashSalesAccount &&
      !this.delivery.dateTba &&
      this.customerInDispute.workflowStatus === WorkflowStatus.Approved &&
      (this.isCustomerInDispute || (this.maxOrderAmountForCustomer && this.maxOrderAmountForCustomer < this.total))
    );
  }

  @computed
  public get isQuoteInDispute() {
    return (
      this.isInDispute &&
      (this.status === OrderStatus.Quote || this.orderWorkflow === OrderWorkflow.TransitioningToOrder)
    );
  }

  @computed
  public get isOrderInDispute() {
    return this.isInDispute && this.status === OrderStatus.Order;
  }

  @computed
  public get productLinesGrouped(): { [key: string]: ProductLine[] } {
    const result: { [key: string]: ProductLine[] } = {};
    this.productLines.forEach((item) => {
      const key = `${item.supplierName} ${item.exWorksName}`;
      result[key] = result[key] || [];
      result[key].push(item);
      return result;
    });
    return result;
  }

  @computed
  public get hasNoRegion() {
    const region = this.getSelectedRegionId();
    return region !== null && region === 0;
  }

  @computed
  private get requiresExtraDeliveryFee(): boolean {
    if (this.deliveryType !== DeliveryType.Delivery || this.serviceLines.some((s) => s.code === this.deliveryCode))
      return false;

    if (!this.totalWeight) return false;

    return !this.isAbcFrieghtJobsAccount && this.totalWeight < this.sixTonnesInKg;
  }

  @computed
  private get exceedsExtraDeliveryFeeRequirement(): boolean {
    if (this.deliveryType !== DeliveryType.Delivery) return false;
    if (!this.totalWeight) return false;
    return this.totalWeight >= this.sixTonnesInKg && this.serviceLines.some((s) => s.code === this.deliveryCode);
  }

  @computed
  public get totalWeight(): number {
    const productsWeight = this.productLines.map((p) => p.totalWeight).reduce((acc, weight) => acc + weight, 0);
    const serviceWeight = this.serviceLines
      .map((s) => s.chosenUnitWeightInKilos)
      .reduce((acc, weight) => acc + weight, 0);
    if (!isNaN(serviceWeight)) {
      return productsWeight + serviceWeight;
    }
    return productsWeight;
  }

  @computed
  public get totalWeightFormatted(): string {
    if (this.totalWeight < 0) return '';
    return `${(this.totalWeight / 1000).toFixed(2)} tonnes`;
  }

  @computed
  public get quoteExpired(): boolean {
    if (this.status !== OrderStatus.Quote || !this.quoteExpiryDate) return false;
    return this.quoteExpiryDate <= DateTime.local().startOf('day');
  }

  @computed
  public get hasNote(): boolean {
    return Validate.hasValue(this.note);
  }

  @computed
  public get isNew(): boolean {
    return Validate.hasValue(this.orderNumber);
  }

  @computed
  public get eventsOrderedByDate() {
    return (
      this.events
        .slice()
        // Decending date
        .sort((a: IOrderEvent, b: IOrderEvent) => {
          return b.date.toMillis() - a.date.toMillis();
        })
    );
  }

  @computed
  public get approvalNotes() {
    const showLatestCustomerInDisputeApproval =
      this.customerInDispute && this.customerInDispute.workflowStatus === WorkflowStatus.Approved;

    const eventTypes = [
      OrderEventType.CustomPricingApproved,
      OrderEventType.CustomPricingRejected,
      OrderEventType.OrderDeletionApproved,
      OrderEventType.OrderDeletionRejected,
      OrderEventType.InDisputeCustomerRejected,
      OrderEventType.RefundApproved,
      OrderEventType.RefundRejected,
      OrderEventType.ProductChecked,
    ];
    if (showLatestCustomerInDisputeApproval) {
      eventTypes.push(OrderEventType.InDisputeCustomerApproved);
    }
    const eventsToShow = this.eventsOrderedByDate.filter((e) => eventTypes.some((t) => t === e.eventType));
    const uniqueArray = eventsToShow.filter((item, pos, self) => {
      return (
        item.eventType !== OrderEventType.InDisputeCustomerApproved ||
        self.findIndex((it) => it.eventType === OrderEventType.InDisputeCustomerApproved) === pos
      );
    });
    return uniqueArray;
  }

  @computed
  public get requiresFreightToStoreFee() {
    if (!this.totalWeight) return false;
    return (
      this.pickup.address.locationType === LocationType.Abc &&
      this.deliveryType === DeliveryType.PickUp &&
      this.totalWeight < this.oneAndHalfTonnesInKg &&
      !this.serviceLines.some((s) => s.code === this.freightToStoreCode)
    );
  }

  @computed
  private get exceedsFreightToStoreRequirement() {
    if (this.deliveryType !== DeliveryType.PickUp || this.pickup.address.locationType !== LocationType.Abc)
      return false;
    return (
      this.totalWeight >= this.oneAndHalfTonnesInKg && this.serviceLines.some((s) => s.code === this.freightToStoreCode)
    );
  }

  @computed
  public get isParentOrder() {
    return (
      !this.parentOrder &&
      this.splitOrders.length &&
      this.splitOrders.find((split) => split.lifetimeStatus !== OrderLifetimeStatus.Cancelled)
    );
  }

  @computed
  public get isSplitOrder() {
    return (this.parentOrder && this.parentOrder.id !== 0) || this.isSplitting;
  }

  @computed
  public get hasRefund() {
    return (
      this.orderWorkflow === OrderWorkflow.RefundingOrder ||
      this.orderRefund.workflowStatus === WorkflowStatus.PendingApproval ||
      this.refund.subTotalCredited > 0
    );
  }

  @computed
  public get isRefunding() {
    return this.orderWorkflow === OrderWorkflow.RefundingOrder;
  }

  @computed
  public get isSplitting() {
    return this.orderWorkflow === OrderWorkflow.SplittingOrder;
  }

  public getParentProductLine(productId: number, lineNumber: number): ProductLine | undefined {
    return computed(() => {
      return this.isSplitOrder ? this.parentOrder!.getProductLine(productId, lineNumber) : undefined;
    }).get();
  }

  public getProductLine(productId: number, lineNumber: number) {
    return this.productLines.find((p) => p.productId === productId && p.lineNumber === lineNumber);
  }
  public get getPOWarehouseSplit() {
    return this.productLines?.some(v => v.raisePurchaseOrder) && !this.parentOrder?.productLines?.some(v => v.raisePurchaseOrder);
  }

  public productQtyAllocated(productId: number, lineNumber: number): number {
    return computed(() => {
      return this.splitOrders
        .filter((o) => o.id !== this.id)
        .map((o) => o.productLines)
        .reduce((acc, curr) => acc.concat(curr), [])
        .filter((p) => p.productId === productId && p.lineNumber === lineNumber)
        .reduce((sumQty, pl) => {
          return pl.addQuantities(sumQty, pl.quantity);
        }, 0);
    }).get();
  }

  public productQtyAvailable(productId: number, lineNumber: number): number {
    return computed(() => {
      const parentLine = this.getParentProductLine(productId, lineNumber);
      const allocatedQty = this.productQtyAllocated(productId, lineNumber);

      if (parentLine && this.isSplitOrder) {
        return parentLine.calculateAvailableQuantity(allocatedQty);
      }
      const productLine = this.getProductLine(productId, lineNumber);
      if (productLine && this.isParentOrder) {
        return productLine.calculateAvailableQuantity(allocatedQty);
      }
      return 0;
    }).get();
  }

  public get totalProductQtyAvailableOnParent(): number {
    return computed(() => {
      if (!this.parentOrder) {
        return 0;
      }

      if (this.isFreightOnly) {
        const remaining = (this.serviceLines || []).map((p) => p.quantityAvailable).reduce((qty, sum) => qty + sum, 0);
        const parentOrderLinesRemovedFromSplit = this.parentOrder.serviceLines.filter(
          (pp) => !this.serviceLines.find((p) => p.lineNumber === pp.lineNumber),
        );
        const totalRemainingOnParentFromRemovedLines = parentOrderLinesRemovedFromSplit
          .map((pp) => pp.quantity)
          .reduce((qty, sum) => qty + sum, 0);
        return remaining + totalRemainingOnParentFromRemovedLines;
      } else {
        const remaining = (this.productLines || []).map((p) => p.quantityAvailable).reduce((qty, sum) => qty + sum, 0);
        const parentOrderLinesRemovedFromSplit = this.parentOrder.productLines.filter(
          (pp) => !this.productLines.find((p) => p.lineNumber === pp.lineNumber),
        );
        const totalRemainingOnParentFromRemovedLines = parentOrderLinesRemovedFromSplit
          .map((pp) => pp.quantity)
          .reduce((qty, sum) => qty + sum, 0);
        return remaining + totalRemainingOnParentFromRemovedLines;
      }
    }).get();
  }

  public getParentServiceLine(productId: number, lineNumber: number): ServiceLine | undefined {
    return computed(() => {
      return this.isSplitOrder ? this.parentOrder!.getServiceLine(productId, lineNumber) : undefined;
    }).get();
  }

  public getServiceLine(serviceId: number, lineNumber: number) {
    return this.serviceLines.find((p) => p.serviceId === serviceId && p.lineNumber === lineNumber);
  }

  public getSupplierComment(supplierName: string) {
    return this.supplierCommentLines.find((s) => s.supplierName === supplierName);
  }

  public getSelectedRegionId() {
    if (this.deliveryType === DeliveryType.Delivery) return this.delivery.regionId;
    else if (this.deliveryType === DeliveryType.PickUp) return this.pickup.regionId;
    else return null;
  }

  public serviceQtyAllocated(serviceId: number, lineNumber: number): number {
    return computed(() => {
      return this.splitOrders
        .filter((o) => o.id !== this.id)
        .map((o) => o.serviceLines)
        .reduce((acc, curr) => acc.concat(curr), [])
        .filter((s) => s.serviceId === serviceId && s.lineNumber === lineNumber && !s.isSupplementary)
        .map((s) => s.quantity)
        .reduce((acc, curr) => acc + curr, 0);
    }).get();
  }

  public serviceQtyAvailable(serviceId: number, lineNumber: number): number {
    return computed(() => {
      const parent = this.getParentServiceLine(serviceId, lineNumber);
      const allocatedQty = this.serviceQtyAllocated(serviceId, lineNumber);
      if (parent && this.isSplitOrder) {
        return Math.max(parent.totalSplitableQuantity - allocatedQty, 0);
      }
      const service = this.getServiceLine(serviceId, lineNumber);
      if (service && this.isParentOrder) {
        return Math.max(service.totalSplitableQuantity - allocatedQty, 0);
      }
      return 0;
    }).get();
  }

  private productsFromSameSupplierExWorks(productLine: ProductLine) {
    const linesForSuplier = this.productLines.filter((p) => p.supplierName === productLine.supplierName);
    if (!linesForSuplier.length) return true;
    return linesForSuplier.every((p) => p.exWorksId === productLine.exWorksId);
  }

  private getAddressForPricing() {
    return this.deliveryType === DeliveryType.Delivery ? this.delivery.address.latLng : this.pickup.address.latLng;
  }

  private supplierCommentExists(supplierComment: SupplierCommentLine) {
    return this.supplierCommentLines.some((s) => s.exWorksId === supplierComment.exWorksId);
  }

  @computed
  public get paymentsMade(): number {
    let paid = 0;
    if (this.isSplitOrder) paid = this.parentOrder!.totalPaid - this.parentOrder!.customerAdjustment;
    if (this.isParentOrder)  {
      var filteredItems = this.splitOrders.filter((split) => split.lifetimeStatus !== OrderLifetimeStatus.Cancelled)
      paid =this.totalPaid - this.customerAdjustment + filteredItems.reduce((total, item) => {
        const paymentTotal = item.paymentLines.reduce((sum, payment) => sum + (payment.amount || 0), 0);
        return total + paymentTotal - item.customerAdjustment;
      }, 0);
      return paid;
    };
    return round(paid - this.splitAdjustments);
  }

  @computed
  private get splitAdjustments() {
    return this.splitOrders
      .filter((o) => o.id !== this.id)
      .map((o) => o.customerAdjustment)
      .reduce((acc, curr) => acc + curr, 0);
  }

  @computed
  public get grandTotal(): number {
    let total = 0;
    if (this.parentOrder && this.isSplitOrder) total = this.parentOrder!.total + this.total;
    if (this.isParentOrder) total = this.totalCost;
    const splitsTotal = this.splitOrders
      .filter((o) => o.id !== this.id)
      .map((o) => o.totalCost)
      .reduce((acc, curr) => acc + curr, 0);
    return round(splitsTotal + total);
  }

  @computed
  public get grandTotalBalanceDue() {
    if (!this.isParentOrder && !this.isSplitOrder) return this.balanceDue;
    return round(this.grandTotal - this.paymentsMade);
  }

  @computed
  public get isSubContract() {
    return (
      (this.freightOnly && this.freightOnly.subcontract) ||
      (this.delivery && this.delivery.subcontract) ||
      (this.pickup && this.pickup.subcontract)
    );
  }

  @action
  public validatePaymentLines = (): boolean => {
    this.errors.paymentLines = null;
    if (!this.isCashSalesAccount || !this.paymentLines.length) return true;
    if (!this.isParentOrder && this.paymentsMade > this.grandTotal)
      this.errors.paymentLines = 'Total Payment and adjustments must be less than or equal to Grand Total';

    return this.errors.paymentLines === null;
  };

  @action
  public validateProductAndSeviceLines = () => {
    this.errors.lines =
      this.productLines.length || this.serviceLines.length
        ? null
        : 'At least one product or service must be added to an order';
    return this.errors.lines === null;
  };

  private validatePhoneNumbers = (phone: string) => {
    const isValid = /^[0-9\s]*$/.test(phone);

    return isValid ? null : 'Phone number should only contain numbers and spaces';
  };

  protected validators: { [name: string]: (val: any) => boolean } = {
    customer: (val: number) => {
      this.errors.customer = val > 0 ? null : 'Customer required';
      return this.errors.customer === null;
    },
    email: (val: string) => {
      this.errors.email = !Validate.hasValue(val) || EmailValidator.validate(val) ? null : 'Email address is invalid';
      return this.errors.email === null;
    },
    phone: (val: string) => {
      this.errors.phone = Validate.hasValue(val) ? null : 'Provide a phone number';
      if (!this.errors.phone) this.errors.phone = this.validatePhoneNumbers(val);
      return this.errors.phone === null;
    },
    status: (val: OrderStatus) => {
      if (val !== OrderStatus.Order) return true;
      if (this.deliveryType === DeliveryType.Delivery) {
        this.delivery.errors.date =
          this.delivery.date || this.delivery.dateTba ? null : 'Provide a delivery date or confirm date is TBA';
        return this.delivery.errors.date === null;
      } else if (this.deliveryType === DeliveryType.PickUp) {
        this.pickup.errors.date =
          this.pickup.date || this.pickup.dateTba ? null : 'Provide a pickup date or confirm date is TBA';
        return this.pickup.errors.date === null;
      } else if (this.deliveryType === DeliveryType.FreightOnly) {
        this.freightOnly.errors.date =
          this.freightOnly.date || this.freightOnly.dateTba ? null : 'Provide a date or confirm date is TBA';
        return this.freightOnly.errors.date === null;
      }
      return false;
    },
    paymentLines: this.validatePaymentLines,
    customerAdjustment: (val: number) => {
      if (!this.isCashSalesAccount) return true;
      if (this.isParentOrder || this.isSplitOrder) {
        this.errors.customerAdjustment =
          this.grandTotalBalanceDue < 0 && val !== 0 ? 'Adjustment must not be greater than balance due' : null;
      } else {
        this.errors.customerAdjustment =
          this.balanceDue < 0 && val !== 0 ? 'Adjustment must not be greater than balance due' : null;
      }
      return this.errors.customerAdjustment === null;
    },
    refund: () => this.refund.validate(),
    name: (val: string) => {
      this.errors.name = !this.isCashSalesAccount || Validate.hasValue(val) ? null : 'Site contact is required';
      return this.errors.name === null;
    },
    serviceLines: this.validateProductAndSeviceLines,
    productLines: this.validateProductAndSeviceLines,
  };

  @action
  public static fromResponse(
    o: IOrderResponse,
    productsApi: ProductsApi,
    saleApi: SalesApi,
    settingsApi: SettingsApi,
  ): Order {
    const order = new Order(productsApi, saleApi, settingsApi);
    order.id = o.id;
    order.canSplit = o.canSplit;
    order.parentOrder = o.parentOrder ? RelatedOrder.fromResponse(o.parentOrder) : null;
    order.splitOrders = o.splitOrders.map((c) => RelatedOrder.fromResponse(c));
    order.orderNumber = o.orderNumber;
    order.orderCancellation = Workflow.fromResponse(o.orderCancellation, WorkflowType.OrderCancellation);
    order.customPricing = Workflow.fromResponse(o.customPricing, WorkflowType.CustomPricing);
    order.customerInDispute = Workflow.fromResponse(o.customerInDispute, WorkflowType.InDispute);
    order.orderRefund = Workflow.fromResponse(o.orderRefund, WorkflowType.Refund);
    order.customer = o.customer;
    order.stockingPoint = o.stockingPoint;
    order.customerName = o.customerName;
    order.isCashSalesAccount = o.isCashSalesAccount;
    order.isActiveCustomer = o.isActiveCustomer;
    order.isOnlineStore = o.isOnlineStore;
    order.lastModifiedTimeStamp = o.lastModifiedTimeStamp;
    order.isAbcFrieghtJobsAccount = o.isAbcFrieghtJobsAccount;
    order.customerRef = o.customerRef || '';
    order.leadIdentifier = o.leadIdentifier || '';
    order.customerAdjustment = o.customerAdjustment;
    order.fuelLevyServicePrice = o.fuelLevyServicePrice;
    order.isCustomerInDispute = o.isCustomerInDispute;
    order.maxOrderAmountForCustomer = o.maxOrderAmountForCustomer;
    order.name = o.name || '';
    order.email = o.email || '';
    order.firstPickupEtaUtc = !o.firstPickupEtaUtc ? null : DateTime.fromISO(o.firstPickupEtaUtc);
    order.firstPickupEtaLocal = !o.firstPickupEtaUtc ? null : utcToLocal(o.firstPickupEtaUtc);
    order.phone = o.phone || '';
    order.deliveryType = o.deliveryType;
    order.delivery = Delivery.fromResponse(o.delivery, order);
    order.pickup = Pickup.fromResponse(o.pickup, order);
    order.isFreightOnly = o.isFreightOnly;
    order.freightOnly = FreightOnly.fromResponse(o.freightOnly, order);
    order.serviceLines = o.serviceLines.map((s) => ServiceLine.fromResponse(s, order));
    order.supplierCommentLines = o.supplierCommentLines.map((s) => SupplierCommentLine.fromResponse(s, order));
    order.productLines = o.productLines.map((p) => ProductLine.fromResponse(p, order));
    order.paymentLines = o.paymentLines.map((p) => PaymentLine.fromResponse(p));
    order.status = o.status;
    order.createdDate = utcToLocal(o.createdDate);
    order.modifiedDate = !o.modifiedDate ? null : utcToLocal(o.modifiedDate);
    order.owner = o.owner || '';
    order.quoteExpiryDate = !o.quoteExpiryDate ? null : DateTime.fromISO(o.quoteExpiryDate);
    order.note = o.note || '';
    order.active = o.active;
    order.events = o.events ? o.events.map((e) => ({ ...e, date: utcToLocal(e.date) } as IOrderEvent)) : [];
    order.totalCost = o.totalCost;
    order.clonedFrom = o.clonedFrom;
    order.lifetimeStatus = o.lifetimeStatus;
    order.refund = Refund.fromResponse(o.refund, order);
    order.purchaseOrderPending = o.purchaseOrderPending;
    return order;
  }

  public static toRequest(order: Order) {
    return {
      ...order,
      parentOrder: order.parentOrder
        ? {
            ...order.parentOrder,
            productLines: order.parentOrder.productLines.map((p) => ({ ...p, order: null })),
            serviceLines: order.parentOrder.serviceLines.map((s) => ({ ...s, order: null })),
            supplierCommentLines: order.parentOrder.supplierCommentLines.map((s) => ({ ...s, order: null })),
            orderDeliveryDate: toISODate(order.parentOrder.orderDeliveryDate),
          }
        : null,
      productLines: order.productLines.map((p) => ({ ...p, order: null })),
      serviceLines: order.serviceLines.map((s) => ({ ...s, order: null })),
      supplierCommentLines: order.supplierCommentLines.map((s) => ({ ...s, order: null })),
      gst: order.gst,
      subtotal: order.subtotal,
      total: order.total,
      grandTotalBalanceDue: order.grandTotalBalanceDue,
      freightOnly: {
        ...order.freightOnly,
        date: toISODate(order.freightOnly.date),
        order: null,
      },
      delivery: {
        ...order.delivery,
        date: toISODate(order.delivery.date),
        order: null,
        regionId: null,
      },
      pickup: {
        ...order.pickup,
        date: toISODate(order.pickup.date),
        order: null,
        regionId: null,
      },
      refund: {
        ...order.refund,
        creditTotal: order.refund.subTotalCredited,
        order: null,
      },
      quoteExpiryDate: toISODate(order.quoteExpiryDate),
      bodyTruckService: null,
      deliveryService: null,
      fuelLevyService: null,
      freightToStoreService: null,
      parentOrderCopy: null,
      orderWorkflow: null,
    };
  }

  private async getAutoAddService(serviceCode: string): Promise<ServiceLine> {
    const svc = await this.productsApi.getService(serviceCode);
    return new ServiceLine(
      0,
      svc.id,
      svc.name,
      svc.code,
      svc.price,
      svc.purchaseUnit,
      this.nextServiceLineNumber,
      '',
      1,
      true,
      1,
      this.isSplitting,
      0,
      0,
      0,
      this,
    );
  }

  @computed
  public get nextServiceLineNumber(): number {
    return Math.max(...this.serviceLines.map((x) => x.lineNumber), -1) + 1;
  }

  @computed
  public get serialized(): string {
    return JSON.stringify(Order.toRequest(this));
  }

  @computed
  public get isDeliveryToday(): boolean {
    if (this.delivery.date) {
      const theDate = this.delivery.date ? this.delivery.date.toJSDate() : undefined;
      return !!theDate && isSameDateToday(DateTime.fromJSDate(theDate));
    }
    return false;
  }
}

export enum DeliveryType {
  None = 0,
  Delivery = 1,
  PickUp = 2,
  FreightOnly = 3,
}

export enum PaymentType {
  None = 0,
  Card = 1,
  Cash = 2,
  Cheque = 3,
  DirectDeposit = 4,
}

export enum TimeslotType {
  None = 0,
  AnyTime = 1,
  FirstUp = 2,
  AM = 3,
  PM = 4,
  ASAP = 5,
  Custom = 6,
}

export enum TruckType {
  None = 0,
  Standard = 1,
  BodyTruck = 2,
}

export enum OrderStatus {
  None = 0,
  Quote = 1,
  Order = 2,
}

export enum OrderEventType {
  None = 0,
  Created = 1,
  Modified = 2,
  SharedQuote = 3,
  PurchaseOrderRaised = 4,
  CustomPricingApproved = 5,
  CustomPricingRejected = 6,
  OrderDeletionApproved = 7,
  OrderDeletionRejected = 8,
  PurchaseOrderSuccess = 9,
  PurchaseOrderFailure = 10,
  PurchaseOrderingComplete = 11,
  OrderSplit = 12,
  ConsignmentNoteSent = 13,
  InDisputeCustomerApproved = 14,
  InDisputeCustomerRejected = 15,
  InDisputeCustomerAutoApproved = 16,
  SentToDispatch = 17,
  InvoiceSent = 19,
  InvoiceFailure = 20,
  RefundApproved = 21,
  RefundRejected = 22,
  PurchaseOrderEmailed = 23,
  PaymentSent = 24,
  PaymentComplete = 25,
  PaymentError = 26,
  PurchaseOrderUpdateRaised = 27,
  PurchaseOrderUpdateFailure = 28,
  PurchaseOrderUpdateSuccess = 29,
  PurchaseOrderUpdateEmailed = 30,
  QuoteCancelled = 31,
  GrnRaised = 32,
  GrnError = 33,
  ProductChecked = 34,
  UnleashedOrderFailed = 36,
  AllocationSent = 37,
  AllocationComplete = 38,
  AllocationError = 39,
  ManualCompletion = 99,
  Complete = 100,
}

export interface IOrderEvent {
  eventType: OrderEventType;
  date: DateTime;
  repCode: string;
  payload:
    | string
    | IShareQuoteEventPayload
    | IPurchaseOrderFailurePayload
    | IPurchaseOrderSuccessPayload
    | IConsignmentNotesPayload
    | ISentToDispatchPayload;
}

export enum OrderWorkflow {
  None = 0,
  TransitioningToOrder = 1,
  SplittingOrder = 2,
  CloningOrder = 3,
  RefundingOrder = 4,
}

export enum OrderLifetimeStatus {
  None = 0,
  PoRaised = 1,
  PoError = 2,
  AwaitingDispatch = 3,
  DispatchError = 4,
  ReceiptedIntoStock = 5,
  Delivered = 6,
  InvoiceSent = 7,
  InvoiceError = 8,
  Complete = 9,
  Cancelled = 10,
}

export enum RaisePurchaseOrderOn {
  None = 0,
  Approve = 1,
  QuoteToOrder = 2,
}

export enum OrderReportType {
  Tba = 0,
  OrderStatus = 1,
  PricingApproval = 2,
  BranchBanking = 6,
  CompletedAndFailedInvoices = 8
}
