import './styles.css';

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { Header } from '../Header/Header';
import { SuppliersStore } from '../../stores/suppliers-store';
import Suppliers from './Suppliers';
import { NoRecords } from '../NoRecords/NoRecords';
import tracker from '../Loader/loader-tracker';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface ISupplierIndexProps {
  suppliersStore?: SuppliersStore;
}

type Props = ISupplierIndexProps & RouteComponentProps<{}>;

@inject('suppliersStore')
@observer
class SupplierIndex extends React.Component<Props, {}> {
  private suppliersStore?: SuppliersStore;

  constructor(props: Props) {
    super(props);
    this.suppliersStore = props.suppliersStore;
  }

  public async componentDidMount() {
    await tracker.track(this.suppliersStore!.getSuppliers());
  }

  public render() {
    return (
      <React.Fragment>
        <Header title="Suppliers" hideTitleForDesktop />

        <section className="suppliers">
          <Suppliers suppliers={this.suppliersStore!.suppliers} {...this.props} />
          <NoRecords message="No more suppliers" active />
        </section>
      </React.Fragment>
    );
  }
}

export default withAITracking(reactPlugin, SupplierIndex, 'SupplierIndex');
