import { ProductLine } from './../../models/product-line';
import { observable, action, computed } from 'mobx';
import { SuggestionItem } from '../../components/Autocomplete/Autocomplete';
import { ICustomerCode } from '../../services/customers-api';
import { IPickupLocation } from '../suppliers-store';
import { Order, OrderStatus, OrderWorkflow, OrderLifetimeStatus, OrderEventType } from '../../models/order';
import { AddressMapUiState } from './address-map-ui-state';
import { ProductSuggestion } from '../products-store';
import { PaymentLine, PaymentLifetimeStatus } from 'src/models/payment-line';
import { ServiceLine } from '../../models/service-line';
import { AuthStore } from '../auth-store';

export class OrderUiState {
    @observable public approvalDialogActive: boolean = false;
    @observable public cancelDialogActive: boolean = false;
    @observable public customPricingDialogActive: boolean = false;
    @observable public inDisputeDialogActive: boolean = false;
    @observable public refundDialogActive: boolean = false;
    @observable public rejectionDialogActive: boolean = false;
    @observable public manuallyCompleteDialogActive: boolean = false;
    @observable public resendInvoiceDialogActive: boolean = false;
    @observable public productCheckDialogActive: boolean = false;
    @observable public selectedCustomer?: SuggestionItem<ICustomerCode>;
    @observable public selectedPickupAddress?: SuggestionItem<IPickupLocation>;
    @observable public formReadOnly: boolean = false;
    @observable public orderSubmissionDialogActive: boolean = false;
    @observable public orderPoSubmissionDialogActive: boolean = false;
    @observable public quoteCancellationDialogActive: boolean = false;
    @observable public quoteShareDialogActive: boolean = false;
    @observable public invoiceShareDialogActive: boolean = false;
    @observable public addServiceActive: boolean = false;
    @observable public addProductActive: boolean = false;
    @observable public addSupplierCommentActive: boolean = true;
    @observable public productFilterExWorks: SuggestionItem<ProductSuggestion> | null;
    @observable public splitOrderSubmissionDialogActive: boolean = false;
    @observable public saveAsQuoteForInDispute: boolean = false;

    @observable private order: Order | null;

    constructor(private addressMapUiState: AddressMapUiState, private authStore: AuthStore) {}

    @action
    public setProductFilterExWorks(exWorks: SuggestionItem<ProductSuggestion> | null) {
        this.productFilterExWorks = exWorks;
    }

    @action
    public setAddProductActive(active: boolean) {
        this.addProductActive = active;
    }

    @action
    public setAddServiceActive(active: boolean) {
        this.addServiceActive = active;
    }

    @action
    public setAddSupplierComment(active: boolean) {
        this.addSupplierCommentActive = active;
    }

    @action
    public setFormReadOnly(readOnly: boolean) {
        this.formReadOnly = readOnly;
    }

    @action
    public setSaveAsQuoteForInDispute(value: boolean) {
        this.saveAsQuoteForInDispute = value;
    }

    @action
    public initNewOrder(order: Order) {
        this.order = order;
        this.formReadOnly = false;
        this.selectedCustomer = undefined;
        this.selectedPickupAddress = undefined;
        this.productFilterExWorks = null;
        this.addProductActive = false;
        this.addServiceActive = false;
        this.addSupplierCommentActive = false;
        this.addressMapUiState.setLatLng('deliveryAddress', { lat: null, lng: null });
        this.addressMapUiState.setLatLng('fromAddress', { lat: null, lng: null });
        this.addressMapUiState.setLatLng('toAddress', { lat: null, lng: null });
    }

    @action
    public initViewOrder(order: Order) {
        this.order = order;
        this.formReadOnly = true;
        this.productFilterExWorks = null;
        this.addProductActive = false;
        this.addServiceActive = false;
        this.addSupplierCommentActive = false;
        this.saveAsQuoteForInDispute = false;
        this.selectedCustomer = {
            label: order.customerName,
            value: {
                value: order.customer,
                isCashSaleAccount: false,
                isOnlineStore: false,
                isAbcFreightJobsAccount: order.isAbcFrieghtJobsAccount,
            }
        };
        if (order.pickup.address) {
            this.selectedPickupAddress = order.pickup.address.toSuggestionItem();
        }
        if (order.delivery.address.latLng) {
            this.addressMapUiState.setLatLng('deliveryAddress', { lat: order.delivery.address.latLng.lat, lng: order.delivery.address.latLng.lng });
        }
        if (order.freightOnly.fromAddress.latLng && order.freightOnly.toAddress.latLng) {
            this.addressMapUiState.setLatLng('fromAddress', {
                lat: order.freightOnly.fromAddress.latLng.lat,
                lng: order.freightOnly.fromAddress.latLng.lng
            });
            this.addressMapUiState.setLatLng('toAddress', {
                lat: order.freightOnly.toAddress.latLng.lat,
                lng: order.freightOnly.toAddress.latLng.lng
            });
        }
        if (order.refund.fromAddress.latLng && order.refund.toAddress.latLng) {
            this.addressMapUiState.setLatLng('refundFromAddress', {
                lat: order.refund.fromAddress.latLng.lat,
                lng: order.refund.fromAddress.latLng.lng
            });
            this.addressMapUiState.setLatLng('refundToAddress', { lat: order.refund.toAddress.latLng.lat, lng: order.refund.toAddress.latLng.lng });
        }
    }

    @action
    public initCloneOrder(order: Order) {
        this.initViewOrder(order);
        this.formReadOnly = false;
    }

    @action
    public setApprovalDialogActive(active: boolean): void {
        this.approvalDialogActive = active;
    }

    @action
    public setCancelDialogActive(active: boolean) {
        this.cancelDialogActive = active;
    }

    @action
    public setCustomPricingDialogActive(active: boolean) {
        this.customPricingDialogActive = active;
    }

    @action
    public setInDisputeDialogActive(active: boolean) {
        this.inDisputeDialogActive = active;
    }

    @action
    public setRefundDialogActive(active: boolean) {
        this.refundDialogActive = active;
    }

    @action
    public setManuallyCompleteDialogActive(active: boolean) {
        this.manuallyCompleteDialogActive = active;
    }

    @action
    public setResendInvoiceDialogActive(active: boolean) {
        this.resendInvoiceDialogActive = active;
    }

    @action
    public setProductCheckDialogActive(active: boolean) {
        this.productCheckDialogActive = active;
    }

    @action
    public setLatLng(key: string, latLng: { lat: number | null; lng: number | null }) {
        this.addressMapUiState.setLatLng(key, latLng);
    }

    @action
    public setRejectionDialogActive(active: boolean): void {
        this.rejectionDialogActive = active;
    }

    @action
    public setSelectedPickupAddress(selected: SuggestionItem<IPickupLocation>) {
        this.selectedPickupAddress = selected;
    }

    @action
    public setOrderSubmissionDialogActive(active: boolean) {
        this.orderSubmissionDialogActive = active;
    }

    @action
    public setOrderPoSubmissionDialogActive(active: boolean) {
        this.orderPoSubmissionDialogActive = active;
    }

    @action
    public setQuoteCancellationDialogActive(active: boolean) {
        this.quoteCancellationDialogActive = active;
    }

    @action
    public setQuoteShareDialogActive(active: boolean) {
        this.quoteShareDialogActive = active;
    }

    @action
    public setInvoiceShareDialogActive(active: boolean) {
        this.invoiceShareDialogActive = active;
    }

    @action
    public setSplitOrderSubmissionDialogActive(active: boolean) {
        this.splitOrderSubmissionDialogActive = active;
    }

    @computed
    public get productFilterExWorksId(): number {
        return this.productFilterExWorks ? this.productFilterExWorks.value.id : 0;
    }

    @computed
    public get canSaveOrderChanges() {
        return (
            this.order &&
            this.order.status === OrderStatus.Order &&
            !this.formReadOnly &&
            this.order.orderWorkflow !== OrderWorkflow.TransitioningToOrder &&
            this.order.orderWorkflow !== OrderWorkflow.SplittingOrder &&
            this.order.orderWorkflow !== OrderWorkflow.RefundingOrder &&
            !this.order.hasCustomPricing &&
            this.order.active
        );
    }

    @computed
    public get quoteEditable() {
        return this.order && this.order.status === OrderStatus.Quote && !this.formReadOnly && !this.order.quoteExpired;
    }

    @computed
    public get quoteReadOnly() {
        return this.order && this.order.status === OrderStatus.Quote && this.formReadOnly;
    }

    @computed
    public get canCancelQuote() {
        return this.quoteReadOnly && this.order && this.order.active && !this.order.quoteExpired &&
                this.order.splitOrders.find;
    }

    @computed
    public get orderReadOnly() {
        return this.order && this.order.status === OrderStatus.Order && this.formReadOnly;
    }

    @computed
    public get orderScheduleLocked() {
        return !!(window.config.enableApplyFirstPickupEta && this.order && this.order.firstPickupEtaUtc);
    }

    @computed
    public get canCompletePickup() {
        if (!this.order) return false;
        return this.order.canCompletePickup && this.formReadOnly;
    }

    @computed
    public get canEdit() {
        if (!this.order) return false;

        if (this.order.status === OrderStatus.Order) {

            return this.formReadOnly && this.order.active && this.order.lifetimeStatus < OrderLifetimeStatus.ReceiptedIntoStock
                && !this.order.purchaseOrderPending;
        }
        if (this.order.status === OrderStatus.Quote) {
            return this.formReadOnly && this.order.active && !this.order.quoteExpired;
        }
        return false;
    }

    @computed
    public get canDelete() {
        if (!this.order) return false;

        if (this.orderScheduleLocked) {
            return false;
        }
        if (this.order.status === OrderStatus.Order) {
            return this.order.lifetimeStatus < OrderLifetimeStatus.ReceiptedIntoStock;
        }
        return true;
    }

    @computed
    public get canSaveQuoteChanges() {
        return this.quoteEditable && this.order && !this.order.hasCustomPricing && this.order.active;
    }

    @computed
    public get canShareQuote() {
        return this.quoteReadOnly && this.order && this.order.active && !this.order.quoteExpired;
    }

    @computed
    public get canShareOrder() {
        return this.orderReadOnly && this.order && this.order.active;
    }

    @computed
    public get canSaveAsOrder() {
        if (!this.order || this.order.quoteExpired || this.order.hasCustomPricing || !this.order.active) return false;
        return this.order.status === OrderStatus.Quote || this.order.orderWorkflow === OrderWorkflow.TransitioningToOrder;
    }

    @computed
    public get canSubmitCustomPricing() {
        return !this.formReadOnly && this.order && this.order.hasCustomPricing;
    }

    @computed
    public get canSubmitInDisputeCustomer() {
        return !this.formReadOnly && this.order && !this.order.hasCustomPricing && this.order.isInDispute && this.order.lifetimeStatus !== OrderLifetimeStatus.Complete;
    }

    @computed
    public get canSplitOrder() {
        return this.formReadOnly
            && this.order
            && this.order.canSplit;
    }

    @computed
    public get canSaveSplitOrder() {
        return !this.formReadOnly && this.order && this.order.status === OrderStatus.Order && this.order.isSplitting;
    }

    @computed
    public get canClone() {
        return this.formReadOnly && this.order && !this.order.isSplitting && !this.order.isSplitOrder && this.order.isActiveCustomer;
    }

    @computed
    public get canRefund() {
        return (
            this.formReadOnly &&
            this.order &&
            this.order.status === OrderStatus.Order &&
            this.order.lifetimeStatus === OrderLifetimeStatus.Complete &&
            this.order.refund.subTotalCredited === 0
        );
    }

    @computed
    public get canSubmitRefund() {
        return (
            !this.formReadOnly &&
            this.order &&
            this.order.status === OrderStatus.Order &&
            this.order.orderWorkflow === OrderWorkflow.RefundingOrder &&
            this.order.lifetimeStatus === OrderLifetimeStatus.Complete
        );
    }

    @computed
    public get canAddProduct() {
        return !this.isReadonly('addProduct') && !this.addServiceActive && !this.order!.isParentOrder && !this.addSupplierCommentActive;
    }

    @computed
    public get canDeleteProduct() {
        return !this.isReadonly('deleteProduct') && !this.order!.isParentOrder;
    }

    @computed
    public get canAddService() {
        return !this.isReadonly('addService') && !this.addProductActive && !this.addSupplierCommentActive;
    }

    @computed
    public get canAddSupplierComment() {
        return !this.isReadonly('addSupplierComment') && !this.addProductActive && !this.addServiceActive;
    }

    @computed
    public get canEditStockingPoint(){
        return !this.isReadonly('stockingPoint') && this.order!.isParentOrder;
    }

    @computed
    public get canManuallyComplete() {
        return this.formReadOnly && this.order && this.order.status === OrderStatus.Order && this.order.lifetimeStatus !== OrderLifetimeStatus.Complete;
    }

    @computed
    public get canResendInvoice() {
        return this.formReadOnly && this.order && this.order.status === OrderStatus.Order && this.order.lifetimeStatus === OrderLifetimeStatus.InvoiceError;
    }

    @computed
    public get needsProductCheck() {
        return this.formReadOnly
        && this.order
        && this.order.status === OrderStatus.Order
        && this.order.isAbcFrieghtJobsAccount && this.order.isSubContract
        && !this.order.events.find(e => e.eventType === OrderEventType.ProductChecked);
    }

    public canDeleteServiceLine(serviceLine: ServiceLine) {
        return !this.isReadonly('deleteService')
    }

    public canEditProductQuantity(productLine: ProductLine) {
      return computed(() => !this.isReadonly('productQuantity') && !this.order!.isParentOrder && !(window.config.enableApplyFirstPickupEta && productLine.jobPickedUp)).get();
    }

    public canEditPayment(paymentLine: PaymentLine) {
        return computed(() => !this.isReadonly('payments') && paymentLine.lifetimeStatus !== PaymentLifetimeStatus.Complete).get();
    }

    public canEditDeliveryAddress(): boolean {
        return computed(() => !this.isReadonly('deliveryAddress') && this.order!.lifetimeStatus < OrderLifetimeStatus.Delivered).get();
    }

    public isReadonly(field: string) {
        return computed(() => {
            if (!this.order) return true;
            if (this.order.status === OrderStatus.Quote || this.order.orderWorkflow === OrderWorkflow.TransitioningToOrder) {
                return this.formReadOnly;
            }
            if (this.order.status === OrderStatus.Order && this.order.orderWorkflow === OrderWorkflow.SplittingOrder) {
                return this.formReadOnly || !this.splittingOrderEditableFields.some(f => f === field);
            }
            if (this.order.status === OrderStatus.Order && this.order.orderWorkflow === OrderWorkflow.RefundingOrder) {
                return this.formReadOnly || !this.refundingOrderEditableFields.some(f => f === field);
            }
            if (this.order.isSplitOrder) {
                return this.formReadOnly || !this.splitOrderEditableFields.some(f => f === field);
            }

            if (this.order.status === OrderStatus.Order && this.orderScheduleLocked) {
                return this.formReadOnly || !this.scheduleLockedEditableFields.some(f => f === field);
            }
            
            if (this.order.status === OrderStatus.Order) {
                let readOnly = this.formReadOnly || !this.orderEditableFields.some(f => f === field);
                const deliveryToday = this.order.isDeliveryToday;
                readOnly = readOnly || window.config.restrictOrderModifyOnDeliveryDate && deliveryToday &&
                    !this.authStore.isPricingManager && !this.authStore.isMJDispatch && !this.orderSameDayEditableFields.some(f => f === field);
                // TODO: Temp toggle to override editing products and services for orders
                if (!readOnly && !window.config.editOrderItemsEnabled) {
                    if (['deleteProduct', 'deleteService', 'productQuantity', 'serviceQuantity', 'addProduct', 'addService', 'tierId', 'price'].some(f => f === field)) return true;
                }

                return readOnly;
            }
            return true;
        }).get();
    }

    private orderEditableFields: string[] = [
        'serviceNote',
        'note',
        'payments',
        'customerAdjustment',
        'deliveryDate',
        'deliveryAddress',
        'pickupDate',
        'freightDate',
        'supplierComment',
        'deliveryInstruction',
        'freightInstruction',
        'pickupInstruction',
        'deliveryTimeslot',
        'freightTimeslot',
        'isCrane',
        'deleteProduct',
        'deleteService',
        'productQuantity',
        'serviceQuantity',
        'addProduct',
        'addService',
        'tierId',
        'price',
        'phone',
        'email',
        'name',
        'customerRef',
        'leadIdentifier',
        'product-note',
        'subcontract',
        'address',
        'stockingPoint',
        'truckType',
        'deleteSupplierComment',
        'addSupplierComment',
        'noRegionFreightMessage'
    ];

    private orderSameDayEditableFields: string[] = [
        'payments',
        'phone',
        'email',
        'deliveryInstruction',
        'customerRef'
    ];

    private scheduleLockedEditableFields: string[] = [
        'serviceNote',
        'note',
        'payments',
        'customerAdjustment',
        'supplierComment',
        'deliveryInstruction',
        'freightInstruction',
        'pickupInstruction',
        'tierId',
        'price',
        'phone',
        'email',
        'name',
        'customerRef',
        'leadIdentifier',
        'product-note'
    ];

    private splittingOrderEditableFields: string[] = [
        'serviceNote',
        'note',
        'productQuantity',
        'serviceQuantity',
        'deleteProduct',
        'deleteService',
        'addService',
        'addSupplierComment',
        'payments',
        'deliveryInstruction',
        'deliveryTimeslot',
        'freightInstruction',
        'freightTimeslot',
        'pickupInstruction',
        'customerAdjustment',
        'deliveryDate',
        'supplierComment',
        'subcontract',
        'phone',
        'email',
        'name',
        'customerRef',
        'leadIdentifier',
        'product-note',
        'truckType'
    ];

    private splitOrderEditableFields: string[] = [
        'serviceNote',
        'note',
        'payments',
        'customerAdjustment',
        'deliveryDate',
        'pickupDate',
        'freightDate',
        'deleteService',
        'supplierComment',
        'deliveryInstruction',
        'freightInstruction',
        'pickupInstruction',
        'deliveryTimeslot',
        'freightTimeslot',
        'productQuantity',
        'serviceQuantity',
        'addService',
        'phone',
        'email',
        'name',
        'customerRef',
        'leadIdentifier',
        'product-note',
        'truckType'
    ];

    private refundingOrderEditableFields: string[] = ['credit', 'restockingFee', 'refundFromAddress', 'refundToAddress', 'pickupFee', 'pickupRequired'];
}
