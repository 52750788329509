import * as React from 'react';
import { withScriptjs, GoogleMap, withGoogleMap, Marker } from 'react-google-maps'
import { compose, withProps } from "recompose"
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import { reaction, IReactionDisposer } from 'mobx';

interface IAddressSearchProps {
    uiStore?: UiStore;
    lat: number | null;
    lng: number | null;
    forFieldName: string;
    moveMarker: boolean;
    handleClick(lat: number, lng: number): void;
}

interface IState {
    lat: number;
    lng: number;
    zoom: number;
    showMarker: boolean;
}

@inject('uiStore')
@observer
class AddressMap extends React.Component<IAddressSearchProps, IState> {

    private defaultStateAbcBricks = { lat: -27.93492, lng: 153.3676164, zoom: 8, showMarker: false };
    private reactionHandler: IReactionDisposer;

    constructor(props: IAddressSearchProps) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.state = this.defaultStateAbcBricks;

        // React to changes in the latLng observable. Then setState will re-render the map
        this.reactionHandler = reaction(
            () => this.props.uiStore!.addressMapUiState.latLng[this.props.forFieldName],
            latLng => {
                if (latLng.lat && latLng.lng) {
                    console.log("reaction to lat lng change:", latLng.lat, latLng.lng);
                    this.setState({ lat: latLng.lat, lng: latLng.lng, zoom: 18, showMarker: true });
                }
            }
        );
    }

    public componentDidMount() {
        const latLng = this.props.uiStore!.addressMapUiState.latLng[this.props.forFieldName];
        if (latLng && latLng.lat && latLng.lng) {
            console.log("mounted setting map to position:", latLng.lat, latLng.lng);
            this.setState({ lat: latLng.lat, lng: latLng.lng, zoom: 18, showMarker: true });
        }
    }

    public componentWillUnmount() {
        this.reactionHandler();
    }

    private handleClick(e: google.maps.MouseEvent | google.maps.IconMouseEvent) {
        if (!this.props.moveMarker) return;
        console.log({ lat: e.latLng.lat(), lng: e.latLng.lng() })
        this.props.handleClick(e.latLng.lat(), e.latLng.lng());
    }

    private Map = compose<{}, IAddressSearchProps>(
        withProps({
            googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${window.config.googleApiKey}&libraries=geometry,drawing,places`,
            loadingElement: <div style={{ height: `100%` }} />,
            containerElement: <div style={{ height: `208px` }} />,
            mapElement: <div style={{ height: `100%` }} />,
        }),
        withScriptjs,
        withGoogleMap
    )((props: any) =>
        <GoogleMap
            defaultZoom={12}
            zoom={this.state.zoom}
            center={{ lat: this.state.lat, lng: this.state.lng }}
            onClick={this.handleClick}
            options={{ disableDefaultUI: true }}>
            {
                this.state.showMarker
                && <Marker position={{ lat: this.state.lat, lng: this.state.lng }} />
            }
        </GoogleMap>);

    public render() {
        return <div className="address-map">
            <this.Map {...this.props} lat={this.state.lat} lng={this.state.lng} />
            {
                this.props.moveMarker
                && <p>Press &amp; hold on the map to set a specific location</p>
            }
        </div>
        
    }
}

export default AddressMap;