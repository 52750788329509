import { Configuration, PublicClientApplication, type RedirectRequest } from '@azure/msal-browser';

export const config: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/theabcgroup.onmicrosoft.com',
    clientId: window.config?.clientId ?? ' ',
    postLogoutRedirectUri: window.location.origin,
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  telemetry: {
    application: {
      appName: 'Mojoe Web',
      appVersion: window.config?.version ?? 'development',
    },
  },
};

export const loginRequest: RedirectRequest = {
  scopes: ['User.Read'],
};

export const msalInstance = new PublicClientApplication(config);

export function getClaimValue<T>(propertyName: string): T | null {
  const account = msalInstance.getActiveAccount();
  if (!account?.idTokenClaims) return null;
  const property = Object.keys(account.idTokenClaims).find((prop) => prop.indexOf(propertyName) > -1);
  if (!property) return null;
  const prop = account.idTokenClaims[property];
  if (!Array.isArray(prop)) return prop as T;
  return prop.length > 0 ? prop[0] : '';
}
