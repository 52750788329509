import * as React from 'react';
import './styles.css';
import { inject, observer } from 'mobx-react';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { WithStyles, withStyles, FormGroup, FormControlLabel, Checkbox, TextField, createStyles, Theme, Typography } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { UiStore } from '../../stores/ui-store';
import { SalesStore } from '../../stores/sales-store';
import { SuppliersStore, IPickupLocation } from 'src/stores/suppliers-store';
import { dateFormatted } from '../../datetime';
import { globalStyles } from '../../theme';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';

export interface IPickupTabProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore
    suppliersStore?: SuppliersStore
    uiStore?: UiStore
}

@inject('uiStore', 'salesStore', 'suppliersStore')
@observer
class PickupTab extends React.Component<IPickupTabProps, {}> {
    constructor(props: IPickupTabProps) {
        super(props);
        this.handleChanged = this.handleChanged.bind(this);
        this.handlePickupAddressSelected = this.handlePickupAddressSelected.bind(this);
        this.getPickupSuggestions = this.getPickupSuggestions.bind(this);
    }

    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        return (<div className="pickup">
        <Autocomplete
            label="Address"
            onSelect={this.handlePickupAddressSelected}
            getSuggestions={this.getPickupSuggestions}
            errorText={salesStore!.order.pickup.address.errors.formattedAddress}
            selectedItem={orderUiState.selectedPickupAddress}
            disabled={orderUiState!.isReadonly('address')}
            suggestionToComponent={{
                containerStyle: { height: "50px" },
                getComponent: (item: SuggestionItem<IPickupLocation>) => {
                    return (<div>
                        <div>{item.value.name}</div>
                        <div className="sub-text"><small>{item.value.address.formattedAddress}</small></div>
                    </div>);
                }
            }}
        />
        {
            this.props.salesStore!.order.hasNoRegion
            && <TextField
                className={this.props.classes.textField}
                InputProps={{ disableUnderline: true }}
                label="Freight Details"
                type="text"
                name="noRegionFreightMessage"
                error={!!salesStore!.order.pickup.errors.noRegionFreightMessage}
                helperText="Freight provider and their freight per tonne?"
                value={salesStore!.order.pickup.noRegionFreightMessage}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('noRegionFreightMessage', e.currentTarget.value)}
                disabled={orderUiState!.isReadonly('noRegionFreightMessage')}
                fullWidth
            />
        }
        <FormGroup row>
            <MuiPickersUtilsProvider utils={LuxonUtils}>
                <InlineDatePicker
                    className={this.props.classes.inlineDateField}
                    onlyCalendar
                    label={salesStore!.order.pickup.dateTba ? "Date is TBA" : "Date"}
                    value={salesStore!.order.pickup.date}
                    onChange={(date: DateTime) => this.handleChanged('date', date.startOf('day'))}
                    labelFunc={(date: DateTime) => dateFormatted(date)}
                    InputProps={{ disableUnderline: true }}
                    leftArrowIcon={<KeyboardArrowLeft />}
                    rightArrowIcon={<KeyboardArrowRight />}
                    error={!!salesStore!.order.pickup.errors.date}
                    helperText={salesStore!.order.pickup.errors.date || null}
                    maxDate={DateTime.local().plus({ months: 6 })}
                    minDate={DateTime.local().minus({ days: 1 })}
                    disabled={salesStore!.order.pickup.dateTba || orderUiState.isReadonly('pickupDate')}
                />
            </MuiPickersUtilsProvider>
            <FormControlLabel
                className={this.props.classes.checkBox}
                control={
                    <Checkbox
                        checked={salesStore!.order.pickup.dateTba}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => salesStore!.order.pickup.updateDateTba(checked)}
                        disabled={orderUiState.isReadonly('pickupDate')}
                    />
                }
                label="TBA"
            />
        </FormGroup>
        <TextField
            className={this.props.classes.textFieldFullWidth}
            InputProps={{ disableUnderline: true }}
            inputProps={{ maxLength: 200 }}
            label="Special Instructions"
            type="text"
            name="pickupInstruction"
            autoComplete="no"
            value={salesStore!.order.pickup.instruction}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('instruction', e.currentTarget.value)}
            disabled={orderUiState!.isReadonly('pickupInstruction')}
            fullWidth
            multiline
        />
        <FormControlLabel
            className={this.props.classes.checkBox}
            control={
                <Checkbox
                    name='subcontract'
                    checked={salesStore!.order.pickup.subcontract}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.handleChanged(e.currentTarget.name, checked)}
                    disabled={orderUiState.isReadonly('subcontract')}
                />
            }
            label={<Typography className={this.props.classes.checkBoxLabel}>Is this job for a sub-contractor?</Typography>}
        />
    </div>);
    }

    private handleChanged(property: string, value: any) {
        this.props.salesStore!.order.pickup.updateAndValidate(property, value);
    }

    private getPickupSuggestions(value: string): Promise<Array<SuggestionItem<IPickupLocation>>> {
        return this.props.suppliersStore!.searchPickupLocations(value);
    }

    private handlePickupAddressSelected(selected: SuggestionItem<IPickupLocation>) {
        this.props.salesStore!.order.pickup.updatePickupAddress(selected.value);
        this.props.uiStore!.orderUiState.setSelectedPickupAddress(selected);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    checkBox: {
        marginTop: -14,
        marginRight: 0,
    },
    checkBoxLabel: {
        fontSize: "1rem",
        color: theme.palette.text.secondary
    }
});

export default withStyles(styles)(PickupTab);
