import * as React from 'react';
import './styles.css';
import { TextField, Grid, Theme, createStyles, Checkbox, FormControlLabel, WithStyles, withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import { globalStyles } from 'src/theme';
import { ProductsStore } from 'src/stores/products-store';
import { Address } from 'src/models/address';

export interface ILocationFormProps extends WithStyles<typeof styles> {
    productsStore?: ProductsStore
    uiStore?: UiStore
}

@inject('productsStore', 'uiStore')
@observer
class LocationForm extends React.Component<ILocationFormProps> {
    private productsStore?: ProductsStore;

    constructor(props: ILocationFormProps) {
        super(props);
        this.handleAddressChanged = this.handleAddressChanged.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleChecked = this.handleChecked.bind(this);
        this.productsStore = props.productsStore;
    }

    public render() {
        const location = this.productsStore!.location;
        return <form>
            <Grid container className="section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Detail</h2>
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Name"
                        type="text"
                        name="name"
                        error={!!this.productsStore!.location.errors.name}
                        helperText={this.productsStore!.location.errors.name || null}
                        value={location.name}
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <AddressSearchBox
                        address={location.address.formattedAddress}
                        className="no-map"
                        handleAddressSelected={this.handleAddressChanged}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressChanged(new Address(
                            { ...location.address, formattedAddress: e.currentTarget.value }))
                        }
                        label="Address"
                        fieldName="address"
                        error={location.address.errors.formattedAddress} />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Code"
                        type="text"
                        name="code"
                        value={location.code}
                        disabled
                        fullWidth
                    />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="GL Set"
                        type="text"
                        name="glSet"
                        error={!!this.productsStore!.location.errors.glSet}
                        helperText={this.productsStore!.location.errors.glSet || null}
                        onChange={this.handleChange}
                        value={location.glSet}
                        fullWidth
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={location.isDeleted}
                                onChange={this.handleChecked}
                                name="isDeleted"
                            />
                        }
                        label="Deleted"
                    />

                </Grid>
            </Grid>
            {this.props.children}

        </form>
    }

    private handleAddressChanged(address: Address) {
        this.productsStore!.location.updateAndValidate('address', address);
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.productsStore!.location.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.productsStore!.location.update(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChecked(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) {
        this.productsStore!.location.update(e.currentTarget.name, checked);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    textFieldCost: {
        width: "70px",
        fontSize: "18px",
    }
});

export default withStyles(styles)(LocationForm);
