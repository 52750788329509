import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import { observer } from 'mobx-react';

export interface IResendInvoiceDialogProps {
    onCancel(): void
    onOk(): void
    prompt: string
    title: string
}

@observer
export class ResendInvoiceDialog extends React.Component<IResendInvoiceDialogProps> {
    constructor(props: IResendInvoiceDialogProps) {
        super(props);
    }

    public render() {
        return <div className="full-dialog">
            <Loader area="submit-workflow-dialog" />
            <div className="dialog-content">
                <h3>{this.props.title}</h3>
                <p>{this.props.prompt}</p>
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onCancel}>
                    <span>Cancel</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={() => this.props.onOk()}>
                    <span>Ok</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }
}