import * as React from 'react';
import './styles.css';
import { inject, observer } from 'mobx-react';
import { InlineDatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import LuxonUtils from '@date-io/luxon';
import { DateTime } from 'luxon';
import { WithStyles, withStyles, FormGroup, FormControlLabel, Checkbox, TextField, createStyles, Theme, Typography, Grid } from '@material-ui/core';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { TruckType, TimeslotType } from '../../models/order';
import { UiStore } from '../../stores/ui-store';
import { SalesStore } from '../../stores/sales-store';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import AddressMap from '../AddressSearch/AddressMap';
import { dateFormatted } from '../../datetime';
import { globalStyles } from '../../theme';
import { Address, LocationType } from 'src/models/address';
import Autocomplete, { SuggestionItem } from '../Autocomplete/Autocomplete';
import { IPickupLocation, SuppliersStore } from 'src/stores/suppliers-store';

export interface IDeliveryTabProps extends WithStyles<typeof styles> {
    salesStore?: SalesStore;
    uiStore?: UiStore;
    suppliersStore?: SuppliersStore
}

@inject('uiStore', 'salesStore', 'suppliersStore')
@observer
class DeliveryTab extends React.Component<IDeliveryTabProps, {}> {

    private readonly tabs: Array<{ name: string; value: boolean }> = [
        { name: 'Depot/Exworks', value: false },
        { name: 'Google', value: true }
    ];

    private readonly truckTypes: Array<{ name: string; value: TruckType }> = [
        { name: 'Standard', value: TruckType.Standard },
        { name: 'Body Truck', value: TruckType.BodyTruck }
    ];

    constructor(props: IDeliveryTabProps) {
        super(props);
        this.handleChanged = this.handleChanged.bind(this);
        this.getPickupSuggestions = this.getPickupSuggestions.bind(this);
    }

    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        const delivery = this.props.salesStore!.order.delivery;

        return (
            <div className='delivery'>
                <div className='inline-tabs'>
                    <ul>
                        {this.tabs.map((t, idx) => (
                                <li key={idx}>
                                    <a
                                        className={(salesStore!.order.delivery.address.locationType === LocationType.None) === t.value ? 'active' : ''}
                                        onClick={() => {
                                            if (orderUiState!.isReadonly('address')) return;
                                            this.handleChanged('address', new Address({ locationType: t.value ? LocationType.None : LocationType.Supplier }));
                                        }}
                                        key={t.value[0]}>
                                        {t.name}
                                    </a>
                                </li>
                            ))}
                    </ul>
                </div>
                {
                    salesStore!.order.delivery.address.locationType !== LocationType.None &&
                    <Autocomplete
                    label='Address'
                    onSelect={(item: SuggestionItem<IPickupLocation>) => this.handleChanged('address', item.value.address)}
                    getSuggestions={this.getPickupSuggestions}
                    errorText={salesStore!.order.delivery.address.errors.formattedAddres}
                    selectedItem={salesStore!.order.delivery.address.toSuggestionItem()}
                    disabled={orderUiState!.isReadonly('address')}
                    suggestionToComponent={{
                        containerStyle: { height: '50px' },
                        getComponent: (item: SuggestionItem<IPickupLocation>) => (
                            <div>
                                <div>{item.value.name}</div>
                                <div className='sub-text'>
                                    <small>{item.value.address.formattedAddress}</small>
                                </div>
                            </div>
                        )
                    }}
                />
                }
                {salesStore!.order.delivery.address.locationType === LocationType.None && (
                <AddressSearchBox
                    address={salesStore!.order.delivery.address.formattedAddress}
                    handleAddressSelected={(address: Address) => {
                            // Todo - do we intercept here to show a message if no address was found?

                            this.handleChanged('address', address)
                        }}
                    handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            this.handleChanged('address', new Address({ ...salesStore!.order.delivery.address, formattedAddress: e.currentTarget.value }))
                        }
                    label='Address'
                    fieldName='deliveryAddress'
                    error={salesStore!.order.delivery.address.errors.formattedAddress}
                    disabled={!orderUiState!.canEditDeliveryAddress()}>
                {salesStore!.order.hasNoRegion && (
                            <TextField
                                className={this.props.classes.textField}
                                InputProps={{ disableUnderline: true }}
                                label='Freight Details'
                                type='text'
                                name='noRegionFreightMessage'
                                error={!!salesStore!.order.delivery.errors.noRegionFreightMessage}
                                helperText='Freight provider and their freight per tonne?'
                                value={salesStore!.order.delivery.noRegionFreightMessage}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('noRegionFreightMessage', e.currentTarget.value)}
                                disabled={orderUiState!.isReadonly('noRegionFreightMessage')}
                                fullWidth
                            />
                        )}
                <AddressMap
                    forFieldName='deliveryAddress'
                    moveMarker={orderUiState.canEditDeliveryAddress()}
                    lat={salesStore!.order.delivery.address.latLng.lat}
                    lng={salesStore!.order.delivery.address.latLng.lng}
                            handleClick={(lat: number, lng: number) => {

                        if (orderUiState!.canEditDeliveryAddress()) {
                            // Find the nearest formatted address
                            // - todo reverse geocode lookup for lat lng
                            const geocoder = new google.maps.Geocoder();
                            const latlng = { lat, lng };

                            geocoder.geocode({ location: latlng},
                                (
                                    results: google.maps.GeocoderResult[],
                                    status: google.maps.GeocoderStatus
                                ) => {
                                    if (status === google.maps.GeocoderStatus.OK) {

                                        if (!results.length) return;
                                        const place = results[0];

                                        if (place) {
                                            const result = Address.fromPlace(place, latlng);

                                            orderUiState.setLatLng('deliveryAddress', latlng);

                                            this.handleChanged('address', result);
                                        }
                                    }
                                }
                            );

                        }

                        this.handleChanged('address', new Address({ ...salesStore!.order.delivery.address, latLng: { lat, lng } }));
                        }}
                />
                </AddressSearchBox>
                )}
                <FormGroup row>
                    <MuiPickersUtilsProvider utils={LuxonUtils}>
                        <InlineDatePicker
                            onlyCalendar
                            className={this.props.classes.inlineDateField}
                            label={salesStore!.order.delivery.dateTba ? 'Date is TBA' : 'Date'}
                            clearable
                            value={salesStore!.order.delivery.date}
                            onChange={(date: DateTime) => this.handleChanged('date', date.startOf('day'))}
                            labelFunc={(date: DateTime) => dateFormatted(date)}
                            InputProps={{ disableUnderline: true }}
                            leftArrowIcon={<KeyboardArrowLeft />}
                            rightArrowIcon={<KeyboardArrowRight />}
                            error={!!salesStore!.order.delivery.errors.date}
                            helperText={salesStore!.order.delivery.errors.date || null}
                            maxDate={DateTime.local().plus({ months: 6 })}
                            minDate={DateTime.local().minus({ days: 1 })}
                            disabled={salesStore!.order.delivery.dateTba || orderUiState.isReadonly('deliveryDate')}/>
                    </MuiPickersUtilsProvider>
                    <FormControlLabel
                        className={this.props.classes.checkBox}
                        control={
                            <Checkbox
                                checked={salesStore!.order.delivery.dateTba}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => salesStore!.order.delivery.updateDateTba(checked)}
                                disabled={orderUiState.isReadonly('deliveryDate')}
                            />
                        }
                        label='TBA'/>
                </FormGroup>
                <FormGroup row>
                    <FormControl disabled={orderUiState!.isReadonly('deliveryTimeslot')} className={this.props.classes.textField}>
                        <InputLabel htmlFor='deliveryTimeslot'>Timeslot</InputLabel>
                        <Select
                            disableUnderline
                            value={salesStore!.order.delivery.timeslot}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) => this.handleChanged('timeslot', e.target.value)}
                            inputProps={{ name: 'deliveryTimeslot', id: 'deliveryTimeslot' }}>
                            {this.props.uiStore!.timeslots.map(t => (
                                <MenuItem value={t.value} key={t.value}>
                                    {t.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    {salesStore!.order.delivery.timeslot === TimeslotType.Custom && (
                        <React.Fragment>
                            <TextField
                                className={this.props.classes.textField}
                                error={!!delivery.errors.deliveryStartTime}
                                helperText={delivery.errors.deliveryStartTime || null}
                                InputProps={{ disableUnderline: true }}
                                inputProps={{ maxLength: 5 }}
                                label='Start Time'
                                type='text'
                                name='DeliveryStartTime'
                                autoComplete='no'
                                value={delivery.deliveryStartTime}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('deliveryStartTime', e.currentTarget.value)}
                                disabled={orderUiState!.isReadonly('deliveryTimeslot')}
                            />
                            <TextField
                                className={this.props.classes.textField}
                                error={!!delivery.errors.deliveryEndTime}
                                helperText={delivery.errors.deliveryEndTime || null}
                                InputProps={{ disableUnderline: true }}
                                inputProps={{ maxLength: 5 }}
                                label='End Time'
                                type='text'
                                name='DeliveryEndTime'
                                autoComplete='no'
                                value={delivery.deliveryEndTime}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('deliveryEndTime', e.currentTarget.value)}
                                disabled={orderUiState!.isReadonly('deliveryTimeslot')}
                            />
                        </React.Fragment>
                    )}
                </FormGroup>
                <FormControl disabled={orderUiState!.isReadonly('truckType')} className={this.props.classes.textField}>
                    <InputLabel htmlFor='truckType'>Truck Type</InputLabel>
                    <Select
                        disableUnderline
                        value={salesStore!.order.delivery.truckType}
                        onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                salesStore!.order.delivery.update('truckType', e.target.value);
                                salesStore!.order.checkTruckType(salesStore!.order.delivery.truckType, this.props.uiStore!.notificationUiState);
                            }}
                        inputProps={{ name: 'truckType', id: 'truckType' }}>
                        {this.truckTypes.map(t => (
                                <MenuItem value={t.value} key={t.value}>
                                    {t.name}
                                </MenuItem>
                            ))}
                    </Select>
                </FormControl>
                <TextField
                    className={this.props.classes.textFieldFullWidth}
                    InputProps={{ disableUnderline: true }}
                    inputProps={{ maxLength: 200 }}
                    label='Special Instructions'
                    type='text'
                    name='deliveryInstruction'
                    autoComplete='no'
                    value={salesStore!.order.delivery.instruction}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleChanged('instruction', e.currentTarget.value)}
                    disabled={orderUiState!.isReadonly('deliveryInstruction')}
                    fullWidth
                    multiline/>
                <Grid container>
                    <Grid item xs={6} md={4} xl={3}>
                        <FormControlLabel
                            className={this.props.classes.checkBox}
                            control={
                                <Checkbox
                                    name='subcontract'
                                    checked={salesStore!.order.delivery.subcontract}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => this.handleChanged(e.currentTarget.name, checked)}
                                    disabled={orderUiState.isReadonly('subcontract')}
                                />
                            }
                            label={<Typography className={this.props.classes.checkBoxLabel}>Is this job for a sub-contractor?</Typography>}/>
                    </Grid>
                    <Grid item xs={6} md={4} xl={3}>
                        <FormControlLabel
                            className={this.props.classes.checkBox}
                            control={
                                <Checkbox
                                    checked={salesStore!.order.delivery.isCrane}
                                    name="isCrane"
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
                                        salesStore!.order.delivery.update('isCrane', checked);
                                    }}
                                    disabled={orderUiState.isReadonly('isCrane')}
                                />
                            }
                            label={<Typography className={this.props.classes.checkBoxLabel}>Meet crane on site?</Typography>}/>
                    </Grid>
                </Grid>
            </div>
);
}

private getPickupSuggestions(value: string): Promise<Array<SuggestionItem<IPickupLocation>>> {
        return this.props.suppliersStore!.searchPickupLocations(value);
    }

private handleChanged(property: string, value: any) {
        this.props.salesStore!.order.delivery.updateAndValidate(property, value);
    }
}

const styles = (theme: Theme) =>
createStyles({
...globalStyles,
checkBox: {
            marginTop: -14,
            marginRight: 0
        },
checkBoxLabel: {
            fontSize: '1rem',
            color: theme.palette.text.secondary
        }
});

export default withStyles(styles)(DeliveryTab);
