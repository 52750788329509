import { observable, action, computed } from 'mobx';
import { Model } from './model';
import { Order, OrderWorkflow} from './order';
import { DateTime } from 'luxon';

export abstract class DeliveryDate extends Model {

    @observable public date: DateTime | null | undefined = null;
    @observable public dateTba: boolean | undefined = false;
    @observable public order: Order;

    constructor(order: Order) {
        super();
        this.order = order;
    }

    @action
    public updateDateTba(isTba: boolean) {
        this.dateTba = isTba;
        if (isTba) this.updateAndValidate('date', null);
    }

    @action
    protected validateDate(val: DateTime) {
        this.errors.date = val && this.order.orderWorkflow === OrderWorkflow.TransitioningToOrder
            && val < DateTime.local().startOf('day')
            ? "Date cannot be in the past" : null;

        if (this.errors.date === null) {
            this.errors.date = val || this.dateTba
                ? null : "Provide a date or confirm date is TBA";
        }
    }

    @computed
    public get hasDeliveryDate(): boolean {
        return (this.date !== null && this.date !== undefined)
            && !this.dateTba;
    }
}