import { observable, runInAction, computed, action } from 'mobx';
import { Model } from './model';
import { Validate } from '../validate';
import { IProductLineResponse } from '../services/sales-api';
import { Order, RaisePurchaseOrderOn } from './order';
import { IProductDetail } from '../stores/products-store';

export class ProductLine extends Model {
    @observable public id: number = 0;
    @observable public name: string = '';
    @observable public quantity: number = 0;
    @observable public creditQuantity: number = 0;
    @observable public purchaseUnit: string = '';
    @observable public noPerPurchaseUnit: number = 0;
    @observable public price: number = 0;
    @observable public initialPrice: number = 0;
    @observable public acceptedPrice: number = 0;
    @observable public code: string = '';
    @observable public exWorksId: number = 0;
    @observable public exWorksName: string = '';
    @observable public parentId: number | undefined = undefined;
    @observable public raisePurchaseOrder: boolean = false;
    @observable public raisePurchaseOrderOn: RaisePurchaseOrderOn = RaisePurchaseOrderOn.None;
    @observable public supplierName: string = '';
    @observable public unitWeight: number = 0;
    @observable public note: string = '';
    @observable public productId: number = 0;
    @observable public buyUnitCost: number | undefined = 0;
    @observable public tierId: number = 0;
    @observable public totalSplitableQuantity: number = 0;
    @observable public lineNumber: number = 0;
    @observable public jobPickedUp: boolean = false;
    @observable public order?: Order;
    @observable public purchaseOrderNote: string = '';
    @observable public purchaseOrderNumber: string = '';
    @observable public showWarningMessageWhenPOUnchecked: boolean = false;
    @observable public chosenUnitWeight: number = 0;
    @observable public chosenNoOfPacks: number = 0;
    @observable public chosenNoOfPallets: number = 0;
    @observable public receiptedIntoStockQuantity: number = 0;
    @observable public isProductDeleted: boolean = false;
    @observable public exWorksPrice: number = 0;
    @observable public freightCost: number = 0;

    constructor(obj: Partial<ProductLine>) {
        super();
        runInAction(() => Object.assign(this, obj));
    }

    @computed
    public get hasCustomPrice(): boolean {
        return this.price !== this.acceptedPrice;
    }

    @computed
    public get hadPreviousCustomPrice(): boolean {
        return this.price !== this.initialPrice;
    }

    @computed
    public get hasCredit(): boolean {
        return this.creditQuantity > 0;
    }

    @computed
    public get unroundedSubtotal(): number {
        return this.price * this.quantity;
    }

    @computed
    public get totalCredited(): number {
        return this.price * this.creditQuantity;
    }

    @computed
    public get chosenUnitWeightInKilos(): number {
        return this.chosenUnitWeight * 1000;
    }

    @computed
    public get totalPieces(): number {
        return this.noPerPurchaseUnit * this.quantity;
    }

    @computed
    public get totalPiecesOverLimit(): boolean {
        const attacheLimit = 6;
        return Math.round(this.totalPieces).toString().length > attacheLimit;
    }

    @computed
    public get getUnitWeight(): number {
        // convert the chosen weight to kilos
        return this.chosenUnitWeight && this.chosenUnitWeight > 0 ? this.chosenUnitWeightInKilos : this.unitWeight;
    }

    @computed
    public get totalWeight(): number {
        return this.getUnitWeight * this.totalPieces;
    }

    @computed
    public get grossProfit(): string {
        
        if (this.exWorksPrice === null || this.exWorksPrice === 0 || this.quantity === 0) {
            return "Not Available"
        }
       
        const gp = (((this.price - (this.exWorksPrice + this.freightCost)) / this.price) * 100).toFixed(2);
        return gp.toString();
    }

    @computed
    public get isNew(): boolean {
        return this.id === 0 && typeof (this.parentId) === 'undefined';
    }

    @action
    public setAcceptedPrice(price: number) {
        this.updateAndValidate('price', price);
        this.acceptedPrice = price;
    }

    @action
    public setInitialPrice(price: number) {
        this.acceptedPrice = price;
        this.initialPrice = price;
        this.price = price;
    }

    @action
    public split() {
        this.parentId = this.id;
        this.id = 0;
        this.quantity = 0;
        this.totalSplitableQuantity = 0;
    }

    @action
    public setTier(tierId: number) {
        this.tierId = tierId;
    }

    @action
    public setBuyPrice(buyPrice: number) {
        this.buyUnitCost = buyPrice;
    }

    @action
    public setQuantity(qty: number) {
        this.quantity = qty;
    }

    @action
    public setExWorksPrice(price: number) {
        this.exWorksPrice = price;
    }

    @action
    public setFreightCost(cost: number) {
        this.freightCost = cost;
    }

    @action
    public handleChosenPalletsChange(property: string, value: any) {
        this.update(property, value);

        const error = this.oneOrOtherButNotBoth(value, this.chosenNoOfPacks);
        if (error) {
            this.setError('chosenNoOfPallets', error);
        }
        else {
            this.errors.chosenNoOfPallets = null;
            this.errors.chosenNoOfPacks = null;
        }
        return this.errors.chosenNoOfPallets === null;
    }

    @action
    public handleChosenPackChange(property: string, value: any) {
        this.update(property, value);

        const error = this.oneOrOtherButNotBoth(value, this.chosenNoOfPallets);
        if (error) {
            this.setError('chosenNoOfPacks', error);
        }
        else {
            this.errors.chosenNoOfPallets = null;
            this.errors.chosenNoOfPacks = null;
        }
        return this.errors.chosenNoOfPacks === null;
    }

    @action
    public allocateQuantities(quantity: number, parentProductLine?: ProductLine) {
        this.updateAndValidate('quantity', quantity);
        const parentLine = parentProductLine || this.order!.getParentProductLine(this.productId, this.lineNumber);
        const allocatedQuantity = this.order!.productQtyAllocated(this.productId, this.lineNumber);
        if (!parentLine || this.isNew) {
            // This is a parent order, update quantity available across entire split
            this.totalSplitableQuantity = this.addQuantities(allocatedQuantity, quantity);
            return;
        }
        // This is a child order, update quantity remaining on the parent
        // dont validate
        parentLine.update('quantity', this.subtractQuantities(this.subtractQuantities(parentLine.totalSplitableQuantity, allocatedQuantity), quantity));
    }

    // When adding quantities, we need to consider noPerPurchaseUnit as quantity can be < 1,
    // which sometimes causes precision loss when adding / subtracting
    // therefore, we always achieve whole numbers when multiplying with the quantity*noPerPurchaseUnit before calculating...
    // and after we divide again - this method helps add 2 qty values safely
    // returns qa + qb
    public addQuantities(qa: number, qb: number) {
        const result = Number((qa + qb).toFixed(10));
        return result;
    }
    // When adding quantities, we need to consider noPerPurchaseUnit as quantity can be < 1,
    // which sometimes causes precision loss when adding / subtracting
    // therefore, we always achieve whole numbers when multiplying with the quantity*noPerPurchaseUnit before calculating...
    // and after we divide again - this method helps subtract 2 qty values safely
    // returns qa - qb
    public subtractQuantities(qa: number, qb: number) {
        const result = Number((qa - qb).toFixed(10));
        return result;
    }

    public calculateAvailableQuantity(allocatedQuantity: number) {
        const availableQuantity = this.subtractQuantities(this.totalSplitableQuantity, allocatedQuantity);
        return Math.max(availableQuantity, 0);
    }

    @action
    public setRaisePurchaseOrderOn(value: boolean) {
        this.showWarningMessageWhenPOUnchecked = !value && this.raisePurchaseOrder;
        this.update("raisePurchaseOrder", value);
    }

    @action
    public overridePrice(price: number) {
        this.updateAndValidate('price', price);
    }

    @computed
    public get orderSplitQtyLabel() {
        if (!this.order || this.isNew) return;
        const parent = this.order.getParentProductLine(this.productId, this.lineNumber);
        const allocatedQty = this.order.productQtyAllocated(this.productId, this.lineNumber);
        return this.order.orderSplitQtyLabel(parent, allocatedQty, this.quantity, this.totalSplitableQuantity);
    }

    @computed
    public get quantityAvailable() {
        if (!this.order) return 0;
        const parent = this.order.getParentProductLine(this.productId, this.lineNumber);
        const allocatedQty = this.order.productQtyAllocated(this.productId, this.lineNumber);
        return this.order.quantityAvailable(parent, allocatedQty, this.quantity);
    }

    @computed
    public get hasValidPrice() {
        return this.initialPrice !== 0;
    }

    public acceptPriceChange(price: number) {
        return computed(() => (this.hasValidPrice && price >= this.initialPrice) || (this.order && (this.order.isOnlineStore || this.order.isAbcFrieghtJobsAccount))).get();
    }

    @computed
    public get unitsLabel() {
        return `Units ${this.purchaseUnit ? '(' + this.purchaseUnit + ')' : ''}`;
    }

    @computed
    public get raisePurchaseOrderIsDeferred() {
        return this.raisePurchaseOrderOn !== RaisePurchaseOrderOn.None;
    }

    public clone(): ProductLine {
        return new ProductLine({ ...this });
    }

    public static fromResponse(p: IProductLineResponse, order?: Order): ProductLine {
        // initialPrice will be set to the Accepted Price as we are only interested if they are reducing the price below the server accepted price
        return new ProductLine({
            ...p,
            initialPrice: p.initialPrice > 0 ? p.initialPrice : p.acceptedPrice,
            order
        });
    }

    @action
    public static fromProductDetail(d: IProductDetail, order: Order) {
        return new ProductLine({
            productId: d.id,
            name: d.productName,
            code: d.code,
            purchaseUnit: d.purchaseUnit,
            noPerPurchaseUnit: d.noPerPurchaseUnit,
            exWorksId: d.exWorks.id,
            exWorksName: d.exWorks.name,
            supplierName: d.exWorks.supplierName,
            unitWeight: d.unitWeight,
            exWorksPrice: d.exWorksPrice,
            freightCost: d.freightCost,
            order,
        });
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "price": (val: number) => {
            if (this.order!.isAbcFrieghtJobsAccount) {
                this.errors.price = val !== 0 ? 'Price must be 0' : null;
            }
            else
                this.errors.price = Validate.decimal(val) && val > 0 ? null : 'Must be more than 0';
            return this.errors.price === null;
        },
        "quantity": (val: number) => {
            if (!this.order) return true;

            this.errors.totalpieces = !this.totalPiecesOverLimit ? null : 'Too many pieces';

            const remaining = this.order.productQtyAvailable(this.productId, this.lineNumber);
            if (this.order.isParentOrder && remaining === 0 && val === 0) return true;

            this.errors.quantity = !Validate.decimal(val) || val <= 0 ? `Must be more than 0${this.order.isSplitting ? ' or delete item' : ''}` : null;
            if (this.errors.quantity === null) {
                this.errors.quantity = this.order.isSplitOrder && !this.isNew && val > remaining ? 'Allocation too large' : null;
            }
            return this.errors.quantity === null && this.errors.totalpieces === null;
        },
        "creditQuantity": (val: number) => {
            if (!val) return true;
            this.errors.creditQuantity = Validate.decimal(val) && val <= this.quantity ? null : `Must be between 1 and ${this.quantity}`;
            return this.errors.creditQuantity === null;
        },
        "buyUnitCost": (val: number) => {
            if (!val) return true;
            this.errors.buyUnitCost = Validate.decimal(val) && val >= 0 ? null : `Must be equal to or greater than 0`;
            return this.errors.buyUnitCost === null;
        }
    };

    private oneOrOtherButNotBoth(a: number, b: number) {
        if (a && b)
            return "Enter only Packs or Pallets";
        else return Validate.moreThanZeroIfHasValue(a);
    }
}
