import * as React from 'react';
import './styles.css';
import Close from '@material-ui/icons/Close';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { Loader } from '../Loader/Loader';
import { observer, inject } from 'mobx-react';
import { Workflow } from 'src/models/workflow';
import { WorkflowType } from 'src/models/workflow-type';
import { UiStore } from 'src/stores/ui-store';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { OrderStatus } from 'src/models/order';

export interface IApprovalDialogProps {
    onClose(): void
    onSubmit(): void
    workflow?: Workflow
    uiStore?: UiStore
    orderStatus: OrderStatus
}

@inject('uiStore')
@observer
export class ApprovalDialog extends React.Component<IApprovalDialogProps> {
    constructor(props: IApprovalDialogProps) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
    }

    public async componentDidMount() {
        if (!this.props.workflow) return;
        this.props.workflow!.setReason('');
    }

    public render() {
        return <div className="full-dialog">
            <Loader area="approval-dialog" />
            <div className="dialog-content">
                <h3>Confirm Request</h3>
                <p>
                    You are about to approve this request
                </p>
                {this.props.workflow && this.props.workflow!.workflowType === WorkflowType.InDispute && this.props.orderStatus === OrderStatus.Quote &&
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={this.props.uiStore!.orderUiState.saveAsQuoteForInDispute}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
                                    this.props.uiStore!.orderUiState.setSaveAsQuoteForInDispute(checked)}
                            />}
                        label="Keep order as Quote?"
                    />
                }
                {
                    this.props.workflow
                    && <TextField
                        InputProps={{ disableUnderline: true }}
                        label="Reason"
                        type="text"
                        name="reason"
                        error={!!this.props.workflow.errors.reason}
                        helperText={this.props.workflow.errors.reason || null}
                        value={this.props.workflow.reason}
                        onChange={this.handleChange}
                        fullWidth
                    />
                }
            </div>
            <div className="dialog-actions">
                <div className="close" onClick={this.props.onClose}>
                    <span>Cancel</span>
                    <Close className="icon" />
                </div>
                <div className="done" onClick={this.props.onSubmit}>
                    <span>Approve</span>
                    <ArrowForward className="icon" />
                </div>
            </div>
        </div>
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (!this.props.workflow) return;
        this.props.workflow.update(e.currentTarget.name, e.currentTarget.value);
    }
}