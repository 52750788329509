import { observable, action } from 'mobx';

export class CustomerUiState {
    @observable public activeCustomerView: CustomerView = CustomerView.Pricing;
    @observable public isPricingReadOnly: boolean = false;

    @action
    public setActiveCustomerView(view: CustomerView) {
        this.activeCustomerView = view;
    }

    @action
    public setIsPricingReadOnly(isReadOnly: boolean){
        this.isPricingReadOnly = isReadOnly;
    }
}

export enum CustomerView {
    Pricing
}