import * as React from 'react';
import './styles.css';
import { TextField, Grid, InputAdornment, Theme, createStyles, WithStyles, withStyles } from '@material-ui/core';
import { observer, inject } from 'mobx-react';
import { UiStore } from '../../stores/ui-store';
import { SuppliersStore } from 'src/stores/suppliers-store';
import AddressSearchBox from '../AddressSearch/AddressSearchBox';
import { globalStyles } from 'src/theme';
import { Address } from 'src/models/address';
import LuxonUtils from '@date-io/luxon';
import { InlineTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';
import { DateTime } from 'luxon';

export interface IExWorksFormProps extends WithStyles<typeof styles> {
    suppliersStore?: SuppliersStore
    uiStore?: UiStore
}

@inject('suppliersStore', 'uiStore')
@observer
class ExWorksForm extends React.Component<IExWorksFormProps> {
    private suppliersStore?: SuppliersStore;

    constructor(props: IExWorksFormProps) {
        super(props);
        this.handleAddressChanged = this.handleAddressChanged.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSelectChanged = this.handleSelectChanged.bind(this);
        this.handleExWorksPricingChange = this.handleExWorksPricingChange.bind(this);
        this.suppliersStore = props.suppliersStore;
    }

    public render() {
        return <form>
            <Grid container className="section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Detail</h2>
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Name"
                        type="text"
                        name="name"
                        error={!!this.suppliersStore!.exWorks.errors.name}
                        helperText={this.suppliersStore!.exWorks.errors.name || null}
                        value={this.suppliersStore!.exWorks.name}
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <AddressSearchBox
                        address={this.suppliersStore!.exWorks.address.formattedAddress}
                        className="no-map"
                        handleAddressSelected={this.handleAddressChanged}
                        handleChange={(e: React.ChangeEvent<HTMLInputElement>) => this.handleAddressChanged(new Address(
                            { ...this.suppliersStore!.exWorks.address, formattedAddress: e.currentTarget.value }))
                        }
                        label="Address"
                        fieldName="address"
                        error={this.suppliersStore!.exWorks.address.errors.formattedAddress} />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Orders Email Address"
                        type="text"
                        name="emailAddress"
                        error={!!this.suppliersStore!.exWorks.errors.emailAddress}
                        helperText={this.suppliersStore!.exWorks.errors.emailAddress || null}
                        value={this.suppliersStore!.exWorks.emailAddress}
                        onChange={this.handleChange}
                        fullWidth
                    />
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Dispatch Email Address"
                        type="text"
                        name="dispatchEmailAddress"
                        error={!!this.suppliersStore!.exWorks.errors.dispatchEmailAddress}
                        helperText={this.suppliersStore!.exWorks.errors.dispatchEmailAddress || null}
                        value={this.suppliersStore!.exWorks.dispatchEmailAddress}
                        onChange={this.handleChange}
                        fullWidth
                    />
                       <MuiPickersUtilsProvider utils={LuxonUtils}>
                            <InlineTimePicker
                                className={this.props.classes.textField}
                                label="Opening Time"
                                value={this.suppliersStore!.exWorks.openingTime}
                                onChange={(date) => this.handleOpeningTimeChanged(date)}
                                InputProps={{ disableUnderline: true }}
                                helperText={this.suppliersStore!.exWorks.errors.openingTime}
                                error={Boolean(this.suppliersStore!.exWorks.errors.openingTime)}

                            />
                             </MuiPickersUtilsProvider>
                             <MuiPickersUtilsProvider utils={LuxonUtils}>
                             <InlineTimePicker
                                label="Closing Time"
                                className={this.props.classes.textField}
                                value={this.suppliersStore!.exWorks.closingTime}
                                onChange={(date) => this.handleClosingTimeChanged(date)}
                                InputProps={{ disableUnderline: true }}
                                helperText={this.suppliersStore!.exWorks.errors.closingTime}
                                error={Boolean(this.suppliersStore!.exWorks.errors.closingTime)}
                            />
                        </MuiPickersUtilsProvider>
                    <TextField
                        className={this.props.classes.textField}
                        InputProps={{ disableUnderline: true }}
                        label="Code"
                        type="text"
                        name="code"
                        value={this.suppliersStore!.exWorks.code}
                        disabled
                        fullWidth
                    />
                </Grid>
            </Grid>
            <Grid container className="section">
                <Grid item xs={1} />
                <Grid item xs={10}>
                    <h2>Freight Pricing</h2>
                    {
                        this.suppliersStore!.exWorks.freightCosts.map((fc, i) => (
                            <Grid container className="item" key={i}>
                                <Grid item xs={8}><span>{fc.region}</span></Grid>
                                <Grid item xs={4} className="cost">
                                    <TextField
                                        className={this.props.classes.textFieldCost}
                                        error={!!fc.errors.costPerTonne}
                                        helperText={fc.errors.costPerTonne}
                                        type="text"
                                        name="costPerTonne"
                                        value={fc.costPerTonne === null ? '' : fc.costPerTonne}
                                        onChange={(e) => this.handleExWorksPricingChange(i, e)}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            {this.props.children}

        </form>
    }

    private handleAddressChanged(address: Address) {
        this.suppliersStore!.exWorks.updateAndValidate('address', address);
    }

    private handleBlur(e: React.ChangeEvent<HTMLInputElement>) {
        this.suppliersStore!.exWorks.updateAndValidate(e.currentTarget.name, e.currentTarget.value);
    }

    private handleExWorksPricingChange(index: number, e: any) {
        return this.suppliersStore!.exWorks.freightCosts[index].update(e.currentTarget.name, e.currentTarget.value);
    }

    private handleChange(e: React.ChangeEvent<HTMLInputElement>) {
        this.suppliersStore!.exWorks.update(e.currentTarget.name, e.currentTarget.value);
    }

    private handleSelectChanged(e: React.ChangeEvent<HTMLSelectElement>) {
        this.props.suppliersStore!.exWorks.updateAndValidate(e.target.name, e.target.value);
    }

    private handleOpeningTimeChanged(time: DateTime) {
        this.suppliersStore!.exWorks.updateAndValidate('openingTime', time);
    }
    private handleClosingTimeChanged(time: DateTime) {
        this.suppliersStore!.exWorks.updateAndValidate('closingTime', time);
    }
}

const styles = (theme: Theme) => createStyles({
    ...globalStyles,
    textFieldCost: {
        width: "70px",
        fontSize: "18px",
    }
});

export default withStyles(styles)(ExWorksForm);
