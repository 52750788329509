import * as React from 'react';
import { inject, observer } from 'mobx-react';
import { SalesStore } from '../../stores/sales-store';
import { UiStore } from '../../stores/ui-store';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import tracker from '../Loader/loader-tracker';
import { globalStyles } from 'src/theme';
import { WithStyles, withStyles, FormControl, InputLabel } from '@material-ui/core';
import { ProductsStore } from 'src/stores/products-store';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { Warning } from '@material-ui/icons';

export interface IStockingPointProps extends WithStyles<typeof globalStyles> {
    salesStore?: SalesStore
    productsStore?: ProductsStore
    uiStore?: UiStore
}

@inject('salesStore', 'productsStore', 'uiStore')
@observer
class StockingPoint extends React.Component<IStockingPointProps> {

    private productsStore?: ProductsStore;

    constructor(props: IStockingPointProps) {
        super(props);
        this.productsStore = this.props.productsStore;
    }

    public async componentDidMount() {
        await tracker.track(this.productsStore!.getStockingPoints());
    }

    public render() {
        const { salesStore } = this.props;
        const { orderUiState } = this.props.uiStore!;
        const disableChangeStockingPoint = salesStore && salesStore.order.hasActiveChildOrders;

        return (<FormControl
            disabled={orderUiState!.isReadonly('stockingPoint') || !salesStore!.order.readyForProductLines || disableChangeStockingPoint}
            className={this.props.classes.textField}>
            <InputLabel shrink htmlFor="stockingPoint">Stocking Point</InputLabel>
            <Select
                disableUnderline
                displayEmpty={true}
                value={salesStore!.order.stockingPoint}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => salesStore!.order.update('stockingPoint', e.target.value)}
                inputProps={{ name: 'stockingPoint', id: 'stockingPoint' }}>
                {
                    this.productsStore!.stockingPoints.map((s, i) => (<MenuItem value={s.code} key={i}>{s.name}</MenuItem>))
                }
                {
                     salesStore!.order.isOnlineStore && !salesStore!.order.isNew &&
                     <MenuItem value="OL" key={this.productsStore!.stockingPoints.length + 1}>Online</MenuItem>
                }
            </Select>
            {
            !orderUiState.formReadOnly && disableChangeStockingPoint &&
                <div className="stockingpoint-disabled-warning">
                    <IconTooltip title="The stocking point cannot be changed - this order has active split orders." placement="left">
                        {{ icon: <Warning color="error" /> }}
                    </IconTooltip>
                </div>
            }
        </FormControl>
        )
    }
}

export default withStyles(globalStyles)(StockingPoint);