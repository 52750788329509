import { observable, action } from "mobx";

export class Tracker {
    @observable private promiseCount: number = 0;
    @observable private isComplete: boolean = true;

    get complete(): boolean {
        return this.isComplete;
    }

    @action
    public track(promise: Promise<any>) {
        const complete = () => {
            this.promiseCompleted();
            return promise;
        };
        const reject = (e: any) => {
            this.promiseCompleted();
            return Promise.reject(e);
        };

        this.isComplete = false;
        this.promiseCount++;

        return promise.then(complete, reject);
    }

    @action
    private promiseCompleted() {
        this.promiseCount = Math.max(0, this.promiseCount - 1);
        this.isComplete = this.promiseCount === 0;
    }
}