import { apiFetch } from '../utilities/apiFetch';
import {
  Order,
  type DeliveryType,
  type TimeslotType,
  type TruckType,
  type OrderStatus,
  type PaymentType,
  type OrderEventType,
  type OrderLifetimeStatus,
  type RaisePurchaseOrderOn,
} from '../models/order';
import { OrdersFilter } from 'src/models/orders-filter';
import type { WorkflowStatus } from 'src/models/workflow-status';
import type { QuoteCancellation } from 'src/models/quote-cancellation';
import type { QuoteShared } from 'src/models/quote-shared';
import type { PurchaseOrderStatus } from 'src/models/purhcase-order-status';
import type { ILatLng, LocationType } from 'src/models/address';
import type { PaymentLifetimeStatus } from 'src/models/payment-line';

export class SalesApi {
  public async approveCustomPricing(approval: IWorkflowApproval): Promise<boolean> {
    const response = await apiFetch('/api/ordercustompricing/approve', {
      method: 'post',
      body: JSON.stringify(approval),
    });
    return response?.ok ?? false;
  }

  public async approveCustomerInDispute(order: Order, reason: string): Promise<boolean> {
    const response = await apiFetch('/api/ordercustomerindispute/approve', {
      method: 'post',
      body: JSON.stringify({
        vm: Order.toRequest(order),
        reason,
      }),
    });
    return response?.ok ?? false;
  }

  public async approveOrderRefund(approval: IWorkflowApproval): Promise<boolean> {
    const response = await apiFetch('/api/orderrefund/approve', {
      method: 'post',
      body: JSON.stringify(approval),
    });
    return response?.ok ?? false;
  }

  public async approveOrderCancellation(approval: IWorkflowApproval): Promise<boolean> {
    const response = await apiFetch('/api/ordercancellation/approve', {
      method: 'post',
      body: JSON.stringify(approval),
    });
    return response?.ok ?? false;
  }

  public async getQuotes(filter: OrdersFilter): Promise<IQuoteSummaryResponse[]> {
    const response = await apiFetch('/api/ordersummary/quotes', {
      method: 'post',
      body: OrdersFilter.toJson(filter),
    });
    const json = await response?.json();
    return json ?? [];
  }

  public async getOrders(filter: OrdersFilter): Promise<IOrderSummaryResponse[]> {
    const response = await apiFetch('/api/ordersummary/orders', {
      method: 'post',
      body: OrdersFilter.toJson(filter),
    });
    const json = await response?.json();
    return json ?? [];
  }

  public async rejectCustomPricing(rejection: IWorkflowApproval): Promise<boolean> {
    const response = await apiFetch('/api/ordercustompricing/reject', {
      method: 'post',
      body: JSON.stringify(rejection),
    });
    return response?.ok ?? false;
  }

  public async rejectCustomerInDispute(rejection: IWorkflowApproval): Promise<boolean> {
    const response = await apiFetch('/api/ordercustomerindispute/reject', {
      method: 'post',
      body: JSON.stringify(rejection),
    });
    return response?.ok ?? false;
  }

  public async rejectOrderCancellation(rejection: IWorkflowApproval): Promise<boolean> {
    const response = await apiFetch('/api/ordercancellation/reject', {
      method: 'post',
      body: JSON.stringify(rejection),
    });
    return response?.ok ?? false;
  }

  public async rejectOrderRefund(rejection: IWorkflowApproval): Promise<boolean> {
    const response = await apiFetch('/api/orderrefund/reject', {
      method: 'post',
      body: JSON.stringify(rejection),
    });
    return response?.ok ?? false;
  }

  public async submitOrderCancellation(approvalRequest: IApprovalRequest): Promise<boolean> {
    const response = await apiFetch('/api/ordercancellation/submit', {
      method: 'post',
      body: JSON.stringify(approvalRequest),
    });
    return response?.ok ?? false;
  }

  public async saveOrder(order: Order): Promise<string> {
    const response = await apiFetch('/api/orders', {
      method: 'post',
      body: order.serialized,
    });
    const json = await response?.json();
    return json ?? '';
  }

  public async splitOrder(order: Order): Promise<string> {
    const response = await apiFetch('/api/orders/split', { method: 'post', body: order.serialized });
    const json = await response?.json();
    return json ?? '';
  }

  public async updateOrder(order: Order): Promise<boolean> {
    const response = await apiFetch('/api/orders', {
      method: 'put',
      body: order.serialized,
    });
    return response?.ok ?? false;
  }

  public async manuallyCompleteOrder(orderNumber: string): Promise<boolean> {
    const response = await apiFetch('/api/orders/manually-complete', {
      method: 'put',
      body: JSON.stringify({ orderNumber }),
    });
    return response?.ok ?? false;
  }

  public async resendInvoice(orderNumber: string): Promise<boolean> {
    const response = await apiFetch(`/api/orders/resend-invoice`, {
      method: 'put',
      body: JSON.stringify({ orderNumber }),
    });
    return response?.ok ?? false;
  }

  public async confirmProductCheck(orderNumber: string): Promise<boolean> {
    const response = await apiFetch(`/api/orders/confirm-product-check`, {
      method: 'put',
      body: JSON.stringify({ orderNumber }),
    });
    return response?.ok ?? false;
  }

  public async cancelQuote(orderNumber: string, cancellation: QuoteCancellation): Promise<boolean> {
    const response = await apiFetch(`/api/quotecancellation`, {
      method: 'post',
      body: JSON.stringify({
        orderNumber,
        reason: cancellation.reason,
      }),
    });
    return response?.ok ?? false;
  }

  public async shareQuote(orderNumber: string, share: QuoteShared, asInvoice: boolean = false): Promise<boolean> {
    const response = await apiFetch(`/api/sharequote/email`, {
      method: 'post',
      body: JSON.stringify({
        orderNumber,
        asInvoice,
        ...share,
      }),
    });
    return response?.ok ?? false;
  }

  public async downloadQuote(orderNumber: string, asInvoice: boolean = false): Promise<Blob | null> {
    const response = await apiFetch(`/api/sharequote/download/${orderNumber}?asinvoice=${asInvoice}`);
    const blob = await response?.blob();
    return blob ?? null;
  }

  public async downloadOrderConfirmation(orderNumber: string): Promise<Blob | null> {
    const response = await apiFetch(`/api/sharequote/orderconfirmation/${orderNumber}`);
    const blob = await response?.blob();
    return blob ?? null;
  }

  public async submitRefund(order: Order): Promise<boolean> {
    const response = await apiFetch(`/api/orderrefund`, {
      method: 'post',
      body: JSON.stringify({
        orderNumber: order.orderNumber,
        request: order.orderRefund,
        productLines: order.productLines.map((p) => ({ ...p, order: null })),
        refund: {
          ...order.refund,
          creditTotal: order.refund.subTotalCredited,
          order: null,
        },
      }),
    });
    return response?.ok ?? false;
  }

  public async searchDeliveryAddresses(query: string): Promise<string[]> {
    const response = await apiFetch(`/api/orders/deliveryAddresses/${query}`);
    const json = await response?.json();
    return json ?? [];
  }

  public async getRegion(lat: number, lng: number): Promise<number> {
    const response = await apiFetch(`/api/locations/region`, {
      method: 'post',
      body: JSON.stringify({
        lat,
        lng,
      }),
    });
    const json = await response?.json();
    return json ?? Number.MIN_SAFE_INTEGER;
  }

  public async loadOrder(orderNumber: string): Promise<IOrderResponse> {
    const response = await apiFetch(`/api/orders/${orderNumber}`);
    return response?.json();
  }

  public async cloneOrder(orderNumber: string): Promise<IOrderResponse> {
    const response = await apiFetch(`/api/orders/clone/${orderNumber}`);
    return response?.json();
  }

  public async searchOrderNumberByOrderOrPoNumber(orderOrPoNumber: string): Promise<string> {
    const response = await apiFetch(`/api/orders/search/${orderOrPoNumber}`);
    const json = await response?.json();
    return json ?? '';
  }

  public async hasDeletedProducts(orderId: number): Promise<boolean> {
    const response = await apiFetch(`/api/orders/has-deleted-products/${orderId}`, {
      method: 'get',
    });
    const json = await response?.json();
    return json ?? false;
  }

  public async completePickup(orderId: number): Promise<boolean> {
    const response = await apiFetch(`/api/test/completepickup`, {
      method: 'post',
      body: JSON.stringify({
        orderId,
      }),
    });
    return response?.ok ?? false;
  }

  public async getStockInfo(locationCode: string, productIds: number[]): Promise<IStockInfo[]> {
    const response = await apiFetch(`/api/stock`, {
      method: 'post',
      body: JSON.stringify({
        locationCode,
        productIds,
      }),
    });
    const json = await response?.json();
    return json ?? [];
  }
}

export interface IWorkflowApproval {
  orderNumber: string;
  reason: string;
}

export interface IInDisputeWorkflow {
  order: Order;
}

export interface IApprovalRequest {
  orderNumber: string;
  reason: string;
}

export interface IExWorksResponse {
  address: IAddressResponse;
  code: string;
  emailAddress: string;
  dispatchEmailAddress: string;
  id: number;
  name: string;
  supplierId: number;
  freightCosts: IExWorksFreightCostResponse[];
  openingTime: string;
  closingTime: string;
}

export interface IExWorksFreightCostResponse {
  id: number;
  region: string;
  regionId: number;
  costPerTonne: number | null;
}

export interface IQuoteSummaryResponse {
  orderId: number;
  customer: string;
  isCustomerInDispute: boolean;
  number: string;
  address: string;
  createdDate: string;
  isActive: boolean;
  isQuoteExpired: boolean;
  isQuoteExpiring: boolean;
  pricingApprovalStatus: WorkflowStatus;
}

export interface IOrderSummaryResponse {
  orderId: number;
  customer: string;
  isCustomerInDispute: boolean;
  number: string;
  address: string;
  deliveryDate: string;
  createdDate: string;
  isPaymentPending: boolean;
  isActive: boolean;
  purchaseOrderStatus: PurchaseOrderStatus;
  customerReference: string;
}

export interface IWorkflowResponse {
  approvalGroup: string;
  reason: string;
  rejectionReason: string;
  workflowStatus: WorkflowStatus;
}

export interface IOrderResponse {
  id: number;
  canSplit: boolean;
  parentOrder: IRelatedOrderResponse;
  splitOrders: IRelatedOrderResponse[];
  orderNumber: string;
  orderCancellation: IWorkflowResponse | null;
  customPricing: IWorkflowResponse | null;
  customerInDispute: IWorkflowResponse | null;
  orderRefund: IWorkflowResponse | null;
  customer: number;
  customerName: string;
  customerRef: string;
  leadIdentifier: string;
  isCustomerInDispute: boolean;
  maxOrderAmountForCustomer: number;
  stockingPoint: string;
  isCashSalesAccount: boolean;
  isActiveCustomer: boolean;
  isOnlineStore: boolean;
  lastModifiedTimeStamp?: string;
  isAbcFrieghtJobsAccount: boolean;
  customerAdjustment: number;
  fuelLevyServicePrice: number;
  name: string;
  phone: string;
  email: string;
  firstPickupEtaUtc: string;
  deliveryType: DeliveryType;
  serviceLines: IServiceLineResponse[];
  productLines: IProductLineResponse[];
  paymentLines: IPaymentLineResponse[];
  supplierCommentLines: ISupplierCommentResponse[];
  status: OrderStatus;
  createdDate: string;
  modifiedDate: string;
  owner: string;
  quoteExpiryDate: string;
  note: string;
  active: boolean;
  events: IOrderEventResponse[];
  totalCost: number;
  isFreightOnly: boolean;
  freightOnly: IFreightOnlyResponse;
  delivery: IDeliveryResponse;
  pickup: IPickupResponse;
  clonedFrom: string;
  lifetimeStatus: OrderLifetimeStatus;
  refund: IRefundResponse;
  purchaseOrderPending: boolean;
}

export interface IPaymentLineResponse {
  id: number;
  amount: number;
  type: PaymentType;
  receivedDate: string;
  paymentLocation: string;
  paymentLocationId: number;
  lifeTimeStatus: PaymentLifetimeStatus;
}

export interface IServiceLineResponse {
  id: number;
  serviceId: number;
  code: string;
  name: string;
  price: number;
  quantity: number;
  purchaseUnit: string;
  note: string;
  totalSplitableQuantity: number;
  isSupplementary: boolean;
  lineNumber: number;
  chosenUnitWeight: number;
  chosenNoOfPacks: number;
  chosenNoOfPallets: number;
}

export interface IProductLineResponse {
  id: number;
  acceptedPrice: number;
  buyUnitCost: number;
  chosenUnitWeight: number;
  chosenNoOfPacks: number;
  chosenNoOfPallets: number;
  code: string;
  creditQuantity: number;
  exWorksId: number;
  exWorksName: string;
  jobPickedUp: boolean;
  lineNumber: number;
  name: string;
  noPerPurchaseUnit: number;
  note: string;
  parentId: number | undefined;
  price: number;
  productId: number;
  purchaseOrderNote: string;
  purchaseOrderNumber: string;
  purchaseUnit: string;
  quantity: number;
  raisePurchaseOrder: boolean;
  raisePurchaseOrderOn: RaisePurchaseOrderOn;
  supplierName: string;
  tierId: number;
  totalSplitableQuantity: number;
  unitWeight: number;
  isProductDeleted: boolean;
  initialPrice: number;
}

export interface ISupplierCommentResponse {
  id: number;
  supplierName: string;
  exWorksName: string;
  exWorksId: number | undefined;
  comment: string;
}

export interface IOrderEventResponse {
  eventType: OrderEventType;
  date: string;
  repCode: string;
  payload:
    | string
    | IPurchaseOrderFailurePayload
    | IShareQuoteEventPayload
    | IPurchaseOrderSuccessPayload
    | ISentToDispatchPayload
    | IWorkflowPayload
    | IGrnRaisedEventPayload;
}

export interface IWorkflowPayload {
  reason: string;
  comment?: string;
}

export interface IShareQuoteEventPayload {
  email: string;
  name: string;
}

export interface ISentToDispatchPayload {
  jobId: string;
}

export interface IPurchaseOrderFailurePayload {
  supplierName: string;
}

export interface IPurchaseOrderSuccessPayload {
  purchaseOrderNumber: string;
  supplierName: string;
}

export interface IUpdatePurchaseOrderPayloadPayload {
  purchaseOrderNumber: string;
  supplierName: string;
  supplierId: string;
}

export interface IGrnRaisedEventPayload extends IUpdatePurchaseOrderPayloadPayload {
  asapJobGuid: string;
}

export interface IPaymentPayload {
  Id: string;
  Amount: string;
}

export interface IAllocationPayload {
  invoiceAuditNo: number;
  receiptAuditNo: number;
}

export interface IConsignmentNotesPayload {
  dateTba?: boolean;
  deliveryDate?: string;
}

export interface IRelatedOrderResponse {
  id: number;
  orderNumber: string;
  serviceLines: IServiceLineResponse[];
  supplierCommentLines: ISupplierCommentResponse[];
  productLines: IProductLineResponse[];
  paymentLines: IPaymentLineResponse[];
  totalCost: number;
  customerAdjustment: number;
  lifetimeStatus: OrderLifetimeStatus;
  active: boolean;
}

export interface IFreightOnlyResponse {
  deliveryEndTime: string;
  deliveryStartTime: string;
  fromAddress: IAddressResponse;
  toAddress: IAddressResponse;
  date: string;
  dateTba: boolean;
  isCrane: boolean;
  timeslot: TimeslotType;
  instruction: string;
  truckType: TruckType;
  subcontract: boolean;
}

export interface IDeliveryResponse {
  address: IAddressResponse;
  date: string;
  dateTba: boolean | undefined;
  deliveryEndTime: string;
  deliveryStartTime: string;
  isCrane: boolean;
  timeslot: TimeslotType;
  instruction: string;
  truckType: TruckType;
  noRegionFreightMessage: string;
  subcontract: boolean;
}

export interface IPickupResponse {
  address: IAddressResponse;
  date: string;
  dateTba: boolean | undefined;
  instruction: string;
  noRegionFreightMessage: string;
  subcontract: boolean;
}

export interface IAddressResponse {
  exWorksId?: number;
  formattedAddress: string;
  name: string;
  street: string;
  suburb: string;
  state: string;
  postcode: string;
  latLng: ILatLng;
  locationType: LocationType;
}

export interface IRefundResponse {
  fromAddress: IAddressResponse;
  toAddress: IAddressResponse;
  pickupFee: number;
  restockingFee: number;
  creditTotal: number;
  pickupRequired: boolean;
}

export interface IStockInfo {
  productId: number;
  productCode: string;
  productDescription: string;
  locationId: number;
  locationName: string;
  locationCode: string;
  quantityAvailable: number;
}
