import * as React from 'react';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { IRangeSummary } from '../../models/range-summary';

interface IRangesProps {
    ranges: IRangeSummary[];
}

type Props = IRangesProps & RouteComponentProps<{}>;

const Ranges: React.SFC<Props> = (props) => {
    return <div>
        {
            props.ranges.map(s => {
                return <Grid container className="item" key={s.id}
                    onClick={() => { props.history.push(`ranges/${s.id}`); }} >
                    <Grid item xs={1} />
                    <Grid item xs={11} className="name">{s.name}</Grid>
                    <Grid item xs={1} />
                    <Grid item xs={11} className="summary">
                        <ul>
                            {
                                s.markups.map((m) => <li key={m.tier}><span className="tier-name">{m.tier}</span> {m.percentageMarkup}%</li>)
                            }
                            {
                                s.markups.length === 0
                                && <li>NOT SPECIFIED</li>
                            }
                        </ul>
                    </Grid>
                </Grid>
            })
        }
    </div>
};

export default Ranges;