import * as React from 'react';
import Chip from "@material-ui/core/Chip";
import Grid from '@material-ui/core/Grid/Grid';
import { observer, inject } from 'mobx-react';
import { SalesStore } from 'src/stores/sales-store';
import { UiStore } from 'src/stores/ui-store';
import { Validate } from 'src/validate';
import { OrderStatus } from 'src/models/orders-filter';

interface IFilterStatusProps {
    salesStore?: SalesStore;
    uiStore?: UiStore;
    onFilterRemoved(): void;
}

@inject('salesStore', 'uiStore')
@observer
export class FilterStatus extends React.Component<IFilterStatusProps> {

    public render() {
        const { salesStore, uiStore, onFilterRemoved } = this.props;
        return <Grid container className="search-state">
            <Grid item xs={1} />
            <Grid item xs={10}>
                <div className="chips">
                    {
                        salesStore!.ordersFilter.filterByRep
                        && <Chip className="chip" label={"My " + (salesStore!.ordersFilter.orderStatus === OrderStatus.Order ? "Orders" : "Quotes")} />
                    }
                    {
                        salesStore!.ordersFilter.customerId !== 0
                        && <Chip className="chip" label="Customer" onDelete={() => {
                            salesStore!.ordersFilter.setCustomerId(0);
                            uiStore!.orderSearchUiState.setCustomerSuggestion(undefined);
                            onFilterRemoved();
                        }} />
                    }
                    {
                        Validate.hasValue(salesStore!.ordersFilter.siteContact)
                        && <Chip className="chip" label="Customer Details" onDelete={() => {
                            salesStore!.ordersFilter.setSiteContact('');
                            uiStore!.orderSearchUiState.setSiteContactSuggestion(undefined);
                            onFilterRemoved();
                        }} />
                    }
                    {
                        Validate.hasValue(salesStore!.ordersFilter.leadIdentifier)
                        && <Chip className="chip" label="Lead Identifer" onDelete={() => {
                            salesStore!.ordersFilter.setLeadIdentifier('');
                            onFilterRemoved();
                        }} />
                    }
                     {
                        Validate.hasValue(salesStore!.ordersFilter.customerRef)
                        && <Chip className="chip" label="Customer Ref" onDelete={() => {
                            salesStore!.ordersFilter.setCustomerRef('');
                            onFilterRemoved();
                        }} />
                    }
                    {
                        Validate.hasValue(salesStore!.ordersFilter.repCode)
                        && <Chip className="chip" label="Owner" onDelete={() => {
                            salesStore!.ordersFilter.setOwnerRepCode('');
                            uiStore!.orderSearchUiState.setOwnerSuggestion(undefined);
                            onFilterRemoved();
                        }} />
                    }
                    {
                        Validate.hasValue(salesStore!.ordersFilter.address)
                        && <Chip className="chip" label="Address" onDelete={() => {
                            salesStore!.ordersFilter.setAddress('');
                            onFilterRemoved();
                        }} />
                    }
                    
                    {
                        salesStore!.ordersFilter.startDate && salesStore!.ordersFilter.endDate
                        && <Chip className="chip" label="Date Range" onDelete={() => {
                            salesStore!.ordersFilter.setStartDate(null);
                            salesStore!.ordersFilter.setEndDate(null);
                            onFilterRemoved();
                        }} />
                    }
                </div>
            </Grid>
        </Grid>
    }
}

