import * as React from 'react';
import { IQuoteSummary } from '../../models/quotes-summary';
import { Grid } from '@material-ui/core';
import { RouteComponentProps } from 'react-router';
import { observer } from 'mobx-react';
import ThumbDown from '@material-ui/icons/ThumbDown';
import TimerOff from '@material-ui/icons/TimerOff';
import Flag from '@material-ui/icons/Flag';
import { IconTooltip } from '../IconTooltip/IconTooltip';
import { WorkflowStatus } from 'src/models/workflow-status';
import { AttachMoney, MoneyOff, PersonAddDisabled } from '@material-ui/icons';
import { LoadWhenVisible } from '../LoadWhenVisible/LoadWhenVisible';
import { InactiveProductsIcon } from './InactiveProductsIcon';
import { SalesStore } from 'src/stores/sales-store';

interface IQuotesProps {
    quotes: IQuoteSummary[];
    salesStore?: SalesStore;
}

type Props = IQuotesProps & RouteComponentProps<{}>;

@observer
class Quotes extends React.Component<Props> {

    constructor(props: Props) {
        super(props);
    }

    public render() {
        return <div>
            {
                this.props.quotes.map(q => {
                    const date = q.createdDate.toLocaleString({ month: 'short', day: '2-digit' });
                    return <Grid container
                        className={`item ${!q.isActive || q.isQuoteExpired ? 'cancelled' : ''}`}
                        key={q.number}
                        onClick={() => this.props.history.push(`view-order/${q.number}`)
                        }>
                        <Grid item xs={1} />
                        <Grid item xs={7} className={`number ${q.isCustomerInDispute ? 'indispute' : ''}`}>{q.customer}</Grid>
                        <Grid item xs={3} className="icon">
                            {this.getIcon(q)}
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={1} />
                        <Grid item xs={7} className="customer"><span><b>{q.number}:</b> {q.address}</span></Grid>
                        <Grid item xs={3} className="date"> Created {date}</Grid>
                    </Grid>
                })
            }
        </div>
    }

    private getIcon(q: IQuoteSummary): React.ReactElement<{}> {
        return (<React.Fragment>
            {
                !q.isActive
                && <IconTooltip title="Quote cancelled" placement="left">
                    {{ icon: <ThumbDown /> }}
                </IconTooltip>
            }
            {
                q.isQuoteExpired
                && <IconTooltip title="Quote expired" placement="left">
                    {{ icon: <TimerOff /> }}
                </IconTooltip>
            }
            {
                !q.isQuoteExpired && q.isQuoteExpiring
                && <IconTooltip title="Quote expiring" placement="left">
                    {{ icon: <Flag color="error"/> }}
                </IconTooltip>
            }
            {
                q.isCustomerInDispute
                && <IconTooltip title="Customer in Dispute" placement="left">
                    {{ icon: <PersonAddDisabled color="error" /> }}
                </IconTooltip>
            }
            {
                q.pricingApprovalStatus === WorkflowStatus.PendingApproval
                && <IconTooltip title="Custom pricing pending approval" placement="left">
                    {{ icon: <AttachMoney /> }}
                </IconTooltip>
            }
            {
                q.pricingApprovalStatus === WorkflowStatus.Rejected
                && <IconTooltip title="Custom pricing rejected" placement="left">
                    {{ icon: <MoneyOff color="error" /> }}
                </IconTooltip>
            }                            {
                <LoadWhenVisible>
                    <InactiveProductsIcon orderId={q.orderId} salesStore={this.props.salesStore} />
                </LoadWhenVisible>
            }
        </React.Fragment>);
    }
}

export default Quotes;