import './styles.css';

import * as React from 'react';
import { observer, inject } from 'mobx-react';
import { RouteComponentProps } from 'react-router';
import { withStyles, WithStyles } from '@material-ui/core';
import { withAITracking } from '@microsoft/applicationinsights-react-js';

import { SuppliersStore } from '../../stores/suppliers-store';
import { Header } from '../Header/Header';
import ExWorks from './ExWorks';
import { NoRecords } from '../NoRecords/NoRecords';
import tracker from '../Loader/loader-tracker';
import { globalStyles } from 'src/theme';
import { reactPlugin } from '../../utilities/appInsightsConfig';

export interface ISupplierDetailProps extends WithStyles<typeof globalStyles> {
  suppliersStore?: SuppliersStore;
}

interface MatchParams {
  id: string;
}
type Props = ISupplierDetailProps & RouteComponentProps<MatchParams>;

@inject('suppliersStore')
@observer
class SupplierDetail extends React.Component<Props> {
  private suppliersStore?: SuppliersStore;

  constructor(props: Props) {
    super(props);
    this.suppliersStore = props.suppliersStore;
  }

  public async componentDidMount() {
    const id = Number(this.props.match.params.id);
    await tracker.track(this.suppliersStore!.load(id));
  }
  public render() {
    return (
      <React.Fragment>
        <Header title={this.suppliersStore!.supplier!.name} />
        <section className="supplier">
          <ExWorks exWorkses={this.suppliersStore!.supplier!.exWorkses} {...this.props} />
          <NoRecords active />
        </section>
      </React.Fragment>
    );
  }
}

export default withStyles(globalStyles)(withAITracking(reactPlugin, SupplierDetail, 'SupplierDetail'));
