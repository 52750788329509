import { observable, action } from 'mobx';
import { Model } from './model';
import { Markup } from './markup';
import { IRangeDetail } from '../services/products-api';
import { Validate } from 'src/validate';

export enum ShippingType {
    Neither = 0,
    Pallets = 1,
    Packs = 2,
}

export class Range extends Model {
    @observable public id: number;
    @observable public markups: Markup[] = [];
    @observable public name: string = '';
    @observable public code: string = '';
    @observable public shippingType: ShippingType = ShippingType.Neither;

    @action 
    public static fromResponse(r: IRangeDetail): Range {
        const range = new Range();
        range.id = r.id;
        range.markups = r.markups.map(m => Markup.fromResponse(m));
        range.name = r.name || '';
        range.code = r.code || '';
        range.shippingType = r.shippingType;
        return range;
    }

    @action
    public validate(): boolean {
        return super.validate() && this.validateMarkups();
    }

    private validateMarkups(): boolean {
        return this.markups.every(m => m.validate());
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "name": (val: string) => {
            this.errors.name = Validate.hasValue(val)
                ? null : "Range name required";
            return this.errors.name === null;
        }
    }
 }