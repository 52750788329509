import { observable, runInAction } from 'mobx';
import { Model } from './model';
import { PaymentType } from './order';
import { Validate } from '../validate';
import { IPaymentLineResponse } from '../services/sales-api';

export class PaymentLine extends Model {

    @observable public id: number;
    @observable public type: PaymentType;
    @observable public amount: number;
    @observable public receivedDate: string;
    @observable public paymentLocation: string;
    @observable public paymentLocationId: number;
    @observable public lifetimeStatus: PaymentLifetimeStatus;

    constructor(id: number, type: PaymentType, amount: number, receivedDate: string, paymentLocation: string, paymentLocationId: number, lifetimeStatus: PaymentLifetimeStatus = PaymentLifetimeStatus.None) {
        super();
        runInAction(() => {
            this.id = id;
            this.type = type;
            this.amount = amount;
            this.receivedDate = receivedDate;
            this.paymentLocation = paymentLocation;
            this.paymentLocationId = paymentLocationId;
            this.lifetimeStatus = lifetimeStatus;
        })
    }

    protected validators: { [name: string]: (val: any) => boolean } = {
        "amount": (val: number) => {
            this.errors.amount = Validate.decimal(val) && val > 0
                ? null : "Must be more than 0";
            return this.errors.amount === null;
        }
    }

    public clone(): PaymentLine {
        return new PaymentLine(this.id, this.type, this.amount, this.receivedDate, this.paymentLocation, this.paymentLocationId, this.lifetimeStatus);
    }

    public static fromResponse(p: IPaymentLineResponse): PaymentLine {
        return new PaymentLine(p.id, p.type, p.amount, p.receivedDate, p.paymentLocation, p.paymentLocationId, p.lifeTimeStatus);
    }
}

export enum PaymentLifetimeStatus {
    None = 0,
    PaymentSent = 1,
    PaymentError = 2,
    Complete = 3
}