import React from 'react';
import { Grid } from '@material-ui/core';
import './styles.css';

interface INoRecordsProps {
  active: boolean;
  message?: string;
}

export const NoRecords: React.FC<INoRecordsProps> = ({ active, message }) => {
  return (
    <React.Fragment>
      {active && (
        <Grid container className="no-records" alignContent={'center'} alignItems={'center'}>
          <Grid item xs={1} />
          <Grid item xs={10} className="message">
            {message ?? 'No more records'}
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};
